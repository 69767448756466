import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageTagsPage } from "../manage-tags/manage-tags.page";
@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.scss"]
})
export class TagsComponent implements OnInit {
  public getAllTags: any;
  public items: any;
  public selectedTag: any = "";
  public manageSelectedTag: any = "";
  public id: any;
  public name: any;
  public moq: any;
  public type: any;
  public listOfValue: any;
  public tagData: any;
  public arrowKeyLocation = -1;
  public flgBtn: any;
  public assignProduct: any;
  public itemsValue: any;
  public arrowKeyLocationValue = -1;
  public arrowKeyLocationCustomer = -1;
  public assignCustomer: any;
  public listOfCustomers: any;
  constructor(public toastCtrl: ToastController, public modalCtrl: ModalController, public storage: Storage, public httpApiService: HttpApiService, private authService: AuthenticationService) { }

  ngOnInit() {
    this.getTags();
  }
  async getTags() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllTags(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllTags = res.json();
      this.items = this.getAllTags;
    }
    // this.items = [{ id: 1, name: "test1", type: "description" }, { id: 2, name: "test2", type: "description" }, { id: 3, name: "test3", type: "description" }];
  }
  setItems() {
    this.items = this.getAllTags;
  }

  async manageTags(flagId) {
    // flagId = 0 => add new Product Variant
    // flagId = 1 => add new Tags
    // flagId = 2 => add new customer
    let obj;
    if (flagId == 0) {
      obj = { flag: flagId, id: this.id, data: this.listOfValue };
    } else if (flagId == 2) {
      obj = { flag: flagId, id: this.id, data: this.listOfCustomers };
    } else {
      obj = { flag: flagId, id: this.id, data: this.listOfValue };
    }
    let modalPage = await this.modalCtrl.create({ component: ManageTagsPage, componentProps: obj });
    modalPage.onDidDismiss().then(data => {
      console.log("data", this.selectedTag);
      this.getTagDetail(this.manageSelectedTag);
      // if (!!data.data) {
      //   this.processBy = data.data;
      //   // this.updateBlock(block);
      // }
    });
    await modalPage.present();
  }

  getTagDetail(name) {
    this.searchTagDetail(name);
  }
  async getAllProduct(id) {
    this.assignProduct = [];
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getAllProductsByTagId(refCompanyId, id);
    await this.httpApiService.hideLoading();
    console.log("res", res.json());
    this.assignProduct = res.json().data;
    console.log("assignProduct", this.assignProduct);
    this.setValues();
  }

  async getAllCustomers(id) {
    this.assignCustomer = [];
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getAllCustomersByTagId(refCompanyId, id);
    await this.httpApiService.hideLoading();
    console.log("res", res.json());
    this.assignCustomer = res.json().data;
    console.log("assignCustomer", this.assignCustomer);
    this.setCustomers();
  }

  async searchTagDetail(name) {
    let tmpName = "";
    this.items = this.getAllTags;
    if (name != undefined) {
      tmpName = name;

      this.manageSelectedTag = name;
      this.selectedTag = name;
    } else {
      this.selectedTag = this.manageSelectedTag;
      tmpName = this.manageSelectedTag;
    }

    let selectedTagId = this.items.filter(function (search) {
      if (search.name == tmpName) {
        return search.id;
      }
    });
    if (selectedTagId.length > 0) {
      this.id = selectedTagId[0].id;
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getTagById(this.id, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log("data", data);
        this.name = data.data.name;
        this.type = data.data.type;
        this.moq = data.data.moq;
        console.log("data.data.id", data.data.id);
        if (this.type == "Product") {
          await this.getAllProduct(data.data.id);
        } else if (this.type == "Customer") {
          await this.getAllCustomers(data.data.id);
        }
        // if (data.listOfParameter && data.listOfParameter.length > 0) {
        //   this.listOfValue = data.listOfParameter;
        //   this.tagData = this.listOfValue;
        // } else {
        //   this.listOfValue = [];
        // }
        let index = this.items.findIndex(x => x.name == selectedTagId.name);
        this.arrowKeyLocation = index;
        console.log(this.listOfValue);
      }
    } else {
      this.name = "";
      this.selectedTag = "";
    }
  }
  async validateControls() {
    if (this.name != "" && this.type != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({ message: "Please insert the detail", duration: 2000, position: "top", color: "danger" });
      await toast.present();
      return true;
    }
  }

  async showButton(name) {
    if (name == "type") {
      this.flgBtn = "type";
    } else if (name == "name") {
      this.flgBtn = "name";
    } else if (name == "moq") {
      this.flgBtn = "moq";
    }
  }
  async updateTag() {
    let validate = await this.validateControls();
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj: any = { name: this.name, id: refCompanyId, type: this.type, tagId: this.id, moq: 1 };

      if (!!this.moq) {
        jsonObj = { name: this.name, id: refCompanyId, type: this.type, tagId: this.id, moq: parseInt(this.moq) };
      }

      // console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateTags(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: userData.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          this.flgBtn = "";
          await this.getTags();
          await this.searchTagDetail(this.name);
          let toast = await this.toastCtrl.create({ message: "Tag updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }
  setValues() {
    this.listOfValue = this.assignProduct;
  }

  setCustomers() {
    this.listOfCustomers = this.assignCustomer;
  }

  async filterValues(ev: any) {
    this.setValues();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfValue = await this.listOfValue.filter(function (item) {
        return item.title.toLowerCase().includes(val.toLowerCase());
      });
      console.log(this.listOfValue);
      this.arrowKeyLocationValue = -1;
    } else {
      this.listOfValue = this.assignProduct;
      this.arrowKeyLocationValue = -1;
    }
  }
  keyDownValues(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.itemsValue && this.itemsValue.length > 0) {
          if (this.arrowKeyLocationValue != 0) {
            this.arrowKeyLocationValue--;
            // this.manageSelectedProduct = this.items[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.itemsValue && this.itemsValue.length > 0) {
          if (this.listOfValue.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocationValue++;
          }
          if (this.itemsValue.length > this.arrowKeyLocation) {
            // this.manageSelectedProduct = this.items[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
        if (this.listOfValue.length > this.arrowKeyLocationValue) {
          if (this.listOfValue.length != this.arrowKeyLocationValue + 1) {
            this.arrowKeyLocationValue++;
          }
          break;
        } else {
          break;
        }
    }
    console.log(this.arrowKeyLocationValue);
  }
  async filterCustomers(ev: any) {
    this.setValues();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfCustomers = await this.listOfCustomers.filter(function (item) {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
      console.log(this.listOfCustomers);
      this.arrowKeyLocationCustomer = -1;
    } else {
      this.listOfCustomers = this.assignCustomer;
      this.arrowKeyLocationCustomer = -1;
    }
  }
  keyDownCustomers(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.itemsValue && this.itemsValue.length > 0) {
          if (this.arrowKeyLocationCustomer != 0) {
            this.arrowKeyLocationCustomer--;
            // this.manageSelectedProduct = this.items[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.itemsValue && this.itemsValue.length > 0) {
          if (this.listOfCustomers.length != this.arrowKeyLocationCustomer + 1) {
            this.arrowKeyLocationCustomer++;
          }
          if (this.itemsValue.length > this.arrowKeyLocationCustomer) {
            // this.manageSelectedProduct = this.items[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
        if (this.listOfCustomers.length > this.arrowKeyLocationCustomer) {
          if (this.listOfCustomers.length != this.arrowKeyLocationCustomer + 1) {
            this.arrowKeyLocationCustomer++;
          }
          break;
        } else {
          break;
        }
    }
    console.log(this.arrowKeyLocationCustomer);
  }
  async deleteTags(row) {
    console.log("deleteTags", row);
    let refCompanyId = await this.storage.get("refCompanyId");
    // console.log(jsonObj);
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteProductTagsForProduct(row.PID, refCompanyId);
    await this.httpApiService.hideLoading();
    // delete Tags by ID
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let userData = res.json();
      if (userData.error) {
        if (userData.error == "invalid_token") {
          this.modalCtrl.dismiss();
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({ message: userData.error, duration: 3000, position: "top", color: "danger" });
          await toast.present();
        }
      } else {
        this.flgBtn = "";
        await this.getTags();
        await this.searchTagDetail(this.name);
        let toast = await this.toastCtrl.create({ message: "Product tag delete successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async removeTag(removeTag) {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.deleteTags(removeTag, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let data = res.json();
      if (!data.isSuccess) {
        let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
        await toast.present();
      } else {
        await this.getTags();
        await this.searchTagDetail(this.name);
        this.manageSelectedTag = '';
        let toast = await this.toastCtrl.create({ message: "tag deleted successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }
  async deleteCustomerTags(row) {
    console.log("deleteCustomerTags", row);
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteCustomerTagsForCustomer(row.CID, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let userData = res.json();
      if (userData.error) {
        if (userData.error == "invalid_token") {
          this.modalCtrl.dismiss();
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({ message: userData.error, duration: 3000, position: "top", color: "danger" });
          await toast.present();
        }
      } else {
        this.flgBtn = "";
        await this.getTags();
        await this.searchTagDetail(this.name);
        let toast = await this.toastCtrl.create({ message: "Customer tag deleted successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }
}
