import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "timeAgo"
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (moment(value).isAfter(moment().subtract(3, "hours"))) {
      return moment(value).fromNow();
    } else if (args == 0) {
      return moment(value)
        .utcOffset(0)
        .format("DD-MMM hh:mm a");
    } else if (args == 1) {
      if (moment(value).isAfter(moment().subtract(3, "hours"))) {
        return moment
          .utc(value)
          .local()
          .fromNow();
      } else {
        return moment
          .utc(value)
          .local()
          .format("DD-MMM hh:mm a");
      }
    } else {
      return moment(value).format("DD-MMM hh:mm a");
    }
  }
}
