import { Component, Input, ViewChild } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams, InfiniteScroll } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageUserPage } from "../manage-user/manage-user.page";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"]
})
export class UserComponent implements ProcessActionComponent {
  @ViewChild(InfiniteScroll) infiniteScroll: InfiniteScroll;
  @Input() data: any;
  @Input() cid: any;
  @Input() deletedUsers: any;

  public getAllUserData: any;
  public manageSelectedUser: "";
  public selectedUser: "";
  public items: any;
  public id: any;
  public arrowKeyLocation = -1;
  public fullName = "";
  public mobileNumber = "";
  public email: "";
  public companyName = "";
  public listOfRoles: any = [];
  public listOfArea: any = [];
  public flgBtn = "";
  public appName = "";
  public userData: any;
  public username = "";
  public getDesignationList: any = [];
  public getDepartmentList: any = [];

  // public parentNo: any;
  public designation = "";
  public department = "";
  public oldUsername = "";
  public biometricId = "";
  public tempUserName = "";
  public listOfActions: any;
  public deletionAllow: any;
  public isDeleted = "";
  public image = "";
  public offset = 0;
  public limit = 20;
  public refCompanyId = false;
  public userType = "";
  public searchUsers: string;
  public isInfinateScroll = true;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.getCompanyId();

    this.selectedUser = "";
    this.manageSelectedUser = "";
    // this.getAllUser(null);
    // await this.getAllDesignation(null);
    // await this.getAllDepartment(null);
    this.getAppName();
    this.deletionAllow = false;
    await this.fetchUsersByPagination("");
  }
  async loadData(event) {
    this.offset++;
    await this.fetchUsersByPushRecord(this.searchUsers || '');
    event.target.complete();
    console.log('Done');
 
  }
 
  async getAllDesignation(desc) {
    let res: any;
    res = await this.httpApiService.getAllDesignation(this.refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getDesignationList = res.json().data;
      let selectedDesignation = await this.getDesignationList.filter(function (designation) {
        if (designation.id == desc) {
          return designation.id;
        }
      });
      if (selectedDesignation.length > 0) {
        this.designation = await selectedDesignation[0].shortName;
      } else {
        this.designation = "";
      }
      console.log(this.designation);
    }
  }

  async getAllDepartment(desc) {
    console.log("Find Designation");
    let res: any;
    res = await this.httpApiService.getAllDepartment(this.refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getDepartmentList = res.json().data;
      if (this.getDepartmentList.length > 0) {
        let selectedDepartment = await this.getDepartmentList.filter(function (department) {
          if (department.id == desc) {
            return department.id;
          }
        });
        if (selectedDepartment.length > 0) {
          this.department = await selectedDepartment[0].id;
        } else {
          this.department = "";
        }
        console.log(this.department);
      }
    }
  }

  getAppName() {
    this.appName = this.ConfigService.getAppName();
    console.log(this.appName);
  }

  async getCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
    console.log(this.refCompanyId);
  }

  setItems() {
    this.items = this.getAllUserData;
  }

  async getUserDetail(username) {
    this.offset = 0;
    this.isInfinateScroll = true;
    console.log(username);

    this.searchUsers = username;
    await this.searchUser(username);
  }

  async searchUser(user) {
    console.log(user, this.manageSelectedUser, this.getAllUserData, this.tempUserName);
    let tmpUserName = "";
    this.isDeleted = "";
    this.companyName = "";
    this.deletionAllow = false;
    this.items = this.getAllUserData;
    if (user != undefined && user != null && user != "") {
      tmpUserName = user.username;
      this.manageSelectedUser = user.name;
      this.tempUserName = tmpUserName;
      this.selectedUser = user.userName;
    } else {
      this.selectedUser = this.manageSelectedUser;
      tmpUserName = this.tempUserName;
    }
    let selectedActionId = this.items.filter(function (search) {
      if (search.username == tmpUserName) {
        return search.id;
      }
    });
    console.log(selectedActionId);
    if (selectedActionId.length > 0) {
      let id = selectedActionId[0].id;
      console.log(id);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getAllUsersDetailsById(id, this.refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        this.userData = res.json().data;
        console.log(this.userData);
        this.selectedUser = this.userData[0].username;
        this.email = this.userData[0].email;
        this.id = this.userData[0].id;
        this.username = this.userData[0].username;
        this.oldUsername = this.username;
        this.fullName = this.userData[0].fullName;
        this.biometricId = this.userData[0].biometricId;
        let desc = this.userData[0].refDesignationId;
        this.isDeleted = this.userData[0].isDeleted;
        this.image = this.userData[0].image;
        this.userType = this.userData[0].userType;
        if (this.userData[0].customerDetails.length > 0) {
          this.companyName = this.userData[0].customerDetails[0].companyName;
          console.log("this.companyName", this.companyName, this.userType);
        }
        await this.httpApiService.showLoading();
        await Promise.all([this.getAllActions(), this.getAllDesignation(desc), this.getAllDepartment(this.userData[0].refDepartmentId)]);
        await this.httpApiService.hideLoading();
        if (this.listOfActions.length > 0) {
          let action = this.listOfActions.filter(function (action) {
            if (action.actionName == "Users") {
              return action;
            }
          });
          if (action.length > 0) {
            console.log("action", action);
            if (action[0].listOfActionPermissions.length > 0) {
              let permission = action[0].listOfActionPermissions.filter(function (permission) {
                if (permission.permissionName == "Delete" || permission.permissionName == "DELETE") {
                  return permission;
                }
              });
              if (permission.length > 0) {
                this.deletionAllow = true;
              }
            }
          }
        }
        let index = this.items.findIndex(x => x.name == selectedActionId[0].name);
        this.arrowKeyLocation = index;
        let pNo = this.userData[0].refParentId;
        if (this.userData[0].listOfRoles && this.userData[0].listOfRoles.length > 0) {
          this.listOfRoles = this.userData[0].listOfRoles;
        } else {
          this.listOfRoles = [];
        }
        if (this.userData[0].listOfArea && this.userData[0].listOfArea.length > 0) {
          this.listOfArea = this.userData[0].listOfArea;
        } else {
          this.listOfArea = [];
        }

        // await this.getAllUser(pNo);
        console.log(this.userData);
      }
    } else {
      this.tempUserName = "";
      this.selectedUser = "";
      this.username = "";
      this.email = "";
      this.fullName = "";
      this.designation = "";
      this.department = "";
      // this.parentNo = null;
      this.listOfRoles = [];
      this.biometricId = "";
    }
  }

  async getAllUser(pNo) {
    let res: any;
    await this.httpApiService.showLoading();
    if (this.deletedUsers == "true") {
      res = await this.httpApiService.getAllDeletedUsers(this.refCompanyId);
    } else {
      res = await this.httpApiService.getAllUser(this.refCompanyId);
    }
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllUserData = res.json().data;
      this.items = this.getAllUserData;
      console.log(this.items);
      // if (pNo != undefined && pNo != "" && pNo != null) {
      //   let phone = this.getAllUserData.filter(function(record) {
      //     if (record.id == pNo) {
      //       return record.id;
      //     }
      //   });
      //   let selectedParent;
      //   if (phone.length > 0) {
      //     let userName = phone[0].username;
      //     selectedParent = this.getAllUserData.filter(function(parentNo) {
      //       if (parentNo.username == userName) {
      //         return parentNo.id;
      //       }
      //     });
      //     if (selectedParent.length == 0) {
      //       this.parentNo = -1;
      //     } else {
      //       this.parentNo = await selectedParent[0].username;
      //     }
      //   } else {
      //     this.parentNo = -1;
      //   }
      //   console.log(this.parentNo, selectedParent);
      // }
    }
  }

  async fetchUsersByPagination(val) {
    let res;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.fetchUsersByPagination(this.offset, this.limit, val, refCompanyId);
    console.log("res", res);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllUserData = res.json().data;
      this.items = this.getAllUserData;
      console.log("get all users data", this.getAllUserData);
      // this.tempUserName = "";
      this.arrowKeyLocation = -1;
    }
  }

  async fetchUsersByPushRecord(val) {
    let res;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.fetchUsersByPagination(this.offset, this.limit, val, refCompanyId);
    console.log("res", res);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllUserData = res.json().data;
      this.getAllUserData.forEach(res => { this.items.push(res); return res });
      console.log("get all users data", this.getAllUserData);
      // this.tempUserName = "";
      this.arrowKeyLocation = -1;

      this.isInfinateScroll = this.getAllUserData.length == this.limit;
    }

  }

  async filterItems(ev: any) {
    this.selectedUser = "";
    this.offset = 0;
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.searchUsers = val;
      console.log("filter", this.items, val);
      let res;
      let refCompanyId = await this.storage.get("refCompanyId");
      // await this.httpApiService.showLoading();
      if (this.deletedUsers == "true") {
        console.log(this.deletedUsers);
        res = await this.httpApiService.getDeletedUsersByPagination(this.offset, this.limit, val, refCompanyId);
        console.log("res", res);
        if (res.status == 401) {
          this.authService.logout();
        } else {
          this.getAllUserData = res.json().data;
          this.items = this.getAllUserData;
          console.log("get all users data", this.getAllUserData);
          // this.tempUserName = "";
          this.arrowKeyLocation = -1;
        }
      } else {
        await this.fetchUsersByPagination(val);
        // res = await this.httpApiService.fetchUsersByPagination(this.offset, this.limit, val, refCompanyId);
      }
      // await this.httpApiService.hideLoading();

    } else {
      this.searchUsers = "";
      await this.fetchUsersByPagination(val);
      this.tempUserName = "";
      this.arrowKeyLocation = -1;
      this.items = this.getAllUserData;
    }
  }

  //open model for add user's role

  async addRoleForUser(id, flgModal) {
    console.log(flgModal);
    let obj;
    if (flgModal == "addWorkArea") {
      obj = {
        id: id,
        username: this.manageSelectedUser,
        flgModal: flgModal,
        data: this.listOfArea
      };
    } else {
      obj = {
        id: id,
        username: this.manageSelectedUser,
        flgModal: flgModal
      };
    }
    let modalPage = await this.modalCtrl.create({
      component: ManageUserPage,
      componentProps: obj
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d, this.selectedUser, this.manageSelectedUser);
    if (this.manageSelectedUser != "" && d.data != "addUser") {
      await this.searchUser(null);
    } else {
      this.getAllUser(null);
    }
    // this.getAllUser(null);
  }
  //delete user role

  async deleteUserRole(id) {
    console.log(id);
    let res: any;
    let jsonObj = {
      userId: this.id,
      roleId: id
    };
    console.log(jsonObj);
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteUserRole(jsonObj, this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp: any;
      resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 2000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      } else {
        this.searchUser(null);
        let toast = await this.toastCtrl.create({
          message: "User role deleted successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }

  //update

  async onChange(name) {
    this.showButton(name);
  }

  async showButton(name) {
    if (name == "userName") {
      this.flgBtn = "userName";
    } else if (name == "email") {
      this.flgBtn = "email";
    } else if (name == "designation") {
      this.flgBtn = "designation";
    } else if (name == "department") {
      this.flgBtn = "department";
    } else if (name == "biometricId") {
      this.flgBtn = "biometricId";
    } else {
      this.flgBtn = "fullName";
    }
  }

  async updateUser() {
    let validate = await this.validateControls();
    if (!validate) {
      if (this.biometricId == null) {
        this.biometricId = "";
      }
      let jsonObj = {
        userId: this.id,
        username: this.oldUsername,
        username1: this.username,
        email: this.email,
        fullName: this.fullName,
        designation: this.designation,
        biometricId: this.biometricId,
        department: this.department
      };
      console.log(jsonObj);
      let res: any;
      let id = this.id;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateUser(jsonObj, this.refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: userData.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          this.flgBtn = "";
          // await this.getAllUser(null);
          await this.searchUser(null);
          let toast = await this.toastCtrl.create({
            message: "User updated successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
        }
      }
    }
  }

  async deleteWorkAreaFromUser(id) {
    console.log(id);
    let res: any;
    let jsonObj = {
      userId: this.id,
      workAreaId: id
    };
    console.log(jsonObj);
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteWorkAreaFromUser(jsonObj, this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp: any;
      resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 2000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      } else {
        this.searchUser(null);
        let toast = await this.toastCtrl.create({
          message: "User work area deleted successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }

  async validateControls() {
    if (this.username != "" && this.email != "" && this.fullName != "") {
      if (!(await this.ConfigService.isValidEmail(this.email))) {
        let toast = await this.toastCtrl.create({
          message: "Please enter correct email address",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
      let phone = this.username.toString();
      if (phone.length != 12) {
        let toast = await this.toastCtrl.create({
          message: "Please enter 12 digit Phone Number",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please insert the detail",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }

  async getAllActions() {
    let res: any;
    let username = await this.authService.userName();
    res = await this.httpApiService.getAllActionsByUsername(username, this.refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    }
    this.listOfActions = res.json().data;
    console.log("list of actions by http", res, this.listOfActions);
  }

  async deleteOrActivate() {
    let res: any;
    let deleteOrActivate;
    if (parseInt(this.isDeleted) == 0 || this.isDeleted == null) {
      deleteOrActivate = 1;
    } else {
      deleteOrActivate = 0;
    }
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteUser(this.refCompanyId, this.id, deleteOrActivate);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().isSuccess) {
        // await this.getAllUser(null);
        await this.searchUser(null);
        let toast = await this.toastCtrl.create({
          message: "User deleted/activated successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      } else {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      }
    }
    console.log("res", res.json());
  }
}
