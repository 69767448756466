import { Component, OnInit, Input } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-manage-tags",
  templateUrl: "./manage-tags.page.html",
  styleUrls: ["./manage-tags.page.scss"]
})
export class ManageTagsPage implements OnInit {
  public name: any;
  public type: any;
  public moq: any;
  public fetchData: any;
  public fetchId: any;
  public flag: any;
  public allProducts: any;
  public id: any;
  public result: any;
  public selectedProducts: any = [];
  public title: any = "Add Tags";
  public allCustomers: any;
  public selectedCustomers: any = [];

  constructor(public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public storage: Storage, public authService: AuthenticationService, public toastCtrl: ToastController, public events: Events) {
    this.events.subscribe("selected-values", data => {
      // this.areaIdsList = data;
      if (data.length > 0 && this.flag == 0) {
        data.forEach(element => {
          this.selectedProducts.push(element);
        });
        console.log("this.selectedProducts", this.selectedProducts);
      } else if (data.length > 0 && this.flag == 2) {
        data.forEach(element => {
          this.selectedCustomers.push(element);
        });
        console.log("this.selectedCustomers", this.selectedCustomers);
      }
    });
  }

  ngOnInit() {
    this.flag = this.navParams.get("flag");
    this.id = this.navParams.get("id");
    this.fetchData = this.navParams.get("data");
    console.log("this.fetchData", this.fetchData);
    if (this.flag == 0) {
      this.title = "Add Product Tag";
      this.getAllProducts();
    } else if (this.flag == 2) {
      this.title = "Add Customer Tag";
      this.getAllCustomers();
    }
  }

  async getAllProducts() {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getAllProducts(refCompanyId);
    await this.httpApiService.hideLoading();
    console.log("res", res.json());
    this.allProducts = res.json().data;
    console.log("allProducts", this.allProducts);
    if (this.fetchData) {
      this.fetchData.forEach(element => {
        this.selectedProducts.push(element);
      });
      console.log("temp products", this.selectedProducts);
    }
  }

  async getAllCustomers() {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.findAllCustomerContact(refCompanyId);
    await this.httpApiService.hideLoading();
    console.log("res", res.json());
    this.allCustomers = res.json().data;
    console.log("allCustomers", this.allCustomers);
    if (this.fetchData) {
      this.fetchData.forEach(element => {
        this.selectedCustomers.push(element);
      });
      console.log("temp customers", this.selectedCustomers);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
  async addTags() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let objData = {
      name: this.name,
      type: this.type,
      id: refCompanyId,
      moq: this.moq
    };
    console.log("objData", objData);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.createTags(objData);
    await this.httpApiService.hideLoading();
    let resp = res.json();
    if (resp.status == 401) {
      this.authService.logout();
    } else {
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.resetForm();
        let toast = await this.toastCtrl.create({
          message: "Tags created successfully",
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
      }
    }
  }
  async updateTags() {}
  async resetForm() {
    this.name = "";
    this.type = "";
    this.moq = "";
  }
  async updateProducts() {
    console.log("selectedProducts", this.selectedProducts);
    let refCompanyId = await this.storage.get("refCompanyId");
    let PIdList = [];
    this.selectedProducts.forEach(product => {
      PIdList.push(product.id);
    });
    // -------------------------
    // let test = [];
    // this.fetchData.forEach(element => {
    //   test.push(element.id);
    // });
    // if (this.fetchData) {
    //   this.selectedProducts = this.selectedProducts.concat(test);
    //   console.log("selectedProducts 2", this.selectedProducts);
    // }
    // -------------------------
    let data = { id: refCompanyId, PIdList: PIdList, tagId: this.id };
    console.log("data", data);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.addProductTags(data);
    await this.httpApiService.hideLoading();
    let resp = res.json();
    if (resp.status == 401) {
      this.authService.logout();
    } else {
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetForm();
        let toast = await this.toastCtrl.create({ message: "Products added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
      this.closeModal();
    }
  }

  async updateCustomers() {
    console.log("selectedCustomers", this.selectedCustomers);
    let refCompanyId = await this.storage.get("refCompanyId");
    let userIdList = [];
    this.selectedCustomers.forEach(customer => {
      userIdList.push(customer.id);
    });
    let data = { id: refCompanyId, userIdList: userIdList, tagId: this.id };
    console.log("data", data);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.addCustomerTags(data);
    await this.httpApiService.hideLoading();
    let resp = res.json();
    if (resp.status == 401) {
      this.authService.logout();
    } else {
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetForm();
        let toast = await this.toastCtrl.create({ message: "Customers added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
      this.closeModal();
    }
  }
}
