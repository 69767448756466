import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { IonicSelectSearchableComponent } from "../../ionic-select-searchable/ionic-select-searchable/ionic-select-searchable.component";

@Component({
  selector: "app-manage-user",
  templateUrl: "./manage-user.page.html",
  styleUrls: ["./manage-user.page.scss"]
})
export class ManageUserPage implements OnInit {
  public allRoles: any = [];
  public roleId: any;
  public id: any;
  public isModal: any;
  public name: any;

  //Add new User
  public username = "";
  public password = "";
  public companyName = "atishae";
  public appName = "";
  public fullName = "";
  public mobileNumber = "";
  public email = "";
  public getAllWorkArea: any;
  public getDesignationList: any = [];
  public getDepartmentList: any = [];
  public getAllUserData: any;
  public designation = "";
  public department = "";
  public biometricId = "";
  public areaIdsList = [];
  public refCompanyId: any;

  constructor(
    private modalCtrl: ModalController,
    public storage: Storage,
    private navParams: NavParams,
    public ConfigService: ConfigService,
    public authService: AuthenticationService,
    private toastCtrl: ToastController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public events: Events
  ) {
    this.events.subscribe("selected-values", data => {
      this.areaIdsList = [];
      if (data.length > 0) {
        data.forEach(element => {
          this.areaIdsList.push(element);
        });
      }
      console.log("this.areaIdsList", this.areaIdsList);
    });
  }
  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async ngOnInit() {
    await this.getRefCompanyId();
    this.username = "";
    this.isModal = this.navParams.get("flgModal");
    if (this.isModal == "addUserRole") {
      await this.getAllRole();
      this.name = this.navParams.get("username");
      this.id = this.navParams.get("id");
    } else if (this.isModal == "addWorkArea") {
      this.id = this.navParams.get("id");
      let selectedArea = this.navParams.get("data");
      selectedArea.forEach(area => {
        this.areaIdsList.push({ id: area.workAreaId, name: area.name });
      });
      console.log("selected area", this.navParams.get("data"));
      // this.areaIdsList = this.navParams.get("data");
      await this.getWorkArea();
    } else {
      await this.getWorkArea();
      await this.getAllDesignation();
      await this.getAllDepartment();
    }
    console.log(this.name);
    // if(window && "location" in window
    // && "protocol" in window.location
    // && "pathname" in window.location
    // && "host" in window.location) {
    //   this.companyName = window.location.hostname;
    //   console.log("company name", this.companyName);
    // }
  }

  async getWorkArea() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllWorkArea(this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().isSuccess) {
        this.getAllWorkArea = res.json().data;
      } else {
        this.getAllWorkArea = [];
      }
      console.log("get All User Data", this.getAllWorkArea);
    }
  }

  async getAllDesignation() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllDesignation(this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getDesignationList = res.json().data;
      console.log("getDesignationList", this.getDesignationList);
    }
  }

  async getAllDepartment() {
    console.log("Find Designation");
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllDepartment(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getDepartmentList = res.json().data;
      console.log(this.getDepartmentList);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss(this.isModal);
  }

  async getAllRole() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllRoles(this.refCompanyId);
    await this.httpApiService.hideLoading();
    let userData = res.json();
    this.allRoles = userData;
    console.log(userData);
  }

  //Create New User

  async createUser() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        username: this.username,
        email: this.email,
        password: this.password,
        designation: this.designation,
        fullName: this.fullName,
        areaIdsList: this.areaIdsList,
        biometricId: this.biometricId,
        department: this.department
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createUser(jsonObj, this.refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        this.allRoles = userData;
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: userData.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({
            message: "User created successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
          this.resetForm();
          // await this.closeModal();
        }
      }
    }
  }

  // Save Role for User

  async saveRoleForUser() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        roleId: this.roleId,
        userId: this.id
      };
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.saveRoleForUser(jsonObj, this.refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          if (resp.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: resp.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({
            message: "Role assigned successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          this.roleId = "";
          await toast.present();
          // await this.closeModal();
        }
      }
    }
  }

  async createWorkArea() {
    let validate = await this.validateControls();
    if (!validate) {
      let areaList = [];
      let chatRoomList = [];
      await this.areaIdsList.forEach(area => {
        areaList.push(area.id);
      });

      await this.areaIdsList.forEach(area => {
        chatRoomList.push(area.chatRoomID);
      });
      let jsonObj = {
        userId: this.id,
        areaIdsList: areaList,
        chatRoomList: chatRoomList
      };
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createWorkAreaForUser(jsonObj, this.refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          if (resp.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: resp.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({
            message: "Work Area added successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          this.roleId = "";
          await toast.present();
          // await this.closeModal();
        }
      }
    }
  }

  async validateControls() {
    if (this.isModal == "addUserRole") {
      if (this.roleId != "" && this.roleId != null && this.roleId != undefined) {
        return false;
      } else {
        let toast = await this.toastCtrl.create({
          message: "Please select/insert all the information",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
    } else if (this.isModal == "addUser") {
      if (this.username != "" && this.password != "" && this.email != "" && this.designation != "" && this.fullName != "" && this.areaIdsList.length > 0) {
        console.log(await this.ConfigService.isValidEmail(this.email));
        if (!(await this.ConfigService.isValidEmail(this.email))) {
          let toast = await this.toastCtrl.create({
            message: "Please enter correct email address",
            duration: 2000,
            position: "top",
            color: "danger"
          });
          await toast.present();
          return true;
        }
        let phone = this.username.toString();
        if (phone.length != 12) {
          let toast = await this.toastCtrl.create({
            message: "Please enter 12 digit Phone Number",
            duration: 2000,
            position: "top",
            color: "danger"
          });
          await toast.present();
          return true;
        }
        return false;
      } else {
        let toast = await this.toastCtrl.create({
          message: "Please select/insert all the information",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
    } else {
      if (this.areaIdsList.length > 0) {
        return false;
      } else {
        let toast = await this.toastCtrl.create({
          message: "Please select/insert the information",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
    }
  }

  resetForm() {
    this.username = "";
    this.password = "";
    this.email = "";
    this.designation = "";
    this.areaIdsList = [];
    this.fullName = "";
    this.biometricId = "";
    this.department = "";
  }
}
