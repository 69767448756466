import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { PrintPagePage } from "../print-page/print-page.page";
import * as moment from "moment";

@Component({
  selector: "app-pending-items",
  templateUrl: "./pending-items.component.html",
  styleUrls: ["./pending-items.component.scss"]
})
export class PendingItemsComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  public fromDate: any;
  public toDate: any;
  public pendingOrders: any;
  public summaryDetail: any;
  public listOfProduct: any;
  public orderNo: any;
  public orderId: any;
  public items: any;
  public arrowKeyLocation = 0;
  public list: any;
  public type: any;
  public offset = 0;
  public limit = 20;
  public search = "";
  public counter;
  public resultColumns: any = [];
  public selectedLevel: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {
    this.summaryDetail = "summary";
    let fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);
    this.fromDate = new Date().getFullYear() + "-" + ("0" + (fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + fromDate.getDate()).slice(-2);
    let toDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 3);
    this.toDate = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + toDate.getDate()).slice(-2);
    this.selectedLevel = "3";
  }

  ngOnInit() { }

  setItems() {
    this.items = this.pendingOrders;
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.search = val;
      this.arrowKeyLocation = 0;
    } else {
      this.search = "";
      this.arrowKeyLocation = 0;
      // this.items = [];
    }
    await this.getPendingOrders();
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.arrowKeyLocation != 0) {
          this.arrowKeyLocation--;
          // this.selectedProcess = this.items[this.arrowKeyLocation].actionName;
          if (this.arrowKeyLocation == 0) {
            this.arrowKeyLocation++;
          }
          break;
        } else {
          break;
        }
      case 40: // this is the ascii of arrow down
        if (this.items.length > this.arrowKeyLocation) {
          // this.selectedProcess = this.items[this.arrowKeyLocation].actionName;
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          break;
        } else {
          break;
        }
    }
    // console.log(this.arrowKeyLocation);
  }

  async getPendingOrders() {
    this.list = null;
    this.items = null;
    this.fromDate = this.fromDate.replace("T", " ").slice(0, 19);
    this.toDate = this.toDate.replace("T", " ").slice(0, 19);
    console.log("get pending orders", this.fromDate, this.toDate);
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getPendingOrdersItemWisePivot(refCompanyId, this.fromDate + " 00:00:00", this.toDate + " 23:59:59", this.offset, this.limit, this.search, this.selectedLevel);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.pendingOrders = res.json().data;
          this.resultColumns = [];
          this.pendingOrders.map(res => {
            if (!!res['Ordered at']) {
              res['Ordered at'] = moment(res['Ordered at']).format("DD-MM-YYYY hh:mm a");
            }
            return res;
          })
          Object.keys(this.pendingOrders[0]).map(res => {
            this.resultColumns.push({ name: res, prop: res });
          });
          this.counter = this.pendingOrders.length;
          this.list = this.pendingOrders;
          this.setItems();
        }
      }
    }
    console.log("this.pendingOrders", this.pendingOrders);
  }

  async showOrder(row) {
    console.log("row", row);
    this.summaryDetail = "Detail";
    this.orderId = row.id;
    this.orderNo = row.name + "-" + row.id;
  }

  async changeViewType(view) {
    this.summaryDetail = view;
  }

  async print() {
    let alert = await this.alertCtrl.create({
      // title: 'Confirm purchase',
      message: "Select print options",
      buttons: [
        {
          text: "Item Wise",
          handler: () => {
            console.log("Item wise clicked");
            this.printAction("Item Wise");
          }
        },
        {
          text: "Order Wise",
          handler: () => {
            console.log("Order Wise clicked");
            this.printAction("Order Wise");
          }
        }
      ]
    });
    alert.present();
  }

  async printAction(type) {
    this.type = type;
    if (this.type == "Item Wise") {
      await this.getPendingOrdersItemWise();
    } else {
      await this.getPendingOrders();
    }
    // this.changeViewType('Print');
    let modalPage = await this.modalCtrl.create({
      component: PrintPagePage,
      componentProps: {
        type: this.type,
        list: this.list
      }
    });
    // modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async getPendingOrdersItemWise() {
    this.list = null;
    this.fromDate = this.fromDate.replace("T", " ").slice(0, 19);
    this.toDate = this.toDate.replace("T", " ").slice(0, 19);
    console.log("get pending orders item wise", this.fromDate, this.toDate);
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getPendingOrdersItemWise(refCompanyId, this.fromDate + " 00:00:00", this.toDate + " 23:59:59", this.offset, this.limit, this.search);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.list = res.json().data;
        }
      }
    }
    console.log("this.pendingOrdersItemWise", this.list);
  }

  async setPage(event) {
    console.log("event", event);
    this.limit = event.limit;
    this.offset = event.offset;
    await this.getPendingOrders();
  }

  async downloadExcel() {

    let tempData = this.items;
    this.JSONToCSVConvertor(tempData, "Locations_" + this.fromDate + " 00:00:00" + this.toDate + " 23:59:59", "report");

  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index.replace(/,/g, "-") + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      for (var index1 in arrData[0]) {
        if (arrData[i][index1]) {
          row += '"' + arrData[i][index1] + '",';
        } else {
          row += ',';
        }
      }
      //2nd loop will extract each column and convert it in string comma-seprated
      // for (var index in arrData[i]) {
      // }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV == "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
