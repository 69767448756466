import { Injectable } from "@angular/core";
import { ConfigService } from "../config/config.service";
import { Headers, Http } from "@angular/http";
import { Storage } from "@ionic/storage";
import { LoadingController } from "@ionic/angular";
const TOKEN_KEY = "auth-token";

@Injectable({
  providedIn: "root"
})
export class HttpApiService {
  baseUrl: string;
  appName: string;
  headers: any;
  loader: any;
  appTitle: string;
  public companyObj: any;
  public selectedImages: any = false;

  constructor(private storage: Storage, public http: Http, public configService: ConfigService, public loadingCtrl: LoadingController) {
    console.log("Hello http api service");
    this.getBaseUrl();
    this.getAppName();
    this.getAppTitle();
    // this.getApiHeader();
  }

  getBaseUrl() {
    this.baseUrl = this.configService.getBaseUrl();
    console.log("base url=", this.baseUrl);
  }

  getAppName() {
    this.appName = this.configService.getAppName();
    console.log("app name", this.appName);
  }

  getAppTitle() {
    this.appTitle = this.configService.getAppTitle();
    console.log("app appTitle", this.appTitle);
  }

  async getApiHeader() {
    this.headers = await this.configService.getAPIHeader();
    console.log("headers received", this.headers);
  }

  async showLoading() {
    console.log("loader start...");
    this.loader = await this.loadingCtrl.create({
      message: "Please wait...",
      spinner: "crescent"
    });
    return await this.loader.present();
  }
  async hideLoading() {
    console.log("loader stop ...");
    return await this.loader.dismiss();
  }

  async getAllActionsByUsername(username, id) {
    console.log(username, id);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "actions/" + id + "/findAllActionsByUsername?username=" + username, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllActionsByUsername", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllActions(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "actions/" + id + "/getAllActions", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllActions", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllActionPermissionById(actionId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "actions/" + id + "/getAllActionDataByActionId?actionId=" + actionId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllActions", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllRoles(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "roleInfos/" + id + "/getAllRoles", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllRoles", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addRole(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "roleInfos/" + id + "/createRole", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addRole", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addRolePermission(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "rolePermissions/" + id + "/createActionPermissionForRole", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response addRolePermission", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateRole(jsonObj, id) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(this.baseUrl + "roleInfos/" + id + "/updateRole", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response updateRole", response);
    return await response;
  }

  async deleteRole(roleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "user-service/api/role/delete/" + roleId, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response deleteRole", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteRolePermission(roleId, actionPermissionId, id) {
    console.log(roleId, actionPermissionId, id);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "rolePermissions/" + id + "/deleteRolePermission?roleId=" + roleId + "&actionPermissionId=" + actionPermissionId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRolePermission", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async findRoleDetailsById(roleId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "roleInfos/" + id + "/findAllRoleDataById?roleId=" + roleId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response findRoleById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  //Start Manage-User

  async getAllUser(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "users/" + id + "/getAllUsers", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllUser", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllUsersDetailsById(userId, id) {
    console.log(userId, id);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "users/" + id + "/getAllUsersDetailsById?userId=" + userId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllUsersDetailsById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createUser(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "users/" + id + "/createUser", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response createUser", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteUserRole(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "userRoles/" + id + "/deleteUserRole?userId=" + jsonObj.userId + "&roleId=" + jsonObj.roleId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteUserRole ", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async saveRoleForUser(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "userRoles/" + id + "/createUserRole", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response saveRoleForUser", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async changePassword(jsonObj) {
    try {
      this.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jsonObj.resetPasswordToken
      };
      // this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "users/reset-password", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response changePassword", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async forgotPassword(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "users/reset", jsonObj).toPromise();
      console.log("response forgotPassword", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateUser(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "users/" + id + "/updateUser", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateUser", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async findFormDetailsByNameAndPhoneNo(formName, phoneNo, cid) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "formSubmissions/" + cid + "/findFormDetailsByNameAndPhoneNo?formName=" + formName + "&phoneNo=" + phoneNo, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getFormDetailsByFormName", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getUserPhNoByUserId(userId, cid) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "users/" + cid + "/findMobNumByUserId?userId=" + userId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getUserPhNoByUserId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getProcess(processId, refCompanyId, phoneNumber) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "processTemplates/" + refCompanyId + "/getProcess?processId=" + processId + "&phoneNumber=" + phoneNumber, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getProcess", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addReplyToTicketTalkBrite(jsonObj, cid) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "runningProcessMessages/" + parseInt(cid) + "/createMessage", jsonObj).toPromise();
      console.log("response addReplyToTicketTalkBrite", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getProcessTimeline(runningProcessId, refCompanyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "formSubmissions/" + refCompanyId + "/findProcessTimelineByRunningProcessId?runningProcessId=" + runningProcessId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getProcessTimeline", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async changeProcessStatus(jsonObj, cid) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "runningProcesses/" + parseInt(cid) + "/changeStatus", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response changeProcessStatus", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  //work area start

  async getAllWorkArea(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "workAreas/" + id + "/getAllWorkArea").toPromise();
      console.log("response getAllWorkArea", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addWorkArea(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "workAreas/" + id + "/addWorkArea", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addWorkArea", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createWorkArea(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "workAreas/" + id + "/createWorkArea", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response createWorkArea", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createWorkAreaForUser(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "workAreaMappings/" + id + "/createWorkAreaForUser", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createWorkAreaForUser", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createWorkAreaForCustomer(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "customerWorkAreas/" + id + "/createWorkAreaForCustomer", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createWorkAreaForCustomer", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async changeUserCompany(id, userId, companyId, oldCompanyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "shop_customerContacts/" + id + "/updateCustomerCompany",
          {
            userId: userId,
            companyId: companyId,
            oldCompanyId: oldCompanyId
          },
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      console.log("response createWorkAreaForCustomer", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteWorkAreaFromUser(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "workAreaMappings/" + id + "/deleteWorkAreaFromUser?userId=" + jsonObj.userId + "&workAreaId=" + jsonObj.workAreaId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRole", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async deleteWorkAreaFromCustomer(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "customerWorkAreas/" + id + "/deleteWorkAreaFromCustomer?customerId=" + jsonObj.customerId + "&workAreaId=" + jsonObj.workAreaId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRole", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async updateWorkArea(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "workAreas/" + id + "/updateWorkArea", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateWorkArea", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteWorkArea(workAreaId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "workAreas/" + id + "/deleteWorkArea?workAreaId=" + workAreaId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRole", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllDesignation(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "designations/" + id + "/getAllDesignation").toPromise();
      console.log("response getAllDesignation", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllOrderBooks(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_orderBooks/" + id + "/getAllOrderBooks").toPromise();
      console.log("response getAllOrderBooks", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllPromotionalProducts(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_promotional_products/" + id + "/getAllProducts").toPromise();
      console.log("response getAllPromotionalProducts", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllPaymentMethod(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_payment_methods/" + id + "/getAllMethods").toPromise();
      console.log("response getAllPromotionalProducts", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllShippingMethods(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_shipping_methods/" + id + "/getAllMethods").toPromise();
      console.log("response getAllPromotionalProducts", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllDepartment(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "departments/" + id + "/getAllDepartment").toPromise();
      console.log("response getAllDepartment", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async copyProcess(id, processName, companyId, processId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "processTemplates/" + id + "/copyProcess",
          {
            processName: processName,
            companyId: companyId,
            processId: processId
          },
          {
            headers: new Headers(this.headers)
          }
        )
        .toPromise();
      console.log("response createCompany", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllProcessTemplates(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "processTemplates/" + id + "/getAllProcessTemplates", { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllProcessTemplates", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllProcessTemplatesStates(processTemplateId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "processTemplateStates/" + id + "/getAllStatesByProcessTemplateId?processTemplateId=" + processTemplateId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllProcessTemplatesStates", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllProcessTemplateConfig(processTemplateId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "processTemplateConfigs/" + id + "/findConfig?refProcessTemplateId=" + processTemplateId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllProcessTemplatesStates", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getStateDescription(processTemplateId, processTemplateStateId, refFormTemplateId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "processTemplateStates/" + id + "/getStateDescription?processTemplateId=" + processTemplateId + "&processTemplateStateId=" + processTemplateStateId + "&refFormTemplateId=" + refFormTemplateId, { headers: new Headers(this.headers) })
        .toPromise();
      console.log("response getStateDescription", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllCompany() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "companies/getAllCompany", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllCompany", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getCompanyDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "companies/" + id + "/getCompanyDetailByCompanyId", { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCompanyDetailById", response);
      this.companyObj = response.json();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getCompanyBasicDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "companies/" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getCompanyDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateCompany(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "companies/" + id + "/updateCompany", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateCompany", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createCompany(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "companies/" + id + "/createCompany", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response createCompany", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async findAllFormTemplates(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "formTemplates/" + id + "/findAllTemplates", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response findAllTemplates", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async updateTemplateState(id, jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "processTemplateStates/" + id + "/updateTemplateState", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response updateTemplateState", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createProcessTemplate(jsonObj, id) {
    console.log(jsonObj, id);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "processTemplates/" + id + "/createProcessTemplate", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createProcessTemplate", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async createProcessTemplateStates(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "processTemplateStates/" + id + "/createProcessTemplateStates", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createProcessTemplateStates", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async exportProcessTemplate(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "processTemplates/" + id + "/exportProcessTemplate", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createProcessTemplate", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async importProcessTemplate(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "processTemplates/" + id + "/importProcessTemplate", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response importProcessTemplate", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateArea(jsonObj, id) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(this.baseUrl + "companies/" + id + "/attendanceConfig", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response attendanceConfig", response);
    return await response;
  }
  async getDesignationDetail(DesignationId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "designations/" + DesignationId + "/available?companyId=" + id).toPromise();
      console.log("response getDesignationDetail", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getOrderBookDetail(dId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_orderBooks/" + dId + "/available?companyId=" + id).toPromise();
      console.log("response getOrderBookDetail", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getPromotionalId(PromotionId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_promotional_products/" + id + "/getProduct?productId=" + PromotionId).toPromise();
      console.log("response getPromotionalId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getSchemesId(PromotionId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_schemes/" + id + "/getScheme?schemeId=" + PromotionId).toPromise();
      console.log("response getPromotionalId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getPaymentMethodId(MethodId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_payment_methods/" + id + "/getShopPaymentMethods?shopPaymentMethodId=" + MethodId).toPromise();
      console.log("response getPromotionalId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getShippingMethodId(MethodId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_shipping_methods/" + id + "/getShippingMethods?shippingMethodId=" + MethodId).toPromise();
      console.log("response getPromotionalId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addPromotionalProduct(jsonObj, id) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .post(
          this.baseUrl +
          "shop_promotional_products/" +
          id +
          "/createProduct?" +
          Object.keys(jsonObj)
            .map(function (key) {
              return key + "=" + jsonObj[key];
            })
            .join("&"),
          jsonObj.formData,
          { headers: new Headers(headers) }
        )
        .toPromise();
      console.log("response addPromotionalProduct", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addSchemes(jsonObj, id) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .post(
          this.baseUrl +
          "shop_schemes/" +
          id +
          "/createScheme?" +
          Object.keys(jsonObj)
            .map(function (key) {
              return key + "=" + jsonObj[key];
            })
            .join("&"),
          jsonObj.formData,
          { headers: new Headers(headers) }
        )
        .toPromise();
      console.log("response addSchemes", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addAddOn(jsonObj, id) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .post(
          this.baseUrl +
          "shop_addOns/" +
          id +
          "/createAddOn?" +
          Object.keys(jsonObj)
            .map(function (key) {
              return key + "=" + jsonObj[key];
            })
            .join("&"),
          jsonObj.formData,
          { headers: new Headers(headers) }
        )
        .toPromise();
      console.log("response createAddOn", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addPaymentMethod(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_payment_methods/" + id + "/createShopPaymentMethods", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response Add addPromotionalProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addShippingMethod(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_shipping_methods/" + id + "/createShippingMethods", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response Add addPromotionalProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addDesignation(jsonObj, id) {
    console.log(jsonObj, id);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "designations/" + id + "/createAfter", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response Add Designation", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addOrderBook(jsonObj, id) {
    console.log(jsonObj, id);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_orderBooks/" + id + "/AddOrderBook", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addOrderBook", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async updateDesignation(jsonObj, did) {
    console.log(jsonObj);
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "designations/" + did + "/updateDesignation", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updateDesignation", response);
    return await response;
  }

  async updateOrderBook(jsonObj, did) {
    console.log(jsonObj);
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "shop_orderBooks/" + did + "/updateOrderBook", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updateOrderBook", response);
    return await response;
  }

  async updatePromotion(jsonObj, did) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .post(
          this.baseUrl +
          "shop_promotional_products/" +
          did +
          "/updateProduct?" +
          Object.keys(jsonObj)
            .map(function (key) {
              return key + "=" + jsonObj[key];
            })
            .join("&"),
          jsonObj.formData,
          { headers: new Headers(headers) }
        )
        .toPromise();
      console.log("response addPromotionalProduct", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateSchema(jsonObj, did) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .put(
          this.baseUrl +
          "shop_schemes/" +
          did +
          "/updateScheme?" +
          Object.keys(jsonObj)
            .map(function (key) {
              return key + "=" + jsonObj[key];
            })
            .join("&"),
          jsonObj.formData,
          { headers: new Headers(headers) }
        )
        .toPromise();
      console.log("response updateSchema", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateAddon(jsonObj, did) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .put(
          this.baseUrl +
          "shop_addOns/" +
          did +
          "/updateAddOn?" +
          Object.keys(jsonObj)
            .map(function (key) {
              return key + "=" + jsonObj[key];
            })
            .join("&"),
          jsonObj.formData,
          { headers: new Headers(headers) }
        )
        .toPromise();
      console.log("response updateSchema", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updatePaymentMethod(jsonObj, did) {
    console.log(jsonObj);
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "shop_payment_methods/" + did + "/updateShopPaymentMethods", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updatePaymentMethod", response);
    return await response;
  }

  async updateShippingMethod(jsonObj, did) {
    console.log(jsonObj);
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "shop_shipping_methods/" + did + "/updateShippingMethod", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updateShippingMethod", response);
    return await response;
  }

  async addProcessBlock(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "processBlocks/createScript", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response Add ProcessBlock", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async updateProcessBlock(ObjData) {
    console.log("object Data", ObjData);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "processBlocks/updateProcessBlocks", ObjData, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllWorkArea", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async deleteProcessBlock(id, refId) {
    console.log(id, refId);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "processBlocks/" + id + "/removeProcessBlocks?refId=" + refId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRolePermission", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  //Shop API

  async getAllProductParameter(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productParameterMasters/getAllProductParameterMaster?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllProductParameter", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addVariantAddons(id, refPvID, addOnId) {
    let obj = {
      refPvID: refPvID,
      refAddOnsId: addOnId
    };
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productVariantAddOnsMappings/" + id + "/addVariantAddons", obj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response addVariantAddons", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async removeVariantAddons(id, refPvID, addOnId) {
    let obj = {
      refPvID: refPvID,
      refAddOnsId: addOnId
    };
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productVariantAddOnsMappings/" + id + "/removeVariantAddons", obj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response addVariantAddons", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getVariantsAddons(id, refPvID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productVariantAddOnsMappings/" + id + "/findAddOnsByVariantId?refPvID=" + refPvID, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getVariantsAddons", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllAddons(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_addOns/" + id + "/getAllAddOns", { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllAddons", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getProductParameterValuesById(productParameterId, id) {
    console.log(productParameterId, id);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productParameterMasters/getProductParameterValuesMasterById?productParameterId=" + productParameterId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response findAllActionsByUsername", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteProductParamValue(productParameterValueId, id) {
    console.log(productParameterValueId, id);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_productParameterValuesMasters/deleteProductParameterValuesMaster?productParameterValueId=" + productParameterValueId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRolePermission", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addProductParameterValue(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productParameterValuesMasters/createProductParameterValuesMaster", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response addProductParameterValue", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateProductParameterValue(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "shop_productParameterValuesMasters/updateProductParameterValuesMaster", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updateProductParameterValue", response);
    return await response;
  }

  async updateProductParamName(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "shop_productParameterMasters/updateProductParameterMaster", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updateProductParamName", response);
    return await response;
  }

  async addProductParameterName(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productParameterMasters/createProductParameterMaster", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response addProductParameterName", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllProducts(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_products/getAllProduct?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllProducts", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getProductDetailById(productId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_products/getProductDetailByProductId?productId=" + productId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getProductDetailByProductId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteProductParameterForProduct(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_productParameters/deleteProductParameterForProduct?id=" + jsonObj.id + "&PPvMId=" + jsonObj.PPvMId + "&ProductId=" + jsonObj.ProductId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteProductParameterForProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createProductParameterForProduct(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productParameters/createProductParameterForProduct", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createProductParameterForProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateProduct(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(this.baseUrl + "shop_products/updateProduct", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response updateProduct", response);
    return await response;
  }
  async getAllTags(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_tags/getAllTags?id=" + id).toPromise();
      console.log("response get All tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async createTags(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_tags/createTags", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response create Tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async updateTags(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(this.baseUrl + "shop_tags/updateTags", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response updateRole", response);
    return await response;
  }
  async getTagById(tagId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_tags/getTagDetailByTagId?tagId=" + tagId + "&id=" + id).toPromise();
      console.log("response get All tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllProductVariants(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productVariants/getAllProductVariants?id=" + id).toPromise();
      console.log("response get All tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getAllProductVariantsByTagId(id, tagId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productVariants/getAllProductVariantsByTagId?id=" + id + "&tagId=" + tagId).toPromise();
      console.log("response get All tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async addProductVariantTags(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productVariantTags/addProductVariantTags", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response Product Variant", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllProductCollection(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productCollections/getAllProductCollection?id=" + id).toPromise();
      console.log("response get All Product collection Tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getAllCollectionOrderOptions() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productCollections/getAllOrderByOptions").toPromise();
      console.log("response getAllCollectionOrderOptions", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async createProductCollection(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productCollections/createProductCollection", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response Product collection", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async addProductCollections(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productCollectionTags/addProductCollections", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response Product collection tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getAllCollectionDetailById(id, PcID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productCollections/getAllCollectionDetailById?id=" + id + "&PcID=" + PcID).toPromise();
      console.log("response get All Product collection Tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async deletePCTags(PcTID, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_productCollectionTags/deleteProductCollectionTagForProduct?PcTID=" + PcTID + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRole", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async updateProductCollection(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "shop_productCollections/updateProductCollection", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response update Product Collection", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async deleteProductVariantTagsForProduct(PvTID, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_productVariantTags/deleteProductVariantTagsForProduct?PvTID=" + PvTID + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRole", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateProductParameter(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "shop_productParameters/updateProductParameterForProduct", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updateProductParameter", response);
    return await response;
  }

  async addProductVariant(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productVariants/createProductVariant", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response addProductVariant", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateProductVariant(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "shop_productVariants/updateProductVariant", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updateProductVariant", response);
    return await response;
  }

  async deleteProductVariant(pvId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_productVariants/deleteProductVariantById?PvID=" + pvId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteProductVariant", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllPages(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_pages/getAllPage?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllPages", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updatePage(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(this.baseUrl + "shop_pages/updatePage", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response updateProduct", response);
    return await response;
  }

  async getSectionByPageId(pageId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_pages/getAllSectionByPageId?id=" + id + "&pageId=" + pageId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getSectionByPageId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllSectionImageBySectionID(sectionID, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_sections/getAllSectionImageBySectionId?id=" + id + "&sectionId=" + sectionID, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllSectionImageBySectionID", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createPage(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .post(this.baseUrl + "shop_pages/createPage", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response createPage", response);
    return await response;
  }

  async createSection(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .post(this.baseUrl + "shop_sections/createSection", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response createSection", response);
    return await response;
  }

  async updateSection(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(this.baseUrl + "shop_sections/updateSection", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response updateSection", response);
    return await response;
  }

  async deleteSection(sectionId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_sections/deleteSectionById?sectionId=" + sectionId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteSection", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getProductVariantById(id, productId, PvId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_productVariants/getProductVariantsDetailById?id=" + id + "&productId=" + productId + "&PvID=" + PvId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getProductVariantById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createSectionImage(jsonObj) {
    let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
    const response = await this.http
      .post(this.baseUrl + "shop_sectionImages/createSectionImage?id=" + jsonObj.id + "&sectionId=" + jsonObj.sectionId + "&name=" + jsonObj.name + "&redirectTo=" + jsonObj.redirectTo + "&config=" + jsonObj.config + "&order=" + jsonObj.order + "&customHTML=" + jsonObj.customHTML, jsonObj.formData, {
        headers: new Headers(headers)
      })
      .toPromise();
    console.log("response createSectionImage", response, headers);
    return await response;
  }
  async createSectionImageWithImagePath(jsonObj) {
    let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
    const response = await this.http
      .post(
        this.baseUrl +
        "shop_sectionImages/createSectionImageWithImagePath?id=" +
        jsonObj.id +
        "&imageLink=" +
        jsonObj.imageLink +
        "&sectionId=" +
        jsonObj.sectionId +
        "&name=" +
        jsonObj.name +
        "&redirectTo=" +
        jsonObj.redirectTo +
        "&config=" +
        jsonObj.config +
        "&order=" +
        jsonObj.order +
        "&customHTML=" +
        jsonObj.customHTML,
        jsonObj.formData,
        { headers: new Headers(headers) }
      )
      .toPromise();
    console.log("response createSectionImage", response, headers);
    return await response;
  }

  async updateSectionImage(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(this.baseUrl + "shop_sectionImages/updateSectionImage", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response updateSectionImage", response);
    return await response;
  }

  async deleteSectionImage(sectionImageId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_sectionImages/deleteSectionImage?sectionImageId=" + sectionImageId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteSectionImage", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getCartDetailById(id, customerId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_carts/getCartDetailById?id=" + id + "&customerId=" + customerId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCartDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllSectionType(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_sectionTypes/getAllsectionType?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllSection", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getActivitySummary(id, fromDate, toDate, formName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "formSubmissions/activitySummary?id=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&formName=" + formName, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCartDetailById", response);
      return await response.json().data;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getActivitySummaryDetail(id, fromDate, toDate, formName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "formSubmissions/activitySummaryDetail?id=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&formName=" + formName, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCartDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  getFormUrl(formName) {
    if (formName == "Leaves") {
      return "leaveRemarks/getLeavesFromToDate";
    } else if (formName == "Logins") {
      return "checkInCheckouts/getCheckinCheckoutFromToDate";
    } else if (formName == "Logouts") {
      return "checkInCheckouts/getCheckinCheckoutFromToDate";
    } else if (formName == "Meetings") {
      return "meetings/getMeetingsFromToDate";
    } else if (formName == "WorkLogs") {
      return "events/getEventsFromToDate";
    } else {
      return false;
    }
  }

  async getLocationData(id, fromDate, toDate, user, companyWebHookBaseURL) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        //.get(companyWebHookBaseURL , {
        .get(companyWebHookBaseURL + "TravelHistories/getAllLocationByMobNum?refCompanyId=" + id + "&mobNum=" + user + "&sDate=" + fromDate + "&eDate=" + toDate + "&sIndex=0&eIndex=99999", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getLocationData", response);
      return await response.json();
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllUsersWithoutCustomers(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "users/getAllUsersWithoutCustomers?refCompanyId=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllUsersWithoutCustomers", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getActivitySummaryClient(id, fromDate, toDate, formName, companyWebHookBaseURL) {
    try {
      let companyWebHook = "";
      let formUrl = this.getFormUrl(formName);
      if (!!formUrl) {
        companyWebHook = formUrl + "?refCompanyId=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate;
        if (formName == "Logins") {
          companyWebHook = companyWebHook + "&type=in";
        } else if (formName == "Logouts") {
          companyWebHook = companyWebHook + "&type=out";
        }

        this.headers = await this.configService.getAPIHeader();
        const response = await this.http
          .get(companyWebHookBaseURL + companyWebHook, {
            headers: new Headers(this.headers)
          })
          .toPromise();
        console.log("response getCartDetailById", response);
        return await response.json();
      } else {
        return await {};
      }
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getActivitySummaryClientWithGroup(id, fromDate, toDate, formName, companyWebHookBaseURL, designationId = false) {
    try {
      let companyWebHook = "";
      let formUrl = this.getFormUrl(formName);
      if (!!formUrl) {
        companyWebHook = formUrl + "DetailUserGroup?refCompanyId=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate;
        if (formName == "Logins") {
          companyWebHook = companyWebHook + "&type=in";
        } else if (formName == "Logouts") {
          companyWebHook = companyWebHook + "&type=out";
        }
        if (designationId) {
          companyWebHook = companyWebHook + "&designationId=" + designationId;
        }
        this.headers = await this.configService.getAPIHeader();
        const response = await this.http
          .get(companyWebHookBaseURL + companyWebHook, {
            headers: new Headers(this.headers)
          })
          .toPromise();
        console.log("response getCartDetailById", response);
        return await response.json();
      } else {
        return await {};
      }
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getActivitySummaryClientDetail(id, fromDate, toDate, formName, companyWebHookBaseURL) {
    try {
      let companyWebHook = "";
      if (formName == "Leaves") {
        companyWebHook = "leaveRemarks/getLeavesFromToDateDetail?refCompanyId=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate;
      } else if (formName == "Logins") {
        companyWebHook = "checkInCheckouts/getCheckinCheckoutFromToDateDetail?refCompanyId=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&type=in";
      } else if (formName == "Logouts") {
        companyWebHook = "checkInCheckouts/getCheckinCheckoutFromToDateDetail?refCompanyId=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&type=out";
      } else if (formName == "Meetings") {
        companyWebHook = "meetings/getMeetingsFromToDateDetail?refCompanyId=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate;
      } else if (formName == "WorkLogs") {
        companyWebHook = "events/getEventsFromToDateDetail?refCompanyId=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate;
      }

      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(companyWebHookBaseURL + companyWebHook, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getCartDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllCustomers(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_customers/getAllCustomer?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllCustomers", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getCustomerContactByCustomerId(id, customerID, offset, limit, search = "") {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customers/getCustomerContactByCustomerId?id=" + id + "&CustomerID=" + customerID + "&offset=" + offset + "&limit=" + limit + "&search=" + search, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCustomerContactByCustomerId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getCustomerContactDetailById(id, userID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customerContacts/getCustomerContactDetailById?id=" + id + "&userId=" + userID, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCustomerContactDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllAreaDetailByPinCode(pinCode) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customers/getAllAreaDetailByPinCode?pinCode=" + pinCode, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllAreaDetailByPinCode", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createCustomer(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_customers/createCustomer", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response createCustomer", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async setParentCustomer(refCompanyId, customerId, refParentCustomerId) {
    // "/shop_customers/updateOutstandingAmount?id=" + this.refCompanyId + "&customerId=" + userId + "&deductAmount=" + amount, { headers: new Headers(this.headers) }).toPromise();
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customers/setParentCustomerId?id=" + refCompanyId + "&customerId=" + customerId + "&refParentCustomerId=" + refParentCustomerId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateCustomer(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "shop_customers/updateCustomer", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateCustomer", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createCustomerContact(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_customerContacts/createCustomerContact", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createCustomerContact", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateCustomerContact(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "shop_customerContacts/updateCustomerContact", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response updateCustomerContact", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createCustomerAddress(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_customerAddresses/createCustomerAddress", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createCustomerAddress", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateCustomerAddress(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "shop_customerAddresses/updateCustomerAddress", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response updateCustomerAddress", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async setCustomerDefaultAddress(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "shop_customerAddresses/setDefaultAddress", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response setCustomerDefaultAddress", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteCustomerAddress(id, customerAddressID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_customerAddresses/deleteCustomerAddress?id=" + id + "&CaId=" + customerAddressID, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteCustomerAddress", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateCart(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "shop_cartProducts/updateCart", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateCart", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateProductQuantity(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "shop_cartProducts/updateProductQuantity", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response updateCart", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async removeCartProduct(id, cartId, cartProductId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_cartProducts/removeCartProduct?id=" + id + "&cartId=" + cartId + "&cartProductId=" + cartProductId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response removeCart", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createProductImageForCloudinary(ObjData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_productImages/createProductImageForCloudinary", ObjData, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllSection", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateProductImage(ObjData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "shop_productImages/updateProductImage", ObjData, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllSection", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addProducts(ObjData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "shop_productImages/updateProductImage", ObjData, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllSection", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateProductCoverImage(imageId, id, isCoverImage) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productImages/updateProductCoverImage?id=" + id + "&imageId=" + imageId + "&isCoverImage=" + isCoverImage, { headers: new Headers(this.headers) }).toPromise();
      console.log("response updateProductCoverImage", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async removeProductImage(imageId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_productImages/removeProductImage?id=" + id + "&imageId=" + imageId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteSectionImage", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async createProductImage(jsonObj) {
    this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
    try {
      const response = await this.http.post(this.baseUrl + "shop_productImages/createProductImage?id=" + jsonObj.id + "&productId=" + jsonObj.productId, jsonObj.formData, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createSectionImage", response);
      return await response;
    } catch (err) {
      return err;
    }
  }
  async getAllCart(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_carts/getAllActiveCart?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllCart", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getCartDetailsByCartId(id, cartID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_carts/getCartDetailByCartId?id=" + id + "&cartId=" + cartID, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCartDetailsByCartId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getAllOrders(id, offset, limit, orderId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_orders/getAllOrders?id=" + id + "&offset=" + offset + "&limit=" + limit + "&orderId=" + orderId, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllOrders", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getPreviousOrders(id, customerId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_orders/getAllOrdersByCustomerId?id=" + id + "&customerId=" + customerId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllOrderDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getProductDetailsByOrderId(orderId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_orders/getProductDetailsByOrderId?orderId=" + orderId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response ", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllOrderDetailById(id, orderId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_orders/getAllOrderDetailById?id=" + id + "&orderId=" + orderId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllOrderDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getPendingOrders(id, fromDate, toDate, offset, limit, search) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_orders/getPendingOrders?id=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&offset=" + offset + "&limit=" + limit + "&search=" + search, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getPendingOrders", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getPendingOrdersItemWisePivot(id, fromDate, toDate, offset, limit, search, level) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_orders/getPendingOrdersItemWisePivot?id=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&offset=" + offset + "&limit=" + limit + "&search=" + search + "&level=" + parseInt(level), { headers: new Headers(this.headers) })
        .toPromise();
      console.log("response getPendingOrdersItemWisePivot", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getPendingOrdersItemWise(id, fromDate, toDate, offset, limit, search) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_orders/getPendingOrdersItemWise?id=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&offset=" + offset + "&limit=" + limit + "&search=" + search, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getPendingOrders", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async orderDispatch(jsonObj) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      // this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_shipments/orderDispatch?id=" + jsonObj.id, jsonObj.formData, { headers: new Headers(this.headers) }).toPromise();
      console.log("response orderDispatch", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async cancelOrder(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_orderProducts/cancelOrderProduct", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response cancelOrder", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async cancelAllOrder(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_orderProducts/cancelAllPendingOrderProducts", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response cancelOrder", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async fileOrderDispatch(jsonObj) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      // this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "fileFormats/downloadFile?id=" + jsonObj.id + "&fileFormatName=" + jsonObj.fileFormatName + "&userId=" + jsonObj.userId + "&shouldWait=" + jsonObj.shouldWait, jsonObj.formData, { headers: new Headers(this.headers) }).toPromise();
      console.log("response fileOrderDispatch", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async downloadExcelFile(jsonObj) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      // this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "fileFormats/downloadExcelFile?id=" + jsonObj.id + "&fileFormatName=" + jsonObj.fileFormatName + "&userId=" + jsonObj.userId + "&shouldWait=" + jsonObj.shouldWait, jsonObj.formData, { headers: new Headers(this.headers) }).toPromise();
      console.log("response downloadExcelFile", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createProduct(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_products/createProduct", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addProduct(jsonObj) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      // this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_products/addProductFile?id=" + jsonObj.id, jsonObj.formData, { headers: new Headers(this.headers) }).toPromise();
      console.log("response fileOrderDispatch", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async mergeWorkArea(id, fromWorkAreaId, toWorkAreaId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "workAreaMappings/" + id + "/mergeWorkAreas?fromWorkArea=" + fromWorkAreaId + "&toWorkArea=" + toWorkAreaId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response mergeWorkArea", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteUser(id, userID, deleteOrActivate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "users/deleteUser?id=" + id + "&userID=" + userID + "&deleteOrActivate=" + deleteOrActivate, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteUser", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllDeletedUsers(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "users/getAllDeletedUsers?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllDeletedUsers", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllActiveUsers(id, reportName, startDate, endDate, designation = "") {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "users/getAllActiveUsers?id=" + id + "&reportName=" + reportName + "&startDate=" + startDate + "&endDate=" + endDate + "&designationId=" + designation, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addProductTags(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_productTags/addProductTags", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addProductTags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllProductsByTagId(id, tagId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_products/getAllProductsByTagId?id=" + id + "&tagId=" + tagId).toPromise();
      console.log("response get All tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteProductTagsForProduct(PID, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_productTags/deleteProductTagsForProduct?PID=" + PID + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteProductTagsForProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteTags(tagId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_tags/deleteTags?tagId=" + tagId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteProductTagsForProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllCustomersByTagId(id, tagId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "users/getAllUsersByTagId?id=" + id + "&tagId=" + tagId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response get all customer tags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async findAllCustomerContact(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customerTags/findAllCustomerContact?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response findAllCustomerContact", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addCustomerTags(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_customerTags/addCustomerTags", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addCustomerTags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async collectionProductReorder(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "shop_productCollectionTags/reorder",
          { ids: jsonObj.map(res => res.PcTID) },
          {
            headers: new Headers(this.headers)
          }
        )
        .toPromise();
      console.log("response", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async productReorder(jsonObj, refCompanyId, productId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "shop_productImages/reorder",
          { ids: jsonObj.map(res => res.id), id: refCompanyId, productId: productId },
          {
            headers: new Headers(this.headers)
          }
        )
        .toPromise();
      console.log("response addCustomerTags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async productPageSectionReorder(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "shop_sections/imageReorder",
          { ids: jsonObj.map(res => res.id) },
          {
            headers: new Headers(this.headers)
          }
        )
        .toPromise();
      console.log("response addCustomerTags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async exportCustomer(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customers/exportCustomer?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteCustomerTagsForCustomer", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteCustomerTagsForCustomer(CtID, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_customerTags/deleteCustomerTagsForCustomer?CtID=" + CtID + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteCustomerTagsForCustomer", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllProductTags(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_productTags/getAllProductTags?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllProductTags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllSchemes(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_schemes/" + id + "/getAllSchemes", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllSchemes", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getFeedById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "socialmedia/findById?id=" + id).toPromise();
      console.log("response getPromotionalId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async findByScocialMediaId(refSocialMediaId, refCompanyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "socialmediaposts/findByScocialMediaId?refCompanyId=" + refCompanyId + "&refSocialMediaId=" + refSocialMediaId).toPromise();
      console.log("response getPromotionalId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteScocialMedia(socialMediaId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "socialmedia/deleteScocialMedia?socialMediaId=" + socialMediaId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteScocialMedia", response);
      return await response;
    } catch (error) {
      console.log("error deleteScocialMedia", error);
      return await error;
    }
  }

  async removePostLink(postId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "socialmediaposts/removePostLink?id=" + id + "&postId=" + postId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteSectionImage", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateSocialMedia(jsonObj, refCompanyId) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .put(this.baseUrl + "socialmedia/" + refCompanyId + "/updateSocialMedia", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateSchema", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addSocialMediaPostLink(jsonObj, refCompanyId) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .post(this.baseUrl + "socialmediaposts/" + refCompanyId + "/addSocialMediaPostLink", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateSchema", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async editSocialMediaPostLink(jsonObj, refCompanyId) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .post(this.baseUrl + "socialmediaposts/" + refCompanyId + "/editSocialMediaPostLink", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateSchema", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addSocialMediaFeed(jsonObj, refCompanyId) {
    try {
      let headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http
        .post(this.baseUrl + "socialmedia/" + refCompanyId + "/addSocialMediaFeed", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateSchema", response, headers);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getSocialMediaTypes(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "socialmedia/" + id + "/getSocialMediaTypes", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async changeImageTransformation(refCompanyId, imageId, jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.patch(this.baseUrl + "shop_productImages/" + imageId, jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response orderDispatch", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getTransformationTypes(id) {
    try {
      if (!this.selectedImages) {
        this.headers = await this.configService.getAPIHeader();
        const response = await this.http
          .get(this.baseUrl + "shop_productImages/" + id + "/getTransformationTypes", {
            headers: new Headers(this.headers)
          })
          .toPromise();
        console.log("response ", response);
        this.selectedImages = await response;
      }
      return this.selectedImages;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllFeeds(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "socialmedia/" + id + "/getAllFeeds", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllSchemes", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAddOnById(addOnId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_addOns/findById?addOnId=" + addOnId).toPromise();
      console.log("response getPromotionalId", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllAddOns(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_addOns/" + id + "/getAllAddOns", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllSchemes", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllCustomerTags(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_customerTags/getAllCustomerTags?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllCustomerTags", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllPricing(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "shop_pricings/getAllPrice?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllPricing", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addPricing(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_pricings/createPricing", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addPricing", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updatePricing(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_pricings/updatePricing", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updatePricing", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deletePricing(pricingId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_pricings/deletePricing?pricingId=" + pricingId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deletePricing", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllTagsByType(id, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_tags/getAllTagsByType?id=" + id + "&type=" + type, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllTagsByType", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addMultipleTagsToProduct(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_productTags/addMultipleTags", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addMultipleTagsToProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addMultipleTagsToCustomer(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_customerTags/addMultipleTags", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addMultipleTagsToCustomer", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async fetchCustomerByPagination(offset, limit, searchText, refCompanyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customers/getAllCustomerByPagination?id=" + refCompanyId + "&offset=" + offset + "&limit=" + limit + "&search=" + searchText, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async fetchUsersByPagination(offset, limit, searchText, refCompanyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "users/getAllUsersByPagination?id=" + refCompanyId + "&offset=" + offset + "&limit=" + limit + "&search=" + searchText, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async fetchProductsByPagination(offset, limit, searchText, refCompanyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_products/getAllProductsByPagination?id=" + refCompanyId + "&offset=" + offset + "&limit=" + limit + "&search=" + searchText, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async mergeCustomer(id, fromCustomerId, toCustomerId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customers/mergeCustomers?id=" + id + "&fromCustomer=" + fromCustomerId + "&toCustomer=" + toCustomerId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response mergeCustomer", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async copyUser(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .post(this.baseUrl + "users/copyUser", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response copyUser", response);
    return await response;
  }

  async copyProduct(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .post(this.baseUrl + "shop_products/copyProduct", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response copyUser", response);
    return await response;
  }

  async bulkProduct(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .post(this.baseUrl + "shop_products/bulkParamAdd", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response copyUser", response);
    return await response;
  }

  async copyProductPythinAdmin(jsonObj) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .post(this.baseUrl + "shop_products/copyToAtisundar", jsonObj, {
        headers: new Headers(this.headers)
      })
      .toPromise();
    console.log("response copyUser", response);
    return await response;
  }

  //coupon app
  async generateCoupons(noOfCoupons, points) {
    let baseUrl = "http://ec2-13-233-148-177.ap-south-1.compute.amazonaws.com:9999/";
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(baseUrl + "master-service/api/coupon/generateCode?totalCoupons=" + noOfCoupons + "&points=" + points, { headers: new Headers(this.headers) }).toPromise();
      return await response.json();
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  async generatedCouponsList(refCompanyId, pageNumber, noOfRecords) {
    let baseUrl = "http://ec2-13-233-148-177.ap-south-1.compute.amazonaws.com:9999/";
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(baseUrl + "master-service/api/coupon/findByCompanyIdGroupByUserAndCreatedDate?companyId=" + refCompanyId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response.json();
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  // http://192.168.0.29:9999/master-service/api/coupon/findByCompanyIdAndUsernameAndPointsAndCreatedDate?companyId=1&username=910000000000&points=10&createdDate=2019-02-27
  async generatedCouponsDetails(refCompanyId, username, points, createdDate, pageNumber, noOfRecords) {
    let baseUrl = "http://ec2-13-233-148-177.ap-south-1.compute.amazonaws.com:9999/";
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(baseUrl + "master-service/api/coupon/findByCompanyIdAndUsernameAndPointsAndCreatedDate?companyId=" + refCompanyId + "&username=" + username + "&points=" + points + "&createdDate=" + createdDate + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      return await response.json();
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  async findAllRedeemRequest(pageNumber, noOfRecords) {
    let baseUrl = "http://ec2-13-233-148-177.ap-south-1.compute.amazonaws.com:9999/";
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(baseUrl + "master-service/api/voucher/findAllRedeemRequest?&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response.json();
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  async settleRedeemRequest(jsonObj) {
    let baseUrl = "http://ec2-13-233-148-177.ap-south-1.compute.amazonaws.com:9999/";
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(baseUrl + "master-service/api/voucher/settlePoints", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response copyUser", response);
      return await response.json();
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }
  async getDeletedUsersByPagination(offset, limit, searchText, refCompanyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "users/getDeletedUsersByPagination?id=" + refCompanyId + "&offset=" + offset + "&limit=" + limit + "&search=" + searchText, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getAddressByPagination(offset, limit, searchText) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_addresses/getAddressByPagination?offset=" + offset + "&limit=" + limit + "&search=" + searchText, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createZipCode(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_addresses/createZipCode", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response createZipCode", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateZipCode(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "shop_addresses/updateZipCode", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateZipCode", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteZipCode(zipCodeId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_addresses/deleteZipCode?zipCodeId=" + zipCodeId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteZipCode", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createDepartment(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "departments/createDepartment", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response createDepartment", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateDepartment(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "departments/updateDepartment", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateDepartment", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteDepartment(departmentId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "departments/deleteDepartment?departmentId=" + departmentId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteDepartment", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async findFormDetailsByNameAndPhoneNoUsingPagination(formName, phoneNo, limit, skip, order, orderBy, search, cid, departmentId, assignToUserId, byMe) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "formSubmissions/" +
          cid +
          "/findFormDetailsByNameAndPhoneNo?formName=" +
          formName +
          "&phoneNo=" +
          phoneNo +
          "&limit=" +
          limit +
          "&skip=" +
          skip +
          "&order=" +
          order +
          "&by=" +
          orderBy +
          "&search=" +
          search +
          "&departmentId=" +
          departmentId +
          "&assignToUserId=" +
          assignToUserId +
          "&byMe=" +
          byMe,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      console.log("response findFormDetailsByNameAndPhoneNoUsingPagination", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllApplication() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "applications/getAllApplication", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response getAllApplication", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllApplicationCompany(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "company_applications/getCompaniesByAppId?appId=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllApplicationCompany", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getMobileIdByCompanyAppId(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "company_application_mobileIds/getMobileIdByCompanyAppId?refCompanyAppId=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllApplicationCompany", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateAppDetails(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "applications/updateApplication", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateAddDetails", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async AddApplication(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "applications/createApplication", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response AddApplication", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addCompanyApplication(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "company_applications/addCompanyApplication", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response addCompanyApplication", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getUsersByDepartment(id, departmentId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "users/getUsersByDepartment?id=" + id + "&departmentId=" + departmentId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getUsersByDepartment", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async assignToDeptAndUser(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "runningProcesses/assignToDeptAndUser", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response assignToDeptAndUser", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async companySignUp(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "companies/" + "companySignUp", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response companySignUp", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async createFormTemplate(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "formTemplates/" + id + "/templateCreate", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response createFormTemplate", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateFormTemplate(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "formTemplates/" + id + "/templateUpdate", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateFormTemplate", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllCSVUploadHistory(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "csvUploadHistories/findAll?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllCSVUploadHistoryByType(id, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "csvUploadHistories/findByType?id=" + id + "&type=" + type, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getDashboardProductDetail(id, type, subType, fromDate, toDate, count) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "formSubmissions/activitySummaryDetailForOrders?id=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&type=" + type + "&subType=" + subType + "&count=" + count, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCartDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getDashboardProduct(id, type, fromDate, toDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "formSubmissions/activitySummaryForOrders?id=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&type=" + type, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getCartDetailById", response);
      return await response.json();
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getWorkAreaByName(id, name) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "workAreas/" + id + "/getWorkAreaByName?name=" + name).toPromise();
      console.log("response getWorkAreaByName", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createChatRoom(refCompanyId, name, username) {
    debugger;
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "workAreas/" + refCompanyId + "/createChatRoom?name=" + name + "&username=" + username).toPromise();

      debugger;
      console.log("response getWorkAreaByName", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addAction(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "actions/" + id + "/createAction", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addAction", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async addActionPermission(jsonObj, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "actionPermissions/" + id + "/createActionPermission", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addAction", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  // async salesReporting(jsonObj) {
  //   try {
  //     this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
  //     // this.headers = await this.configService.getAPIHeader();
  //     const response = await this.http.post(this.baseUrl + "shop_orders/salesEntry?id=" + jsonObj.id, jsonObj.formData, { headers: new Headers(this.headers) }).toPromise();
  //     console.log("response orderDispatch", response);
  //     return await response;
  //   } catch (error) {
  //     console.log("error", error);
  //     return await error;
  //   }
  // }

  async addProductCollectionsUsingSKU(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "shop_productCollectionTags/addProductCollectionsUsingSKU", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response Product collection tags using sku", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteProduct(productId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_products/deleteProduct?productId=" + productId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteProduct", response);
      return await response;
    } catch (error) {
      console.log("error deleteProduct", error);
      return await error;
    }
  }

  async outOfStock(productId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_products/outOfStockProduct?productId=" + productId + "&outofStock=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteProduct", response);
      return await response;
    } catch (error) {
      console.log("error deleteProduct", error);
      return await error;
    }
  }

  async getAllCostDefinitions(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "costsDefinitions/getAllCostDefinition?id=" + id).toPromise();
      console.log("response getAllCostDefinitions", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async updateCostDefinitions(jsonObj) {
    console.log(jsonObj);
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "costsDefinitions/updateCostDefinition", jsonObj, { headers: new Headers(this.headers) }).toPromise();
    console.log("response updateCostDefinitions", response);
    return await response;
  }
  async getCostDefinitionDetail(costDefinitionId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "costsDefinitions/available?id=" + costDefinitionId + "&refCompanyId=" + id).toPromise();
      console.log("response getCostDefinitionDetail", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async addCostDefinition(jsonObj) {
    console.log(jsonObj);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "costsDefinitions/add", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addCostDefinition", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getAllShippingMethod() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "costsDefinitions/getAllShippingMethod").toPromise();
      console.log("response getAllShippingMethod", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createDomain(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "companyDomains/addDomains", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response createDomain", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateDomain(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "companyDomains/updateDomain", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateDomain", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteDomain(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "companyDomains/deleteDomainById?domainId=" + jsonObj.domainId + "&id=" + jsonObj.id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteRole", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllCampaigns(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "campaigns/getAllCampaigns?id=" + id).toPromise();
      console.log("response getAllCampaigns", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getCampaignDetail(campaignId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "campaigns/available?id=" + campaignId + "&refCompanyId=" + id).toPromise();
      console.log("response getCampaignDetail", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addCampaign(jsonObj) {
    console.log(jsonObj);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "campaigns/add", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addCostDefinition", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async compareCampaign(id, firstCampaign, secondCampaign) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "campaigns/compare?id=" + id + "&firstCampaign=" + firstCampaign + "&secondCampaign=" + secondCampaign).toPromise();
      console.log("response compareCampaign", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getViewReport(id, fromDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/report?id=" + id + "&fromDate=" + fromDate).toPromise();
      console.log("response getViewReport", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getProductWiseViewReport(id, fromDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/productWiseReport?id=" + id + "&fromDate=" + fromDate).toPromise();
      console.log("response getViewReport", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getCollectionWiseViewReport(id, fromDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/productCollectionWiseReport?id=" + id + "&fromDate=" + fromDate).toPromise();
      console.log("response getViewReport", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllUsersWhoLoggedIn(id, fromDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/getAllUsersWhoLoggedIn?id=" + id + "&fromDate=" + fromDate).toPromise();
      console.log("response getAllUsersWhoLoggedIn", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async whoAddToCartWhichProduct(id, fromDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/whoAddToCartWhichProduct?id=" + id + "&fromDate=" + fromDate).toPromise();
      console.log("response whoAddToCartWhichProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async whoRemoveFromCartWhichProduct(id, fromDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/whoRemoveFromCartWhichProduct?id=" + id + "&fromDate=" + fromDate).toPromise();
      console.log("response whoRemoveFromCartWhichProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async whoBuyWhichProduct(id, fromDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/whoBuyWhichProduct?id=" + id + "&fromDate=" + fromDate).toPromise();
      console.log("response whoBuyWhichProduct", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }
  async getProductWiseDetailViewReport(id, fromDate, refProductID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/productWiseDetailReport?id=" + id + "&fromDate=" + fromDate + "&refProductID=" + refProductID).toPromise();
      console.log("response getProductWiseDetailViewReport", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getCollectionWiseDetailViewReport(id, fromDate, refPcID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "viewDetails/productCollectionWiseDetailReport?id=" + id + "&fromDate=" + fromDate + "&refPcID=" + refPcID).toPromise();
      console.log("response getCollectionWiseDetailViewReport", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async sendPushNotificationSF(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "users/sendPushNotificationSF", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response addRolePermission", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getUsersByScheme(id, schemeId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_schemes/getUsersByScheme?id=" + id + "&schemeId=" + schemeId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getUsersByScheme", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async fileOrderFreshness(jsonObj) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      // this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "fileFormats/downloadExcelFile?id=" + jsonObj.id + "&fileFormatName=" + jsonObj.fileFormatName + "&userId=" + jsonObj.userId + "&shouldWait=" + jsonObj.shouldWait, jsonObj.formData, { headers: new Headers(this.headers) }).toPromise();
      console.log("response fileOrderDispatch", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getUsersWithSFPlayerID(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "users/getAllUsersWithSFPlayerID?refCompanyId=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getUsersWithSFPlayerID", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createCard(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "notificationCards/createCard", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createCard", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllSources(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_inquiries/getAllSourcesTypes?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllOrderDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getInquieries(id, fromDate, toDate, source, offset, limit, search) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_inquiries/getListOfInqueries?id=" + id + "&fromDate=" + fromDate + "&toDate=" + toDate + "&offset=" + offset + "&inqVia=" + source + "&limit=" + limit + "&search=" + search, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllOrderDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async pauseResumeOrders(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "companies/pauseResumeOrders", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response pauseResumeOrders", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteOrEnableCustomer(refCompanyId, customerId, isDeleted) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "shop_customers/deleteOrEnableCustomer?id=" + refCompanyId + "&customerId=" + customerId + "&isDeleted=" + isDeleted, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllOrderDetailById", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async createDispatch(jsonObj) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http.post(this.baseUrl + "shop_shipments/createDispatch", jsonObj, { headers: new Headers(this.headers) }).toPromise();
      console.log("response createDispatch", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllShipmentByPagination(refCompanyId, offset, limit) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http.get(this.baseUrl + "shop_shipments/getAllShipmentByPagination?refCompanyId=" + refCompanyId + "&offset=" + offset + "&limit=" + limit, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllShipmentByPagination", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getShipmentDetailByShipId(id, refShipId) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http.get(this.baseUrl + "shop_shipments/getShipmentDetailByShipId?id=" + id + "&refShipID=" + refShipId, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllShipmentByPagination", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async getAllCourierService(id) {
    try {
      this.headers = { Authorization: await this.storage.get(TOKEN_KEY) };
      const response = await this.http.get(this.baseUrl + "shop_courierServices/getAllCourierService?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response getAllCourierService", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async deleteCourier(courierServiceId, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "shop_courierServices/deleteCourier?courierServiceId=" + courierServiceId + "&id=" + id, { headers: new Headers(this.headers) }).toPromise();
      console.log("response deleteCourier", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async addCourierService(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "shop_courierServices/addCourierService", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response addCourierService", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async updateCourierService(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "shop_courierServices/updateCourierService", jsonObj, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      console.log("response updateCourierService", response);
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

}
