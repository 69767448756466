import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManagePricingPage } from "../manage-pricing/manage-pricing.page";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"]
})
export class PricingComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public items: any;
  public allPricing: any;
  selectedFile: File = null;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.getPricing();
  }

  setItems() {
    this.items = this.allPricing;
  }

  async getPricing() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllPricing(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.allPricing = res.json().data;
      this.items = this.allPricing;
      console.log("get pricing", this.allPricing);
    }
  }

  async filterItems(ev: any) {
    if (this.allPricing && this.allPricing.length > 0) {
      this.setItems();
      let val = ev.target.value;
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function (item) {
          console.log("item", item, typeof item);
          if (item.fromDate.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.discountPercent.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.productTag.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.customerTag.toLowerCase().includes(val.toLowerCase())) {
            return true;
          }
        });
      } else {
        this.items = this.allPricing;
      }
    }
  }

  async deletePricing(pricing) {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.deletePricing(pricing.id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      console.log("delete pricing response", res);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.getPricing();
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
      }
    }
  }

  async managePricing(item, id) {
    let modalPage = await this.modalCtrl.create({
      component: ManagePricingPage,
      componentProps: {
        id: id,
        item: item
      }
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    await this.getPricing();
  }

  async downloadFormat() {
    // console.log("download format");
    window.open("assets/fileFormat/PricingUpload.xlsx", "_blank");
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  async pricingUpload() {
    if (this.selectedFile) {
      console.log(this.selectedFile);
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      console.log("form data", formData);
      let userData = await this.authService.userData();
      let refCompanyId = await this.storage.get("refCompanyId");
      let res: any;
      let jsonObj = { id: refCompanyId, fileFormatName: "Pricing Upload", userId: userData.id, formData: formData, shouldWait: false };
      await this.httpApiService.showLoading();
      res = await this.httpApiService.downloadExcelFile(jsonObj);
      await this.httpApiService.hideLoading();
      console.log("response", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().error) {
          let toast = await this.toastCtrl.create({
            message: res.json().error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        } else {
          if (res.json().data) {
            let toast = await this.toastCtrl.create({
              message: res.json().data,
              duration: 3000,
              position: "top",
              color: "success"
            });
            await toast.present();
            await this.getPricing();
          }
        }
      }
    }
  }
}
