import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthenticationService } from "./services/authentication/authentication.service";
import { Router } from "@angular/router";
import { ThemeService } from "./services/theme/theme.service";
const themes = {
  autumn: {
    primary: "#F78154",
    secondary: "#4D9078",
    tertiary: "#B4436C",
    light: "#FDE8DF",
    medium: "#FCD0A2",
    dark: "#B89876"
  },
  night: {
    primary: "#8CBA80",
    secondary: "#FCFF6C",
    tertiary: "#FE5F55",
    medium: "#BCC2C7",
    dark: "#F7F7FF",
    light: "#495867"
  },
  neon: {
    primary: "#39BFBD",
    secondary: "#4CE0B3",
    tertiary: "#FF5E79",
    light: "#F4EDF2",
    medium: "#B682A5",
    dark: "#34162A"
  },
  dark: {
    primary: "#eeae15",
    secondary: "#007ACC",
    medium: "#f43e00",
    light: "#bdc3c7",
    dark: "#d35400",
    tertiary: "#F39C12"
  },
  benara: {
    primary: "#F9ADA8",
    secondary: "#08491c",
    medium: "#f43e00",
    tertiary: "#d82827",
    light: "#f4f4f4",
    dark: "#222",
    bgImage: "url('assets/img/bg.jpg')",
    txt: "#FFFFFF",
    bgColor: "rgb(103, 111, 165)",
    fs: "20px"
  }
};

@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  public changePasswordPage = false;
  themes: Array<{ title: string; theme: string }>;

  constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar, private authenticationService: AuthenticationService, private router: Router, private theme: ThemeService) {
    this.initializeApp();
    //used to change the theme of the app, commented to keep the default
    // this.changeTheme("benara");
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.authenticationService.authenticationState.subscribe(state => {
      this.changePasswordPage = false;
      if (window && "location" in window && "protocol" in window.location && "pathname" in window.location && "host" in window.location) {
        console.log("window.location", window.location);
        if (window.location.href.toString().includes("changePassword") || window.location.href.toString().includes("orderQr")) {
          this.changePasswordPage = true;
        }
      }
      console.log("about to navigate", state, this.changePasswordPage, state && !this.changePasswordPage);
      if (state && !this.changePasswordPage) {
        this.router.navigate(["app", "dashboard"]);
      } else if (!this.changePasswordPage) {
        console.log("routing to login page");
        this.router.navigate(["login"]);
      }
    });
  }

  changeTheme(name) {
    this.theme.setTheme(themes[name]);
  }

  changeSpeed(val) {
    this.theme.setVariable("--speed", `${val}ms`);
  }
}
