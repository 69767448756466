import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageUserPage } from "../manage-user/manage-user.page";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageWorkAreaPage } from "../../actions/manage-work-area/manage-work-area.page";

@Component({
  selector: "app-work-area",
  templateUrl: "./work-area.component.html",
  styleUrls: ["./work-area.component.scss"]
})
export class WorkAreaComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  public getAllWorkArea: any;
  public items: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {}

  ngOnInit() {
    this.getWorkArea();
  }

  setItems() {
    this.items = this.getAllWorkArea;
  }

  async getWorkArea() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllWorkArea(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllWorkArea = res.json().data;
      this.items = this.getAllWorkArea;
      console.log("get All User Data", this.getAllWorkArea);
    }
  }

  async filterItems(ev: any) {
    if (this.getAllWorkArea && this.getAllWorkArea.length > 0) {
      this.setItems();
      let val = ev.target.value;
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function(item) {
          console.log("item", item, typeof item);
          return item.name.toLowerCase().includes(val.toLowerCase());
        });
      } else {
        this.items = this.getAllWorkArea;
      }
    }
  }

  async deleteWorkArea(workArea) {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.deleteWorkArea(workArea.id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      console.log("delete work Area response", res);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.getWorkArea();
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
      }
    }
  }

  async createChatRoom(workArea) {
    debugger;
    let userData = await this.authService.userData();
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();

    let res = await this.httpApiService.createChatRoom(refCompanyId, encodeURIComponent(workArea.name), userData.username);
    debugger;
    res = res.json();

    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      console.log("createChatRoom response", res);
      if (res.error) {
        let toast = await this.toastCtrl.create({
          message: res.data,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.getWorkArea();
        let toast = await this.toastCtrl.create({
          message: "Chat Room Created",
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
      }
    }
  }

  //open model for add user's role

  async manageWorkArea(item, id) {
    let modalPage = await this.modalCtrl.create({
      component: ManageWorkAreaPage,
      componentProps: {
        id: id,
        item: item
      }
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    await this.getWorkArea();
  }
}
