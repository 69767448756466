import { Component, OnInit } from '@angular/core';
import { Storage } from "@ionic/storage";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-manage-cost-definition',
  templateUrl: './manage-cost-definition.page.html',
  styleUrls: ['./manage-cost-definition.page.scss'],
})
export class ManageCostDefinitionPage implements OnInit {
  public items: any;
  public country: any;
  public shippingMethod: any;
  public perKgRate: any;
  public allShippingMethods: any;

  constructor(public storage: Storage, public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {
    this.items = this.navParams.get("items");
  }

  ngOnInit() {
    this.getAllShippingMethod();
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async getAllShippingMethod() {
    let res: any;

    res = await this.httpApiService.getAllShippingMethod();
    if (res.status == 0) {
      console.log("error");
    } else {
      this.allShippingMethods = res.json().data;
      console.log("getAllShippingMethod res", res, this.allShippingMethods);
    }
  }

  async addCostDefinition() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    let objData = { id: refCompanyId, country: this.country, shippingMethod: this.shippingMethod, perKgRate: parseInt(this.perKgRate) };
    console.log(objData);
    let validate = await this.validateControls();
    if (!validate) {
      res = await this.httpApiService.addCostDefinition(objData);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Cost Definition added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }
  async resetControls() {
    this.country = "";
    this.shippingMethod = "";
    this.perKgRate = "";
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.country != null && this.country != null && this.shippingMethod != "" && this.shippingMethod != null && this.perKgRate != "" && this.perKgRate != null) {
      flag = 0;
    }
    console.log("flag=", flag);
    if (flag) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await true;
    } else {
      return await false;
    }
  }
}
