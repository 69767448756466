import { Component, OnInit } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { NavController, ModalController, ToastController, AlertController, NavParams, Events } from "@ionic/angular";
import { ManageSettlementPage } from "../../actions/manage-settlement/manage-settlement.page";

@Component({
  selector: "app-settlement",
  templateUrl: "./settlement.component.html",
  styleUrls: ["./settlement.component.scss"]
})
export class SettlementComponent implements OnInit {
  constructor(public httpApiService: HttpApiService, public modalCtrl: ModalController, public toastCtrl: ToastController) {}
  public pageNumber = 0;
  public noOfRecords = 10;
  public items: any;
  public selected = [];
  public searchText: any;
  public allRequestData: any;

  async ngOnInit() {
    await this.findAllRedeemRequest();
  }

  ionViewDidEnter() {
    // this.findAllRedeemRequest();
  }
  async findAllRedeemRequest() {
    await this.httpApiService.showLoading();
    let response = await this.httpApiService.findAllRedeemRequest(this.pageNumber, this.noOfRecords);
    await this.httpApiService.hideLoading();
    console.log("response", response);
    if (!response.error) {
      this.items = response.data.content;
      this.allRequestData = this.items;
      console.log(" this.items", this.items);
    }
  }

  async manageSettlement() {
    if (this.selected.length != 0) {
      let modalPage = await this.modalCtrl.create({
        component: ManageSettlementPage,
        componentProps: {
          // id: id,
          item: this.selected
        }
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please select data for settlement",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
    }
  }
  async handleModalDismiss(d) {
    this.selected = [];
    await this.findAllRedeemRequest();
  }

  async onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    console.log("this.selected", this.selected);
    console.log("event", event);
  }
  async onActivate(event) {
    // console.log("onActivated event", event);
  }

  searchByUserName() {
    console.log("in search function");
    if (this.searchText && this.searchText != "") {
      let allData = this.allRequestData;
      return allData.filter(item => {
        return item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1;
      });
    } else {
      return this.allRequestData;
    }
  }

  setFilterData() {
    this.items = this.searchByUserName();
    console.log("this.items", this.items);
  }
}
