import { Component, Input, OnInit } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { PrintPagePage } from "../print-page/print-page.page";
import * as moment from "moment";
import { TimeAgoPipe } from "../../../pipes/timeAgo/time-ago.pipe";

@Component({
  selector: 'app-inquiries',
  templateUrl: './inquiries.component.html',
  styleUrls: ['./inquiries.component.scss'],
  providers: [TimeAgoPipe]
})
export class InquiriesComponent implements OnInit {
  public summaryDetail: any;
  public fromDate: any;
  public toDate: any;
  public source: any = "0";
  public sources: any;
  public refCompanyId: any;
  public items: any;
  public list: any;
  public offset = 0;
  public limit = 20;
  public search = "";
  public counter;
  public inqueries: any;
  public arrowKeyLocation = 0;


  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService, public timeAgo: TimeAgoPipe) {
    this.summaryDetail = "summary";
    let fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.fromDate = new Date().getFullYear() + "-" + ("0" + (fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + fromDate.getDate()).slice(-2);
    this.toDate = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2);
  }

  async ngOnInit() {
    this.refCompanyId = await this.storage.get("refCompanyId");
    await this.getAllSources();
    //await this.getInquieries()
  }

  async getAllSources() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllSources(this.refCompanyId);
    this.sources = res.json().data;
    await this.httpApiService.hideLoading();
  }

  async setPage(event) {
    console.log("event", event);
    this.limit = event.limit;
    this.offset = event.offset;
    await this.getInquieries();
  }

  async getInquieries() {
    this.list = null;
    this.items = null;
    this.fromDate = this.fromDate.replace("T", " ").slice(0, 19);
    this.toDate = this.toDate.replace("T", " ").slice(0, 19);
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getInquieries(this.refCompanyId, this.fromDate + " 00:00:00", this.toDate + " 23:59:59", this.source, this.offset, this.limit, this.search);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.inqueries = res.json().data.data;
          this.counter = res.json().data.counter;
          this.list = this.inqueries;
          this.setItems();
        }
      }
    }
    console.log("this.inqueries", this.inqueries);
  }

  setItems() {
    this.items = this.inqueries;
  }

  async submit() {
    this.offset = 0;
    this.limit = 20;
    await this.getInquieries();
  }

  async filterItems(ev: any) {
    this.offset = 0;
    this.limit = 20;
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.search = val;
      this.arrowKeyLocation = 0;
    } else {
      this.search = "";
      this.arrowKeyLocation = 0;
      // this.items = [];
    }
    await this.getInquieries();
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.arrowKeyLocation != 0) {
          this.arrowKeyLocation--;
          // this.selectedProcess = this.items[this.arrowKeyLocation].actionName;
          if (this.arrowKeyLocation == 0) {
            this.arrowKeyLocation++;
          }
          break;
        } else {
          break;
        }
      case 40: // this is the ascii of arrow down
        if (this.items.length > this.arrowKeyLocation) {
          // this.selectedProcess = this.items[this.arrowKeyLocation].actionName;
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          break;
        } else {
          break;
        }
    }
    // console.log(this.arrowKeyLocation);
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    console.log("arrData ", arrData);
    var CSV = "";
    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      row += "Customer Name" + "," + "Dealer Name" + "," + "Product Name" + "," + "SKU" + "," + "Source" + "," + "Date";
      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      row += '"' + arrData[i]["customerName"] + '",';
      row += '"' + arrData[i]["DealerName"] + '",';
      row += '"' + arrData[i]["productName"] + '",';
      row += '"' + arrData[i]["productSKU"] + '",';
      row += '"' + arrData[i]["inqVia"] + '",';
      row += '"' + this.timeAgo.transform(arrData[i]["createdOn"]) + '",';

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }


    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    // console.log("CSV ", CSV);
    //Generate a file name
    var fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async exportToCSV() {
    this.offset = 0;
    this.limit = 1000;
    await this.getInquieries();
    this.offset = 0;
    this.limit = 20;
    this.JSONToCSVConvertor(this.list, "Inquiries:_" + moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), "report");
  }
}
