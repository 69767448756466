import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-manage-coupon-generator",
  templateUrl: "./manage-coupon-generator.page.html",
  styleUrls: ["./manage-coupon-generator.page.scss"]
})
export class ManageCouponGeneratorPage implements OnInit {
  public noOfCoupons: any;
  public points: any;
  public generatedCoupons: any;
  public items: any;
  public couponCode: any;
  public elementType = "canvas";
  public qrValue: any;
  public urls = [];

  constructor(public event: Events, public navCtrl: NavController, public router: Router, public modalCtrl: ModalController, public toastCtrl: ToastController, public httpApiService: HttpApiService, public authService: AuthenticationService, public navParams: NavParams) {
    this.items = this.navParams.get("item");
    console.log("data in manageCoupon", this.items);
    // if (this.items) {
    //   this.couponCode = this.items.code
    // }
  }

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async generateCoupons() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let response = await this.httpApiService.generateCoupons(this.noOfCoupons, this.points);
      await this.httpApiService.hideLoading();
      if (!response.error) {
        let toast = await this.toastCtrl.create({
          message: response.message,
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
        this.closeModal();
      }
    }
  }

  async validateControls() {
    if (this.noOfCoupons && this.points) {
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please insert all the information",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }

  async generateQRCode() {
    console.log("code", this.couponCode);
    this.qrValue = this.couponCode;
  }

  async print() {
    const canvas = document.querySelectorAll("canvas");
    console.log("canvas", canvas);
    canvas.forEach(items => {
      const imageData = items.toDataURL("image/jpeg").toString();
      console.log("imageData", imageData);
      var url = imageData.replace(/^data:image\/[^;]+/, "data:application/octet-stream");
      // window.location.href = url;
      this.urls.push(url);
      this.closeModal();
    });
    this.event.publish("qrData", this.urls);
    this.navCtrl.navigateForward("printCoupon");
    //   console.log("this.urls ", this.urls);
    //   window.print()
    // }
  }
}
