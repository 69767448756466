import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-add-order-book',
  templateUrl: './add-order-book.page.html',
  styleUrls: ['./add-order-book.page.scss'],
})
export class AddOrderBookPage implements OnInit {
  public Name: any;
  public items: any;

  constructor(public storage: Storage, public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {
    this.items = this.navParams.get("items");
  }

  ngOnInit() {}

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async add() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    let objData = { name: this.Name};
    console.log(objData);
    if (!!this.Name) {
      res = await this.httpApiService.addOrderBook(objData, refCompanyId);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Order Book added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
	else
	{
		let toast = await this.toastCtrl.create({ message: "Please add order book name", duration: 3000, position: "top", color: "danger" });
		toast.present();
	 }
  }
  async resetControls() {
    this.Name = "";
    
  }
}
