import { Component, OnInit } from "@angular/core";
import { ToastController, ModalController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ManageApplicationPage } from "../../actions/manage-application/manage-application.page";

@Component({
  selector: "app-application",
  templateUrl: "./application.component.html",
  styleUrls: ["./application.component.scss"]
})
export class ApplicationComponent implements OnInit {
  public appList: any;
  public items: any;
  public companyList: any;
  public allCompanyList: any;
  public compList: any;
  public manageSelectedApp: any;
  public manageSelectedAppId: any;
  public selectedApp: any;
  public manageSelectedCompany: any;
  public manageSelectedCompanyId: any;
  public selectedCompany: any;
  public oneSingleAppId: any;
  public oneSignalUserId: any;
  public mobileList: any;
  public flag: any;
  public appName: any;
  public appID: any;

  constructor(public httpApiService: HttpApiService, public authService: AuthenticationService, public toastCtrl: ToastController, public modalCtrl: ModalController) {}

  async ngOnInit() {
    await this.getAllProcessTemplates();
  }
  async getAllProcessTemplates() {
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getAllApplication();
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      this.appList = res.json().data;
      console.log("appLins", this.appList);
      await this.setApplication();
    }
  }
  async setApplication() {
    this.items = await this.appList;
  }
  async setCompany() {
    this.companyList = this.compList;
    console.log(this.companyList);
  }
  async openModal(modalTitle) {
    let flag = false;
    if (modalTitle == "addCompany") {
      if (this.manageSelectedAppId) {
        flag = true;
      }
    }
    if (modalTitle == "addApp") {
      flag = true;
    }
    if (flag == true) {
      let modalPage = await this.modalCtrl.create({
        component: ManageApplicationPage,
        componentProps: { appId: this.manageSelectedAppId, modalTitle: modalTitle }
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modalPage.present();
    }
  }

  async handleModalDismiss(d) {
    if (d.data == "addApp") {
      await this.getAllProcessTemplates();
    } else {
      await this.getAllAppCompany(undefined);
    }
  }

  async getAllAppCompany(event) {
    let tmpTemplateName;
    await this.httpApiService.showLoading();
    this.selectedCompany = null;
    this.manageSelectedCompany = null;
    if (event != undefined) {
      tmpTemplateName = event.name;
      this.selectedApp = event;
      this.manageSelectedApp = event.name;
      this.appName = event.name;
      this.manageSelectedAppId = event.id;
      this.oneSingleAppId = event.oneSignalAppId;
      this.oneSignalUserId = event.oneSignalUserId;
      this.appID = event.appId;
    } else {
      this.selectedApp = this.manageSelectedApp;
      tmpTemplateName = this.manageSelectedApp;
    }
    // let selectedAppId = this.items.filter(function(search) {
    //   if (search.templateName == tmpTemplateName) {
    //     return search.id;
    //   }
    // });
    let res1 = await this.httpApiService.getAllCompany();
    if (res1.json().isSuccess) {
      this.allCompanyList = res1.json().data;
      console.log("allCompanyList", this.allCompanyList);
    }
    let res = await this.httpApiService.getAllApplicationCompany(this.manageSelectedAppId);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      this.compList = res.json().data;
      await this.setCompany();
    }
  }

  async showCompanyDetail(event) {
    let tmpCompanyName;
    await this.httpApiService.showLoading();
    this.mobileList = null;
    console.log(event);
    if (event != undefined) {
      tmpCompanyName = event.companyName;
      this.selectedCompany = event;
      this.manageSelectedCompany = event.companyName;
      this.manageSelectedCompanyId = event.id;
    } else {
      this.selectedCompany = this.manageSelectedCompany;
      tmpCompanyName = this.manageSelectedCompany;
    }
    let res = await this.httpApiService.getMobileIdByCompanyAppId(this.manageSelectedCompanyId);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      this.mobileList = res.json().data;
      console.log(this.mobileList);
    }
  }

  async updateBtn(name) {
    console.log(name);
    this.flag = name;
  }

  async updateDetail() {
    await this.httpApiService.showLoading();

    // if (this.manageSelectedCompanyId != this.newCompId) {
    //   let jsonData1 = {
    //     appId: this.manageSelectedAppId,
    //     id: this.newCompId
    //   };
    //   console.log("change", jsonData1);
    //   let res = await this.httpApiService.addUpdateCompanyApplication(jsonData1);
    //   console.log(res.json());
    //   await this.httpApiService.hideLoading();
    // }
    let jsonData = {
      refAppId: this.manageSelectedAppId,
      name: this.appName,
      appId: this.appID,
      oneSignalAppId: this.oneSingleAppId,
      oneSignalUserId: this.oneSignalUserId
    };
    console.log(jsonData);
    let res = await this.httpApiService.updateAppDetails(jsonData);
    await this.httpApiService.hideLoading();
    let resp = res.json();
    if (resp.status == 401) {
      this.authService.logout();
    } else {
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: res.error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
        await this.getAllProcessTemplates();
      }
    }
  }
}
