import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-manage-work-area",
  templateUrl: "./manage-work-area.page.html",
  styleUrls: ["./manage-work-area.page.scss"]
})
export class ManageWorkAreaPage implements OnInit {
  public name = "";
  public id: any;
  public item: any;
  public designations: any;
  public designation = 0;

  constructor(private modalCtrl: ModalController, public storage: Storage, private navParams: NavParams, public ConfigService: ConfigService, public authService: AuthenticationService, private toastCtrl: ToastController, private nav: NavController, public httpApiService: HttpApiService) {}

  async ngOnInit() {
    this.item = this.navParams.get("item");
    this.id = this.navParams.get("id");
    console.log(this.id, this.item);
    if (this.id != 0) {
      this.name = this.item.name;
    } else {
      this.name = "";
    }
    await this.getAllDesignation();
  }

  async AddWorkArea() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        name: this.name,
        designationId: this.designation
      };
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createWorkArea(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          this.resetForm();
          let toast = await this.toastCtrl.create({
            message: "WorkArea created successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    }
  }

  async updateWorkArea() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        workAreaId: this.id,
        name: this.name
      };
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateWorkArea(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      console.log("resp", resp);
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: res.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.data,
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    }
  }

  resetForm() {
    this.name = "";
    this.designation = 0;
  }

  async validateControls() {
    if (this.name != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please select/insert the information",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }

  async getAllDesignation() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllDesignation(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.designations = res.json().data;
      console.log("designations", this.designations);
    }
  }
}
