import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-add-schemes',
  templateUrl: './add-schemes.page.html',
  styleUrls: ['./add-schemes.page.scss'],
})
export class AddSchemesPage implements OnInit {
  public ProductName: String;
  public ProductImage: String;
  public ProductPrice: Number;
  public PromotionalProduct: Number = 0;
  public IsActive: String = '1';
  public imageLink: File = null;
  public listOfPromotionalProducts: any;

  constructor(public storage: Storage, public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {
  }

  async ngOnInit() {
    await this.getAllPromotionalProduct();
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async addDesignation() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    const formData: FormData = new FormData();

    formData.append("file", this.imageLink);

    let objData: any = { formData: formData, name: this.ProductName, image: this.ProductImage, isActive: this.IsActive }
    if (!!this.ProductPrice) {
      objData.price = this.ProductPrice;
    }
    if (!!this.PromotionalProduct) {
      objData.refPromotionalProductID = this.PromotionalProduct;
    }

    let validate = await this.validateControls();
    if (validate) {
      res = await this.httpApiService.addSchemes(objData, refCompanyId);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Schemes added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }

  async getAllPromotionalProduct() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    res = await this.httpApiService.getAllPromotionalProducts(refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      let listOfPromotionalProducts = res.json();
      this.listOfPromotionalProducts = listOfPromotionalProducts.data;
    }
  }

  async resetControls() {
    this.ProductName = "";
    this.ProductImage = "";
    this.ProductPrice;
    this.PromotionalProduct = 0;
    this.IsActive = '1';
  }

  checkNull(val) {
    return !!val;
  }

  async validateControls() {
    debugger;
    let flag: number;
    flag = 1;
    if (this.checkNull(this.ProductName)) {
      flag = 0;
    }
    if (flag == 1) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await false;
    } else {
      return await true;
    }
  }

  onFileSelected(event) {

    this.imageLink = <File>event.target.files[0];
    console.log("image link", this.imageLink);
  }
}
