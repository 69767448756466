import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import * as moment from "moment";

const TOKEN_KEY = "auth-token";

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  public mode = "Production";

  // public mode = "Dev";
  // public mode = "Testing";
  public couponData: any;
  public appName = "TSV World";
  public appTitle = "MobiOffice";

  constructor(private storage: Storage) {
    if (window && "location" in window && "protocol" in window.location && "pathname" in window.location && "host" in window.location) {
      if (window.location.hostname.split(".").length > 1) {
        this.appName = window.location.hostname;
        this.appTitle = window.location.hostname.split(".")[1];
        console.log("company name", this.appName);
      }
    }
  }

  getBaseUrl() {
    if (this.mode == "Production") {
      return "https://tsv-api.mobioffice.io/api/";
    } else if (this.mode == "Testing") {
      return "https://talkbrite-dev.atishae.com/api/";
    } else {
      return "http://localhost:3000/api/";
    }
  }

  async getAPIHeader() {
    let token = await this.storage.get(TOKEN_KEY);
    var obj = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token
      //"Access-Control-Allow-Origin": "*"
    };

    return obj;
  }

  async isValidEmail(email) {
    const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email);

    if (re) {
      return true;
    } else {
      return false;
    }
  }

  getAppName() {
    return this.appName;
  }

  getAppTitle() {
    return this.appTitle;
  }

  async print() {
    let css = "@media print { @page { size: A4; } }";
    // if (document.getElementsByTagName("app-invoice-template").length != 0) {
    //   css = '@media print { @page { size: A4; } }';
    // }
    if (!!document.getElementById("stylePrint")) {
      document.getElementById("stylePrint").innerHTML = css;
    } else {
      let head = document.head || document.getElementsByTagName("head")[0],
        style: any = document.createElement("style");
      head.appendChild(style);
      style.type = "text/css";
      style.id = "stylePrint";
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
    console.log("============>", css);
    window.print();
  }
}
