import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, ToastController, AlertController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageCostDefinitionPage } from "../manage-cost-definition/manage-cost-definition.page";
@Component({
  selector: 'app-cost-definition',
  templateUrl: './cost-definition.component.html',
  styleUrls: ['./cost-definition.component.scss']
})
export class CostDefinitionComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public listOfCostDefinitions: any;
  public items: any;
  public manageSelectedCostDefinition = "";

  public selectedCostDefinition = "";
  public itemsActions: any;
  public listOfActions: any;
  public listOfActionPermissions: any;
  public flagButton = "";
  public id: any;
  public country: any;
  public shippingMethod: any;
  public perKgRate: any;
  public updateButton: any = false;
  public refCompanyId: any;
  public allShippingMethods: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllCostDefinitions();
    await this.getAllShippingMethod();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllShippingMethod() {
    let res: any;

    res = await this.httpApiService.getAllShippingMethod();
    if (res.status == 0) {
      console.log("error");
    } else {
      this.allShippingMethods = res.json().data;
      console.log("getAllShippingMethod res", res, this.allShippingMethods);
    }
  }
  async getAllCostDefinitions() {
    let res: any;

    res = await this.httpApiService.getAllCostDefinitions(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfCostDefinitions = res.json();
      this.setItems();
      console.log("getAllCostDefinitions res", res, this.listOfCostDefinitions);
    }
  }

  setItems() {
    this.items = this.listOfCostDefinitions.data;
  }

  async showButton(name) {
    console.log("call");
    if (name == "country") {
      this.flagButton = "country";
    } else if (name == "shippingMethod") {
      this.flagButton = "shippingMethod";
    } else if (name == "perKgRate") {
      this.flagButton = "perKgRate";
    }
  }

  async updateCostDefinitions() {
    let objData = { id: this.id, country: this.country, shippingMethod: this.shippingMethod, perKgRate: parseInt(this.perKgRate), refCompanyId: this.refCompanyId };
    console.log(objData);
    let res = await this.httpApiService.updateCostDefinitions(objData);
    let resp = res.json();
    console.log("resp", resp);
    if (resp.error) {
      let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
      toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: "Cost Definition update successfully", duration: 3000, position: "top", color: "success" });
      toast.present();
    }
    this.getAllCostDefinitions();
  }

  async searchCostDefinition(country) {
    if (country) {
      country = country.country;
    } else {
      country = this.manageSelectedCostDefinition;
    }

    this.updateButton = false;
    this.flagButton = null;
    console.log(country);
    let tmpcountry = "";
    this.items = this.listOfCostDefinitions.data;
    if (country != undefined) {
      tmpcountry = country;
      this.selectedCostDefinition = country;
      this.manageSelectedCostDefinition = country;
    } else {
      this.selectedCostDefinition = this.manageSelectedCostDefinition;
      tmpcountry = this.manageSelectedCostDefinition;
    }
    console.log("country", country, tmpcountry);
    let selectedCostDefinitionId = this.items.filter(function (search) {
      if (search.country == tmpcountry) {
        return search.id;
      }
    });
    console.log("selectedCostDefinitionId", selectedCostDefinitionId);
    if (selectedCostDefinitionId.length > 0) {
      let costDefinitionId = selectedCostDefinitionId[0].id;
      let country = selectedCostDefinitionId[0].country;
      console.log(country, costDefinitionId);
      let res: any;
      console.log("ids", costDefinitionId, this.refCompanyId);
      res = await this.httpApiService.getCostDefinitionDetail(costDefinitionId, this.refCompanyId);
      if (res.status == 0) {
      } else {
        console.log("res", res, res.json());
        let DesignationData = res.json().data;
        console.log(DesignationData);
        this.country = DesignationData.country;
        this.shippingMethod = DesignationData.shippingMethod;
        this.perKgRate = DesignationData.perKgRate;
        this.id = DesignationData.id;
        console.log("this.listOfActions", this.listOfActionPermissions, this.selectedCostDefinition);
      }
    } else {
      this.country = "";
      this.shippingMethod = "";
      this.perKgRate = "";
    }
  }

  async newCostDefinition() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: ManageCostDefinitionPage,
      componentProps: { items: this.items }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAllCostDefinitions();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

}
