import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-summary-report-products",
  templateUrl: "./summary-report-products.page.html",
  styleUrls: ["./summary-report-products.page.scss"]
})
export class SummaryReportProductsPage implements OnInit {
  public para: any;
  public type: any;
  public subType: any;
  public reportData: any;
  public refCompanyId: any;
  public fromDate: any;
  public toDate: any;
  public fromDatePast: any;
  public toDatePast: any;
  public companyWebHookBaseURL: any;
  constructor(
    private modalCtrl: ModalController,
    public storage: Storage,
    private navParams: NavParams,
    public ConfigService: ConfigService,
    public authService: AuthenticationService,
    private toastCtrl: ToastController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public events: Events
  ) {}

  ngOnInit() {
    debugger;
    this.refCompanyId = this.navParams.get("refCompanyId");
    this.type = this.navParams.get("type");
    this.subType = this.navParams.get("subType");

    this.fromDate = this.navParams.get("fromDate");
    this.toDate = this.navParams.get("toDate");
    this.fromDatePast = this.navParams.get("fromDatePast");
    this.toDatePast = this.navParams.get("toDate");
    this.companyWebHookBaseURL = this.navParams.get("companyWebHookBaseURL");

    this.loadData();
  }
  async loadData() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getDashboardProductDetail(this.refCompanyId, this.type, this.subType, this.fromDate, this.toDate, 99999);
    await this.httpApiService.hideLoading();
    this.reportData = res.json().data;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
