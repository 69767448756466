import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./services/auth-guard/auth-guard.service";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", loadChildren: "./public/login/login.module#LoginPageModule" },
  { path: "register", loadChildren: "./public/register/register.module#RegisterPageModule" },
  {
    path: "app",
    canActivate: [AuthGuardService],
    loadChildren: "./app/apps-routing.module#AppsRoutingModule"
  },
  { path: "changePassword", loadChildren: "./public/change-password/change-password.module#ChangePasswordPageModule" },
  { path: "forgotPassword", loadChildren: "./public/forgot-password/forgot-password.module#ForgotPasswordPageModule" },
  { path: "summaryReport", loadChildren: "./components/actions/summary-report/summary-report.module#SummaryReportPageModule" },
  { path: "printCoupon", loadChildren: "./public/print-coupon/print-coupon.module#PrintCouponPageModule" },
  { path: "signUp", loadChildren: "./public/sign-up/sign-up.module#SignUpPageModule" },
  { path: "manageFormTemplate", loadChildren: "./components/actions/manage-form-template/manage-form-template.module#ManageFormTemplatePageModule" },
  { path: "summaryReportProducts", loadChildren: "./components/actions/summary-report-products/summary-report-products.module#SummaryReportProductsPageModule" },
  { path: 'orderQr/:id', loadChildren: './public/order-qr/order-qr.module#OrderQrPageModule' },
  { path: 'add-payment-methods', loadChildren: './components/actions/add-payment-methods/add-payment-methods.module#AddPaymentMethodsPageModule' },
  { path: 'add-schemes', loadChildren: './components/actions/add-schemes/add-schemes.module#AddSchemesPageModule' },
  { path: 'add-shipping-method', loadChildren: './components/actions/add-shipping-method/add-shipping-method.module#AddShippingMethodPageModule' },
  { path: 'add-addons', loadChildren: './components/actions/add-addons/add-addons.module#AddAddonsPageModule' },
  { path: 'add-socialmdedia', loadChildren: './components/actions/add-socialmdedia/add-socialmdedia.module#AddSocialmdediaPageModule' },  { path: 'manage-courier-service', loadChildren: './components/actions/manage-courier-service/manage-courier-service.module#ManageCourierServicePageModule' },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
