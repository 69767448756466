import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";
// import { IonicSelectableModule } from 'ionic-selectable';

@Component({
  selector: "app-manage-roles",
  templateUrl: "./manage-roles.page.html",
  styleUrls: ["./manage-roles.page.scss"]
})
export class ManageRolesPage implements OnInit {
  public roleDetails: any;
  public type: any;
  public roleName: any;
  public newRoleName: any;
  public description: any;
  public title: String;
  public listOfActions: any;
  public listOfActionsPermissions: any;
  public selectedActionName: any;
  public selectedPermissionName: any;

  constructor(private authService: AuthenticationService, public storage: Storage, public navParams: NavParams, public modalCtrl: ModalController, public toastCtrl: ToastController, public httpApiService: HttpApiService, public configService: ConfigService) {
    this.roleDetails = this.navParams.get("role");
    this.type = this.navParams.get("type");
    if (this.roleDetails.length > 0) {
      this.roleName = this.roleDetails[0].roleName;
    }
    if (this.type == "1") {
      this.title = "Add new Role Permission";
    } else {
      this.title = "Add Role";
    }
    console.log("role details", this.roleDetails, this.type);
    this.getAllActions();
  }

  ngOnInit() {}

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  onActionSelect() {
    console.log(this.selectedActionName);
    if (this.selectedActionName != "") {
      console.log(this.listOfActions);
      this.listOfActionsPermissions = this.listOfActions.filter(action => {
        if (action.actionName == this.selectedActionName) {
          return action.actionPermission;
        }
      });
      console.log(this.listOfActionsPermissions);
      if (this.listOfActionsPermissions[0].actionPermission.length > 0) {
        this.listOfActionsPermissions = this.listOfActionsPermissions[0].actionPermission;
        console.log("list of action permissions", this.listOfActionsPermissions);
      } else {
        this.listOfActionsPermissions = [];
      }
    }
  }

  async getAllActions() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllActions(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    }
    this.listOfActions = res.json().data;
    console.log("list of actions by http", res, this.listOfActions);
  }

  async addRoleActionPermission() {
    let validate = await this.validateControls();
    if (!validate) {
      let roleId: any;
      let actionPermission: any;
      let actionPermissionId: any;
      roleId = this.roleDetails[0].id;
      actionPermission = this.listOfActions.filter(action => {
        if (action.actionName == this.selectedActionName) {
          return action;
        }
      });
      console.log(actionPermission);
      actionPermissionId = actionPermission[0].actionPermission.filter(permission => {
        if (permission.permissionName == this.selectedPermissionName) {
          return permission.id;
        }
      });
      console.log(actionPermissionId);
      actionPermissionId = actionPermissionId[0].id;
      console.log("roleId", roleId, "actionPermissionId", actionPermissionId);
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        roleId: roleId,
        actionPermissionId: actionPermissionId
      };
      await this.httpApiService.showLoading();
      let res = await this.httpApiService.addRolePermission(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      }
      console.log("response", res);
      let resp = res.json();
      console.log("123124", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Action Assigned successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }

  async addRole() {
    let validate = await this.validateControls();
    console.log("newRoleName", this.newRoleName, this.description);
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        roleName: this.newRoleName,
        description: this.description
      };
      await this.httpApiService.showLoading();
      let res = await this.httpApiService.addRole(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      }
      console.log("response", res);
      let resp = res.json();
      console.log("123124", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Role created successfully.", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.type == "1") {
      if (this.roleName != null && this.selectedActionName != null && this.selectedActionName != "" && this.selectedPermissionName != null && this.selectedPermissionName != "") {
        flag = 0;
      }
    } else {
      if (this.newRoleName != null && this.newRoleName != "" && this.description != null && this.description != "") {
        flag = 0;
      }
    }
    console.log("flag=", flag);
    if (flag) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await true;
    } else {
      return await false;
    }
  }

  async resetControls() {
    if (this.type == "1") {
      this.selectedActionName = "";
      this.selectedPermissionName = "";
    } else {
      this.newRoleName = "";
      this.description = "";
    }
  }
}
