import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-manage-cart",
  templateUrl: "./manage-cart.page.html",
  styleUrls: ["./manage-cart.page.scss"]
})
export class ManageCartPage implements OnInit {
  public isModal: any;
  public item: any;
  public cart = "";
  public cartId: any;
  public quantity: any;
  public getAllProductData: any;
  public selectedProduct = "";
  public selectedProductVariant = "";
  public productData: any;
  public listOfValue = [];
  public listOfVariants = [];

  constructor(private modalCtrl: ModalController, public storage: Storage, private navParams: NavParams, public ConfigService: ConfigService, public authService: AuthenticationService, private toastCtrl: ToastController, private nav: NavController, public httpApiService: HttpApiService) {}

  ngOnInit() {
    this.isModal = this.navParams.get("flagModal");
    this.cartId = this.navParams.get("id");
    this.cart = this.navParams.get("cartName");
    this.item = this.navParams.get("item");
    console.log(this.isModal, this.item, this.cart, this.cartId);
    if (this.isModal == "updateCartProduct") {
      this.selectedProductVariant = this.item.title;
      this.quantity = this.item.quantity;
    } else {
      this.getAllProducts();
    }
  }

  async getAllProducts() {
    await this.httpApiService.showLoading();
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllProducts(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllProductData = res.json().data;
      console.log("get All product Data", this.getAllProductData);
    }
    await this.httpApiService.hideLoading();
  }

  async getProductDetail() {
    await this.httpApiService.showLoading();
    console.log("selected product", this.selectedProduct);
    if (this.selectedProduct != "") {
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      res = await this.httpApiService.getProductDetailById(this.selectedProduct, refCompanyId);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json().data;
        console.log(data);
        this.productData = data[0];
        if (data[0].listOfParameter && data[0].listOfParameter.length > 0) {
          this.listOfValue = data[0].listOfParameter;
          // this.productData = this.listOfValue;
        } else {
          this.listOfValue = [];
        }
        if (data[0].listOfVariants && data[0].listOfVariants.length > 0) {
          this.listOfVariants = data[0].listOfVariants;
        } else {
          this.listOfVariants = [];
        }
        console.log(this.listOfValue, this.listOfVariants);
      }
    } else {
      this.selectedProduct = "";
    }
    await this.httpApiService.hideLoading();
  }

  async updateCart() {
    console.log("update cart", this.selectedProductVariant, typeof this.selectedProductVariant, this.quantity, typeof this.quantity);
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        id: refCompanyId,
        cartId: this.cartId,
        cartDetail: {
          PvID: this.selectedProductVariant,
          quantity: this.quantity
        }
      };
      console.log(jsonObj);
      let res: any;
      res = await this.httpApiService.updateCart(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({ message: "Product added successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async updateProductQuantity() {
    console.log("update product quantity", this.item.PvID, typeof this.selectedProductVariant, this.quantity, typeof this.quantity);
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = { id: refCompanyId, cartId: this.cartId, cartDetail: { PvID: this.item.PvID, quantity: this.quantity } };
      console.log(jsonObj);
      let res: any;
      res = await this.httpApiService.updateProductQuantity(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({ message: "Quantity updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async validateControls() {
    if (this.quantity > 0 && this.selectedProductVariant != "" && this.cartId != undefined) {
      return false;
    } else {
      if (this.quantity <= 0) {
        let toast = await this.toastCtrl.create({ message: "Quantity can not be zero or negative", duration: 2000, position: "top", color: "danger" });
        await toast.present();
        return true;
      } else {
        let toast = await this.toastCtrl.create({ message: "Please insert the details", duration: 2000, position: "top", color: "danger" });
        await toast.present();
        return true;
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss(this.isModal);
  }
}
