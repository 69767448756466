import { Component, OnInit } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
@Component({
  selector: "app-manage-form-template",
  templateUrl: "./manage-form-template.page.html",
  styleUrls: ["./manage-form-template.page.scss"]
})
export class ManageFormTemplatePage implements OnInit {
  public name: any;
  public type: any;
  public typeId: any;
  constructor(public modalCtrl: ModalController, public storage: Storage, public httpApiService: HttpApiService, public authService: AuthenticationService, public toastCtrl: ToastController) {}

  async ngOnInit() {}

  async addForm() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let jsonObj = {
      name: this.name,
      type: this.type,
      typeId: this.typeId,
      companyId: refCompanyId
    };
    console.log(jsonObj);
    let validate = await this.validator();
    if (validate == true) {
      let res = await this.httpApiService.createFormTemplate(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          if (data.data) {
            let toast = await this.toastCtrl.create({ message: "Form template added successfully", duration: 3000, position: "top", color: "success" });
            await toast.present();
            await this.resetForm();
          } else {
            let toast = await this.toastCtrl.create({ message: data, duration: 3000, position: "top", color: "danger" });
            await toast.present();
            await this.resetForm();
          }
        }
      }
    }
  }
  async closeModal() {
    this.modalCtrl.dismiss();
  }
  async validator() {
    console.log("new", this.name, this.type, this.typeId);
    if (this.name == undefined || this.type == undefined || this.typeId == undefined || this.name == "" || this.type == "" || this.typeId == "" || this.name == null || this.type == null || this.typeId == null) {
      let toast = await this.toastCtrl.create({ message: "Please fill data", duration: 3000, position: "top", color: "danger" });
      await toast.present();
      return false;
    } else {
      console.log("True");
      return true;
    }
  }
  async resetForm() {
    this.name = "";
    this.type = "";
    this.typeId = "";
  }
}
