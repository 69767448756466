import { Component, Input, OnInit } from '@angular/core';
import * as converter from "number-to-words"
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss']
})
export class InvoiceTemplateComponent implements OnInit {

  @Input() companyDetail: any;
  @Input() shipId: any;

  public shipDetails: any;
  public companyData: any;
  public AmountInwords: string = '';
  public totalAmount = 0;
  public productDetails = [];
  public numbers = [];
  public courierDetails: any;
  constructor(
    private authService: AuthenticationService,
    public storage: Storage,
    public httpApiService: HttpApiService, public configService: ConfigService) { }

  ngOnInit() {
    this.numbers = Array(30).fill(10);
    this.getShipmentDetailByShipId()
    this.companyData = JSON.parse(this.companyDetail.config)
  }

  async getShipmentDetailByShipId() {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res: any;
    res = await this.httpApiService.getShipmentDetailByShipId(refCompanyId, this.shipId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      await this.httpApiService.hideLoading();
      let dataValue = res.json().data;
      console.log(dataValue)
      if (!!dataValue) {
        this.shipDetails = dataValue[0];
        this.productDetails = this.shipDetails.details;
        this.courierDetails = this.shipDetails.courierDetails;
        this.totalAmount = 0
        for (let i = 0; i < this.productDetails.length; i++) {
          this.totalAmount += (this.productDetails[i].priceWithShipCost * this.productDetails[i].quantity)
        }
        this.AmountInwords = converter.toWords(this.totalAmount)
      }
    }
  }


}
