import { Component, Input, ViewChild } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, Slides } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageProductPage } from "../../actions/manage-product/manage-product.page";

@Component({
  selector: 'app-bulk-edit-parameters',
  templateUrl: './bulk-edit-parameters.component.html',
  styleUrls: ['./bulk-edit-parameters.component.scss']
})
export class BulkEditParametersComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  @ViewChild("slides") slides: Slides;

  public getAllProductData: any;
  public items: any;
  public getAllProductParamValues: any;
  public arrowKeyLocation = -1;
  public selectedProduct = "";
  public offset = 0;
  public limit = 30;
  public allCompanyList: any;
  public SKU: string = '';
  public refCompanyId = 0;
  public Company = 0;
  public id: number = 0;
  public all: boolean = false;
  public getAllProductParam: any;
  public PPvMId: any;
  public name = "";
  public item: any;
  public productParamValue: any;
  public productParamName: any;
  public listOfValue: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    this.refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();

    let res1 = await this.httpApiService.getAllCompany();

    await this.httpApiService.hideLoading();

    if (res1.json().isSuccess) {
      this.allCompanyList = res1.json().data;
    }
    this.getAllProductParameter();

    this.SKU = '';
    this.filterItems({
      target: {
        value: '%'
      }
    });
  }

  setItems() {
    this.items = this.getAllProductData;
  }

  async filterItems(ev: any) {
    this.selectedProduct = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      let res = await this.httpApiService.fetchProductsByPagination(this.offset, this.limit, val, this.refCompanyId);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().isSuccess) {
          this.getAllProductData = res.json().data;
          this.items = this.getAllProductData;
          this.arrowKeyLocation = -1;
        }
      }
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.getAllProductData;
    }
  }

  async bulkProduct() {
    console.log(this.productParamValue.id);
    let res = await this.httpApiService.bulkProduct({
      refCompanyId: this.refCompanyId, sku: this.SKU, all: this.all, refPPvMID: this.productParamValue.id
    });
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().isSuccess) {
        this.getAllProductData = res.json().data;
        this.items = this.getAllProductData;
        this.arrowKeyLocation = -1;
      }
    }
  }


  async getAllProductParameter() {
    await this.httpApiService.showLoading();
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllProductParameter(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllProductParam = res.json().data;
      this.onParamSelect(this.name);
      console.log("get All product param Data", this.getAllProductParam);
    }
  }

  onParamSelect(name) {
    if (name != undefined) {
      this.name = name;
    }
    console.log(this.name, this.productParamValue, typeof this.productParamValue);
    this.productParamValue = "";
    this.PPvMId = "";
    if (this.name != "" || this.productParamName != "") {
      let listOfValue = this.getAllProductParam.filter(param => {
        if (param.name == this.name) {
          return param.shop_productParameterValuesMaster;
        } else if (param.name == this.productParamName) {
          return param.shop_productParameterValuesMaster;
        }
      });
      console.log(listOfValue);
      if (listOfValue[0] && listOfValue[0].shop_productParameterValuesMaster.length > 0) {
        this.getAllProductParamValues = listOfValue[0].shop_productParameterValuesMaster;
      } else {
        this.getAllProductParamValues = [];
      }
      console.log("list of values", this.listOfValue, this.productParamName, this.productParamValue, this.getAllProductParam);
    }
  }


  async getProductDetail(ev) {
    if (!!this.SKU) {
      let sku = this.SKU;
      if (sku.split(",").indexOf(ev.sku) == -1) {
        this.SKU += "," + ev.sku;
      } else {
        console.log("error");
      }
    } else {
      this.SKU = ev.sku;
      this.id = Number(ev.id);
    }
  }

}
