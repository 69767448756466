import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, ToastController } from "@ionic/angular";
import { AddDesignationPage } from "../add-designation/add-designation.page";

@Component({
  selector: "app-manage-designation",
  templateUrl: "./manage-designation.component.html",
  styleUrls: ["./manage-designation.component.scss"]
})
export class ManageDesignationComponent implements OnInit {
  public listOfDesignation: any;
  public items: any;
  public manageSelectedDesignation = "";

  public selectedDesignation = "";
  public itemsActions: any;
  public listOfActions: any;
  public listOfActionPermissions: any;
  public description: "";
  public flagButton = "";
  public id: any;
  public DesignationName: any;
  public SortName: any;
  public priority: any;
  public parentsId: any = "";
  public updateButton: any = false;
  public refCompanyId: any;

  constructor(public storage: Storage, public httpApiService: HttpApiService, public modalCtrl: ModalController, public toastCtrl: ToastController) {}

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllDesignation();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllDesignation() {
    let res: any;

    res = await this.httpApiService.getAllDesignation(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfDesignation = res.json();
      this.setItems();
      console.log("listOfDesignation res", res, this.listOfDesignation);
    }
  }

  setItems() {
    this.items = this.listOfDesignation.data;
  }

  async showButton(name) {
    console.log("call");
    if (name == "DesignationName") {
      this.flagButton = "DesignationName";
    } else if (name == "SortName") {
      this.flagButton = "SortName";
    } else if (name == "priority") {
      this.flagButton = "priority";
    } else if (name == "parentsId") {
      this.flagButton = "parentsId";
    }
  }

  async updateDesignation() {
    if (this.parentsId == null) {
      this.parentsId = "";
    }
    let objData = { name: this.DesignationName, shortName: this.SortName, priority: parseInt(this.priority), parentsId: parseInt(this.parentsId), did: this.id };
    console.log(objData);
    let res = await this.httpApiService.updateDesignation(objData, this.refCompanyId);
    let resp = res.json();
    console.log("resp", resp);
    if (resp.error) {
      let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
      toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: "Designation update successfully", duration: 3000, position: "top", color: "success" });
      toast.present();
    }
    this.getAllDesignation();
  }

  async searchDesignation(DesignationName) {
    if (DesignationName) {
      DesignationName = DesignationName.name;
    } else {
      DesignationName = this.manageSelectedDesignation;
    }

    this.updateButton = false;
    this.flagButton = null;
    console.log(DesignationName);
    let tmpDesignationName = "";
    this.items = this.listOfDesignation.data;
    if (DesignationName != undefined) {
      tmpDesignationName = DesignationName;
      this.selectedDesignation = DesignationName;
      this.manageSelectedDesignation = DesignationName;
    } else {
      this.selectedDesignation = this.manageSelectedDesignation;
      tmpDesignationName = this.manageSelectedDesignation;
    }
    console.log("DesignationName", DesignationName, tmpDesignationName);
    let selectedDesignationId = this.items.filter(function(search) {
      if (search.name == tmpDesignationName) {
        return search.id;
      }
    });
    console.log("selectedDesignationId", selectedDesignationId);
    if (selectedDesignationId.length > 0) {
      let DesignationId = selectedDesignationId[0].id;
      let DesignationName = selectedDesignationId[0].name;
      console.log(DesignationName, DesignationId);
      let res: any;
      console.log("ids", DesignationId, this.refCompanyId);
      res = await this.httpApiService.getDesignationDetail(DesignationId, this.refCompanyId);
      if (res.status == 0) {
      } else {
        console.log("res", res, res.json());
        let DesignationData = res.json().data;
        console.log(DesignationData);
        this.DesignationName = DesignationData.name;
        this.SortName = DesignationData.shortName;
        this.priority = DesignationData.priority;
        this.id = DesignationData.id;
        this.parentsId = DesignationData.parentsId;
        console.log("this.listOfActions", this.listOfActionPermissions, this.selectedDesignation);
      }
    } else {
      this.DesignationName = "";
      this.SortName = "";
      this.priority = "";
      this.parentsId = "";
    }
  }

  async NewDesignation() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: AddDesignationPage,
      componentProps: { items: this.items, type: 2 }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAllDesignation();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
}
