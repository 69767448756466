import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, ToastController } from "@ionic/angular";
import { AddPaymentMethodsPage } from "../add-payment-methods/add-payment-methods.page";

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {
  public MethodName: String;
  public MethodImage: String;
  public MethodDiscount: Number = 0;
  public MethodFlat: Number = 0;
  public ProductOnline: Number = 0;

  public listOfProducts: any;
  public items: any;
  public manageSelectedDesignation = "";

  public selectedProduct = "";
  public itemsActions: any;
  public listOfActions: any;
  public listOfActionPermissions: any;
  public flagButton = "";
  public id: any;
  public priority: any;
  public updateButton: any = false;
  public refCompanyId: any;

  constructor(public storage: Storage, public httpApiService: HttpApiService, public modalCtrl: ModalController, public toastCtrl: ToastController) { }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllProducts();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllProducts() {
    let res: any;

    res = await this.httpApiService.getAllPaymentMethod(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfProducts = res.json();
      this.setItems();
      console.log("listOfProducts res", res, this.listOfProducts);
    }
  }

  setItems() {
    this.items = this.listOfProducts.data;
  }

  async updateDesignation() {
    let objData = {
    };
    if (!!this.MethodName) {
      objData['name'] = this.MethodName;
    }
    if (!!this.MethodImage) {
      objData['image'] = this.MethodImage;
    }
    if (!!this.MethodDiscount) {
      objData['discount'] = this.MethodDiscount;
    }
    if (!!this.MethodFlat) {
      objData['flat'] = this.MethodFlat;
    }
    if (!!this.ProductOnline) {
      objData['isOnline'] = this.ProductOnline;
    }
    if (!!this.id) {
      objData['shopPaymentMethodsId'] = this.id;
    }
    let res = await this.httpApiService.updatePaymentMethod(objData, this.refCompanyId);
    let resp = res.json();
    if (resp.error) {
      let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
      toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: "Designation update successfully", duration: 3000, position: "top", color: "success" });
      toast.present();
    }
    this.getAllProducts();
  }
  async flagButtonAssign(val) {
    this.flagButton = val;
  }
  async searchDesignation(ProductName) {
    if (ProductName) {
      ProductName = ProductName.name;
    } else {
      ProductName = this.manageSelectedDesignation;
    }

    this.updateButton = false;
    this.flagButton = null;
    console.log(ProductName);
    let tmpProductName = "";
    this.items = this.listOfProducts.data;
    if (ProductName != undefined) {
      tmpProductName = ProductName;
      this.selectedProduct = ProductName;
      this.manageSelectedDesignation = ProductName;
    } else {
      this.selectedProduct = this.manageSelectedDesignation;
      tmpProductName = this.manageSelectedDesignation;
    }
    console.log("ProductName", ProductName, tmpProductName);
    let selectedProductId = this.items.filter(function (search) {
      if (search.name == tmpProductName) {
        return search.id;
      }
    });
    console.log("selectedProductId", selectedProductId);
    if (selectedProductId.length > 0) {
      let DesignationId = selectedProductId[0].id;
      let ProductName = selectedProductId[0].name;
      console.log(ProductName, DesignationId);
      let res: any;
      console.log("ids", DesignationId, this.refCompanyId);
      res = await this.httpApiService.getPaymentMethodId(DesignationId, this.refCompanyId);
      if (res.status == 0) {
      } else {
        console.log("res", res, res.json());
        let MethodData = res.json().data;
        this.MethodName = MethodData.name;
        this.MethodImage = MethodData.image;
        this.MethodDiscount = MethodData.discount;
        this.MethodFlat = MethodData.flat;
        this.ProductOnline = MethodData.isOnline;
        this.id = MethodData.id;
      }
    } else {
      this.MethodName = "";
      this.MethodImage = "";
      this.MethodDiscount = 0;
      this.MethodFlat = 0;
      this.ProductOnline = 0;

    }
  }

  async NewDesignation() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: AddPaymentMethodsPage,
      componentProps: { items: this.items, type: 2 }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAllProducts();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
}
