import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-merge-work-areas",
  templateUrl: "./merge-work-areas.component.html",
  styleUrls: ["./merge-work-areas.component.scss"]
})
export class MergeWorkAreasComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public getAllWorkArea: any;
  public selectedFromWorkArea: any;
  public selectedToWorkArea: any;
  public manageSelectedFromWorkArea: any;
  public manageSelectedToWorkArea: any;
  public items: any;
  public itemsToWorkArea: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {}

  async ngOnInit() {
    await this.getWorkArea();
  }

  setItems() {
    this.items = this.getAllWorkArea;
  }

  setItemsToWorkArea() {
    this.itemsToWorkArea = this.getAllWorkArea;
  }

  async getWorkArea() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllWorkArea(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllWorkArea = res.json().data;
      this.setItems();
      this.setItemsToWorkArea();
      console.log("get All Work Area", this.getAllWorkArea);
    }
  }

  async selectFromWorkArea(workArea) {
    console.log("from work area", this.manageSelectedFromWorkArea, workArea);
    if (workArea != undefined) {
      this.manageSelectedFromWorkArea = workArea;
    }
    this.selectedFromWorkArea = this.manageSelectedFromWorkArea;
  }

  async selectToWorkArea(workArea) {
    console.log("to work area", this.manageSelectedToWorkArea, workArea);
    if (workArea != undefined) {
      this.manageSelectedToWorkArea = workArea;
    }
    this.selectedToWorkArea = this.manageSelectedToWorkArea;
  }

  async mergeWorkAreas() {
    console.log("merge", this.manageSelectedFromWorkArea, this.selectedFromWorkArea, this.manageSelectedToWorkArea, this.selectedToWorkArea);
    let tmpFromWorkArea, tmpToWorkArea;
    tmpFromWorkArea = this.manageSelectedFromWorkArea;
    let selectedFromWorkAreaId = this.getAllWorkArea.filter(function(search) {
      if (search.name == tmpFromWorkArea) {
        return search.id;
      }
    });
    tmpToWorkArea = this.manageSelectedToWorkArea;
    let selectedToWorkAreaId = this.getAllWorkArea.filter(function(search) {
      if (search.name == tmpToWorkArea) {
        return search.id;
      }
    });
    console.log("selectedFromWorkAreaId", selectedFromWorkAreaId, "selectedToWorkAreaId", selectedToWorkAreaId, "company id ", this.cid);
    if (selectedFromWorkAreaId.length > 0 && selectedToWorkAreaId.length > 0) {
      if (selectedFromWorkAreaId[0].id == selectedToWorkAreaId[0].id) {
        let toast = await this.toastCtrl.create({ message: "From and To work area can not be same", duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let res: any;
        await this.httpApiService.showLoading();
        res = await this.httpApiService.mergeWorkArea(this.cid, selectedFromWorkAreaId[0].id, selectedToWorkAreaId[0].id);
        await this.httpApiService.hideLoading();
        if (res.status == 401) {
          this.authService.logout();
        } else {
          if (res.json().error) {
            let toast = await this.toastCtrl.create({
              message: res.json().error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          } else {
            console.log("res", res);
            if (res.json().isSuccess) {
              let toast = await this.toastCtrl.create({
                message: res.json().data,
                duration: 3000,
                position: "top",
                color: "success"
              });
              await toast.present();
              await this.getWorkArea();
              this.reset();
            }
          }
        }
      }
    }
  }

  async reset() {
    this.selectedFromWorkArea = "";
    this.selectedToWorkArea = "";
    this.manageSelectedFromWorkArea = "";
    this.manageSelectedToWorkArea = "";
  }
}
