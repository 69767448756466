import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, ToastController } from "@ionic/angular";
import { AddAddonsPage } from "../add-addons/add-addons.page";
// import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-addons",
  templateUrl: "./addons.component.html",
  styleUrls: ["./addons.component.scss"]
})
export class AddonsComponent implements OnInit {
  public ProductName: String;
  public ProductPrice: Number;
  public ProductImage: String;
  public Description: String;

  public listOfProducts: any;
  public items: any;
  public manageSelectedDesignation = "";
  public imageLink: File = null;

  public selectedProduct = "";
  public itemsActions: any;
  public listOfActions: any;
  public listOfActionPermissions: any;
  public flagButton = "";
  public id: any;
  public priority: any;
  public updateButton: any = false;
  public refCompanyId: any;
  public listOfPromotionalProducts: any;
  constructor(
    //private authService: AuthenticationService,
    public storage: Storage,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController
  ) {}

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAll();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAll() {
    let res: any;

    res = await this.httpApiService.getAllAddOns(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfProducts = res.json();
      this.setItems();
      console.log("listOfProducts res", res, this.listOfProducts);
    }
  }

  setItems() {
    this.items = this.listOfProducts.data;
  }

  checkNull(val) {
    return !!val;
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.checkNull(this.ProductName)) {
      flag = 0;
    }
    if (flag == 1) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await false;
    } else {
      return await true;
    }
  }

  async update() {
    const formData: FormData = new FormData();
    if (!!this.imageLink) {
      formData.append("file", this.imageLink);
    }
    let objData: any = { formData: formData, name: this.ProductName, image: this.ProductImage, addOnId: this.id, description: this.Description };
    if (!!this.ProductPrice) {
      objData.price = this.ProductPrice;
    }

    let validate = await this.validateControls();
    if (validate) {
      let res = await this.httpApiService.updateAddon(objData, this.refCompanyId);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Addon update successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
      this.flagButton = "";
      this.getAll();
    }
  }

  async flagButtonAssign(val) {
    this.flagButton = val;
  }

  async search(ProductName) {
    if (ProductName) {
      ProductName = ProductName.name;
    } else {
      ProductName = this.manageSelectedDesignation;
    }

    this.updateButton = false;
    this.flagButton = null;
    console.log(ProductName);
    let tmpProductName = "";
    this.items = this.listOfProducts.data;
    if (ProductName != undefined) {
      tmpProductName = ProductName;
      this.selectedProduct = ProductName;
      this.manageSelectedDesignation = ProductName;
    } else {
      this.selectedProduct = this.manageSelectedDesignation;
      tmpProductName = this.manageSelectedDesignation;
    }
    console.log("ProductName", ProductName, tmpProductName);
    let selectedProductId = this.items.filter(function(search) {
      if (search.name == tmpProductName) {
        return search.id;
      }
    });
    console.log("selectedProductId", selectedProductId);
    if (selectedProductId.length > 0) {
      let DesignationId = selectedProductId[0].id;
      let res: any;
      res = await this.httpApiService.getAddOnById(DesignationId, this.refCompanyId);
      if (res.status == 0) {
      } else {
        let DesignationData = res.json();
        this.ProductName = DesignationData.name;
        this.Description = DesignationData.description;
        this.ProductPrice = DesignationData.price;
        this.ProductImage = DesignationData.image;
        this.id = DesignationData.id;
      }
    } else {
      this.ProductName = "";
      this.ProductPrice = 0;
      this.ProductImage = "";
      this.Description = "";
    }
  }

  async add() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: AddAddonsPage,
      componentProps: { items: this.items, type: 2 }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAll();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  onFileSelected(event) {
    this.imageLink = <File>event.target.files[0];
    this.flagButtonAssign("image");
    console.log("image link", this.imageLink);
  }
}
