import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-manage-application",
  templateUrl: "./manage-application.page.html",
  styleUrls: ["./manage-application.page.scss"]
})
export class ManageApplicationPage implements OnInit {
  public modalTitle: any;
  public getAppId: any;
  public applicationId: any;
  public allCompanyList: any;
  public companyId: any;
  public oneSignalAppId: any;
  public oneSignalUserId: any;
  public companyAppId: any;
  public appName: any;
  constructor(public navParams: NavParams, public httpApiService: HttpApiService, public modalCtrl: ModalController, public authService: AuthenticationService, public toastCtrl: ToastController) {}

  async ngOnInit() {
    this.modalTitle = await this.navParams.get("modalTitle");
    if (this.modalTitle == "addCompany") {
      this.getAppId = await this.navParams.get("appId");
      let res1 = await this.httpApiService.getAllCompany();
      if (res1.json().isSuccess) {
        this.allCompanyList = res1.json().data;
        console.log("allCompanyList", this.allCompanyList);
      }
    }
  }
  async addCompany() {
    if (this.companyId != "" && this.companyId != undefined && this.companyId != null) {
      let jsonData = {
        appId: this.getAppId,
        id: parseInt(this.companyId)
      };
      console.log(jsonData);
      let res = await this.httpApiService.addCompanyApplication(jsonData);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      console.log(resp);
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Company saved successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    } else {
      let toast = await this.toastCtrl.create({
        message: "Select company",
        duration: 3000,
        position: "top",
        color: "danger"
      });
      toast.present();
    }
  }
  async addApp() {
    let valid = await this.validation();
    if (valid) {
      let objData = {
        name: this.appName,
        appId: this.applicationId,
        oneSignalAppId: this.oneSignalAppId,
        oneSignalUserId: this.oneSignalUserId
      };
      console.log(objData);
      let res = await this.httpApiService.AddApplication(objData);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      console.log(resp);
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Application saved successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    } else {
      let toast = await this.toastCtrl.create({
        message: "Same field is empty",
        duration: 3000,
        position: "top",
        color: "danger"
      });
      toast.present();
    }
  }
  public closeModal() {
    this.modalCtrl.dismiss(this.modalTitle);
  }
  async validation() {
    if (this.appName != null && this.appName != undefined && this.appName != "" && this.applicationId != null && this.applicationId != undefined && this.applicationId != "") {
      return true;
    } else {
      return false;
    }
  }
}
