import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { CodeEditorComponent } from "../../codeEditor/code-editor/code-editor.component";
import { ManageProcessTemplatePage } from "../manage-process-template/manage-process-template.page";
import { ModalController, ToastController, AlertController } from "@ionic/angular";
import { ComponentsModule } from "../../components.module";

@Component({
  selector: "app-process-template",
  templateUrl: "./process-template.component.html",
  styleUrls: ["./process-template.component.scss"]
})
export class ProcessTemplateComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public templates: any;
  public templateStates: any;
  public selectedTemplate: any;
  public selectedTemplateState: any;
  public stateDesc: any;
  public formName: any;
  public type: any;
  public typeId: any;
  public manageSelectedTemplate: any;
  public manageSelectedState: any;
  public items: any;
  public itemsStates: any;
  public arrowKeyLocationState = -1;
  public formTemplates: any;
  public selectedForm: any;
  public flagButton: any;
  public processId: any;
  public formTemplateId: any;
  processvalue: any;
  public blocksItem: any = [];
  filedata: any;
  fileContent: any;
  jsonData: any;

  constructor(public httpApiService: HttpApiService, public modalCtrl: ModalController, public toastCtrl: ToastController, private alertCtrl: AlertController) { }

  async ngOnInit() {
    await this.getAllProcessTemplates();
  }

  async getAllProcessTemplates() {
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getAllProcessTemplates(this.cid);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      this.templates = res.json().data;
      this.setTemplates();
    }
  }

  async showStates(process) {
    let tmpTemplateName;
    if (process != undefined) {
      tmpTemplateName = process.templateName;
      this.selectedTemplate = process;
      this.manageSelectedTemplate = process.templateName;
    } else {
      this.selectedTemplate = this.manageSelectedTemplate;
      tmpTemplateName = this.manageSelectedTemplate;
    }
    let selectedTemplateId = this.items.filter(function (search) {
      if (search.templateName == tmpTemplateName) {
        return search.id;
      }
    });
    let id;
    if (selectedTemplateId.length > 0) {
      id = selectedTemplateId[0].id;
      this.resetControls();
      await this.findAllFormTemplates();
      await this.httpApiService.showLoading();
      let res = await this.httpApiService.getAllProcessTemplatesStates(id, this.cid);
      let res1 = await this.httpApiService.getAllProcessTemplateConfig(id, this.cid);
      await this.httpApiService.hideLoading();
      if (res.json().isSuccess) {
        this.templateStates = res.json().data;
        this.setStates();
      }
      this.processId = id;
      this.arrowKeyLocationState = -1;
      console.log("this.templateStates", this.templateStates);
    } else {
      this.templateStates = [];
    }
  }

  async findAllFormTemplates() {
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.findAllFormTemplates(this.cid);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      this.formTemplates = res.json().data;
    }
    console.log("form templates", this.formTemplates);
  }

  async readUploadedFileAsText(inputFile) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

  async uploadFile(fileList: FileList) {
    let file = fileList[0];
    try {
      await this.httpApiService.showLoading();
      const fileContents: any = await this.readUploadedFileAsText(file)
      console.log(fileContents);
      let jsonObj = { fileContents: fileContents };

      let res = await this.httpApiService.importProcessTemplate(jsonObj, this.cid);

      await this.httpApiService.hideLoading();
      console.log("import Process Block response", res);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.data, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: resp.data, duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    } catch (e) {
      console.warn(e.message)
    }
  }

  async saveText(text, filename) {
    var a = document.createElement('a');
    a.setAttribute('href', 'data:text/plain;charset=utf-u,' + encodeURIComponent(text));
    a.setAttribute('download', filename);
    a.click()
  }

  async exportProcessTemplate() {
    if (this.processId == undefined) {
      let toast = await this.toastCtrl.create({ message: "Select Process", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await true;
    } else {
      await this.httpApiService.showLoading();
      let jsonObj = { processId: this.processId };

      let res = await this.httpApiService.exportProcessTemplate(jsonObj, this.cid);

      console.log("export Process Block response", res);

      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.data, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let fileName = resp.data.fileName;
        this.saveText(JSON.stringify(resp.data.object), fileName);
        await this.httpApiService.hideLoading();
        let toast = await this.toastCtrl.create({ message: 'Your Export is Done.', duration: 3000, position: "top", color: "success" });
        toast.present();
      }

      // const fileTransfer: FileTransferObject = this.transfer.create();
      // this.storageDirectory = this.file.externalRootDirectory + 'Download/';
      //alert(res.json().data.split("/").pop())
      //const url = "/home/bhumi/Documents/office/talkbrite/" + res.json().data;
      //alert('file exported at this location' + url)
    }
  }

  async showStatesDescription(state) {
    console.log(this.manageSelectedState);
    let refProcessTemplateId;
    let id;
    let refFormTemplateId;
    if (state != undefined) {
      this.selectedTemplateState = state;
      this.manageSelectedState = state.stateName;
      refProcessTemplateId = state.refProcessTemplateId;
      id = state.id;
      refFormTemplateId = state.refFormTemplateId;
    } else {
      let state = this.templateStates.filter(state => {
        if (state.stateName == this.manageSelectedState) {
          return state;
        }
      });
      console.log("state", state);
      this.selectedTemplateState = state[0];
      this.manageSelectedState = state[0].stateName;
      refProcessTemplateId = state[0].refProcessTemplateId;
      id = state[0].id;
      refFormTemplateId = state[0].refFormTemplateId;
    }
    console.log(state);
    let duration: string, blockNo: string;
    this.formName = null;
    this.type = null;
    this.typeId = null;
    this.selectedForm = null;

    if (this.selectedTemplateState.processBy != null && this.selectedTemplateState.processBy.search("processBlock:") == -1) {
    } else if (this.selectedTemplateState.processBy != null) {
      let i: number,
        tmp: string,
        tempBlocks: any = [];

      i = this.selectedTemplateState.processBy.search("processBlock:");
      tmp = this.selectedTemplateState.processBy.slice(i);

      // fetch all block and hour
      tempBlocks = tmp
        .split(/{([^}]+)}/g)
        .filter(Boolean)[1]
        .split(",");

      // split and push in blocksItem
      this.blocksItem = [];
      tempBlocks.forEach(element => {
        this.blocksItem.push(element.trim().split(":"));
      });
      // console.log("blocks", this.blocksItem);
      blockNo = tmp.match(/\d/g)[0];
      duration = tmp.match(/\d/g)[1];
    }
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getStateDescription(refProcessTemplateId, id, refFormTemplateId, this.cid);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      this.stateDesc = res.json().data;
      if (this.stateDesc.formTemplate != undefined) {
        this.formName = this.stateDesc.formTemplate.formName;
        this.type = this.stateDesc.formTemplate.type;
        this.typeId = this.stateDesc.formTemplate.typeId;
        this.selectedForm = this.stateDesc.formTemplate;
      }
      if (this.stateDesc && this.stateDesc.processBlocks != null) {
        this.blocksItem.filter(value => {
          this.stateDesc.processBlocks.filter(block => {
            if (value[1] != "" && value[1] != null && value[1] != undefined) {
              if (block.name == value[0]) {
                block.duration = value[1] + " hours";
              } else {
                // block.duration = "none";
              }
            }
          });
        });
      }
    } else {
      this.stateDesc = null;
      let toast = await this.toastCtrl.create({
        message: res.json().error,
        duration: 3000,
        position: "top",
        color: "danger"
      });
      await toast.present();
    }
    console.log("this.stateDesc", this.stateDesc);
  }

  async updateForm() {
    console.log("update form", this.selectedForm, this.selectedTemplateState);
    if (this.selectedTemplateState != null && this.selectedTemplateState != undefined) {
      if (this.selectedForm == "") {
        this.selectedForm = {};
        this.selectedForm.id = 0;
      }
      let jsonObj = {
        jsonObj: {
          searchObj: [
            {
              refCompanyId: this.cid
            },
            {
              id: this.selectedTemplateState.id
            },
            {
              refProcessTemplateId: this.selectedTemplateState.refProcessTemplateId
            }
          ],
          updateObj: { refFormTemplateId: this.selectedForm.id }
        }
      };
      await this.httpApiService.showLoading();
      let res = await this.httpApiService.updateTemplateState(this.cid, jsonObj);
      await this.httpApiService.hideLoading();
      if (res.json().isSuccess) {
        await this.showStates(this.selectedTemplate);
        this.templateStates.filter(state => {
          if (state.id == this.selectedTemplateState.id) {
            this.selectedTemplateState = state;
            console.log(" change this.selectedTemplateState", this.selectedTemplateState);
          }
        });
        console.log("this.selectedTemplateState", this.selectedTemplateState);
        await this.showStatesDescription(this.selectedTemplateState);
        let toast = await this.toastCtrl.create({ message: "Form updated successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: res.json().error, duration: 3000, position: "top", color: "danger" });
        await toast.present();
        if (this.stateDesc.formTemplate != undefined) {
          this.formName = this.stateDesc.formTemplate.formName;
          this.type = this.stateDesc.formTemplate.type;
          this.typeId = this.stateDesc.formTemplate.typeId;
          this.selectedForm = this.stateDesc.formTemplate;
        }
      }
    } else {
      let toast = await this.toastCtrl.create({ message: "Please select state", duration: 3000, position: "top", color: "danger" });
      await toast.present();
      if (this.stateDesc && this.stateDesc != null && this.stateDesc.formTemplate != undefined) {
        this.formName = this.stateDesc.formTemplate.formName;
        this.type = this.stateDesc.formTemplate.type;
        this.typeId = this.stateDesc.formTemplate.typeId;
        this.selectedForm = this.stateDesc.formTemplate;
      }
    }
  }

  async changeForm(event) {
    if (this.selectedForm != null) {
      this.formName = this.selectedForm.formName;
      this.type = this.selectedForm.type;
      this.typeId = this.selectedForm.typeId;
    }
    console.log("form", event.target.value, this.formName, this.type, this.typeId, this.selectedForm);
  }

  async showBlock(block) {
    console.log("block", block);
    let subtitle: string;
    if (block.name) {
      subtitle = block.name;
    } else {
      subtitle = "Default Block";
    }
    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: block.processBy, title: this.manageSelectedState, subtitle: subtitle }
    });
    modal.onDidDismiss().then(data => {
      console.log("data", data.data);
      if (!!data.data) {
        block.processBy = data.data;
        this.updateBlock(block, "processBy");
      }
    });
    return await modal.present();
  }

  async showReadReceipt(state) {
    console.log("state", state);
    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: state.readWebHook, title: this.manageSelectedState, subtitle: "Read Receipts" }
    });
    modal.onDidDismiss().then(data => {
      console.log("data", data.data);
      if (!!data.data) {
        state.readWebHook = data.data;
        this.updateBlock(state, "readWebHook");
      }
    });
    return await modal.present();
  }

  async updateBlock(block, updateField) {
    console.log("update block", block, updateField);
    let updateObj = {};
    if (updateField == "processBy") {
      updateObj = { processBy: block.processBy };
    } else if (updateField == "readWebHook") {
      updateObj = { readWebHook: block.readWebHook };
    }
    let jsonObj = {
      jsonObj: {
        searchObj: [
          {
            refCompanyId: this.cid
          },
          {
            id: block.id
          },
          {
            refProcessTemplateId: block.refProcessTemplateId
          }
        ],
        updateObj: updateObj
      }
    };
    console.log("jsonObj", jsonObj);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.updateTemplateState(this.cid, jsonObj);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      let toast = await this.toastCtrl.create({ message: "Updated successfully", duration: 3000, position: "top", color: "success" });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: res.json().error, duration: 3000, position: "top", color: "danger" });
      await toast.present();
    }
    console.log("res", res);
  }

  async resetControls() {
    this.manageSelectedState = null;
    this.stateDesc = null;
    this.formName = null;
    this.type = null;
    this.typeId = null;
  }

  setTemplates() {
    this.items = this.templates;
  }

  setStates() {
    this.itemsStates = this.templateStates;
  }

  async showButton(name) {
    if (name == "formName") {
      this.flagButton = "formName";
    } else if (name == "type") {
      this.flagButton = "type";
    } else if (name == "typeId") {
      this.flagButton = "typeId";
    }
  }
  async addProcess() {
    // console.log("addProcess");
    const modal = await this.modalCtrl.create({
      component: ManageProcessTemplatePage,
      componentProps: { type: 1 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async addProcessState() {
    // console.log("addProcessState");

    if (this.processId == undefined) {
      let toast = await this.toastCtrl.create({ message: "Select Process", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await true;
    } else {
      // console.log("refFormTemplateId", this.templateStates);
      const modal = await this.modalCtrl.create({
        component: ManageProcessTemplatePage,
        componentProps: { type: 2, processId: this.processId, formTemplate: this.formTemplates }
      });
      modal.onDidDismiss().then(data => {
        // console.log("data", data.data);
        //success
        this.showStates(this.selectedTemplate);
      });
      return await modal.present();
    }
  }
  async handleModalDismiss(d) {
    console.log("d", d);
    await this.getAllProcessTemplates();
  }
  async showProcessBlock(block) {
    let data;
    if (block == undefined) {
      data = {
        ProcessTemplateId: this.selectedTemplateState.refProcessTemplateId,
        processTemplateStateId: this.selectedTemplateState.id,
        processBy: "",
        name: "",
        PBid: ""
      };
    } else {
      data = {
        ProcessTemplateId: block.refProcessTemplateId,
        processTemplateStateId: block.refProcessTemplateStatesId,
        processBy: block.processBy,
        name: block.name,
        PBid: block.id
      };
    }

    const modal = await this.modalCtrl.create({
      component: ManageProcessTemplatePage,
      componentProps: { type: 3, data: data }
    });
    modal.onDidDismiss().then(data => {
      this.showStatesDescription(this.selectedTemplateState);
    });
    return await modal.present();
  }
  async deleteProcessBlock(blockId) {
    console.log(blockId, this.cid);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.deleteProcessBlock(this.cid, blockId);
    await this.httpApiService.hideLoading();
    console.log("delete Process Block response", res);
    let resp = res.json();
    if (resp.error) {
      let toast = await this.toastCtrl.create({ message: resp.data, duration: 3000, position: "top", color: "danger" });
      toast.present();
      this.showStatesDescription(this.selectedTemplateState);
    } else {
      let toast = await this.toastCtrl.create({ message: resp.data, duration: 3000, position: "top", color: "success" });
      toast.present();
      this.showStatesDescription(this.selectedTemplateState);
    }
  }
}
