import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, ToastController } from "@ionic/angular";
import { AddPromotionalProductPage } from "../add-promotional-product/add-promotional-product.page";

@Component({
  selector: 'app-promotional-products',
  templateUrl: './promotional-products.component.html',
  styleUrls: ['./promotional-products.component.scss']
})
export class PromotionalProductsComponent implements OnInit {
  public ProductName: String;
  public ProductDescription: String;
  public ProductPrice: Number;
  public ProductImage: String;

  public listOfProducts: any;
  public items: any;
  public manageSelectedDesignation = "";

  public selectedProduct = "";
  public itemsActions: any;
  public listOfActions: any;
  public listOfActionPermissions: any;
  public flagButton = "";
  public id: any;
  public priority: any;
  public updateButton: any = false;
  public refCompanyId: any;
  public imageLink: File = null;

  constructor(public storage: Storage, public httpApiService: HttpApiService, public modalCtrl: ModalController, public toastCtrl: ToastController) { }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllProducts();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllProducts() {
    let res: any;
    res = await this.httpApiService.getAllPromotionalProducts(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfProducts = res.json();
      this.setItems();
      console.log("listOfProducts res", res, this.listOfProducts);
    }
  }

  setItems() {
    this.items = this.listOfProducts.data;
  }

  async updateDesignation() {
    const formData: FormData = new FormData();

    formData.append("file", this.imageLink);

    let objData = { formData: formData, name: this.ProductName, description: this.ProductDescription, price: this.ProductPrice, image: this.ProductImage, shopPromotionalProductId: this.id };
    let res = await this.httpApiService.updatePromotion(objData, this.refCompanyId);
    let resp = res.json();
    if (resp.error) {
      let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
      toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: "Designation update successfully", duration: 3000, position: "top", color: "success" });
      toast.present();
    }
    await this.getAllProducts();
    await this.searchDesignation(this.selectedProduct);
  }
  async flagButtonAssign(val) {
    this.flagButton = val;
  }
  async searchDesignation(ProductName) {
    if (ProductName) {
      ProductName = ProductName.name;
    } else {
      ProductName = this.manageSelectedDesignation;
    }

    this.updateButton = false;
    this.flagButton = null;
    console.log(ProductName);
    let tmpProductName = "";
    this.items = this.listOfProducts.data;
    if (ProductName != undefined) {
      tmpProductName = ProductName;
      this.selectedProduct = ProductName;
      this.manageSelectedDesignation = ProductName;
    } else {
      this.selectedProduct = this.manageSelectedDesignation;
      tmpProductName = this.manageSelectedDesignation;
    }
    console.log("ProductName", ProductName, tmpProductName);
    let selectedProductId = this.items.filter(function (search) {
      if (search.name == tmpProductName) {
        return search.id;
      }
    });
    console.log("selectedProductId", selectedProductId);
    if (selectedProductId.length > 0) {
      let DesignationId = selectedProductId[0].id;
      let ProductName = selectedProductId[0].name;
      console.log(ProductName, DesignationId);
      let res: any;
      console.log("ids", DesignationId, this.refCompanyId);
      res = await this.httpApiService.getPromotionalId(DesignationId, this.refCompanyId);
      if (res.status == 0) {
      } else {
        console.log("res", res, res.json());
        let DesignationData = res.json().data;
        console.log(DesignationData);
        this.ProductName = DesignationData.name;
        this.ProductDescription = DesignationData.description;
        this.ProductPrice = DesignationData.price;
        this.ProductImage = DesignationData.image;
        this.id = DesignationData.id;
      }
    } else {
      this.ProductName = "";
      this.ProductDescription = "";
      this.ProductPrice = 0;
      this.ProductImage = "";

    }
  }

  async NewDesignation() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: AddPromotionalProductPage,
      componentProps: { items: this.items, type: 2 }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAllProducts();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  onFileSelected(event) {

    this.imageLink = <File>event.target.files[0];
    this.flagButtonAssign('image');
    console.log("image link", this.imageLink);
  }

}
