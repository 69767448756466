import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import * as moment from "moment";
import { SummaryReportPage } from "../summary-report/summary-report.page";

@Component({
  selector: "app-activity-summary",
  templateUrl: "./activity-summary.component.html",
  styleUrls: ["./activity-summary.component.scss"]
})
export class ActivitySummaryComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public selectedSegment: any;
  public ClientServerWebHookURL = "";
  public refCompanyId: any;
  public claimReimbursementData = { distinctUsers: -1, totalForms: -1 };
  public customerSuggestionFormData = { distinctUsers: -1, totalForms: -1 };
  public customerComplaintFormData = { distinctUsers: -1, totalForms: -1 };
  public collectionIntimationFormData = { distinctUsers: -1, totalForms: -1 };
  public customerRegistrationData = { distinctUsers: -1, totalForms: -1 };
  public checkInData = { in: -1, users: -1 };
  public checkOutData = { out: -1, users: -1 };
  public workLogData = { events: -1, users: -1 };
  public leaveData = { leaves: -1, users: -1 };
  public meetingData = { meetings: -1, users: -1 };

  public fromDate: any;
  public toDate: any;
  public now = new Date();

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {
    this.selectedSegment = "Today";
    this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
    this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    this.loadCompanyData();
  }

  async loadCompanyData() {
    await this.httpApiService.showLoading();
    this.refCompanyId = await this.storage.get("refCompanyId");
    let res = await this.httpApiService.getCompanyBasicDetailById(this.refCompanyId);
    let resp = res.json();
    this.ClientServerWebHookURL = resp.handleCompanyWebHook;
    await this.loadData();
    await this.httpApiService.hideLoading();
  }

  async loadData() {
    [this.claimReimbursementData, this.customerSuggestionFormData, this.customerComplaintFormData, this.collectionIntimationFormData, this.customerRegistrationData] = await Promise.all([
      this.httpApiService.getActivitySummary(this.refCompanyId, this.fromDate, this.toDate, "Claim Reimbursement"),
      this.httpApiService.getActivitySummary(this.refCompanyId, this.fromDate, this.toDate, "Customer Suggestion Form"),
      this.httpApiService.getActivitySummary(this.refCompanyId, this.fromDate, this.toDate, "Customer Complaint Form"),
      this.httpApiService.getActivitySummary(this.refCompanyId, this.fromDate, this.toDate, "Collection Intimation Form"),
      this.httpApiService.getActivitySummary(this.refCompanyId, this.fromDate, this.toDate, "Customer Registration")
    ]);
    if (this.ClientServerWebHookURL.length > 0) {
      [this.leaveData, this.checkInData, this.checkOutData, this.meetingData, this.workLogData] = await Promise.all([
        this.httpApiService.getActivitySummaryClient(this.refCompanyId, this.fromDate, this.toDate, "Leaves", this.ClientServerWebHookURL),
        this.httpApiService.getActivitySummaryClient(this.refCompanyId, this.fromDate, this.toDate, "Logins", this.ClientServerWebHookURL),
        this.httpApiService.getActivitySummaryClient(this.refCompanyId, this.fromDate, this.toDate, "Logouts", this.ClientServerWebHookURL),
        this.httpApiService.getActivitySummaryClient(this.refCompanyId, this.fromDate, this.toDate, "Meetings", this.ClientServerWebHookURL),
        this.httpApiService.getActivitySummaryClient(this.refCompanyId, this.fromDate, this.toDate, "WorkLogs", this.ClientServerWebHookURL)
      ]);
    }
  }

  async segmentChangedMain() {
    await this.httpApiService.showLoading();
    this.now = new Date();
    if (this.selectedSegment == "Today") {
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "Yesterday") {
      this.now.setDate(this.now.getDate() - 1);
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "2 days ago") {
      this.now.setDate(this.now.getDate() - 2);
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "3 days ago") {
      this.now.setDate(this.now.getDate() - 3);
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "4 days ago") {
      this.now.setDate(this.now.getDate() - 4);
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "5 days ago") {
      this.now.setDate(this.now.getDate() - 5);
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "6 days ago") {
      this.now.setDate(this.now.getDate() - 6);
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "A Week ago") {
      this.now.setDate(this.now.getDate() - 7);
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    }

    await this.loadData();
    await this.httpApiService.hideLoading();
  }

  async showReport(formName, type, callbackType) {
    let modalPage = await this.modalCtrl.create({
      component: SummaryReportPage,
      componentProps: {
        id: 1,
        refCompanyId: this.refCompanyId,
        formName: formName,
        type: type,
        fromDate: this.fromDate,
        toDate: this.toDate,
        companyWebHookBaseURL: this.ClientServerWebHookURL,
        callbackType: callbackType
      }
    });
    // modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  // async handleModalDismiss(d) {
  //   this.searchUser(null);
  //   this.getAllUser(null);
  // }

  ngOnInit() {}
}
