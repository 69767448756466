import { Platform, ToastController } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { BehaviorSubject } from "rxjs";
import { Headers, Http } from "@angular/http";
import { ConfigService } from "../config/config.service";

const TOKEN_KEY = "auth-token";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(false);
  public userActions: string;
  headers: any;
  accessToken: any;

  constructor(private storage: Storage, private plt: Platform, public http: Http, public toastCtrl: ToastController, public configService: ConfigService) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    });
  }

  async login(username, password) {
    //await this.storage.set(TOKEN_KEY, 'Bearer 1234567');
    let baseUrl = this.configService.getBaseUrl();
    try {
      let res: any;
      let id;
      let name = this.configService.getAppName();
      res = await this.http.get(baseUrl + "companies/getIdByCompanyName?name=" + name).toPromise();
      if (res.status == 401) {
        this.authenticationState.next(false);
      } else {
        let company = res.json().data;
        id = company.id;
        this.storage.set("refCompanyId", id);
        let body = {
          username: username,
          password: password,
          id: id
        };
        const response = await this.http.post(baseUrl + "users/userLogin", body).toPromise();
        let responseData = response.json();
        console.log(responseData);
        if (responseData.isSuccess) {
          let token = responseData.data.token;
          await this.storage.set(TOKEN_KEY, token);
          this.storage.set("userData", responseData.data);
          this.storage.set("username", responseData.data.username);
          // this.processService.setCompanyId(id);
          // this.router.navigate(['app', 'actionsWindow']);
          this.authenticationState.next(true);
          return response.json();
        } else {
          return responseData;
        }
      }
    } catch (err) {
      console.log(err);
      if (err.json().error && err.json().error.message) {
        let message = err.json().error.message;
        console.log(message);
        let toast = await this.toastCtrl.create({
          message: message,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      }
    }
  }

  async logout() {
    try {
      this.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        accessToken: await this.storage.get(TOKEN_KEY)
      };
      this.accessToken = await this.storage.get(TOKEN_KEY);
      let baseUrl = this.configService.getBaseUrl();
      const response = await this.http
        .post(baseUrl + "users/logout?access_token=" + this.accessToken, {
          headers: this.headers
        })
        .toPromise();
      await this.storage.remove(TOKEN_KEY);
      this.authenticationState.next(false);
    } catch (err) {
      await this.storage.remove(TOKEN_KEY);
      this.authenticationState.next(false);
    }
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  async userData() {
    return await this.storage.get("userData");
  }

  async userName() {
    return await this.storage.get("username");
  }
}
