import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { IonicSelectableComponent } from "ionic-selectable";

@Component({
  selector: "app-manage-pricing",
  templateUrl: "./manage-pricing.page.html",
  styleUrls: ["./manage-pricing.page.scss"]
})
export class ManagePricingPage implements OnInit {
  public discount = "";
  public id: any;
  public item: any;
  public fromDate: any;
  public allProductTags: any;
  public allCustomerTags: any;
  public allSchemes: any = [];
  public productTagId: any;
  public schemeId: any;
  public customerTagId: any;
  public scheme: any;
  public productTag: any;
  public customerTag: any;
  public selectedProductTags: any;
  public selectedCustomerTags: any;
  public selectedScheme: any;

  constructor(private modalCtrl: ModalController, public storage: Storage, private navParams: NavParams, public ConfigService: ConfigService, public authService: AuthenticationService, private toastCtrl: ToastController, private nav: NavController, public httpApiService: HttpApiService, public events: Events) {
  }

  async ngOnInit() {
    await this.httpApiService.showLoading();
    this.item = this.navParams.get("item");
    this.id = this.navParams.get("id");
    console.log(this.id, this.item);
    if (this.id != 0) {
      this.fromDate = this.item.fromDate;
      let tmpDate = new Date(this.fromDate);
      this.fromDate = tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + "-" + ("0" + tmpDate.getDate()).slice(-2);
      this.discount = this.item.discountPercent;
      this.productTagId = this.item.refProductTagID;
      this.customerTagId = this.item.refCustomerTagID;
      this.productTag = this.item.productTag;
      this.customerTag = this.item.customerTag;
      this.scheme = this.item.schemeName;
      this.schemeId = this.item.schemeId;
    } else {
      this.discount = "";
    }
    await this.getAllProductTags();
    await this.getAllCustomerTags();
    await this.getAllSchemes();
    await this.httpApiService.hideLoading();
  }

  async getAllSchemes() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllSchemes(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let noneObj = { id: 0, name: "None" };
      this.allSchemes.push(noneObj);
      res.json().data.forEach(scheme => {
        if (scheme.id == this.schemeId) {
          this.selectedScheme = scheme;
        }
        this.allSchemes.push(scheme)
      });
      console.log("get all scheme", this.allSchemes, this.selectedScheme);
    }
  }

  async getAllProductTags() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllProductTags(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.allProductTags = res.json().data;
      this.allProductTags.forEach(pTag => {
        if (pTag.tagId == this.productTagId) {
          this.selectedProductTags = pTag;
        }
      });
      console.log("get all product tags", this.allProductTags, this.selectedProductTags);
    }
  }

  async getAllCustomerTags() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllCustomerTags(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.allCustomerTags = res.json().data;
      this.allCustomerTags.forEach(cTag => {
        if (cTag.tagId == this.customerTagId) {
          this.selectedCustomerTags = cTag;
        }
      });
      console.log("get all customer tags", this.allCustomerTags, this.selectedCustomerTags);
    }
  }

  async addPricing() {
    console.log("add pricing", this.fromDate, this.discount, this.productTagId, this.customerTagId, this.schemeId, this.selectedProductTags, this.selectedCustomerTags, this.selectedScheme);
    let validate = await this.validateControls();
    let refCompanyId = await this.storage.get("refCompanyId");
    if (!validate) {
      await this.httpApiService.showLoading();
      this.productTagId = this.selectedProductTags.tagId;
      this.customerTagId = this.selectedCustomerTags.tagId;
      let jsonObj: any = {
        id: refCompanyId,
        fromDate: this.fromDate,
        discount: this.discount,
        productTagID: this.productTagId,
        customerTagID: this.customerTagId
      };
      if (!!this.selectedScheme && this.selectedScheme.id != 0) {
        this.schemeId = this.selectedScheme.id;
        jsonObj.refSchemeId = this.schemeId;
      } else {
        jsonObj.refSchemeId = null;
      }

      let res: any;
      res = await this.httpApiService.addPricing(jsonObj);
      let resp = res.json();
      await this.httpApiService.hideLoading();
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          this.resetForm();
          let toast = await this.toastCtrl.create({
            message: "Pricing created successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    }
  }

  async updatePricing() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let refCompanyId = await this.storage.get("refCompanyId");
      this.productTagId = this.selectedProductTags.tagId;
      this.customerTagId = this.selectedCustomerTags.tagId;
      let jsonObj: any = {
        id: refCompanyId,
        pricingId: this.id,
        fromDate: this.fromDate,
        discount: this.discount,
        productTagID: this.productTagId,
        customerTagID: this.customerTagId
      };

      if (!!this.selectedScheme && this.selectedScheme.id != 0) {
        this.schemeId = this.selectedScheme.id;
        jsonObj.refSchemeId = this.schemeId;
      } else {
        jsonObj.refSchemeId = null;
      }

      let res: any;
      res = await this.httpApiService.updatePricing(jsonObj);
      let resp = res.json();
      console.log("resp", resp);
      await this.httpApiService.hideLoading();
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Pricing updated successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    }
  }

  resetForm() {
    this.fromDate = "";
    this.discount = "";
    this.productTagId = "";
    this.customerTagId = "";
    this.schemeId = "";
    this.selectedScheme = "";
    this.selectedProductTags = "";
    this.selectedCustomerTags = "";
  }

  async validateControls() {
    if (this.fromDate != "" && this.discount != "" && !!this.selectedProductTags && !!this.selectedCustomerTags) {
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please select/insert the information",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }
  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async dateSelected(event) {
    // this.fromDate = event.target.value;
    console.log("fromDate", event, this.fromDate);
  }

  productTagChange(event: { component: IonicSelectableComponent; value: any }) {
    console.log("productTagChange ", event.value);
    this.selectedProductTags = event.value;
  }

  customerTagChange(event: { component: IonicSelectableComponent; value: any }) {
    console.log("customerTagChange ", event.value);
    this.selectedCustomerTags = event.value;
  }

  schemeChange(event: { component: IonicSelectableComponent; value: any }) {
    console.log("schemeChange ", event.value);
    this.selectedScheme = event.value;
  }
}
