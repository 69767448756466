import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { Storage } from "@ionic/storage";
import { ModalController } from "@ionic/angular";
import { ManageActionManagerPage } from "../manage-action-manager/manage-action-manager.page";

@Component({
  selector: "app-manage-action",
  templateUrl: "./manage-action.component.html",
  styleUrls: ["./manage-action.component.scss"]
})
export class ManageActionComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  public items: any;
  public userData: any;
  public manageSelectedAction: "";
  public arrowKeyLocation = -1;
  public listOfActions: any;
  public listOfActionPermission: any;
  public selectedAction: "";
  public description: "";
  public username: "";

  constructor(private authService: AuthenticationService, public storage: Storage, public httpApiService: HttpApiService, public modalCtrl: ModalController) {}

  ngOnInit() {
    this.selectedAction = "";
    this.getAllActions();
  }

  async getUserData() {
    return await this.authService.userData();
  }

  setItems() {
    this.items = this.listOfActions;
  }

  manageActionForPermission(item) {
    this.searchManageActionForPermission(item.actionName);
  }

  async searchManageActionForPermission(aName) {
    await this.httpApiService.showLoading();
    let tmpActionName = "";
    this.items = this.listOfActions;
    if (aName != undefined) {
      tmpActionName = aName;
      this.selectedAction = aName;
      this.manageSelectedAction = aName;
    } else {
      this.selectedAction = this.manageSelectedAction;
      tmpActionName = this.manageSelectedAction;
    }
    let selectedActionId = this.items.filter(function(search) {
      if (search.actionName == tmpActionName) {
        return search.id;
      }
    });
    if (selectedActionId.length > 0) {
      let id = selectedActionId[0].id;
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      res = await this.httpApiService.getAllActionPermissionById(id, refCompanyId);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let actionData = res.json().data;
        console.log(actionData.actionPermission);
        this.listOfActionPermission = actionData.actionPermission;
        this.selectedAction = actionData.actionName;
        this.description = actionData.description;
        console.log(this.items);
        let index = this.items.findIndex(x => x.actionName == selectedActionId[0].actionName);
        this.arrowKeyLocation = index;
      }
    } else {
      this.selectedAction = "";
      this.description = "";
      this.listOfActionPermission = [];
    }
    await this.httpApiService.hideLoading();
  }

  async getAllActions() {
    await this.httpApiService.showLoading();
    let res: any;
    this.username = await this.authService.userName();
    console.log(this.userData);
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllActionsByUsername(this.username, refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.listOfActions = res.json().data;
      this.items = this.listOfActions;
      console.log("list of actions by http", res, this.listOfActions);
    }
    await this.httpApiService.hideLoading();
  }

  logout() {
    this.authService.logout();
  }
  async addAction(flag) {
    let modalPage = await this.modalCtrl.create({
      component: ManageActionManagerPage,
      componentProps: { flag: flag }
    });
    modalPage.onDidDismiss().then(d => {
      this.getAllActions();
    });
    return await modalPage.present();
  }
}
