import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-summary-report",
  templateUrl: "./summary-report.page.html",
  styleUrls: ["./summary-report.page.scss"]
})
export class SummaryReportPage implements OnInit {
  public para: any;
  public type: any;
  public reportData: any;
  public refCompanyId: any;
  public fromDate: any;
  public toDate: any;
  public formName: any;
  public companyWebHookBaseURL: any;
  public callbackType: any;
  constructor(
    private modalCtrl: ModalController,
    public storage: Storage,
    private navParams: NavParams,
    public ConfigService: ConfigService,
    public authService: AuthenticationService,
    private toastCtrl: ToastController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public events: Events
  ) {}

  ngOnInit() {
    debugger;
    this.refCompanyId = this.navParams.get("refCompanyId");
    this.formName = this.navParams.get("formName");
    this.type = this.navParams.get("type");
    this.callbackType = this.navParams.get("callbackType");

    this.fromDate = this.navParams.get("fromDate");
    this.toDate = this.navParams.get("toDate");
    this.companyWebHookBaseURL = this.navParams.get("companyWebHookBaseURL");

    this.loadData();
  }

  setDate(Dt) {
    Dt = new Date(Dt);
    Dt.setTime(Dt.getTime() - 5.5 * 60 * 60 * 1000);
    return Dt;
  }

  async loadData() {
    // debugger;
    let res: any;
    if (this.callbackType == "outer") {
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getActivitySummaryClientDetail(this.refCompanyId, this.fromDate, this.toDate, this.formName, this.companyWebHookBaseURL);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      debugger;
      resp.data = resp.data.map(a => {
        a.entDate = this.setDate(a.entDate);
        return a;
      });
      debugger;

      if (this.type == "users") this.reportData = resp.users;
      else this.reportData = resp.data;
    } else if (this.callbackType == "inner") {
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getActivitySummaryDetail(this.refCompanyId, this.fromDate, this.toDate, this.formName);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      if (this.type == "users") this.reportData = resp.data.users;
      else this.reportData = resp.data.data;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
