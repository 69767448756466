import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { RolesComponent } from "./actions/roles/roles.component";
import { ManageRolesPage } from "./actions/manage-roles/manage-roles.page";
import { ManageActionComponent } from "./actions/manage-action/manage-action.component";
import { UserComponent } from "./actions/user/user.component";
import { ManageUserPage } from "./actions/manage-user/manage-user.page";
import { SummaryReportPage } from "./actions/summary-report/summary-report.page";
import { SummaryReportProductsPage } from "./actions/summary-report-products/summary-report-products.page";
import { ProcessComponent } from "./actions/process/process.component";
import { DisplayDateTimeComponent } from "./displayDateTime/display-date-time/display-date-time.component";
import { PipesModule } from "../pipes/pipes.module";
import { WorkAreaComponent } from "./actions/work-area/work-area.component";
import { ManageWorkAreaPage } from "./actions/manage-work-area/manage-work-area.page";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CompanyComponent } from "./actions/company/company.component";
import { ProcessTemplateComponent } from "./actions/process-template/process-template.component";
import { ManageProcessTemplatePage } from "./actions/manage-process-template/manage-process-template.page";
import { AccordionModule } from "ngx-accordion";
import { ManageCompanyPage } from "./actions/manage-company/manage-company.page";
import { CodeEditorComponent } from "./codeEditor/code-editor/code-editor.component";
import { CovalentCodeEditorModule } from "@covalent/code-editor";
import { SplitPaneItemComponent } from "./split-pane-item/split-pane-item.component";
import { IonicSelectableModule } from "ionic-selectable";
import { IonicSelectSearchableComponent } from "./ionic-select-searchable/ionic-select-searchable/ionic-select-searchable.component";
import { ManageDesignationComponent } from "./actions/manage-designation/manage-designation.component";
import { AddDesignationPage } from "./actions/add-designation/add-designation.page";
// import { AddDesignationPageModule } from "./actions/add-designation/add-designation.module";

import { ProductParameterMasterComponent } from "./actions/product-parameter-master/product-parameter-master.component";
import { ManageProductParameterMasterPage } from "./actions/manage-product-parameter-master/manage-product-parameter-master.page";
import { ProductsComponent } from "./actions/products/products.component";
import { ManageProductPage } from "./actions/manage-product/manage-product.page";
import { TagsComponent } from "./actions/tags/tags.component";
import { ManageTagsPage } from "./actions/manage-tags/manage-tags.page";
import { ProductCollectionsComponent } from "./actions/product-collections/product-collections.component";
import { ManageProductCollectionsPage } from "./actions/manage-product-collections/manage-product-collections.page";
import { ShopPageComponent } from "./actions/shop-page/shop-page.component";
import { ManageShopPagePage } from "./actions/manage-shop-page/manage-shop-page.page";
import { CartComponent } from "./actions/cart/cart.component";
import { ActivitySummaryComponent } from "./actions/activity-summary/activity-summary.component";
import { ShopCustomerComponent } from "./actions/shop-customer/shop-customer.component";
import { ManageShopCustomerPage } from "./actions/manage-shop-customer/manage-shop-customer.page";
import { ManageCartPage } from "./actions/manage-cart/manage-cart.page";
import { OrdersComponent } from "./actions/orders/orders.component";
import { MergeWorkAreasComponent } from "./actions/merge-work-areas/merge-work-areas.component";
import { PendingOrdersComponent } from "./actions/pending-orders/pending-orders.component";
import { OrderDispatchComponent } from "./actions/order-dispatch/order-dispatch.component";
import { DeleteUsersComponent } from "./actions/delete-users/delete-users.component";
import { PricingComponent } from "./actions/pricing/pricing.component";
import { ManagePricingPage } from "./actions/manage-pricing/manage-pricing.page";
import { CouponGeneratorComponent } from "./actions/coupon-generator/coupon-generator.component";
import { MergeCustomerComponent } from "./actions/merge-customer/merge-customer.component";
import { CopyUserComponent } from "./actions/copy-user/copy-user.component";
import { ManageCouponGeneratorPage } from "./actions/manage-coupon-generator/manage-coupon-generator.page";
import { NgxQRCodeModule } from "ngx-qrcode2";
import { SettlementComponent } from "./actions/settlement/settlement.component";
import { ManageSettlementPage } from "./actions/manage-settlement/manage-settlement.page";
import { ZipCodeComponent } from "./actions/zip-code/zip-code.component";
import { ManageZipCodePage } from "./actions/manage-zip-code/manage-zip-code.page";
import { DepartmentComponent } from "./actions/department/department.component";
import { ManageDepartmentPage } from "./actions/manage-department/manage-department.page";
import { ApplicationComponent } from "./actions/application/application.component";
import { ManageApplicationPage } from "./actions/manage-application/manage-application.page";
import { UserActivitySummaryComponent } from "./actions/user-activity-summary/user-activity-summary.component";
import { CopyProcessComponent } from "./actions/copy-process/copy-process.component";
import { FormTemplateComponent } from "./actions/form-template/form-template.component";
import { ManageFormTemplatePage } from "./actions/manage-form-template/manage-form-template.page";
import { ProductDashboardComponent } from "./actions/product-dashboard/product-dashboard.component";
import { ManageActionManagerPage } from "./actions/manage-action-manager/manage-action-manager.page";
import { LocationReportComponent } from "./actions/location-report/location-report.component";
import { CustomerDataUploadComponent } from "./actions/customer-data-upload/customer-data-upload.component";
import { CopyProductComponent } from "./actions/copy-product/copy-product.component";
import { BulkEditParametersComponent } from "./actions/bulk-edit-parameters/bulk-edit-parameters.component";
import { CostDefinitionComponent } from "./actions/cost-definition/cost-definition.component";
import { ManageCostDefinitionPage } from "./actions/manage-cost-definition/manage-cost-definition.page";
import { CampaignComponent } from "./actions/campaign/campaign.component";
import { ManageCampaignPage } from "./actions/manage-campaign/manage-campaign.page";
import { CampaignReportsComponent } from "./actions/campaign-reports/campaign-reports.component";
import { ViewReportComponent } from "./actions/view-report/view-report.component";
import { ManageViewReportPage } from "./actions/manage-view-report/manage-view-report.page";
import { PromotionalProductsComponent } from "./actions/promotional-products/promotional-products.component";
// import { SalesEntryComponent } from './actions/sales-entry/sales-entry.component';
import { AddPromotionalProductPage } from "./actions/add-promotional-product/add-promotional-product.page";
import { PaymentMethodsComponent } from "./actions/payment-methods/payment-methods.component";
import { AddPaymentMethodsPage } from "./actions/add-payment-methods/add-payment-methods.page";
import { SchemesComponent } from "./actions/schemes/schemes.component";
import { AddSchemesPage } from "./actions/add-schemes/add-schemes.page";
import { OrderFreshnessComponent } from "./actions/order-freshness/order-freshness.component";
import { ShippingMethodsComponent } from "./actions/shipping-methods/shipping-methods.component";
import { AddShippingMethodPage } from "./actions/add-shipping-method/add-shipping-method.page";
import { PrintFormatComponent } from "./print-format/print-format.component";
import { PrintPagePage } from "./actions/print-page/print-page.page";
import { ManageOrderBookComponent } from "./actions/manage-order-book/manage-order-book.component";
import { AddOrderBookPage } from "./actions/add-order-book/add-order-book.page";
import { AddonsComponent } from "./actions/addons/addons.component";
import { AddAddonsPage } from "./actions/add-addons/add-addons.page";
import { AddSocialmdediaPage } from "./actions/add-socialmdedia/add-socialmdedia.page";
import { PendingItemsComponent } from "./actions/pending-items/pending-items.component";
import { ManageSocialmediaComponent } from './actions/manage-socialmedia/manage-socialmedia.component';
import { InquiriesComponent } from './actions/inquiries/inquiries.component';
import { PopoverComponent } from './popover/popover.component';
import { ManageDispatchOrderPage } from './actions/manage-dispatch-order/manage-dispatch-order.page';
import { InvoiceTemplateComponent } from './actions/invoice-template/invoice-template.component';
import { InfoOrderDispatchDetailPage } from './actions/info-order-dispatch-detail/info-order-dispatch-detail.page';
import { CourierServiceComponent } from './actions/courier-service/courier-service.component';
import { ManageCourierServicePage } from './actions/manage-courier-service/manage-courier-service.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, PipesModule, NgxDatatableModule, AccordionModule, CovalentCodeEditorModule, IonicSelectableModule, NgxQRCodeModule],
  declarations: [
    RolesComponent,
    ManageRolesPage,
    ManageActionComponent,
    UserComponent,
    ManageUserPage,
    SummaryReportPage,
    SummaryReportProductsPage,
    ProcessComponent,
    DisplayDateTimeComponent,
    WorkAreaComponent,
    ManageWorkAreaPage,
    ProcessTemplateComponent,
    CompanyComponent,
    ManageCompanyPage,
    CodeEditorComponent,
    ManageProcessTemplatePage,
    SplitPaneItemComponent,
    ManageDesignationComponent,
    AddDesignationPage,
    IonicSelectSearchableComponent,
    ProductParameterMasterComponent,
    ManageProductParameterMasterPage,
    ProductsComponent,
    ManageProductPage,
    TagsComponent,
    ManageTagsPage,
    ProductCollectionsComponent,
    ManageProductCollectionsPage,
    ShopPageComponent,
    ManageShopPagePage,
    CartComponent,
    ActivitySummaryComponent,
    ShopCustomerComponent,
    ManageShopCustomerPage,
    ManageCartPage,
    OrdersComponent,
    MergeWorkAreasComponent,
    PendingOrdersComponent,
    OrderDispatchComponent,
    DeleteUsersComponent,
    PricingComponent,
    ManagePricingPage,
    CouponGeneratorComponent,
    MergeCustomerComponent,
    CopyUserComponent,
    ManageCouponGeneratorPage,
    SettlementComponent,
    ManageSettlementPage,
    ZipCodeComponent,
    ManageZipCodePage,
    DepartmentComponent,
    ManageDepartmentPage,
    ApplicationComponent,
    ManageApplicationPage,
    UserActivitySummaryComponent,
    CopyProcessComponent,
    FormTemplateComponent,
    ManageFormTemplatePage,
    ProductDashboardComponent,
    ManageActionManagerPage,
    LocationReportComponent,
    CustomerDataUploadComponent,
    CopyProductComponent,
    BulkEditParametersComponent,
    CostDefinitionComponent,
    ManageCostDefinitionPage,
    CampaignComponent,
    ManageCampaignPage,
    CampaignReportsComponent,
    ViewReportComponent,
    ManageViewReportPage,
    PromotionalProductsComponent,
    AddPromotionalProductPage,
    PaymentMethodsComponent,
    AddPaymentMethodsPage,
    SchemesComponent,
    AddSchemesPage,
    OrderFreshnessComponent,
    AddShippingMethodPage,
    ShippingMethodsComponent,
    PrintFormatComponent,
    PrintPagePage,
    ManageOrderBookComponent,
    AddOrderBookPage,
    AddonsComponent,
    AddAddonsPage,
    PendingItemsComponent,
    ManageSocialmediaComponent,
    AddSocialmdediaPage,
    InquiriesComponent,
    PopoverComponent,
    ManageDispatchOrderPage,
    InvoiceTemplateComponent,
    InfoOrderDispatchDetailPage,
    CourierServiceComponent,
    ManageCourierServicePage
  ],
  entryComponents: [
    RolesComponent,
    ManageRolesPage,
    ManageActionComponent,
    UserComponent,
    ManageUserPage,
    SummaryReportPage,
    SummaryReportProductsPage,
    ProcessComponent,
    DisplayDateTimeComponent,
    WorkAreaComponent,
    ManageWorkAreaPage,
    ProcessTemplateComponent,
    CompanyComponent,
    ManageCompanyPage,
    CodeEditorComponent,
    ManageProcessTemplatePage,
    ManageDesignationComponent,
    AddDesignationPage,
    IonicSelectSearchableComponent,
    ProductParameterMasterComponent,
    ManageProductParameterMasterPage,
    ProductsComponent,
    ManageProductPage,
    TagsComponent,
    ManageTagsPage,
    ProductCollectionsComponent,
    ManageProductCollectionsPage,
    ShopPageComponent,
    ManageShopPagePage,
    CartComponent,
    ActivitySummaryComponent,
    ShopCustomerComponent,
    ManageShopCustomerPage,
    ManageCartPage,
    OrdersComponent,
    MergeWorkAreasComponent,
    PendingOrdersComponent,
    OrderDispatchComponent,
    DeleteUsersComponent,
    PricingComponent,
    ManagePricingPage,
    CouponGeneratorComponent,
    MergeCustomerComponent,
    CopyUserComponent,
    ManageCouponGeneratorPage,
    SettlementComponent,
    ManageSettlementPage,
    ZipCodeComponent,
    ManageZipCodePage,
    DepartmentComponent,
    ManageDepartmentPage,
    ApplicationComponent,
    ManageApplicationPage,
    UserActivitySummaryComponent,
    CopyProcessComponent,
    FormTemplateComponent,
    ManageFormTemplatePage,
    ProductDashboardComponent,
    ManageActionManagerPage,
    LocationReportComponent,
    // SalesEntryComponent,
    CustomerDataUploadComponent,
    CopyProductComponent,
    BulkEditParametersComponent,
    CostDefinitionComponent,
    ManageCostDefinitionPage,
    CampaignComponent,
    ManageCampaignPage,
    CampaignReportsComponent,
    ViewReportComponent,
    ManageViewReportPage,
    PromotionalProductsComponent,
    AddPromotionalProductPage,
    PaymentMethodsComponent,
    AddPaymentMethodsPage,
    SchemesComponent,
    AddSchemesPage,
    OrderFreshnessComponent,
    AddShippingMethodPage,
    ShippingMethodsComponent,
    PrintFormatComponent,
    PrintPagePage,
    ManageOrderBookComponent,
    AddOrderBookPage,
    AddonsComponent,
    AddAddonsPage,
    PendingItemsComponent,
    ManageSocialmediaComponent,
    AddSocialmdediaPage,
    InquiriesComponent,
    PopoverComponent,
    ManageDispatchOrderPage,
    InfoOrderDispatchDetailPage,
    InvoiceTemplateComponent,
    CourierServiceComponent,
    ManageCourierServicePage
  ]
})
export class ComponentsModule { }
