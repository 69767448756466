import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-copy-process",
  templateUrl: "./copy-process.component.html",
  styleUrls: ["./copy-process.component.scss"]
})
export class CopyProcessComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public stateDesc: any;
  public formName: any;
  public items: any;
  public allCompanyList: any;
  public Company: number;
  public processList: any;
  public processId: number;
  public processName: string;

  constructor(public httpApiService: HttpApiService, public storage: Storage) {}

  async ngOnInit() {
    await this.getAllProcessTemplates();
  }

  async getAllProcessTemplates() {
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getAllProcessTemplates(this.cid);
    let res1 = await this.httpApiService.getAllCompany();

    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      this.items = res.json().data;
    }
    if (res1.json().isSuccess) {
      this.allCompanyList = res1.json().data;
    }
  }

  async onChange(event) {
    console.log(this.Company);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getAllProcessTemplates(this.Company);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      this.processList = res.json().data;
    }
  }
  async saveProcess() {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.copyProcess(refCompanyId, this.processName, this.Company, this.processId);
    await this.httpApiService.hideLoading();
  }
}
