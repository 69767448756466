import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import * as moment from "moment";
import { SummaryReportProductsPage } from "../summary-report-products/summary-report-products.page";

@Component({
  selector: "app-product-dashboard",
  templateUrl: "./product-dashboard.component.html",
  styleUrls: ["./product-dashboard.component.scss"]
})
export class ProductDashboardComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public selectedSegment: any;
  public reportDataOrders: any;
  public reportDataSales: any;
  public ClientServerWebHookURL = "";
  public refCompanyId: any;
  public fromDate: any;
  public toDate: any;
  public fromDatePast: any;
  public toDatePast: any;
  public now = new Date();
  public pageLoader = false;

  public lastDate: string = moment().format("YYYY-MM-DD");
  public startDate: string = moment()
    .subtract(30, "d")
    .format("YYYY-MM-DD");
  public endDate: string = moment().format("YYYY-MM-DD");
  public startDatePast: string = moment()
    .subtract(30, "d")
    .format("YYYY-MM-DD");
  public endDatePast: string = moment().format("YYYY-MM-DD");

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {
    this.selectedSegment = "Today";
    this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
    this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");

    this.fromDatePast = moment(this.addDays(this.now, -1).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
    this.toDatePast = moment(this.addDays(this.now, -1).setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");

    this.loadCompanyData();
  }
  async loadCompanyData() {
    // await this.httpApiService.showLoading();
    this.pageLoader = true;
    this.refCompanyId = await this.storage.get("refCompanyId");
    let res = await this.httpApiService.getCompanyBasicDetailById(this.refCompanyId);
    let resp = res.json();
    this.ClientServerWebHookURL = resp.handleCompanyWebHook;
    await this.loadData();
    //await this.httpApiService.hideLoading();
    this.pageLoader = false;
  }
  async loadData() {
    this.reportDataOrders = await this.httpApiService.getDashboardProduct(this.refCompanyId, "Orders", this.fromDate, this.toDate);
    this.reportDataOrders = this.reportDataOrders.data;
    this.reportDataSales = await this.httpApiService.getDashboardProduct(this.refCompanyId, "Sales Reporting", this.fromDate, this.toDate);
    this.reportDataSales = this.reportDataSales.data;
  }

  async segmentChangedMain() {
    //await this.httpApiService.showLoading();
    this.pageLoader = true;

    debugger;
    this.now = new Date();
    if (this.selectedSegment == "Today") {
      this.fromDate = moment(this.now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
      this.fromDatePast = moment(this.addDays(this.now, -1).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDatePast = moment(this.addDays(this.now, -1).setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "This Week") {
      this.fromDate = moment(this.getMonday(new Date()).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
      var tempDt = this.addDays(new Date(), -7);
      this.fromDatePast = moment(this.getMonday(tempDt).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      tempDt = this.addDays(this.getMonday(tempDt), 6);
      this.toDatePast = moment(tempDt.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "Month to Date") {
      this.fromDate = moment(new Date(this.now.getFullYear(), this.now.getMonth(), 1).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
      this.fromDatePast = moment(new Date(this.now.getFullYear(), this.now.getMonth() - 1, 1).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      var tempDt = new Date();
      tempDt.setDate(0);
      this.toDatePast = moment(tempDt.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "This Year") {
      this.fromDate = moment(new Date(this.now.getFullYear(), 3, 1).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(this.now.setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
      this.fromDatePast = moment(new Date(this.now.getFullYear() - 1, 0, 1).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDatePast = moment(new Date(this.now.getFullYear() - 1, 11, 31).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
    } else if (this.selectedSegment == "Custom") {
      this.fromDate = moment(new Date(this.startDate).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDate = moment(new Date(this.endDate).setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");

      this.fromDatePast = moment(new Date(this.startDatePast).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
      this.toDatePast = moment(new Date(this.endDatePast).setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
    }
    await this.loadData();
    //await this.httpApiService.hideLoading();
    this.pageLoader = false;
  }

  async showReport(type, subType, currCount) {
    if (!!currCount && currCount > 0) {
      debugger;
      let modalPage = await this.modalCtrl.create({
        component: SummaryReportProductsPage,
        componentProps: {
          id: 1,
          refCompanyId: this.refCompanyId,
          type: type,
          subType: subType,
          fromDate: this.fromDate,
          toDate: this.toDate,
          fromDatePast: this.fromDatePast,
          toDatePast: this.toDatePast,
          companyWebHookBaseURL: this.ClientServerWebHookURL
        }
      });
      // modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    }
  }

  // async loadCustomReport() {
  //   await this.httpApiService.showLoading();

  //   this.fromDate = moment(new Date(this.startDate).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss");
  //   this.toDate = moment(new Date(this.endDate).setHours(23, 59, 59, 999)).format("YYYY-MM-DD HH:mm:ss");
  //   await this.loadData();
  //   await this.httpApiService.hideLoading();
  // }

  ngOnInit() {}
  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }
}
