import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageShopCustomerPage } from "../../actions/manage-shop-customer/manage-shop-customer.page";
import { async } from "q";
import * as moment from "moment";

@Component({
  selector: "app-shop-customer",
  templateUrl: "./shop-customer.component.html",
  styleUrls: ["./shop-customer.component.scss"]
})
export class ShopCustomerComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  public items: any;
  public itemsContacts: any;
  public itemsContactAddresses: any;
  public itemsTags: any;
  public itemsWorkAreas: any;
  public manageSelectedCustomer = "";
  public manageSelectedContact = "";
  public manageSelectedContactAddress = "";
  public arrowKeyLocation = -1;
  public arrowKeyLocationContact = -1;
  public arrowKeyLocationContactAddress = -1;
  public getAllCustomersData: any;
  public selectedCustomer = "";
  public selectedContact = "";
  public customerName = "";
  public customerId: any;
  public contactId: any;
  public listOfCustomerContact = [];
  public listOfContactAddress = [];
  public address = "";
  public category = "";
  public city = "";
  public state = "";
  public zipCode = "";
  public pinCodePattern = "";
  public segment = "";
  public gstNo = "";
  public lang = "";
  public lat = "";
  public contactNo2 = "";
  public designation: any;
  public email = "";
  public userPinCode = "";
  public contactName = "";
  public username = "";
  public isDeleted = 0;
  public incorrectZipCode: boolean;
  public getAllDesignation: any;
  public listOfTags: any;
  public offset = 0;
  public limit = 10;
  public offsetContact = 0;
  public image: any = null;
  public refCompanyId: any;
  public allowAppAccess: any;
  public segmentValue: any;
  public code = "";
  public chatRoomID = -1;
  public workAreaId = -1;
  public workAreaName = "";
  public workAreaNameToBeCreated = "";
  public search = "";
  public listOfWorkAreas: any;
  public outstanding: any;
  public isDeletedCustomer: any;
  public creditLimit: any;
  public parentCustomer: any;
  public memoDays: any = [];
  public memoDay: any;
  public contactList: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    // this.getAllCustomers();
    await this.getRefCompanyId();
    await this.getCustomerByPagination("");
  }
  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllCustomers() {
    let res: any;
    res = await this.httpApiService.getAllCustomers(this.refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllCustomersData = res.json().data;
      this.items = this.getAllCustomersData;
      console.log("get all customer data", this.getAllCustomersData);
    }
  }

  setItems() {
    this.items = this.getAllCustomersData;
  }

  async loadcustomer(ev) {
    let res = await this.httpApiService.getCustomerContactByCustomerId(this.refCompanyId, this.customerId, this.offsetContact, this.limit, ev.detail.value);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let data = res.json().data;
      if (data) {
        this.customerName = data[0].companyName;
        this.code = data[0].code;
        this.address = data[0].address;
        this.zipCode = data[0].zipCode;
        this.pinCodePattern = data[0].pinCodePattern;
        this.category = data[0].category;
        this.city = data[0].city;
        this.gstNo = data[0].gstNo;
        this.state = data[0].state;
        this.segment = data[0].segment;
        this.lang = data[0].lang;
        this.lat = data[0].lat;

        this.outstanding = data[0].outstandingAmount;
        this.creditLimit = data[0].creditLimit;
        debugger;
        this.isDeletedCustomer = data[0].isDeleted;

        if (data[0].listOfCustomerContact && data[0].listOfCustomerContact.length > 0) {
          this.listOfCustomerContact = data[0].listOfCustomerContact;
          this.itemsContacts = this.listOfCustomerContact;
        } else {
          this.itemsContacts = [];
        }
        if (data[0].listOfWorkAreas && data[0].listOfWorkAreas.length > 0) {
          this.listOfWorkAreas = data[0].listOfWorkAreas;
          this.itemsWorkAreas = this.listOfWorkAreas;
        } else {
          this.itemsWorkAreas = [];
        }
        this.segmentValue = "Customer Details";
      }
      console.log(this.listOfCustomerContact);
    }
  }

  async getCustomerByPagination(val) {
    let res = await this.httpApiService.fetchCustomerByPagination(this.offset, this.limit, val, this.refCompanyId);
    console.log("res", res);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllCustomersData = res.json().data;
      this.items = this.getAllCustomersData;
      console.log("get all customer data", this.getAllCustomersData);
      this.arrowKeyLocation = -1;
    }
  }

  async filterItems(ev: any) {
    this.selectedCustomer = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      // let res = await this.httpApiService.fetchCustomerByPagination(this.offset, this.limit, val, this.refCompanyId);
      // console.log("res", res);
      // if (res.status == 401) {
      //   this.authService.logout();
      // } else {
      //   this.getAllCustomersData = res.json().data;
      //   this.items = this.getAllCustomersData;
      //   console.log("get all customer data", this.getAllCustomersData);
      //   this.arrowKeyLocation = -1;
      // }
      await this.getCustomerByPagination(val);
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.getAllCustomersData;
    }
  }

  async getCustomerDetail(name) {
    console.log(name);
    this.getCustomerContactByCustomerId(name);
  }

  async getCustomerContactByCustomerId(name) {
    this.reset("customerContact");
    let tmpName = "";
    this.selectedContact = "";
    this.manageSelectedContact = "";
    this.setItems();
    if (name != undefined) {
      this.manageSelectedCustomer = name;
      // this.selectedCustomer = name;
    } else {
      // this.selectedCustomer = this.manageSelectedCustomer;
    }
    // this.customerName = this.manageSelectedCustomer;
    tmpName = this.manageSelectedCustomer;
    let selectedCustomerId = this.items.filter(function (search) {
      if (search.name == tmpName) {
        return search.id;
      }
    });
    console.log(this.items, selectedCustomerId);
    if (selectedCustomerId.length > 0) {
      //get work area details for selected customer's name
      let resWorkArea = await this.httpApiService.getWorkAreaByName(this.refCompanyId, selectedCustomerId[0].companyName);
      if (resWorkArea.status == 401) {
        this.authService.logout();
      } else {
        resWorkArea = resWorkArea.json().data;
        if (resWorkArea.length > 0) {
          this.workAreaId = resWorkArea[0].id;
          this.workAreaName = resWorkArea[0].name;
          if (!!resWorkArea[0].chatRoomID) {
            this.chatRoomID = resWorkArea[0].chatRoomID;
          } else {
            this.chatRoomID = -1;
          }
        } else {
          this.chatRoomID = -1;
          this.workAreaId = -1;
          this.workAreaName = "";
          this.workAreaNameToBeCreated = selectedCustomerId[0].companyName;
        }
      }
      //--

      this.customerId = selectedCustomerId[0].id;
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getCustomerContactByCustomerId(this.refCompanyId, this.customerId, this.offsetContact, this.limit, this.search);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json().data;
        console.log(data);
        if (data) {
          this.customerName = data[0].companyName;
          this.code = data[0].code;
          this.address = data[0].address;
          this.zipCode = data[0].zipCode;
          this.pinCodePattern = data[0].pinCodePattern;
          this.category = data[0].category;
          this.city = data[0].city;
          this.gstNo = data[0].gstNo;
          this.state = data[0].state;
          this.segment = data[0].segment;
          this.lang = data[0].lang;
          this.lat = data[0].lat;

          this.outstanding = data[0].outstandingAmount;
          this.creditLimit = data[0].creditLimit;
          this.parentCustomer = data[0].parentCustomer;
          this.isDeletedCustomer = data[0].isDeleted;

          if (data[0].listOfCustomerContact && data[0].listOfCustomerContact.length > 0) {
            this.listOfCustomerContact = data[0].listOfCustomerContact;
            this.itemsContacts = this.listOfCustomerContact;
          } else {
            this.itemsContacts = [];
          }
          if (data[0].listOfWorkAreas && data[0].listOfWorkAreas.length > 0) {
            this.listOfWorkAreas = data[0].listOfWorkAreas;
            this.itemsWorkAreas = this.listOfWorkAreas;
          } else {
            this.itemsWorkAreas = [];
          }
          this.segmentValue = "Customer Details";
        }
        let index = this.items.findIndex(x => x.companyName == selectedCustomerId[0].companyName);
        this.arrowKeyLocation = index;
        console.log(this.listOfCustomerContact);
      }
    } else {
      this.manageSelectedCustomer = "";
    }
  }

  async createWorkArea() {
    await this.httpApiService.showLoading();

    let jsonObj = {
      name: this.workAreaNameToBeCreated,
      designationId: 0
    };
    let res = await this.httpApiService.createWorkArea(jsonObj, this.refCompanyId);
    res = res.json();
    if (res.isSuccess) {
      this.workAreaId = res.data.id;
      this.workAreaName = res.data.name;
      this.workAreaNameToBeCreated = "";
      let toast = await this.toastCtrl.create({ message: "Chat Room created.", duration: 3000, position: "top", color: "success" });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: res.data.error, duration: 3000, position: "top", color: "danger" });
      await toast.present();
    }

    await this.httpApiService.hideLoading();
  }

  async createChatRoom() {
    let userData = await this.authService.userData();
    await this.httpApiService.showLoading();

    let res = await this.httpApiService.createChatRoom(this.refCompanyId, !!this.workAreaName ? this.workAreaName : this.workAreaNameToBeCreated, userData.username);
    res = res.json();
    if (res) {
      this.workAreaId = 11;
      this.workAreaName = "adsf";
      this.workAreaNameToBeCreated = "";
      this.chatRoomID = 11;
      let toast = await this.toastCtrl.create({ message: "Chat Room created.", duration: 3000, position: "top", color: "success" });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: res.data.error, duration: 3000, position: "top", color: "danger" });
      await toast.present();
    }

    await this.httpApiService.hideLoading();
  }

  async deleteCustomer() {
    await this.httpApiService.showLoading();

    let res = await this.httpApiService.deleteOrEnableCustomer(this.refCompanyId, this.customerId, 1);
    res = res.json();
    if (res) {
      this.isDeletedCustomer = true;
      let toast = await this.toastCtrl.create({ message: "Customer Deleted.", duration: 3000, position: "top", color: "success" });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: res.data.error, duration: 3000, position: "top", color: "danger" });
      await toast.present();
    }

    await this.httpApiService.hideLoading();
  }
  async activateCustomer() {
    await this.httpApiService.showLoading();

    let res = await this.httpApiService.deleteOrEnableCustomer(this.refCompanyId, this.customerId, 0);
    res = res.json();
    if (res) {
      this.isDeletedCustomer = false;
      let toast = await this.toastCtrl.create({ message: "Customer Activated.", duration: 3000, position: "top", color: "success" });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: res.data.error, duration: 3000, position: "top", color: "danger" });
      await toast.present();
    }

    await this.httpApiService.hideLoading();
  }
  async updateCustomer() {
    let selectedContact;
    if (this.contactName) {
      selectedContact = this.contactName;
    }
    console.log("update customer", this.manageSelectedContact, this.manageSelectedCustomer, this.customerId, this.customerName, this.code, this.city, this.state, this.address, this.zipCode, this.lang, this.lat, this.gstNo, this.category, this.segment, this.pinCodePattern);
    let selectedCustomer = this.manageSelectedCustomer;
    let validate = await this.validateControls("updateCustomer");
    if (!validate) {
      if (this.code == null || this.code == undefined) {
        this.code = "";
      }
      if (this.category == null || this.category == undefined) {
        this.category = "";
      }
      if (this.gstNo == null || this.gstNo == undefined) {
        this.gstNo = "";
      }
      if (this.segment == null || this.segment == undefined) {
        this.segment = "";
      }
      if (this.lat == null || this.lat == undefined) {
        this.lat = "";
      }
      if (this.lang == null || this.lang == undefined) {
        this.lang = "";
      }

      if (this.creditLimit == null || this.creditLimit == undefined) {
        this.creditLimit = null;
      }

      let jsonObj: any = {
        id: this.refCompanyId,
        customerId: this.customerId,
        companyName: this.customerName,
        code: this.code,
        city: this.city,
        state: this.state,
        address: this.address,
        zipCode: this.zipCode,
        lang: this.lang,
        lat: this.lat,
        gstNo: this.gstNo,
        category: this.category,
        segment: this.segment,
        pinCodePattern: this.pinCodePattern
      };

      if (this.outstanding != null && this.outstanding != undefined && this.outstanding.toString().length > 0) {
        jsonObj.outstandingAmount = parseInt(this.outstanding);
        jsonObj.outstandingLastUpdatedDate = new Date();
      }
      if (this.creditLimit != null && this.creditLimit != undefined && this.creditLimit.toString().length > 0) {
        jsonObj.creditLimit = parseInt(this.creditLimit);
        jsonObj.creditLimitLastUpdatedDate = new Date();
      }

      console.log("jsonObj");
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateCustomer(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          // await this.getAllCustomers();
          await this.getCustomerContactByCustomerId(selectedCustomer);
          if (selectedContact) {
            await this.getContactDetailById(selectedContact);
          }
          selectedCustomer = "";
          let toast = await this.toastCtrl.create({ message: "Customer updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async findAreaDetailByZipCode(event) {
    let zipCode = event.target.value;
    await this.httpApiService.showLoading();
    let zipRes = await this.httpApiService.getAllAreaDetailByPinCode(zipCode);
    await this.httpApiService.hideLoading();
    if (zipRes.status == 401) {
      this.authService.logout();
    } else {
      let data = zipRes.json().data;
      console.log(data);
      if (data) {
        this.city = data[0].city;
        this.state = data[0].state;
        this.incorrectZipCode = false;
      } else {
        this.incorrectZipCode = true;
      }
    }
  }

  setItemsContacts() {
    this.itemsContacts = this.listOfCustomerContact;
  }

  async getContactDetail(name) {
    console.log(name);
    this.getContactDetailById(name);
  }

  async getContactDetailById(name) {
    this.reset("contact");
    let tmpName = "";
    this.setItemsContacts();
    if (name != undefined) {
      this.manageSelectedContact = name;
      // this.selectedContact = name;
    } else {
      // this.selectedContact = this.manageSelectedContact;
    }
    // this.contactName = this.manageSelectedContact;
    tmpName = this.manageSelectedContact;
    let selectedContactId = this.itemsContacts.filter(function (search) {
      if (search.username == tmpName) {
        return search.id;
      }
    });
    console.log(this.itemsContacts, selectedContactId, tmpName);
    if (selectedContactId.length > 0) {
      this.contactId = selectedContactId[0].id;
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getCustomerContactDetailById(this.refCompanyId, this.contactId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json().data;
        console.log(data);
        if (data) {
          this.username = data[0].username;
          this.email = data[0].email;
          this.contactNo2 = data[0].contactNo2;
          this.userPinCode = data[0].userPinCode;
          this.designation = parseInt(data[0].designation);
          this.image = data[0].image;
          this.allowAppAccess = data[0].allowAppAccess;
          this.contactName = data[0].name;
          this.isDeleted = data[0].isDeleted;
          if (!!data[0].memoDays && data[0].memoDays.length != 0) {
            this.memoDays = data[0].memoDays;
            this.memoDay = data[0].memoDay;
          }
          let desRes = await this.httpApiService.getAllDesignation(this.refCompanyId);
          if (desRes.status == 401) {
            this.authService.logout();
          } else {
            let desData = desRes.json().data;
            this.getAllDesignation = desData;
            console.log("Designation List", this.getAllDesignation);
          }

          console.log("Designation", this.designation);
          if (data[0].listOfAddresses && data[0].listOfAddresses.length > 0) {
            this.listOfContactAddress = data[0].listOfAddresses;
            this.setContactAddresses();
          }
          if (data[0].listOfTags && data[0].listOfTags.length > 0) {
            this.listOfTags = data[0].listOfTags;
            this.setTags();
          }
          this.segmentValue = "Contact Details";
        }
        let index = this.itemsContacts.findIndex(x => x.name == selectedContactId[0].name);
        this.arrowKeyLocationContact = index;
        console.log(this.listOfContactAddress);
      }
    } else {
      this.manageSelectedContact = "";
    }
  }

  async updateContact() {
    console.log("selected cust", this.manageSelectedCustomer);
    let selectedContact = this.contactName;
    console.log("update contact", this.customerId, this.contactId, this.contactName, this.designation, this.username, this.contactNo2, this.email, this.allowAppAccess);
    let validate = await this.validateControls("updateContact");
    if (!validate) {
      let allowAppAccessVar: any;
      if (this.allowAppAccess == true) {
        allowAppAccessVar = 1;
      } else if (this.allowAppAccess == false) {
        allowAppAccessVar = 0;
      } else if (this.allowAppAccess == null) {
        allowAppAccessVar = null;
      }
      console.log("allow app access", allowAppAccessVar);
      if (this.contactNo2 == null || this.contactNo2 == undefined) {
        this.contactNo2 = "";
      }
      if (this.userPinCode == null || this.userPinCode == undefined) {
        this.userPinCode = "";
      }
      let jsonObj = {
        id: this.refCompanyId,
        customerId: this.customerId,
        CcID: this.contactId,
        name: this.contactName,
        designationId: this.designation.toString(),
        contactNo: this.username,
        contactNo2: this.contactNo2,
        email: this.email,
        allowAppAccess: allowAppAccessVar,
        memoDay: this.memoDay,
        userPinCode: this.userPinCode
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateCustomerContact(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          await this.getCustomerContactByCustomerId(this.manageSelectedCustomer);
          this.manageSelectedContact = selectedContact;
          await this.getContactDetailById(this.manageSelectedContact);
          let toast = await this.toastCtrl.create({ message: "Contact updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async setContactAddresses() {
    this.itemsContactAddresses = this.listOfContactAddress;
  }

  async filterAddresses(ev: any) {
    this.setContactAddresses();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.itemsContactAddresses = await this.itemsContactAddresses.filter(function (item) {
        return item.address.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocationContactAddress = -1;
    } else {
      this.setContactAddresses();
      this.arrowKeyLocationContactAddress = -1;
    }
  }

  keyDownAddresses(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.itemsContactAddresses && this.itemsContactAddresses.length > 0) {
          if (this.arrowKeyLocationContactAddress != 0) {
            this.arrowKeyLocationContactAddress--;
            this.manageSelectedContactAddress = this.itemsContactAddresses[this.arrowKeyLocationContactAddress].name;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.itemsContactAddresses && this.itemsContactAddresses.length > 0) {
          if (this.itemsContactAddresses.length != this.arrowKeyLocationContactAddress + 1) {
            this.arrowKeyLocationContactAddress++;
          }
          if (this.itemsContactAddresses.length > this.arrowKeyLocationContactAddress) {
            this.manageSelectedContactAddress = this.itemsContactAddresses[this.arrowKeyLocationContactAddress].name;
            break;
          } else {
            break;
          }
        }
        if (this.itemsContactAddresses.length > this.arrowKeyLocationContactAddress) {
          if (this.itemsContactAddresses.length != this.arrowKeyLocationContactAddress + 1) {
            this.arrowKeyLocationContactAddress++;
          }
          break;
        } else {
          break;
        }
    }
  }

  async deleteContactAddress(item) {
    console.log("delete contact address", item);
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteCustomerAddress(this.refCompanyId, item.id);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let data = res.json();
      console.log(data);
      if (data.error) {
        if (data.error == "invalid_token") {
          this.modalCtrl.dismiss();
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
          await toast.present();
        }
      } else {
        await this.getCustomerContactByCustomerId(this.customerName);
        this.selectedContact = this.customerName;
        await this.getContactDetailById(this.selectedContact);
        let toast = await this.toastCtrl.create({ message: "Contact Address deleted successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async openModal(item, flagModal, oldId = 0) {
    let data;
    if (flagModal == "addTags") {
      data = this.listOfTags;
    } else if (flagModal == "addWorkAreas") {
      data = this.listOfWorkAreas;
    } else if (flagModal == "setCompany") {
      data = [];
    } else if (flagModal == "setParentCustomer") {
      data = [];
    }
    let modalPage = await this.modalCtrl.create({ component: ManageShopCustomerPage, componentProps: { oldId: oldId, id: this.customerId, item: item, customerName: this.customerName, flagModal: flagModal, data: data } });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d);
    if (d.data == "updateContactAddress" || d.data == "addContactAddress") {
      await this.getContactDetailById(this.manageSelectedContact);
    } else {
      // await this.getAllCustomers();
      await this.getCustomerDetail(this.manageSelectedCustomer);
    }
  }

  async reset(customerContact) {
    if (customerContact == "customerContact") {
      this.customerName = "";
      this.code = "";
      this.address = "";
      this.category = "";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.segment = "";
      this.gstNo = "";
      this.lang = "";
      this.lat = "";
      this.contactNo2 = "";
      this.designation = "";
      this.email = "";
      this.contactName = "";
      this.username = "";
      this.outstanding = "";
      this.creditLimit = "";
      this.isDeletedCustomer = null;
    } else {
      this.contactName = "";
      this.username = "";
      this.contactNo2 = "";
      this.designation = "";
      this.email = "";
    }
    this.listOfContactAddress = [];
    this.itemsContactAddresses = "";
    this.listOfTags = [];
    this.itemsTags = "";
    this.listOfWorkAreas = [];
    this.itemsWorkAreas = "";
  }

  async validateControls(updateCustomerContact) {
    if (updateCustomerContact == "updateCustomer") {
      if (this.customerName != "" && this.address != "" && this.zipCode != "" && !this.incorrectZipCode && this.city != "" && this.state != "") {
        return false;
      } else {
        if (this.incorrectZipCode) {
          let toast = await this.toastCtrl.create({ message: "Please enter correct zipCode", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        } else {
          let toast = await this.toastCtrl.create({ message: "Please insert the details", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        }
      }
    } else {
      if (this.contactName != "" && this.username != "" && this.username != null && this.username.length == 12 && this.designation != "") {
        return false;
      } else {
        if (this.username != null && this.username.length < 12) {
          let toast = await this.toastCtrl.create({ message: "Please enter 12 digit contact number", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        } else {
          let toast = await this.toastCtrl.create({ message: "Please insert the details", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        }
      }
    }
  }

  async setTags() {
    this.itemsTags = this.listOfTags;
  }

  async filterTags(ev: any) {
    this.setTags();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.itemsTags = await this.itemsTags.filter(function (item) {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
    } else {
      this.setTags();
    }
  }

  async deleteTags(item) {
    console.log("item", item);
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteCustomerTagsForCustomer(item.customerTagID, this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        await this.getContactDetailById(this.manageSelectedContact);
        let toast = await this.toastCtrl.create({ message: "Customer tags deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  public segmentChanged(event) {
    console.log("event ", event);
    this.segmentValue = event.target.value;
  }

  async exportCustomer() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.exportCustomer(this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.contactList = res.json().data;
          console.log("contactList", this.contactList);
          this.JSONToCSVConvertor(this.contactList, "Dealers:_" + moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), "report");
        }
      }
    }
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    console.log("arrData ", arrData);
    var CSV = "";
    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }


    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    console.log("CSV ", CSV);
    //Generate a file name
    var fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async deleteWorkAreas(id) {
    console.log(id);
    let res: any;
    let jsonObj = {
      customerId: this.customerId,
      workAreaId: id
    };
    console.log(jsonObj);
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteWorkAreaFromCustomer(jsonObj, this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp: any;
      resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 2000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      } else {
        await this.getCustomerDetail(this.manageSelectedCustomer);
        let toast = await this.toastCtrl.create({
          message: "Customer work area deleted successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }
}
