import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-manage-product-parameter-master",
  templateUrl: "./manage-product-parameter-master.page.html",
  styleUrls: ["./manage-product-parameter-master.page.scss"]
})
export class ManageProductParameterMasterPage implements OnInit {
  public isModal: any;
  public name = "";
  public value = "";
  public productParameterId = "";
  public ProductParamName = "";
  public item: any;
  public id: any;
  public refCompanyId: any;
  public order = 0;

  constructor(private modalCtrl: ModalController, public storage: Storage, private navParams: NavParams, public ConfigService: ConfigService, public authService: AuthenticationService, private toastCtrl: ToastController, private nav: NavController, public httpApiService: HttpApiService) { }

  ngOnInit() {
    this.isModal = this.navParams.get("flagModal");
    this.productParameterId = this.navParams.get("id");
    this.ProductParamName = this.navParams.get("ProductParamName");
    this.item = this.navParams.get("item");
    if (this.isModal == "updateValue") {
      this.value = this.item.value;
      this.productParameterId = this.item.stoneParameterNameId;
      this.id = this.item.id;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async saveValue() {
    let validate = await this.validateControls();
    if (!validate) {
      this.refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        productParameterId: this.productParameterId,
        value: this.value,
        id: this.refCompanyId,
        order: this.order
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.addProductParameterValue(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Product Parameter value created successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
          this.resetForm();
        }
      }
    }
  }

  async updateValue() {
    let validate = await this.validateControls();
    if (!validate) {
      this.refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        productParameterValueId: this.id,
        value: this.value,
        id: this.refCompanyId,
        order: this.order
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateProductParameterValue(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Stone Parameter value updated successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
        }
      }
    }
  }

  async addNewParam() {
    let validate = await this.validateControls();
    if (!validate) {
      this.refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        order: this.order,
        name: this.name,
        id: this.refCompanyId
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.addProductParameterName(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Product Parameter Name added successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
          this.resetForm();
        }
      }
    }
  }

  async validateControls() {
    if (this.isModal == "addValue" || this.isModal == "updateValue") {
      if (this.value != "") {
        return false;
      } else {
        let toast = await this.toastCtrl.create({
          message: "Please select/insert all the information",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
    } else {
      if (this.name != "") {
        return false;
      } else {
        let toast = await this.toastCtrl.create({
          message: "Please insert the information",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
    }
  }

  resetForm() {
    this.value = "";
    this.name = "";
    this.order = 0;
  }
}
