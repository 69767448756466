import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams, Events } from "@ionic/angular";
import * as moment from "moment";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: "app-manage-settlement",
  templateUrl: "./manage-settlement.page.html",
  styleUrls: ["./manage-settlement.page.scss"]
})
export class ManageSettlementPage implements OnInit {
  public type = "cash";
  public amount: any;
  public date: any;
  public remark: any;
  public documentNo: any;
  public selectedRows: any;
  public voucherIds = [];

  constructor(public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {
    this.selectedRows = navParams.get("item");
    this.selectedRows.forEach(element => {
      this.voucherIds.push(element.id);
      console.log("voucherIds", this.voucherIds);
    });
    console.log("this.selectedRows", this.selectedRows);
    this.date = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2);
  }

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async settlePoints() {
    let settleObj = {
      listOfVoucherIds: this.voucherIds,
      type: this.type,
      documentDate: this.date,
      documentReferenceNumber: this.documentNo,
      documentAmount: this.amount,
      remark: this.remark
    };
    console.log("settleObj", settleObj);
    await this.httpApiService.showLoading();
    let response = await this.httpApiService.settleRedeemRequest(settleObj);
    await this.httpApiService.hideLoading();
    console.log("response", response);
    if (!response.error) {
      let toast = await this.toastCtrl.create({
        message: response.message,
        duration: 2000,
        position: "top",
        color: "success"
      });
      await toast.present();
      this.closeModal();
    } else {
      let toast = await this.toastCtrl.create({
        message: response.error,
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
    }
  }
}
