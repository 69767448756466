import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";


@Component({
  selector: 'app-add-socialmdedia',
  templateUrl: './add-socialmdedia.page.html',
  styleUrls: ['./add-socialmdedia.page.scss'],
})
export class AddSocialmdediaPage implements OnInit {
  public refCompanyId: any;
  public name: String;
  public profileName: String;
  public type: String;
  public isActive: boolean;
  public allTypes = [];

  public socialMediaData: any;
  public link: any;
  public mode: any;
  public postData: any;
  public postName = "";

  constructor(public storage: Storage, public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {
    this.socialMediaData = this.navParams.get("data");
    this.mode = this.navParams.get("mode");
    this.postData = this.navParams.get("postData");

    if (this.postData && this.mode == "Edit Link") {
      this.link = this.postData.link;
      this.postName = this.postData.name;
    }
  }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getSocialMediaTypes();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  checkNull(val) {
    return !!val;
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.checkNull(this.name)) {
      flag = 0;
    }
    if (flag == 1) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await false;
    } else {
      return await true;
    }
  }

  async add() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    let objData: any = {
      name: this.name,
      profileName: this.profileName,
      type: this.type,
      isActive: this.isActive
    };

    let validate = await this.validateControls();


    if (validate) {
      res = await this.httpApiService.addSocialMediaFeed(objData, refCompanyId);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Social Media added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }

  async resetControls() {
    this.name = "";
    this.profileName = "";
    this.type = "Instagram";
    this.isActive = false
    this.link = "";
    this.postName = "";
  }

  async getSocialMediaTypes() {
    let res: any;

    res = await this.httpApiService.getSocialMediaTypes(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.allTypes = res.json().data;

    }
  }

  async validateLinkControls() {
    let flag: number;
    flag = 1;
    if (this.checkNull(this.link)) {
      flag = 0;
    }
    if (flag == 1) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await false;
    } else {
      return await true;
    }
  }

  async addLink() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    let objData: any = {
      name: this.postName,
      link: this.link,
      refSocialMediaId: this.socialMediaData.id
    };

    let validate = await this.validateLinkControls();


    if (validate) {
      res = await this.httpApiService.addSocialMediaPostLink(objData, refCompanyId);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Social Media added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }

  async editLink() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    let objData: any = {
      name: this.postName,
      link: this.link,
      refSocialMediaId: this.socialMediaData.id,
      postId: this.postData.id
    };

    let validate = await this.validateLinkControls();


    if (validate) {
      res = await this.httpApiService.editSocialMediaPostLink(objData, refCompanyId);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Social Media added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
        this.modalCtrl.dismiss();
      }
    }
  }

}
