import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { PrintPagePage } from "../print-page/print-page.page";
import { ManageDispatchOrderPage } from "../manage-dispatch-order/manage-dispatch-order.page";

@Component({
  selector: "app-pending-orders",
  templateUrl: "./pending-orders.component.html",
  styleUrls: ["./pending-orders.component.scss"]
})
export class PendingOrdersComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  public fromDate: any;
  public toDate: any;
  public pendingOrders: any;
  public summaryDetail: any;
  public listOfProduct: any;
  public orderNo: any;
  public orderId: any;
  public items: any;
  public arrowKeyLocation = 0;
  public list: any;
  public type: any;
  public offset = 0;
  public limit = 20;
  public search = "";
  public counter;
  public selected: any = [];
  public shareText = "";
  public hideStorePrice = false;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {
    this.summaryDetail = "summary";
    let fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.fromDate = new Date().getFullYear() + "-" + ("0" + (fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + fromDate.getDate()).slice(-2);
    this.toDate = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2);
  }

  async getConfig() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getCompanyDetailById(refCompanyId);
    this.httpApiService.companyObj = res.json().data[0].companyRes;
    this.setConfig();
  }

  async setConfig() {
    if (this.httpApiService.companyObj && this.httpApiService.companyObj.config) {
      let companyJson = this.httpApiService.companyObj.config;
      if (!!companyJson) {
        companyJson = JSON.parse(companyJson);
        if (!!companyJson) {
          if (!!companyJson.hideStorePrice) {
            this.hideStorePrice = companyJson.hideStorePrice;
          }
        }
      }
      // let companyJson = this.httpApiService.companyObj.data.config;
      // console.log(this.httpApiService.companyObj)
      // if (!!this.httpApiService.companyObj.data.enableCloudinaryImage) {
      //   this.enableCloudinaryImage = this.httpApiService.companyObj.data.enableCloudinaryImage
      // }

      // if (!!this.httpApiService.companyObj.data.presetName) {
      //   this.presetName = this.httpApiService.companyObj.data.presetName
      // }

      // alert(this.presetName)
    } else {
      console.log("---");
    }
  }

  async ngOnInit() {
    console.log("fromDate", this.fromDate);
    await this.getConfig();
  }

  setItems() {
    this.items = this.pendingOrders;
  }


  onSelect({ selected }) {
    console.log("Select Event", selected, this.selected);
    this.shareText = "";
    for (let i = 0; i < this.selected.length; i++) {
      let serialNumber = this.selected[i].serialNumber;
      let name = this.selected[i].name;
      let order_quantity = this.selected[i].order_quantity;
      let order_total = this.selected[i].order_total;
      let sku = this.selected[i].sku;
      let text = ""
      if (!this.hideStorePrice) {
        text = "Order No:" + serialNumber + "\nName:" + name + "\nProducts:" + sku + "\nQty:" + order_quantity + "\nTotal Amount:" + order_total + "\n\n";
      } else {
        text = "Order No:" + serialNumber + "\nName:" + name + "\nProducts:" + sku + "\nQty:" + order_quantity + "\n\n";
      }
      this.shareText += text;
    }
    this.shareText = encodeURIComponent(this.shareText)
  }

  async filterItems(ev: any) {
    this.offset = 0;
    this.limit = 20;
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.search = val;
      // this.items = await this.items.filter(function (item) {
      //   console.log("item", item, typeof item);
      //   if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (item.title && item.title.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (item.orderStatus != null && item.orderStatus.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (
      //     item.id != null &&
      //     item.id
      //       .toString()
      //       .toLowerCase()
      //       .includes(val.toLowerCase())
      //   ) {
      //     return true;
      //   } else if (item.city && item.city.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (item.state && item.state.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (item.country && item.country.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (item.addedOn && item.addedOn.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (
      //     item.order_quantity != null &&
      //     item.order_quantity
      //       .toString()
      //       .toLowerCase()
      //       .includes(val.toLowerCase())
      //   ) {
      //     return true;
      //   } else if (
      //     item.shipment_quantity != null &&
      //     item.shipment_quantity
      //       .toString()
      //       .toLowerCase()
      //       .includes(val.toLowerCase())
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      //   // return item.userName.toLowerCase().includes(val.toLowerCase());
      // });
      this.arrowKeyLocation = 0;
    } else {
      this.search = "";
      this.arrowKeyLocation = 0;
      // this.items = [];
    }
    await this.getPendingOrders();
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.arrowKeyLocation != 0) {
          this.arrowKeyLocation--;
          // this.selectedProcess = this.items[this.arrowKeyLocation].actionName;
          if (this.arrowKeyLocation == 0) {
            this.arrowKeyLocation++;
          }
          break;
        } else {
          break;
        }
      case 40: // this is the ascii of arrow down
        if (this.items.length > this.arrowKeyLocation) {
          // this.selectedProcess = this.items[this.arrowKeyLocation].actionName;
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          break;
        } else {
          break;
        }
    }
    // console.log(this.arrowKeyLocation);
  }

  async getPendingOrders() {
    this.list = null;
    this.items = null;
    this.fromDate = this.fromDate.replace("T", " ").slice(0, 19);
    this.toDate = this.toDate.replace("T", " ").slice(0, 19);
    console.log("get pending orders", this.fromDate, this.toDate);
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getPendingOrders(refCompanyId, this.fromDate + " 00:00:00", this.toDate + " 23:59:59", this.offset, this.limit, this.search);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.pendingOrders = res.json().data.data;
          this.counter = res.json().data.counter;
          this.list = this.pendingOrders;
          this.setItems();
        }
      }
    }
    console.log("this.pendingOrders", this.pendingOrders);
  }

  async showOrder(row) {
    console.log("row", row);
    this.summaryDetail = "Detail";
    this.orderId = row.id;
    this.orderNo = row.name + "-" + row.id;
  }

  async changeViewType(view) {
    this.summaryDetail = view;
  }

  async print() {
    let alert = await this.alertCtrl.create({
      // title: 'Confirm purchase',
      message: "Select print options",
      buttons: [
        {
          text: "Item Wise",
          handler: () => {
            console.log("Item wise clicked");
            this.printAction("Item Wise");
          }
        },
        {
          text: "Order Wise",
          handler: () => {
            console.log("Order Wise clicked");
            this.printAction("Order Wise");
          }
        }
      ]
    });
    alert.present();
  }

  async printAction(type) {
    this.type = type;
    if (this.type == "Item Wise") {
      await this.getPendingOrdersItemWise();
    } else {
      await this.getPendingOrders();
    }
    // this.changeViewType('Print');
    let modalPage = await this.modalCtrl.create({
      component: PrintPagePage,
      componentProps: {
        type: this.type,
        list: this.list
      }
    });
    // modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async getPendingOrdersItemWise() {
    this.list = null;
    this.fromDate = this.fromDate.replace("T", " ").slice(0, 19);
    this.toDate = this.toDate.replace("T", " ").slice(0, 19);
    console.log("get pending orders item wise", this.fromDate, this.toDate);
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getPendingOrdersItemWise(refCompanyId, this.fromDate + " 00:00:00", this.toDate + " 23:59:59", this.offset, this.limit, this.search);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.list = res.json().data;
        }
      }
    }
    console.log("this.pendingOrdersItemWise", this.list);
  }

  async setPage(event) {
    console.log("event", event);
    this.limit = event.limit;
    this.offset = event.offset;
    await this.getPendingOrders();
  }

  async submit() {
    this.selected = []
    this.offset = 0;
    this.limit = 20;
    await this.getPendingOrders();
  }

  // open dispatch modal 

  async openDispatchOrderModal() {
    let dispatchModal = await this.modalCtrl.create({
      component: ManageDispatchOrderPage,
      componentProps: {
        selectedOrderForDispatch: this.selected,
      },
      backdropDismiss: false,
      cssClass: "dispatchMOdal"
    })
    dispatchModal.onDidDismiss().then((d: any) => this.handleModalDismiss(d))
    await dispatchModal.present();
  }

  async handleModalDismiss(d) {
    this.selected = []
    this.list = null;
    this.counter = 0;
    this.pendingOrders = [];
    this.offset = 0;
    this.limit = 20;
    await this.getPendingOrders();
  }

  //end
}
