import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular";

@Component({
  selector: "app-code-editor",
  templateUrl: "./code-editor.component.html",
  styleUrls: ["./code-editor.component.scss"]
})
export class CodeEditorComponent implements OnInit {
  editorOptions = { theme: "vs-dark", language: "javascript" };
  public code: any;
  column: number = 0;
  lineNumber: number = 0;
  public title: any;
  public subtitle: any;

  constructor(public navParams: NavParams, public modalCtrl: ModalController) {
    this.code = this.navParams.get("code");
    this.title = this.navParams.get("title");
    this.subtitle = this.navParams.get("subtitle");
  }

  ngOnInit() {
    console.log("inside modal", this.code, this.title, this.subtitle);
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async save() {
    console.log("this.code", this.code);
    this.modalCtrl.dismiss(this.code);
  }
}
