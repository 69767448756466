import { Component, OnInit } from '@angular/core';
import { Storage } from "@ionic/storage";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-manage-campaign',
  templateUrl: './manage-campaign.page.html',
  styleUrls: ['./manage-campaign.page.scss'],
})
export class ManageCampaignPage implements OnInit {
  public items: any;
  public name: any;
  public landingPage: any;
  public utm_source: any;
  public utm_medium: any;
  public utm_campaign: any;
  public utm_term: any;
  public utm_content: any;

  constructor(public storage: Storage, public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {
    this.items = this.navParams.get("items");
  }

  ngOnInit() {
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async addCampaign() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    let objData = { id: refCompanyId, name: this.name, landingPage: this.landingPage, utm_source: this.utm_source, utm_medium: this.utm_medium, utm_campaign: this.utm_campaign, utm_term: this.utm_term, utm_content: this.utm_content };
    console.log(objData);
    let validate = await this.validateControls();
    if (!validate) {
      res = await this.httpApiService.addCampaign(objData);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Campaign added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }
  async resetControls() {
    this.name = "";
    this.landingPage = "";
    this.utm_campaign = "";
    this.utm_content = "";
    this.utm_medium = "";
    this.utm_source = "";
    this.utm_term = "";
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.name != null && this.name != null && this.landingPage != "" && this.landingPage != null) {
      flag = 0;
    }
    console.log("flag=", flag);
    if (flag) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await true;
    } else {
      return await false;
    }
  }
}
