import { Component, Input, ViewChild } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, Slides } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageCartPage } from "../manage-cart/manage-cart.page";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"]
})
export class CartComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public getAllCartData: any;
  public items: any;
  public itemsProducts: any;
  public cartDetails: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationProduct = -1;
  public manageSelectedCart: "";
  public manageSelectedProduct: "";
  public cartCreatedOn = "";
  public cartCustomerName = "";
  public cartSessionId = "";
  public cartDeleted: any;
  public selectedCart = "";
  public selectedProduct = "";
  public cartId: any;
  public cartProducts: any;
  public cartTotal: any;
  public refCompanyId: any;
  public mobileNo: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    this.manageSelectedCart = "";
    await this.httpApiService.showLoading();
    await this.getRefCompanyId();
    await this.getAllCart();
    await this.httpApiService.hideLoading();
  }
  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllCart() {
    let res: any;

    res = await this.httpApiService.getAllCart(this.refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllCartData = res.json().data;
      this.setItems();
    }
  }

  setItems() {
    this.items = this.getAllCartData;
  }

  async getCartDetail(name) {
    console.log("name", name);
    this.getCartDetailByCartId(name);
  }

  async getCartDetailByCartId(cart) {
    await this.httpApiService.showLoading();
    console.log(cart, this.manageSelectedCart);
    let tmpCartName = "";
    this.setItems();
    if (cart != undefined) {
      tmpCartName = cart;
      this.manageSelectedCart = cart;
      this.selectedCart = this.manageSelectedCart;
    } else {
      this.selectedCart = this.manageSelectedCart;
      tmpCartName = this.manageSelectedCart;
    }
    let selectedCartId = this.items.filter(function (search) {
      if (search.name == tmpCartName) {
        return search.id;
      }
    });
    console.log(selectedCartId);
    if (selectedCartId.length > 0) {
      let id = selectedCartId[0].id;
      console.log(id);
      let res: any;

      res = await this.httpApiService.getCartDetailsByCartId(this.refCompanyId, id);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        this.cartCreatedOn = selectedCartId[0].createdOn.replace("T", " ").slice(0, 19);
        this.cartCustomerName = selectedCartId[0].customerName;
        this.mobileNo = selectedCartId[0].mobileNo;
        this.cartSessionId = selectedCartId[0].sessionId;
        this.cartDeleted = selectedCartId[0].isDeleted;
        this.cartId = selectedCartId[0].id;
        if (res.json().data) {
          this.cartDetails = res.json().data;
          this.cartProducts = this.cartDetails;
          this.cartTotal = 0;
          this.cartProducts.forEach(product => {
            if (product.totalDiscountPrice == 0) {
              this.cartTotal = this.cartTotal + product.total;
            } else {
              this.cartTotal = this.cartTotal + product.totalDiscountPrice;
            }
          });
          this.setItemsProducts();
        }
        let index = this.items.findIndex(x => x.name == selectedCartId[0].name);
        this.arrowKeyLocation = index;
        console.log(this.cartDetails);
      }
    }
    await this.httpApiService.hideLoading();
  }

  async deleteCartProduct(item) {
    await this.httpApiService.showLoading();
    let res: any;
    res = await this.httpApiService.removeCartProduct(this.refCompanyId, item.cartId, item.id);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let data = res.json();
      console.log(data);
      if (data.error) {
        if (data.error == "invalid_token") {
          this.modalCtrl.dismiss();
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
          await toast.present();
        }
      } else {
        await this.getCartDetail(this.manageSelectedCart);
        let toast = await this.toastCtrl.create({ message: "Product deleted successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async openModal(item, flagModal) {
    let modalPage = await this.modalCtrl.create({ component: ManageCartPage, componentProps: { id: this.cartId, item: item, cartName: this.manageSelectedCart, flagModal: flagModal } });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d);
    await this.httpApiService.showLoading();
    await this.getAllCart();
    await this.getCartDetail(this.manageSelectedCart);
    await this.httpApiService.hideLoading();
  }

  setItemsProducts() {
    this.itemsProducts = this.cartProducts;
  }

  async filterProducts(ev: any) {
    this.selectedProduct = "";
    this.setItemsProducts();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.itemsProducts = await this.itemsProducts.filter(function (item) {
        return item.title.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocationProduct = -1;
    } else {
      this.arrowKeyLocationProduct = -1;
      this.setItemsProducts();
    }
  }

  keyDownProducts(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.itemsProducts && this.itemsProducts.length > 0) {
          if (this.arrowKeyLocationProduct != 0) {
            this.arrowKeyLocationProduct--;
            this.manageSelectedProduct = this.itemsProducts[this.arrowKeyLocationProduct].title;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.itemsProducts && this.itemsProducts.length > 0) {
          if (this.itemsProducts.length != this.arrowKeyLocationProduct + 1) {
            this.arrowKeyLocationProduct++;
          }
          if (this.itemsProducts.length > this.arrowKeyLocationProduct) {
            this.manageSelectedProduct = this.itemsProducts[this.arrowKeyLocationProduct].title;
            break;
          } else {
            break;
          }
        }
    }
  }
}
