import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, ToastController } from "@ionic/angular";
import { ManageRolesPage } from "../manage-roles/manage-roles.page";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"]
})
export class RolesComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  public listOfRoles: any;
  public selectedRole = "";
  public items: any;
  public itemsActions: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationAction = -1;
  public manageSelectedRole = "";
  public listOfActions: any;
  public listOfActionPermissions: any;
  public description: "";
  public flagButton = "";
  public roleName = "";
  public id: any;

  constructor(private authService: AuthenticationService, public storage: Storage, public httpApiService: HttpApiService, public modalCtrl: ModalController, public toastCtrl: ToastController, public configService: ConfigService) {}

  async ngOnInit() {
    await this.getAllRoles();
  }

  async getAllRoles() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllRoles(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      this.listOfRoles = res.json();
      this.setItems();
      console.log("getAllRoles res", res, this.listOfRoles);
    }
  }

  setItems() {
    this.items = this.listOfRoles;
  }

  async setActions() {
    this.itemsActions = await this.listOfActionPermissions;
  }

  selectRole(item) {
    console.log(item);
    this.selectedRole = item.roleName;
    console.log("this.selectedRole", this.selectedRole);
    this.searchRole(item.roleName);
    // this.items = [];
  }

  async filterActions(ev: any) {
    this.setActions();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.itemsActions = await this.itemsActions.filter(function(item) {
        return item.actionName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocationAction = -1;
    } else {
      this.arrowKeyLocationAction = -1;
      // this.itemsActions = [];
    }
  }

  keyDownActions(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.arrowKeyLocationAction != 0) {
          this.arrowKeyLocationAction--;
          // this.manageSelectedRole = this.items[this.arrowKeyLocation].roleName;
          break;
        } else {
          break;
        }
      // if (this.arrowKeyLocationAction != 0) {
      //   this.arrowKeyLocationAction--;
      //   // this.selectedRole = this.itemsActions[this.arrowKeyLocationAction].actionName;
      //   if (this.arrowKeyLocationAction == 0) {
      //     this.arrowKeyLocationAction++;
      //   }
      //   break;
      // } else {
      //   break;
      // }
      case 40: // this is the ascii of arrow down
        if (this.items.length != this.arrowKeyLocationAction + 1) {
          this.arrowKeyLocationAction++;
        }
        if (this.items.length > this.arrowKeyLocationAction) {
          // this.manageSelectedRole = this.items[this.arrowKeyLocation].roleName;
          break;
        } else {
          break;
        }
      // if (this.itemsActions.length > this.arrowKeyLocationAction) {
      //   // this.selectedRole = this.itemsActions[this.arrowKeyLocationAction].actionName;
      //   if (this.itemsActions.length != this.arrowKeyLocationAction + 1) {
      //     this.arrowKeyLocationAction++;
      //   }
      //   break;
      // } else {
      //   break;
      // }
    }
  }

  async deleteRolePermission(rolePermission) {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.deleteRolePermission(rolePermission.refRoleId, rolePermission.refActionPermissionId, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      console.log("delete role permission response", res);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.searchRole(this.manageSelectedRole);
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
      }
    }
  }

  async searchRole(roleName) {
    // this.arrowKeyLocation = 0;
    let tmpRoleName = "";
    this.items = this.listOfRoles;
    if (roleName != undefined) {
      tmpRoleName = roleName;
      this.selectedRole = roleName;
      this.manageSelectedRole = roleName;
    } else {
      this.selectedRole = this.manageSelectedRole;
      tmpRoleName = this.manageSelectedRole;
    }
    console.log("roleName", roleName, tmpRoleName);
    let selectedRoleId = this.items.filter(function(search) {
      if (search.roleName == tmpRoleName) {
        return search.id;
      }
    });
    console.log("selectedRoleId", selectedRoleId);
    if (selectedRoleId.length > 0) {
      let id = selectedRoleId[0].id;
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.findRoleDetailsById(id, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        console.log("res", res, res.json());
        let roleData = res.json().data;
        this.listOfActions = roleData[0].listOfActions;
        this.selectedRole = roleData[0].roleName;
        this.id = roleData[0].id;
        this.roleName = this.selectedRole;
        this.description = roleData[0].description;
        console.log(roleData[0].description);
        let index = this.items.findIndex(x => x.roleName == selectedRoleId[0].roleName);
        this.arrowKeyLocation = index;
        if (roleData[0].listOfActionPermissions && roleData[0].listOfActionPermissions.length > 0) {
          this.listOfActionPermissions = roleData[0].listOfActionPermissions;
        } else {
          this.listOfActionPermissions = [];
        }
        // await this.filterJson(roleData[0]);
        await this.setActions();
        console.log("this.listOfActions", this.listOfActionPermissions, this.selectedRole);
      }
    } else {
      this.selectedRole = "";
      this.roleName = "";
      this.description = "";
      this.listOfActions = [];
    }
  }

  // async filterJson(roleData) {
  //   console.log(roleData)
  //   let tmpArray: any = [];
  //   roleData[0].listOfActions.filter((role) => {
  //     role.listOfActionPermissions.filter((roleActionPermission) => {
  //       roleActionPermission.actionName = role.actionName;
  //       roleActionPermission.roleId = roleData.id;
  //       tmpArray.push(roleActionPermission);
  //     });
  //   });
  //   this.listOfActionPermissions = tmpArray;
  //   this.setActions();
  //   console.log("list of action permissions",this.listOfActionPermissions);
  //   return await this.listOfActionPermissions;
  // }

  async addRoleActionPermission(selectedRole) {
    let role = this.listOfRoles.filter(item => {
      if (item.roleName == selectedRole) {
        return item;
      }
    });
    console.log("role", role);
    const modal = await this.modalCtrl.create({
      component: ManageRolesPage,
      componentProps: { role: role, type: 1 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async addRole() {
    const modal = await this.modalCtrl.create({
      component: ManageRolesPage,
      componentProps: { role: "", type: 2 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async handleModalDismiss(d) {
    console.log("d", d);
    await this.getAllRoles();
    await this.searchRole(this.manageSelectedRole);
  }

  async ionModalDidDismiss() {
    console.log("inside ionModalDidDismiss");
    await this.getAllRoles();
    await this.searchRole(this.manageSelectedRole);
  }

  async showButton(name) {
    if (name == "roleName") {
      this.flagButton = "roleName";
    } else if (name == "description") {
      this.flagButton = "description";
    }
  }

  async updateRole() {
    let validate = await this.validateControls();
    // let appName = await this.configService.getAppName();
    if (!validate) {
      let jsonObj = {
        roleId: this.id,
        roleName: this.roleName,
        description: this.description
      };
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateRole(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        console.log(resp);
        if (resp.error) {
          if (resp.error == "invalid_token") {
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: resp.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          this.flagButton = "";
          await this.getAllRoles();
          await this.searchRole(this.roleName);
          let toast = await this.toastCtrl.create({
            message: resp.data,
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
        }
      }
    }
  }

  async validateControls() {
    if (this.roleName != "" && this.description != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please enter the details",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }
}
