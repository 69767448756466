import { Component, Input } from '@angular/core';
import { NavController, ModalController, ToastController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: 'app-order-freshness',
  templateUrl: './order-freshness.component.html',
  styleUrls: ['./order-freshness.component.scss']
})
export class OrderFreshnessComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  selectedFile: File = null;
  public csvFileHistories: any;
  public selectedSegment = "orderFreshness";

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  ngOnInit() {
    // this.findAll();
  }
  segmentChanged(ev: any) {
    console.log('Segment changed', ev);
    this.selectedSegment = ev.target.value;
    // this.selectedFile = null;
  }
  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  async findAll() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllCSVUploadHistory(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.csvFileHistories = res.json().data;
          console.log("csvFileHistories", this.csvFileHistories);
        }
      }
    }
  }

  async orderFreshness() {
    console.log(this.selectedFile);
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      console.log("form data", formData);
      let userData = await this.authService.userData();
      let refCompanyId = await this.storage.get("refCompanyId");
      let res: any;
      let jsonObj = { id: refCompanyId, fileFormatName: "Order Freshness", userId: userData.id, formData: formData, shouldWait: false };
      await this.httpApiService.showLoading();
      res = await this.httpApiService.fileOrderFreshness(jsonObj);
      await this.httpApiService.hideLoading();
      console.log("response", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().error) {
          let toast = await this.toastCtrl.create({
            message: res.json().error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        } else {
          if (res.json().data) {
            // this.selectedFile = null;
            let toast = await this.toastCtrl.create({
              message: res.json().data,
              duration: 3000,
              position: "top",
              color: "success"
            });
            await toast.present();
            // await this.findAll();
          }
        }
      }
    }
  }

  async orderProcessingStart() {
    console.log(this.selectedFile);
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      console.log("form data", formData);
      let userData = await this.authService.userData();
      let refCompanyId = await this.storage.get("refCompanyId");
      let res: any;
      let jsonObj = { id: refCompanyId, fileFormatName: "Order Processing Start", userId: userData.id, formData: formData, shouldWait: false };
      await this.httpApiService.showLoading();
      res = await this.httpApiService.fileOrderFreshness(jsonObj);
      await this.httpApiService.hideLoading();
      console.log("response", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().error) {
          let toast = await this.toastCtrl.create({
            message: res.json().error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        } else {
          if (res.json().data) {
            // this.selectedFile = null;
            let toast = await this.toastCtrl.create({
              message: res.json().data,
              duration: 3000,
              position: "top",
              color: "success"
            });
            await toast.present();
            // await this.findAll();
          }
        }
      }
    }
  }
}
