import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageProductParameterMasterPage } from "../manage-product-parameter-master/manage-product-parameter-master.page";

@Component({
  selector: "app-product-parameter-master",
  templateUrl: "./product-parameter-master.component.html",
  styleUrls: ["./product-parameter-master.component.scss"]
})
export class ProductParameterMasterComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  public getAllProductParam: any;
  public items: any;
  public itemsValue: any;
  public arrowKeyLocation = 0;
  public arrowKeyLocationValue = 0;
  public selectedMaster = "";
  public manageSelectedMaster = "";
  public listOfValue = [];
  public productData: any;
  public id: any;
  public valueSearch = "";
  public name = "";
  public flgBtn = "";
  public order = 0;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.getAllProductParameter();
  }

  setItems() {
    this.items = this.getAllProductParam;
  }

  async getAllProductParameter() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllProductParameter(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllProductParam = res.json().data;
      this.items = this.getAllProductParam;
      console.log("get All product param Data", this.getAllProductParam);
    }
  }

  getProductMasterDetail(name) {
    console.log(name);
    this.searchProductMaster(name);
  }

  async searchProductMaster(name) {
    let tmpname = "";
    this.items = this.getAllProductParam;
    if (name != undefined) {
      tmpname = name;

      this.manageSelectedMaster = name;
      this.selectedMaster = name;
    } else {
      this.selectedMaster = this.manageSelectedMaster;
      tmpname = this.manageSelectedMaster;
    }

    let selectedMasterId = this.items.filter(function (search) {
      if (search.name == tmpname) {
        return search.id;
      }
    });
    console.log(this.items, tmpname, selectedMasterId);
    if (selectedMasterId.length > 0) {
      this.id = selectedMasterId[0].id;

      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getProductParameterValuesById(this.id, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json().data;
        console.log(data);
        this.name = data.name;
        this.selectedMaster = data.name;
        this.order = data.order;
        let index = this.items.findIndex(x => x.name == selectedMasterId[0].name);
        this.arrowKeyLocation = index;
        if (data.shop_productParameterValuesMaster && data.shop_productParameterValuesMaster.length > 0) {
          this.listOfValue = data.shop_productParameterValuesMaster;
          this.productData = this.listOfValue;
        } else {
          this.listOfValue = [];
        }
      }
    } else {
      this.name = "";
      this.selectedMaster = "";
    }
  }

  setValues() {
    this.listOfValue = this.productData;
  }

  async filterValues(ev: any) {
    this.setValues();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfValue = await this.listOfValue.filter(function (item) {
        return item.value.toLowerCase().includes(val.toLowerCase());
      });
      console.log(this.listOfValue);
      this.arrowKeyLocationValue = 0;
    } else {
      this.listOfValue = this.productData;
      this.arrowKeyLocationValue = 0;
    }
  }

  keyDownValues(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.arrowKeyLocationValue != 0) {
          this.arrowKeyLocationValue--;
          // this.selectedRole = this.itemsValue[this.arrowKeyLocationValue].actionName;
          if (this.arrowKeyLocationValue == 0) {
            this.arrowKeyLocationValue++;
          }
          break;
        } else {
          break;
        }
      case 40: // this is the ascii of arrow down
        if (this.listOfValue.length > this.arrowKeyLocationValue) {
          if (this.listOfValue.length != this.arrowKeyLocationValue + 1) {
            this.arrowKeyLocationValue++;
          }
          break;
        } else {
          break;
        }
    }
    console.log(this.arrowKeyLocationValue);
  }

  //ENd Inner Search

  async updateProductParamName() {
    let validate = await this.validateControls();
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = { name: this.name, id: refCompanyId, productParameterId: this.id, order: this.order };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateProductParamName(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: userData.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          this.flgBtn = "";
          await this.getAllProductParameter();
          await this.searchProductMaster(this.name);
          let toast = await this.toastCtrl.create({
            message: "Product parameter updated successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
        }
      }
    }
  }

  //delete Value

  async deleterParamValue(id) {
    console.log(id);
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteProductParamValue(id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.valueSearch = "";
        this.searchProductMaster(this.manageSelectedMaster);
        let toast = await this.toastCtrl.create({
          message: "Product parameter value deleted successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }

  async openModal(item, flagModal) {
    let modalPage = await this.modalCtrl.create({
      component: ManageProductParameterMasterPage,
      componentProps: {
        id: this.id,
        item: item,
        ProductParamName: this.selectedMaster,
        flagModal: flagModal
      }
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    this.valueSearch = "";
    await this.getAllProductParameter();
    await this.searchProductMaster(this.manageSelectedMaster);
  }

  async showButton(name) {
    if (name == "productParamName") {
      this.flgBtn = "productParamName";
    } else if (name == "productParamOrder") {
      this.flgBtn = "productParamOrder";
    }
  }

  async validateControls() {
    if (this.name != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please insert the detail",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }
}
