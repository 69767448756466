import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-copy-user",
  templateUrl: "./copy-user.component.html",
  styleUrls: ["./copy-user.component.scss"]
})
export class CopyUserComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public getAllUserData: any;
  public manageSelectedUser: "";
  public selectedUser: "";
  public items: any;
  public arrowKeyLocation = -1;
  public name = "";
  public phoneNo = "";
  public tempUserName = "";
  public offset = 0;
  public limit = 10;
  public selectedUserId: "";

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {}

  ngOnInit() {
    this.selectedUser = "";
  }

  setItems() {
    this.items = this.getAllUserData;
  }

  getUserDetail(username) {
    console.log(username);
    this.searchUser(username);
  }

  async searchUser(user) {
    await this.httpApiService.showLoading();
    console.log(user, this.manageSelectedUser);
    let tmpUserName = "";
    this.items = this.getAllUserData;
    if (user != undefined && user != null && user != "") {
      tmpUserName = user.username;
      this.manageSelectedUser = user.name;
      this.tempUserName = tmpUserName;
      this.selectedUser = user.userName;
    } else {
      this.selectedUser = this.manageSelectedUser;
      tmpUserName = this.tempUserName;
    }
    let selectedUserId = this.items.filter(function(search) {
      if (search.username == tmpUserName) {
        return search.id;
      }
    });
    console.log(selectedUserId);
    if (selectedUserId.length > 0) {
      this.selectedUserId = selectedUserId[0].id;
      console.log(this.selectedUserId);
    } else {
      this.name = "";
      this.selectedUser = "";
      this.phoneNo = "";
    }
    await this.httpApiService.hideLoading();
  }

  async filterItems(ev: any) {
    this.selectedUser = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      console.log("filter", this.items, val);
      let refCompanyId = await this.storage.get("refCompanyId");
      let res = await this.httpApiService.fetchUsersByPagination(this.offset, this.limit, val, refCompanyId);
      console.log("res", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        this.getAllUserData = res.json().data;
        this.items = this.getAllUserData;
        console.log("get all users data", this.getAllUserData);
        this.tempUserName = "";
        this.arrowKeyLocation = -1;
      }
    } else {
      this.tempUserName = "";
      this.arrowKeyLocation = -1;
      this.items = this.getAllUserData;
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        console.log(this.arrowKeyLocation);
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0 && this.arrowKeyLocation != -1) {
            this.arrowKeyLocation--;
            this.manageSelectedUser = this.items[this.arrowKeyLocation].name;
            this.tempUserName = this.items[this.arrowKeyLocation].username;
            //-- Ali#9376105354
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedUser = this.items[this.arrowKeyLocation].name;
            this.tempUserName = this.items[this.arrowKeyLocation].username;
            break;
          } else {
            break;
          }
        }
    }
  }

  async copyUser() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      console.log("copy user", this.selectedUserId, this.name, this.phoneNo);
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        id: refCompanyId,
        fromUserId: this.selectedUserId,
        newUserName: this.name,
        newUserPhoneNo: this.phoneNo
      };
      console.log(jsonObj);
      res = await this.httpApiService.copyUser(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let resp: any;
        resp = res.json();
        if (resp.error) {
          if (resp.error == "invalid_token") {
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: resp.error,
              duration: 2000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          await this.searchUser(null);
          this.name = "";
          this.phoneNo = "";
          let toast = await this.toastCtrl.create({
            message: "User Copied successfully",
            duration: 2000,
            position: "top",
            color: "success"
          });
          await toast.present();
        }
      }
    }
  }

  async validateControls() {
    if (this.name != "" && this.phoneNo != "") {
      let phone = this.phoneNo.toString();
      if (phone.length != 12) {
        let toast = await this.toastCtrl.create({
          message: "Please enter 12 digit Phone Number",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please insert the detail",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }
}
