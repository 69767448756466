import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageCompanyPage } from "../manage-company/manage-company.page";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"]
})
export class CompanyComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public selectedSegment = 'Details';
  public getAllCompanyData: any;
  public manageSelectedCompany: "";
  public selectedCompany: "";
  public items: any;
  public id: any;
  public arrowKeyLocation = -1;
  public contactNumber = "";
  public contactName = "";
  public companyName = "";
  public listOfActions: any = [];
  public listOfActionPermission: any = [];
  public listOfUsers: any = [];
  public flgBtn = "";
  public userData: any;
  public name = "";
  public heading = "";
  public webHookInOutUrl = "";
  public isOpen = false;
  public handleCompanyWebHook = "";
  public whichPage = "company";

  public longitude: any;
  public latitude: any;
  public distance: any;
  public refCompanyId: any;
  public subdomain = "";
  public productType = "";
  public companyLogo = "";
  public listOfDomains: any = [];

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.httpApiService.showLoading();
    this.selectedCompany = "";
    this.manageSelectedCompany = "";
    await this.getAllCompany();
    await this.getRefCompanyId();
    await this.httpApiService.hideLoading();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }
  setItems() {
    this.items = this.getAllCompanyData;
  }

  getCompanyDetail(cName) {
    this.searchCompany(cName);
  }

  async searchCompany(cName) {
    await this.httpApiService.showLoading();
    console.log(cName);
    // this.arrowKeyLocation = 0;
    let tmpCompanyName = "";
    this.items = this.getAllCompanyData;
    if (cName != undefined) {
      tmpCompanyName = cName.name;
      this.manageSelectedCompany = cName.name;
      this.selectedCompany = cName.name;
    } else {
      this.selectedCompany = this.manageSelectedCompany;
      tmpCompanyName = this.manageSelectedCompany;
    }
    let selectedCompanyId = this.items.filter(function (search) {
      if (search.name == tmpCompanyName) {
        console.log(search.id);
        return search.id;
      }
    });
    if (selectedCompanyId.length > 0) {
      this.id = selectedCompanyId[0].id;
      let res: any;
      res = await this.httpApiService.getCompanyDetailById(this.id);
      if (res.status == 401) {
        await this.httpApiService.hideLoading();
        this.authService.logout();
      } else {
        let responseData = res.json().data;
        this.userData = responseData[0].companyRes;
        if (responseData[0].users.length > 0) {
          this.listOfUsers = responseData[0].users;
        } else {
          this.listOfUsers = [];
        }
        console.log(this.userData);
        this.selectedCompany = this.userData.name;
        this.contactNumber = this.userData.contactNumber;
        this.contactName = this.userData.contactName;
        this.webHookInOutUrl = this.userData.webHookInOutUrl;
        this.handleCompanyWebHook = this.userData.handleCompanyWebHook;
        this.name = this.userData.name;
        this.longitude = this.userData.longitude;
        this.latitude = this.userData.latitude;
        this.distance = this.userData.distance;
        this.subdomain = this.userData.subdomain;
        this.productType = this.userData.productType;
        this.companyLogo = this.userData.companyLogo;
        this.isOpen = false;
        this.listOfActionPermission = [];
        this.listOfDomains = responseData[0].domainRes;
        let index = this.items.findIndex(x => x.name == selectedCompanyId[0].name);
        this.arrowKeyLocation = index;
        if (this.userData.action && this.userData.action.length > 0) {
          this.listOfActions = this.userData.action;
        } else {
          this.listOfActions = [];
        }
      }
      await this.httpApiService.hideLoading();
    } else {
      this.selectedCompany = "";
      this.name = "";
      this.contactName = "";
      this.contactNumber = "";
      this.listOfUsers = [];
      this.listOfActions = [];
      this.listOfActionPermission = [];
      await this.httpApiService.hideLoading();
    }
  }

  async getAllCompany() {
    let res: any;
    res = await this.httpApiService.getAllCompany();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllCompanyData = res.json().data;
      this.items = this.getAllCompanyData;
    }
  }
  async updateArea() {
    await this.httpApiService.showLoading();
    let res: any;
    let jsonObj = { longitude: this.longitude, latitude: this.latitude, distance: this.distance };
    console.log("data", jsonObj, this.id);

    res = await this.httpApiService.updateArea(jsonObj, this.id);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      console.log(resp);
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      } else {
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }

  async deleteUserRole(id) {
    await this.httpApiService.showLoading();
    console.log(id);
    let res: any;
    let jsonObj = {
      userId: this.id,
      roleId: id
    };
    console.log(jsonObj);
    res = await this.httpApiService.deleteUserRole(jsonObj, this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp: any;
      resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 2000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      } else {
        this.searchCompany(this.selectedCompany);
        let toast = await this.toastCtrl.create({
          message: "User role deleted successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }

  //update

  async onChange(name) {
    this.showButton(name);
  }

  async showButton(name) {
    if (name == "name") {
      this.flgBtn = "name";
    } else if (name == "contactName") {
      this.flgBtn = "contactName";
    } else if (name == "designation") {
      this.flgBtn = "designation";
    } else if (name == "contactNumber") {
      this.flgBtn = "contactNumber";
    } else if (name == "handleCompanyWebHook") {
      this.flgBtn = "handleCompanyWebHook";
    } else if (name == "longitude") {
      this.flgBtn = "longitude";
    } else if (name == "latitude") {
      this.flgBtn = "latitude";
    } else if (name == "distance") {
      this.flgBtn = "distance";
    } else if (name == "subdomain") {
      this.flgBtn = "subdomain";
    } else if (name == "productType") {
      this.flgBtn = "productType";
    } else if (name == "companyLogo") {
      this.flgBtn = "companyLogo";
    } else {
      this.flgBtn = "webHookInOutUrl";
    }
  }

  async updateCompany() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let jsonObj = {
        companyId: this.id,
        name: this.name,
        contactName: this.contactName,
        contactNumber: this.contactNumber,
        webHookInOutUrl: this.webHookInOutUrl,
        handleCompanyWebHook: this.handleCompanyWebHook,
        subdomain: this.subdomain,
        productType: this.productType,
        companyLogo: this.companyLogo
      };
      let res: any;
      let id = this.id;
      res = await this.httpApiService.updateCompany(jsonObj, this.refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: userData.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          this.flgBtn = "";
          await this.getAllCompany();
          await this.searchCompany(this.name);
          let toast = await this.toastCtrl.create({
            message: "User updated successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
        }
      }
    }
  }

  async deleteWorkAreaFromUser(id) {
    await this.httpApiService.showLoading();
    console.log(id);
    let res: any;
    let jsonObj = {
      userId: this.id,
      workAreaId: id
    };
    console.log(jsonObj);
    res = await this.httpApiService.deleteWorkAreaFromUser(jsonObj, this.refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp: any;
      resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 2000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      } else {
        this.searchCompany(this.manageSelectedCompany);
        let toast = await this.toastCtrl.create({
          message: "User role deleted successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }

  async validateControls() {
    if (this.name != "" && this.handleCompanyWebHook != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please insert the detail",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }

  async getAllActionPermission(data) {
    await this.httpApiService.showLoading();
    console.log(data);
    this.heading = data.actionName;
    let res: any;
    res = await this.httpApiService.getAllActionPermissionById(data.id, this.id);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let actionData = res.json().data;
      this.listOfActionPermission = actionData.actionPermission;
      this.isOpen = true;
    }
    await this.httpApiService.hideLoading();
  }

  //open model for add user's role

  async manageCompany(type) {
    let obj = {
      type: type,
      id: this.id
    }
    let modalPage = await this.modalCtrl.create({
      component: ManageCompanyPage,
      componentProps: obj
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    await this.httpApiService.showLoading();
    this.searchCompany(this.manageSelectedCompany);
    this.getAllCompany();
    await this.httpApiService.hideLoading();
  }

  async deleteDomain(id) {
    await this.httpApiService.showLoading();
    console.log(id);
    let res: any;
    let jsonObj = {
      id: this.id,
      domainId: id
    };
    console.log(jsonObj);
    res = await this.httpApiService.deleteDomain(jsonObj);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp: any;
      resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 2000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      } else {
        this.searchCompany(this.selectedCompany);
        let toast = await this.toastCtrl.create({
          message: "Domain deleted successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }

  async updateDomain(row) {
    let obj = {
      type: 2,
      id: this.id,
      domain: row
    }
    let modalPage = await this.modalCtrl.create({
      component: ManageCompanyPage,
      componentProps: obj
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }
}
