import { Component, Input, ViewChild } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, Slides } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageShopPagePage } from "../manage-shop-page/manage-shop-page.page";

@Component({
  selector: "app-shop-page",
  templateUrl: "./shop-page.component.html",
  styleUrls: ["./shop-page.component.scss"]
})
export class ShopPageComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  @ViewChild("slides") slides: Slides;

  public selectedPage = "";
  public selectedSection = "";
  public getAllPageData: any;
  public items: any;
  public itemsSections: any;
  public itemsSectionImage: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationSections = -1;
  public arrowKeyLocationSectionImage = -1;
  public manageSelectedPage = "";
  public manageSelectedSection = "";
  public manageSelectedSectionImage = "";
  public pageId: any;
  public listOfSections = [];
  // public banners = [];
  public flgBtn = "";
  public pageName = "";
  public defaultPage: boolean;
  public bannerSearch = "";
  public sectionData: any;
  public sectionImages: any;
  public sectionType = "";
  public sectionName = "";
  public sectionId: any;
  public allSectionType: any;
  public order: 0;
  public showSectionName = false;
  public showPrice: any;
  public noOfProducts = 0;
  public collectionName = "";
  public redirectToProduct: any;

  public slideOpts = {
    effect: "flip",
    // loop: true,
    slidesPerView: 3,
    spaceBetween: 5,
    zoom: true,
    scrollbar: true,
    direction: "horizontal"
  };

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.getAllPages();
    await this.getSectionTypes();
  }

  async getSectionTypes() {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.getAllSectionType(refCompanyId);
    await this.httpApiService.hideLoading();
    if (!!res) {
      this.allSectionType = res.json().data;
      console.log("allSectionType", this.allSectionType);
    }
  }
  setItems() {
    this.items = this.getAllPageData;
  }

  async getAllPages() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllPages(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllPageData = res.json().data;
      // this.getAllPageData = [{ id: 1, name: "Diwali" }, { id: 2, name: "Holi" }];
      this.items = this.getAllPageData;
      console.log("get all page Data", this.getAllPageData);
    }
  }

  getPageDetail(name) {
    console.log(name);
    this.searchPageDetail(name);
  }

  async searchPageDetail(name) {
    console.log(name, this.manageSelectedPage);
    this.selectedSection = "";
    this.manageSelectedSection = "";
    let tmpName = "";
    this.setItems();
    if (name != undefined) {
      this.manageSelectedPage = name;
      this.selectedPage = name;
    } else {
      this.selectedPage = this.manageSelectedPage;
    }
    tmpName = this.manageSelectedPage;
    this.pageName = this.selectedPage;
    let selectedPageId = this.items.filter(function (search) {
      if (search.name == tmpName) {
        return search.id;
      }
    });
    console.log(this.items, selectedPageId);
    if (selectedPageId.length > 0) {
      this.pageId = selectedPageId[0].id;
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getSectionByPageId(this.pageId, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json().data;
        console.log(data);
        this.defaultPage = data[0].isDefault;
        if (data && data[0].shop_section && data[0].shop_section.length > 0) {
          this.listOfSections = data[0].shop_section;
          this.itemsSections = this.listOfSections;
        } else {
          this.itemsSections = [];
        }
        let index = this.items.findIndex(x => x.name == selectedPageId[0].name);
        this.arrowKeyLocation = index;
        console.log(this.listOfSections);
      }
    } else {
      this.selectedPage = "";
    }
  }

  getSectionDetail(name) {
    console.log(name);
    this.getSectionDetailById(name);
  }

  async getSectionDetailById(name) {
    console.log(name, this.manageSelectedSection);
    let tmpName = "";
    this.setSections();
    if (name != undefined) {
      this.manageSelectedSection = name;
      this.selectedSection = name;
    } else {
      this.selectedSection = this.manageSelectedSection;
    }
    tmpName = this.manageSelectedSection;
    let selectedSectionId = this.itemsSections.filter(function (search) {
      if (search.name == tmpName) {
        return search.id;
      }
    });
    console.log(this.itemsSections, selectedSectionId);
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    if (selectedSectionId.length > 0) {
      this.sectionId = selectedSectionId[0].id;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getAllSectionImageBySectionID(this.sectionId, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json().data;
        console.log(data);
        if (data) {
          console.log("typeTest", data[0].refSTId);
          this.sectionData = data[0];
          this.sectionName = data[0].name;
          this.sectionType = data[0].refSTId;
          this.order = data[0].order;
          this.showSectionName = data[0].showSectionName;
          this.showPrice = data[0].showPrice;
          this.noOfProducts = data[0].noOfProducts;
          this.collectionName = data[0].collectionName;
          this.redirectToProduct = data[0].redirectToProduct;
          if (data && data[0].shop_sectionImage && data[0].shop_sectionImage.length > 0) {
            this.sectionImages = data[0].shop_sectionImage;
            this.itemsSectionImage = this.sectionImages;
            // this.sectionName = this.sectionImages[0].name;
            // this.imageLink = this.sectionImages[0].link;
            // this.imageRedirectTo = this.sectionImages[0].redirectTo;
            // this.sectionId = this.sectionImages[0].id;
          } else {
            this.sectionImages = [];
            this.itemsSectionImage = [];
          }
        } else {
          this.sectionData = [];
        }
        let index = this.itemsSections.findIndex(x => x.name == selectedSectionId[0].name);
        this.arrowKeyLocationSections = index;
        console.log(this.sectionData);
      }
    } else {
      this.selectedSection = "";
    }
  }
  async setSections() {
    this.itemsSections = this.listOfSections;
  }

  async setSectionImage() {
    this.itemsSectionImage = this.sectionImages;
  }

  async filterSectionImages(ev: any) {
    this.setSectionImage();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.itemsSectionImage = await this.itemsSectionImage.filter(function (item) {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocationSectionImage = -1;
    } else {
      this.itemsSectionImage = this.sectionImages;
      this.arrowKeyLocationSectionImage = -1;
    }
  }

  keyDownSectionImages(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.itemsSectionImage && this.itemsSectionImage.length > 0) {
          if (this.arrowKeyLocationSectionImage != 0 && this.arrowKeyLocationSectionImage != -1) {
            this.arrowKeyLocationSectionImage--;
            this.manageSelectedSectionImage = this.itemsSectionImage[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.itemsSectionImage && this.itemsSectionImage.length > 0) {
          if (this.itemsSectionImage.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocationSectionImage++;
          }
          if (this.itemsSectionImage.length > this.arrowKeyLocation) {
            this.manageSelectedSectionImage = this.itemsSectionImage[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
        if (this.itemsSectionImage.length > this.arrowKeyLocationSectionImage) {
          if (this.itemsSectionImage.length != this.arrowKeyLocationSectionImage + 1) {
            this.arrowKeyLocationSectionImage++;
          }
          break;
        } else {
          break;
        }
    }
  }

  async redirectTo(image) {
    console.log("image", image);
    window.open(image.redirectTo, "_blank");
  }

  next() {
    this.slides.slideNext();
  }

  prev() {
    this.slides.slidePrev();
  }

  async openModal(item, flagModal) {
    let modalPage = await this.modalCtrl.create({ component: ManageShopPagePage, componentProps: { id: this.pageId, item: item, pageName: this.manageSelectedPage, flagModal: flagModal, allSectionType: this.allSectionType } });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d);
    if (d.data == "updateSectionImage" || d.data == "addSectionImage") {
      await this.getSectionDetail(this.manageSelectedSection);
    } else {
      await this.getAllPages();
      await this.searchPageDetail(this.manageSelectedPage);
    }
  }

  async showButton(name) {
    if (name == "link") {
      this.flgBtn = "link";
    } else if (name == "name") {
      this.flgBtn = "name";
    } else if (name == "redirectTo") {
      this.flgBtn = "redirectTo";
    } else if (name == "type") {
      this.flgBtn = "type";
    } else if (name == "pageName") {
      this.flgBtn = "pageName";
    } else if (name == "defaultPage") {
      this.flgBtn = "defaultPage";
    } else if (name == "order") {
      this.flgBtn = "orderPage";
    } else if (name == "showSectionName") {
      this.flgBtn = "showSectionName";
    } else if (name == "showPrice") {
      this.flgBtn = "showPrice";
    } else if (name == "noOfProducts") {
      this.flgBtn = "noOfProducts";
    } else if (name == "collectionName") {
      this.flgBtn = "collectionName";
    } else if (name == "redirectToProduct") {
      this.flgBtn = "redirectToProduct";
    }
  }

  async updatePage() {
    let validate = await this.validateControls();
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = { name: this.pageName, id: refCompanyId, pageId: this.pageId, isDefault: this.defaultPage };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updatePage(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: userData.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          this.flgBtn = "";
          await this.getAllPages();
          await this.searchPageDetail(this.pageName);
          let toast = await this.toastCtrl.create({ message: "Page updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async updateSection() {
    console.log("update section", this.sectionName, this.sectionType, this.pageId);
    let validate = await this.validateControls();
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        id: refCompanyId,
        sectionId: this.sectionId,
        typeId: this.sectionType,
        sectionName: this.sectionName,
        order: this.order,
        showSectionName: this.showSectionName,
        pageId: this.pageId,
        showPrice: this.showPrice,
        noOfProducts: this.noOfProducts,
        collectionName: this.collectionName,
        redirectToProduct: this.redirectToProduct
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateSection(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          await this.searchPageDetail(this.pageName);
          this.selectedSection = this.sectionName;
          await this.getSectionDetailById(this.selectedSection);
          let toast = await this.toastCtrl.create({ message: "Section updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async deleteSection() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteSection(this.sectionId, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.manageSelectedSection = "";
        this.selectedSection = "";
        await this.searchPageDetail(this.pageName);
        let toast = await this.toastCtrl.create({ message: "Section deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async deleteSectionImage(item) {
    console.log(item);
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteSectionImage(item.id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        // this.manageSelectedSection = "";
        await this.getSectionDetail(this.manageSelectedSection);
        let toast = await this.toastCtrl.create({ message: "Section Image deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async validateControls() {
    if (this.pageName != "" || (this.sectionName != "" && this.sectionType != "")) {
      return false;
    } else {
      let toast = await this.toastCtrl.create({ message: "Please insert the details", duration: 2000, position: "top", color: "danger" });
      await toast.present();
      return true;
    }
  }

  array_move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  };

  async reorder(index, position) {
    console.log(index, position, this.itemsSectionImage);
    // let images = this.listOfBanners;
    // images = images.map(res => res.id);
    // index = images.indexOf(index);
    let j = index;
    if (position == 'left') {
      --j;
    }
    if (position == 'right') {
      ++j;
    }
    let array = this.itemsSectionImage;
    this.itemsSectionImage = [];
    setTimeout(() => {
      this.itemsSectionImage = this.array_move(array, index, j);
      this.httpApiService.productPageSectionReorder(this.itemsSectionImage);
      this.toastCtrl.create({ message: "Product Reorder successfully", duration: 2000, position: "top", color: "success" });
    });
    // this.productData.data[0].listOfProductImages = this.array_move(this.productData.data[0].listOfProductImages, index, j);
  }
}
