import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: 'app-manage-view-report',
  templateUrl: './manage-view-report.page.html',
  styleUrls: ['./manage-view-report.page.scss'],
})
export class ManageViewReportPage implements OnInit {
  public type: any;
  public view: any;
  public fromDate: any;
  public refCompanyId: any;
  public usersWhoLoggedIn: any;
  public whoAddToCartWhichProductRes: any;
  public whoRemoveFromCartWhichProductRes: any;
  public whoBuyWhichProductRes: any;
  public productWiseDetailViewReportRes: any;
  public collectionWiseDetailViewReport: any;
  public refProductID: any;
  public refPcID: any;

  constructor(
    private modalCtrl: ModalController,
    public storage: Storage,
    private navParams: NavParams,
    public ConfigService: ConfigService,
    public authService: AuthenticationService,
    private toastCtrl: ToastController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public events: Events
  ) {
  }

  async ngOnInit() {
    await this.getRefCompanyId();
    this.type = this.navParams.get("type");
    this.view = this.navParams.get("view");
    this.fromDate = this.navParams.get("fromDate");
    this.refProductID = this.navParams.get("refProductID");
    this.refPcID = this.navParams.get("refPcID");
    console.log("ManageViewReportPage", this.type, this.view);
    if (this.type == 'login') {
      await this.getAllUsersWhoLoggedIn();
    } else if (this.type == "whoAddToCartWhichProduct") {
      await this.whoAddToCartWhichProduct();
    } else if (this.type == "whoRemoveFromCartWhichProduct") {
      await this.whoRemoveFromCartWhichProduct();
    } else if (this.type == "whoBuyWhichProduct") {
      await this.whoBuyWhichProduct();
    } else if (this.type == "getProductWiseDetailViewReport") {
      await this.getProductWiseDetailViewReport();
    } else if (this.type == "getCollectionWiseDetailViewReport") {
      await this.getCollectionWiseDetailViewReport();
    }
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async getAllUsersWhoLoggedIn() {
    let res: any;

    res = await this.httpApiService.getAllUsersWhoLoggedIn(this.refCompanyId, this.fromDate);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.usersWhoLoggedIn = res.json().data;
      console.log("usersWhoLoggedIn res", res, this.usersWhoLoggedIn);
    }
  }

  async whoAddToCartWhichProduct() {
    let res: any;

    res = await this.httpApiService.whoAddToCartWhichProduct(this.refCompanyId, this.fromDate);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.whoAddToCartWhichProductRes = res.json().data;
      console.log("whoAddToCartWhichProductRes res", res, this.whoAddToCartWhichProductRes);
    }
  }

  async whoRemoveFromCartWhichProduct() {
    let res: any;

    res = await this.httpApiService.whoRemoveFromCartWhichProduct(this.refCompanyId, this.fromDate);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.whoRemoveFromCartWhichProductRes = res.json().data;
      console.log("whoRemoveFromCartWhichProductRes res", res, this.whoRemoveFromCartWhichProductRes);
    }
  }

  async whoBuyWhichProduct() {
    let res: any;

    res = await this.httpApiService.whoBuyWhichProduct(this.refCompanyId, this.fromDate);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.whoBuyWhichProductRes = res.json().data;
      console.log("whoBuyWhichProductRes res", res, this.whoBuyWhichProductRes);
    }
  }

  async getProductWiseDetailViewReport() {
    let res: any;

    res = await this.httpApiService.getProductWiseDetailViewReport(this.refCompanyId, this.fromDate, this.refProductID);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.productWiseDetailViewReportRes = res.json().data;
      console.log("productWiseDetailViewReportRes res", res, this.productWiseDetailViewReportRes);
    }
  }

  async getCollectionWiseDetailViewReport() {
    let res: any;

    res = await this.httpApiService.getCollectionWiseDetailViewReport(this.refCompanyId, this.fromDate, this.refPcID);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.collectionWiseDetailViewReport = res.json().data;
      console.log("collectionWiseDetailViewReport res", res, this.collectionWiseDetailViewReport);
    }
  }
}
