import { Component, Input, OnInit } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { HttpApiService } from "../../services/http-api/http-api.service";
import * as moment from "moment";


@Component({
  selector: "app-print-format",
  templateUrl: "./print-format.component.html",
  styleUrls: ["./print-format.component.scss"]
})
export class PrintFormatComponent implements OnInit {
  @Input() list: any;
  @Input() type: any;
  public listTemp: any;
  public showOnlyPendingQty: any;

  constructor(public httpApiService: HttpApiService) {
    this.showOnlyPendingQty = false;
  }

  ngOnInit() {
    console.log("list ", this.list, this.type);
    this.listTemp = this.list;
  }

  // showOnlyPendingQtyChange($event) {
  //   debugger;
  //   this.showOnlyPendingQty;
  // }

  rowVisible(d) {
    if (this.showOnlyPendingQty) {
      if (d.order_quantity - (d.cancelledQuantity ? d.cancelledQuantity : 0) - (d.shipmentQuantity ? d.shipmentQuantity : 0) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  download() {
    console.log("download ", this.list, this.type);
    this.JSONToCSVConvertor(this.list, this.type + "-" + new Date(), "report");
  }

  async JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    console.log("arrData ", arrData);
    var CSV = "";

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      // console.log("arrData[i] ", arrData[i]);
      if (this.type == "Order Wise") {
        CSV += "Order Id: #" + arrData[i]["id"] + "," + "," + " Date: " + arrData[i]["addedOn"] + "," + "," + " Status: " + arrData[i]["orderStatus"] + "\r\n";
        CSV += "Client Remark:" + arrData[i]["clientRemark"] + "\r\n";
        CSV += "Payment Mode:" + arrData[i]["paymentMode"] + "\r\n";
        CSV += "Company:" + arrData[i]["companyName"] + "\r\n";
        CSV += "Outstanding Amount:" + arrData[i]["outstandingAmount"] + "\r\n";
        CSV += "Contact:" + arrData[i]["name"] + "(" + arrData[i]["city"] + "-" + arrData[i]["pinCode"] + " " + arrData[i]["state"] + " " + arrData[i]["country"] + "\r\n\n";
      } else {
        CSV += "SKU: #" + arrData[i]["sku"] + "\r\n";
      }
      if (ShowLabel) {
        var row1 = "";
        // console.log("arrData[0].details ", arrData[0].details, arrData[i].details.length);
        //Now convert each value to string and comma-seprated
        if (this.type == "Order Wise") {
          row1 += "SKU" + "," + "Qty" + "," + "Bal.Q" + "," + "Unit Price" + "," + "Discount" + "," + "Price" + "," + "Remark";
        } else {
          row1 += "Order Id" + "," + "Qty" + "," + "Bal.Q" + "," + "Customer" + "," + "Contact" + "," + "Unit Price" + "," + "Discount" + "," + "Price" + "," + "Remark";
        }

        //append Label row with line break
        CSV += row1 + "\r\n";
      }
      let resDetails: any;
      resDetails = await this.httpApiService.getProductDetailsByOrderId(arrData[i]["id"])

      if (resDetails.json().data) {
        arrData[i].details = resDetails.json().data.details
      }
      for (var index in arrData[i].details) {
        console.log("index ", arrData[i].details[index]);
        if (this.rowVisible(arrData[i].details[index])) {
          var row = "";
          let qty = 0;
          if (!!arrData[i].details[index]["order_quantity"]) {
            qty = arrData[i].details[index]["order_quantity"];
          } else {
            qty = arrData[i].details[index]["quantity"];
          }

          let cancelQty = 0, shipQty = 0;
          if (!!arrData[i].details[index]["cancelledQuantity"]) {
            cancelQty = arrData[i].details[index]["cancelledQuantity"];
          }
          if (!!arrData[i].details[index]["shipmentQuantity"]) {
            shipQty = arrData[i].details[index]["shipmentQuantity"];
          }
          let balQ = (qty - cancelQty - shipQty);
          let price = arrData[i].details[index]["price"];
          if (!!arrData[i].details[index]["unitPrice"]) {
            price = arrData[i].details[index]["unitPrice"];
          }
          if (this.type == "Order Wise") {
            row += '"' + arrData[i].details[index]["sku"] + '",';
            row += '"' + qty + '",';
            row += '"' + balQ + '",';
            row += '"' + price + '",';
            row += '"' + arrData[i].details[index]["discount"] + '",';
            row += '"' + arrData[i].details[index]["priceWithShipCost"].toFixed(2) + '",';
            row += '"' + arrData[i].details[index]["remark"] + '",';
          } else {
            row += '"' + arrData[i].details[index]["orderId"] + '",';
            row += '"' + qty + '",';
            row += '"' + balQ + '",';
            row += '"' + arrData[i].details[index]["companyName"] + '",';
            row += '"' + arrData[i].details[index]["name"] + '",';
            row += '"' + price + '",';
            row += '"' + arrData[i].details[index]["discount"] + '",';
            row += '"' + arrData[i].details[index]["priceWithShipCost"].toFixed(2) + '",';
            row += '"' + arrData[i].details[index]["remark"] + '",';
          }
          row.slice(0, row.length - 1);

          CSV += row + "\r\n";
        }
      }

      //add a line break after each row
      CSV += "\r\n\n\n";
    }

    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    // console.log("CSV ", CSV);
    //Generate a file name
    var fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
