import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-manage-zip-code",
  templateUrl: "./manage-zip-code.page.html",
  styleUrls: ["./manage-zip-code.page.scss"]
})
export class ManageZipCodePage implements OnInit {
  public pincode = "";
  public city = "";
  public state = "";
  public stateCode = "";
  public id: any;
  public item: any;

  constructor(private modalCtrl: ModalController, public storage: Storage, private navParams: NavParams, public ConfigService: ConfigService, public authService: AuthenticationService, private toastCtrl: ToastController, private nav: NavController, public httpApiService: HttpApiService) {}

  ngOnInit() {
    this.item = this.navParams.get("item");
    this.id = this.navParams.get("id");
    console.log(this.id, this.item);
    if (this.id != 0) {
      this.pincode = this.item.pincode;
      this.city = this.item.city;
      this.state = this.item.state;
      this.stateCode = this.item.stateCode;
    } else {
      this.pincode = "";
    }
  }

  async addZipCode() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        pincode: this.pincode,
        city: this.city,
        state: this.state,
        stateCode: this.stateCode
      };
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createZipCode(jsonObj);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          this.resetForm();
          let toast = await this.toastCtrl.create({
            message: "Zip Code created successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    }
  }

  async updateZipCode() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        zipCodeId: this.id,
        pincode: this.pincode,
        city: this.city,
        state: this.state,
        stateCode: this.stateCode
      };
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateZipCode(jsonObj);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      console.log("resp", resp);
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: res.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.data,
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    }
  }

  resetForm() {
    this.pincode = "";
    this.city = "";
    this.state = "";
    this.stateCode = "";
  }

  async validateControls() {
    if (this.pincode != "" && this.city != "" && this.state != "" && this.stateCode != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please select/insert the information",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }
}
