import { Component, OnInit } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";
import { ModalController, ToastController } from "@ionic/angular";
import { ManageFormTemplatePage } from "../manage-form-template/manage-form-template.page";
@Component({
  selector: "app-form-template",
  templateUrl: "./form-template.component.html",
  styleUrls: ["./form-template.component.scss"]
})
export class FormTemplateComponent implements OnInit {
  public items: any;
  public getAllForm: any;
  public manageSelectedForm: any = "";
  public selectedForm: any;
  public id: any;
  public name: any;
  public typeId: any;
  public type: any;
  public flgBtn: any;
  public arrowKeyLocation = -1;

  constructor(private toastCtrl: ToastController, private authService: AuthenticationService, private httpApiService: HttpApiService, private storage: Storage, public modalCtrl: ModalController) {}

  async ngOnInit() {
    await this.getForms();
  }

  async getForms() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.findAllFormTemplates(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllForm = res.json().data;
      console.log("items", this.getAllForm);
      this.items = this.getAllForm;
    }
  }
  setItems() {
    console.log("items", this.items);
    this.items = this.getAllForm;
  }

  getFormDetail(name) {
    this.searchFormDetail(name);
  }

  async searchFormDetail(name) {
    let tmpName = "";
    this.items = this.getAllForm;
    if (name != undefined) {
      tmpName = name;
      this.manageSelectedForm = name;
      this.selectedForm = name;
    } else {
      this.selectedForm = this.manageSelectedForm;
      tmpName = this.manageSelectedForm;
    }

    let selectedFormId = this.items.filter(function(search) {
      if (search.formName == tmpName) {
        return search.id;
      }
    });
    console.log(selectedFormId);
    this.name = selectedFormId[0].formName;
    this.type = selectedFormId[0].type;
    this.typeId = selectedFormId[0].typeId;
    this.id = selectedFormId[0].id;
  }
  async showButton(item) {
    this.flgBtn = item;
  }
  async manageForms(flag) {
    let modalPage = await this.modalCtrl.create({
      component: ManageFormTemplatePage,
      componentProps: { flag: flag }
    });
    modalPage.onDidDismiss().then(d => {
      this.getForms();
    });
    return await modalPage.present();
  }

  async updateForm() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let jsonObj = {
      formId: this.id,
      name: this.name,
      type: this.type,
      typeId: this.typeId,
      companyId: refCompanyId
    };
    console.log(jsonObj);
    let validate = await this.validator();
    if (validate == true) {
      let res = await this.httpApiService.updateFormTemplate(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          console.log(data.data);
          if (data.data) {
            let toast = await this.toastCtrl.create({ message: "Form template update successfully", duration: 3000, position: "top", color: "success" });
            await toast.present();
            await this.getForms();
          } else {
            let toast = await this.toastCtrl.create({ message: data, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        }
      }
    }
  }

  async validator() {
    console.log("new", this.name, this.type, this.typeId);
    if (this.name == undefined || this.type == undefined || this.typeId == undefined || this.name == "" || this.type == "" || this.typeId == "" || this.name == null || this.type == null || this.typeId == null) {
      let toast = await this.toastCtrl.create({ message: "Please fill data", duration: 3000, position: "top", color: "danger" });
      await toast.present();
      return false;
    } else {
      console.log("True");
      return true;
    }
  }
}
