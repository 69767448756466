import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-manage-company",
  templateUrl: "./manage-company.page.html",
  styleUrls: ["./manage-company.page.scss"]
})
export class ManageCompanyPage implements OnInit {
  public name = "";
  public contactName = "";
  public contactNumber = "";
  public webHookInOutUrl = "";
  public handleCompanyWebHook = "";
  public priorityBase = 0;
  public subdomain = "";
  public productType = "";
  public companyLogo = "";
  public type: any;
  public id: any;
  public domainName = "";
  public domain: any;
  public domainId: any;

  constructor(private modalCtrl: ModalController, public storage: Storage, private navParams: NavParams, public ConfigService: ConfigService, public authService: AuthenticationService, private toastCtrl: ToastController, private nav: NavController, public httpApiService: HttpApiService) { }

  ngOnInit() {
    this.type = this.navParams.get("type");
    this.id = this.navParams.get("id");
    this.domain = this.navParams.get("domain");
    if (!!this.domain && this.type == 2) {
      this.domainName = this.domain.domain;
      this.domainId = this.domain.id;
    }
  }

  //create Company

  async createCompany() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        name: this.name,
        contactName: this.contactName,
        contactNumber: this.contactNumber,
        webHookInOutUrl: this.webHookInOutUrl,
        priorityBase: this.priorityBase,
        handleCompanyWebHook: this.handleCompanyWebHook,
        subdomain: this.subdomain,
        productType: this.productType,
        companyLogo: this.companyLogo
      };
      console.log(jsonObj);
      let res: any;
      res = await this.httpApiService.createCompany(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: userData.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({
            message: "Company created successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
          this.resetForm();
          // await this.closeModal();
        }
      }
    }
  }

  async createDomain() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let jsonObj = {
        id: this.id,
        domain: this.domainName
      };
      console.log(jsonObj);
      let res: any;
      res = await this.httpApiService.createDomain(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: userData.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({
            message: "Domain created successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
          this.resetForm();
          // await this.closeModal();
        }
      }
    }
  }

  async updateDomain() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let jsonObj = {
        id: this.id,
        domainId: this.domainId,
        domain: this.domainName
      };
      console.log(jsonObj);
      let res: any;
      res = await this.httpApiService.updateDomain(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: userData.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({
            message: "Domain updated successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
          this.resetForm();
          // await this.closeModal();
        }
      }
    }
  }

  async validateControls() {
    if (this.type == 0) {
      if (this.name != "" && this.handleCompanyWebHook != "") {
        return false;
      } else {
        let toast = await this.toastCtrl.create({
          message: "Please insert the detail",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
    } else if (this.type == 1) {
      if (this.domainName != "") {
        return false;
      } else {
        let toast = await this.toastCtrl.create({
          message: "Please insert the detail",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  resetForm() {
    this.name = "";
    this.contactName = "";
    this.contactNumber = "";
    this.webHookInOutUrl = "";
    this.handleCompanyWebHook = "";
    this.subdomain = "";
    this.productType = "";
    this.companyLogo = "";
    this.domainName = "";
  }
}
