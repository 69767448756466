import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, ToastController } from "@ionic/angular";
import { AddOrderBookPage } from "../add-order-book/add-order-book.page";

@Component({
  selector: 'app-manage-order-book',
  templateUrl: './manage-order-book.component.html',
  styleUrls: ['./manage-order-book.component.scss']
})
export class ManageOrderBookComponent implements OnInit {
  public listOfData: any;
  public items: any;
  public manageSelectedOrderBook = "";

  public selectedOrderBookId = "";
  public itemsActions: any;
  public listOfActions: any;
  public listOfActionPermissions: any;
  public flagButton = "";
  public id: any;
  public OrderBookName: any;
  public updateButton: any = false;
  public refCompanyId: any;

  constructor(public storage: Storage, public httpApiService: HttpApiService, public modalCtrl: ModalController, public toastCtrl: ToastController) {}

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllData();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllData() {
    let res: any;

    res = await this.httpApiService.getAllOrderBooks(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfData = res.json();
      this.setItems();
      console.log("listOfData res", res, this.listOfData);
    }
  }

  setItems() {
    this.items = this.listOfData.data;
  }

  async showButton(name) {
    console.log("call");
    if (name == "OrderBookName") {
      this.flagButton = "OrderBookName";
    } 
	// else if (name == "SortName") {
      // this.flagButton = "SortName";
    // }
	
  }

  async update() {
    debugger
    let objData = { name: this.OrderBookName, did: this.id };
    console.log(objData);
    let res = await this.httpApiService.updateOrderBook(objData, this.refCompanyId);
    let resp = res.json();
    console.log("resp", resp);
    if (resp.error) {
      let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
      toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: "Order book updated successfully", duration: 3000, position: "top", color: "success" });
      toast.present();
    }
    this.getAllData();
  }

  async search(OrderBookName) {
    if (OrderBookName) {
      OrderBookName = OrderBookName.name;
    } else {
      OrderBookName = this.manageSelectedOrderBook;
    }

    this.updateButton = false;
    this.flagButton = null;
    console.log(OrderBookName);
    let tmpOrderBookName = "";
    this.items = this.listOfData.data;
    if (OrderBookName != undefined) {
      tmpOrderBookName = OrderBookName;
      this.selectedOrderBookId = OrderBookName;
      this.manageSelectedOrderBook = OrderBookName;
    } else {
      this.selectedOrderBookId = this.manageSelectedOrderBook;
      tmpOrderBookName = this.manageSelectedOrderBook;
    }
    console.log("OrderBookName", OrderBookName, tmpOrderBookName);
    let selectedOrderBookIdId = this.items.filter(function(search) {
      if (search.name == tmpOrderBookName) {
        return search.id;
      }
    });
    console.log("selectedOrderBookIdId", selectedOrderBookIdId);
    if (selectedOrderBookIdId.length > 0) {
      let dId = selectedOrderBookIdId[0].id;
      let OrderBookName = selectedOrderBookIdId[0].name;
      console.log(OrderBookName, dId);
      let res: any;
      console.log("ids", dId, this.refCompanyId);
      res = await this.httpApiService.getOrderBookDetail(dId, this.refCompanyId);
      if (res.status == 0) {
      } else {
        console.log("res", res, res.json());
        let dData = res.json().data;
        console.log(dData);
        this.OrderBookName = dData.name;
		this.id = dData.id;

        console.log("this.listOfActions", this.listOfActionPermissions, this.selectedOrderBookId);
      }
    } else {
      this.OrderBookName = "";
      }
  }

  async NewAdd() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: AddOrderBookPage,
      componentProps: { items: this.items, type: 2 }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAllData();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
}
