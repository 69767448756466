import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";
import { Http } from "@angular/http";


@Component({
  selector: 'app-info-order-dispatch-detail',
  templateUrl: './info-order-dispatch-detail.page.html',
  styleUrls: ['./info-order-dispatch-detail.page.scss'],
})
export class InfoOrderDispatchDetailPage implements OnInit {

  public flagModal: string;
  public rowData: any;
  public productDetails = [];
  public shipDetails = [];
  public limit = 20;
  public companyDetail: any;

  constructor(public http: Http,
    private authService: AuthenticationService,
    public storage: Storage, public navParams: NavParams,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController, public httpApiService: HttpApiService, public configService: ConfigService) { }

  async ngOnInit() {
    this.flagModal = await this.navParams.get('flagModal')
    this.rowData = await this.navParams.get('data');
    this.companyDetail = await this.navParams.get('companyDetail');
    if (!this.companyDetail.companyLogo) {
      this.companyDetail.companyLogo = 'assets/logo/atisaheLogo.png';
    }
    if (this.flagModal == 'detail') {
      await this.getShipmentDetailByShipId();

    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async getShipmentDetailByShipId() {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res: any;
    res = await this.httpApiService.getShipmentDetailByShipId(refCompanyId, this.rowData.id);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      await this.httpApiService.hideLoading();
      this.shipDetails = res.json().data;
      if (!!this.shipDetails) {
        this.productDetails = this.shipDetails[0].details;
      }
    }
  }

  // temp
  async openInvoice() {
    await this.configService.print()
    // await this.modalCtrl.dismiss();
  }

}
