import { Component, Input } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { NavController, ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { TimeAgoPipe } from "../../../pipes/timeAgo/time-ago.pipe";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-process",
  templateUrl: "./process.component.html",
  styleUrls: ["./process.component.scss"],
  providers: [TimeAgoPipe]
})
export class ProcessComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  formDetails: any;
  processDetails: any;
  summaryDetail: any;

  public processId: any;
  public phoneNo: any;
  public ticketDetails: any;
  public userData: any;

  public ticketTitle1: any;
  public ticketTitle2: any;
  public processName: any;
  public ticketDesc: any;
  public replies: any = [];
  public createdAt: any;
  public showNextProcess = false;
  public tktData: any;
  public companyName: any;
  public myReplyText: any;
  public items: any;
  public arrowKeyLocation = 0;
  public processTimelineData: any;
  public startedOn: any;
  public startedBy: any;
  public currentState: any;
  public runningProcessId: any;
  public userId: any;
  public isToggled: boolean;
  public ticketStatus: any;
  public processTitle: any;
  public limit = 10;
  public skip = 0;
  public counter;
  public order = "updatedOn";
  public orderBy = "desc";
  public search = "";
  public listOfDept: any;
  public listOfUsers: any;
  public selectedDepartment: any;
  public selectedUser: any;
  public assignedTo: any;

  constructor(private authService: AuthenticationService, private configService: ConfigService, public httpApiService: HttpApiService, public navCtrl: NavController, public router: Router, public toastCtrl: ToastController, public timeAgo: TimeAgoPipe, private storage: Storage) {
    this.summaryDetail = "summary";
    this.ticketTitle1 = "";
    this.ticketTitle2 = "";
    this.ticketDesc = "";
    this.processTitle = this.data;
    this.assignedTo = 0;
  }

  async ngOnInit() {
    await this.getFormDetails();
  }

  setItems() {
    this.items = this.processDetails;
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    console.log("val", val);
    if (val && val.trim() !== "") {
      this.search = val;
      // this.items = await this.items.filter(function(item) {
      //   if (item.createdby && item.createdby.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (item.updatedby && item.updatedby.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (item.status != null && item.status.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else if (item.processId != null && item.processId.toLowerCase().includes(val.toLowerCase())) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });
    } else {
      this.search = "";
    }
    await this.getFormDetails();
    this.arrowKeyLocation = 0;
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.arrowKeyLocation != 0) {
          this.arrowKeyLocation--;
          // this.selectedProcess = this.items[this.arrowKeyLocation].actionName;
          if (this.arrowKeyLocation == 0) {
            this.arrowKeyLocation++;
          }
          break;
        } else {
          break;
        }
      case 40: // this is the ascii of arrow down
        if (this.items.length > this.arrowKeyLocation) {
          // this.selectedProcess = this.items[this.arrowKeyLocation].actionName;
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          break;
        } else {
          break;
        }
    }
    // console.log(this.arrowKeyLocation);
  }

  async getFormDetails() {
    this.userData = await this.getUserData();
    console.log("this.userData", this.userData, this.assignedTo);
    await this.httpApiService.showLoading();
    await this.getUserPhNoByUserId(this.userData.id);
    let res: any;
    this.processTitle = this.data.split(" Process");
    console.log("this.processTitle", this.processTitle);
    if (this.phoneNo) {
      let departmentId, assignToUserId, byMe = 0;
      if (this.assignedTo == 1) {
        departmentId = this.userData.refDepartmentId;
        assignToUserId = 0;
      } else if (this.assignedTo == 2) {
        departmentId = 0;
        assignToUserId = this.userData.id;
      } else if (this.assignedTo == 3) {
        departmentId = 0;
        assignToUserId = 0;
        byMe = this.userData.id;
      } else {
        departmentId = 0;
        assignToUserId = 0;
      }
      res = await this.httpApiService.findFormDetailsByNameAndPhoneNoUsingPagination(this.processTitle[0], this.phoneNo, this.limit, this.skip, this.order, this.orderBy, this.search, this.cid, departmentId, assignToUserId, byMe);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        this.counter = res.json().counter;
        this.formDetails = res.json().data;
        if (this.formDetails) {
          console.log("this.formDetails", this.formDetails);
          this.processDetails = this.formDetails;
          // this.processDetails = this.processDetails.sort((a, b) => {
          //   let bDate: any = new Date(b.updatedOn);
          //   let aDate: any = new Date(a.updatedOn);
          //   return bDate - aDate;
          // });
          // this.processDetails.filter(row => {
          //   row.startedOn = this.timeAgo.transform(row.startedOn);
          //   row.updatedOn = this.timeAgo.transform(row.updatedOn);
          // });
          /*this.processDetails.filter(process => {
            this.formDetails.runningProcessLogRes.filter(rP => {
              if (process.refRunningProcessId == rP.refRunningProcessId) {
                process.lastUpdatedOn = rP.dateTime;
                process.lastUpdatedUserId = rP.refUserId;
                process.lastUpdatedUserName = rP.userName;
                process.status = rP.status;
              }
            });
          });*/
          this.processDetails.filter(process => {
            if (process.status == 1) {
              process.status = "Open";
            } else {
              process.status = "Closed";
            }
          });
          console.log("this.processDetails", this.processDetails);
          this.setItems();
        } else {
          let toast = await this.toastCtrl.create({
            message: res.json().error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      }
    } else {
      await this.httpApiService.hideLoading();
    }
  }

  async showTicket(process) {
    this.summaryDetail = "Detail";
    this.setItems();
    this.processId = process.processId;
    this.runningProcessId = process.id;
    this.userId = process.refUserId;
    this.selectedDepartment = null;
    this.selectedUser = null;
    this.assignedTo = 0;
    await this.getProcessDetails();
    console.log("process", process);
    // let data = {
    //   pid: process.processId,
    //   cid: this.cid
    // };
    // this.navCtrl.navigateForward("showTicket/" + process.processId + "/" + this.cid);
    // this.router.navigate(["showTicket"], { queryParams: data, skipLocationChange: true });
  }
  async changeViewType() {
    if (this.summaryDetail == "Detail") {
      this.summaryDetail = "summary";
      this.ticketTitle1 = "";
      this.ticketTitle2 = "";
      this.ticketDesc = "";
      this.replies = [];
      this.showNextProcess = false;
      this.selectedDepartment = null;
      this.selectedUser = null;
      this.assignedTo = 0;
      this.search = "";
      this.getFormDetails();
    }
  }

  async getUserData() {
    return await this.authService.userData();
  }

  async getProcessDetails() {
    await this.httpApiService.showLoading();
    await Promise.all([this.getProcess(), this.getProcessTimeline()]);
    await this.httpApiService.hideLoading();
  }

  async getUserPhNoByUserId(userId) {
    let res: any;
    res = await this.httpApiService.getUserPhNoByUserId(userId, this.cid);
    if (res.json().data) {
      this.phoneNo = res.json().data.username;
    }
    console.log("phoneNo", this.phoneNo);
  }

  async getDepartments() {
    let depRes: any;
    depRes = await this.httpApiService.getAllDepartment(this.cid);
    if (depRes.status == 401) {
      this.authService.logout();
    } else {
      this.listOfDept = await depRes.json().data;
      console.log("list of departments", this.listOfDept);
    }
  }

  async getProcess() {
    await this.getDepartments();
    let res: any;
    res = await this.httpApiService.getProcess(this.processId, this.cid, this.phoneNo);
    this.tktData = res.json();
    this.processName = this.tktData.data.currentProcess.formName;
    this.ticketTitle1 = this.tktData.data.customJson.title1;
    this.ticketTitle2 = this.tktData.data.customJson.title2;
    this.ticketDesc = this.tktData.data.customJson.mainMsg;
    this.createdAt = this.tktData.data.created;
    if (this.tktData.data.currentUserMobileNo.length > 0) {
      this.companyName = this.tktData.data.currentUserMobileNo[0].name;
    }
    this.ticketStatus = this.tktData.data.status;
    if (this.tktData.data.department != null) {
      this.selectedDepartment = parseInt(this.tktData.data.department);
      // await this.getUsersByDepartment();
    }
    if (this.tktData.data.assignToUser != null) {
      this.selectedUser = parseInt(this.tktData.data.assignToUser);
    }
    console.log("selected department, user", this.selectedDepartment, this.selectedUser);
    if (this.ticketStatus == 1) {
      this.isToggled = true;
    } else {
      this.isToggled = false;
    }
    this.replies = [];
    if (this.tktData.data.discussion) {
      this.tktData.data.discussion.forEach((element, key) => {
        // element.userAvatar = "assets/img/usericon.png";
        // this._smTrackerService.getMobileNo(element.phoneNo).then(res => {
        //   if (!!res.image) this.replies[key].userAvatar = res.image;
        // });
        this.replies.push(element);
      });
    }
    console.log("this.replies", this.replies, this.tktData);
    if (this.tktData.data.parentMobileNo != undefined && this.tktData.data.parentMobileNo.length > 0) {
      this.tktData.data.parentMobileNo.forEach(element => {
        if (element.username == this.phoneNo) {
          //element.phoneNo) {
          this.showNextProcess = true;
        }
      });
    }
  }

  async getProcessTimeline() {
    let res: any;
    res = await this.httpApiService.getProcessTimeline(this.runningProcessId, this.cid);
    this.processTimelineData = res.json();
    if (this.processTimelineData.data) {
      // this.startedOn = this.processTimelineData.data.startedOn;
      // this.startedBy = this.processTimelineData.data.startedBy;
      // this.processTimelineData.data.runningProcessLog.filter(element => {
      //   this.currentState = element.status;
      //   element.msg = element.log.split(") : ")[1].split(" ")[0];
      // });
      this.processTimelineData = this.processTimelineData.data;
      this.processTimelineData.filter(process => {
        process.dateTime = this.timeAgo.transform(process.dateTime);
      });
    }
    console.log("this.processTimelineData", this.processTimelineData);
  }

  async postReply() {
    if (!!!this.myReplyText) {
      // mobiscroll.toast({ message: "Please add some text to reply." });
    } else {
      // this.loader.show("Please wait..");

      let replyObj = {
        message: this.myReplyText,
        id: parseInt(this.cid),
        mobileNumber: parseInt(this.phoneNo),
        runningProcessId: parseInt(this.processId)
      };

      // console.log(replyObj);
      await this.httpApiService.showLoading();
      this.httpApiService.addReplyToTicketTalkBrite(replyObj, this.cid).then(
        response => {
          // this.loader.hide();
          this.getProcess();
          this.httpApiService.hideLoading();
          this.myReplyText = "";
        },
        err => {
          console.error(err);
          this.httpApiService.hideLoading();
        }
      );
    }
  }

  async castNextProcess(str: string) {
    let username = await this.storage.get("username");
    return decodeURI(str)
      .replace("@approveuser", username)
      .replace("@mode", this.configService.mode);
  }

  async redirectToNextForm(str1: string) {
    str1 = await this.castNextProcess(str1);
    console.log("redirect", str1);
    window.open(str1, "_blank");

    /*const options: InAppBrowserOptions = { zoom: "no", closebuttoncaption: "Cancel", toolbarposition: "top", location: "no" };

    let browser = this.inAppBrowser.create(str1, "_blank", options);
    console.log("browser", browser);
    // if (this.onMobile) {
    browser.on("loadstart").subscribe(
      event => {
        // console.log("load start -->", event);
        console.log("URL " + event.url);
        if (event.url.match("#/login")) {
          //alert("back to app");
          browser.close();
        }
      },
      err => {
        console.log("InAppBrowser loadstart Event Error: " + err);
      }
    );

    browser.on("load stop").subscribe(event => {
      console.log("URL " + event.url);

      if (event.url.match("connection success")) {
        browser.close();
      }

      if (event.url.match("login")) {
        browser.close();
      }
    });*/
    // }
  }

  async update() {
    console.log(this.isToggled, this.ticketStatus, this.ticketStatus == 1 && !this.isToggled, this.ticketStatus == 0 && this.isToggled);
    let status;
    if (this.ticketStatus == null || (this.ticketStatus == 1 && !this.isToggled) || (this.ticketStatus == 0 && this.isToggled)) {
      if (this.isToggled) {
        status = 1;
      } else {
        status = 0;
      }
      this.userData = await this.getUserData();
      let jsonObj = { runningProcessId: this.runningProcessId, status: status, userId: this.userData.id };
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.changeProcessStatus(jsonObj, this.cid);
      await this.httpApiService.hideLoading();
      console.log("res", res);
      await this.getProcessDetails();
    }
  }

  async setPage(event) {
    console.log("event", event);
    this.limit = event.limit;
    this.skip = event.offset * event.pageSize;
    await this.getFormDetails();
  }

  async onSort(event) {
    console.log("event", event);
    if (event.newValue == undefined && event.prevValue == "asc") {
      this.orderBy = "desc";
    } else if (event.newValue == undefined && event.prevValue == "desc") {
      this.orderBy = "asc";
    } else {
      this.orderBy = event.newValue;
    }
    this.order = event.column.prop;
    await this.getFormDetails();
  }

  async getUsersByDepartment() {
    let res: any;
    res = await this.httpApiService.getUsersByDepartment(this.cid, this.selectedDepartment);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.listOfUsers = res.json().data;
      console.log("list of users", this.listOfUsers);
    }
  }

  async departmentSelected(event, type) {
    console.log("department selected", event.target.value);
    this.selectedDepartment = event.target.value;
    this.selectedUser = null;
    let res: any;
    await this.httpApiService.showLoading();
    await this.getUsersByDepartment();
    await this.httpApiService.hideLoading();
  }

  async userSelected(event) {
    console.log("user selected", event.target.value);
    this.selectedUser = event.target.value;
    if (this.selectedUser == "0") {
      this.selectedUser = parseInt(this.selectedUser);
    }
  }

  async assign() {
    console.log("assign to ", this.runningProcessId, this.selectedDepartment, this.selectedUser);
    if (this.selectedDepartment == undefined || this.selectedDepartment == null || this.selectedUser == undefined || this.selectedUser == null) {
      let toast = await this.toastCtrl.create({
        message: "Please select Department/User",
        duration: 3000,
        position: "top",
        color: "danger"
      });
      await toast.present();
    } else {
      let updateObj = { runningProcessId: this.runningProcessId, departmentId: this.selectedDepartment, assignToUserId: this.selectedUser, id: this.cid };
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.assignToDeptAndUser(updateObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Process assigned successfully",
            duration: 2000,
            position: "top",
            color: "success"
          });
          await toast.present();
          await this.getProcessTimeline();
        }
      }
    }
  }

  async assigned(event) {
    console.log("event", event.target.value);
    this.assignedTo = event.target.value;
    await this.getFormDetails();
  }
}
