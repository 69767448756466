import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: 'app-manage-courier-service',
  templateUrl: './manage-courier-service.page.html',
  styleUrls: ['./manage-courier-service.page.scss'],
})
export class ManageCourierServicePage implements OnInit {
  public name = "";
  public address = "";
  public link = "";
  public id: any;
  public item: any;

  constructor(private modalCtrl: ModalController, public storage: Storage, private navParams: NavParams, public ConfigService: ConfigService, public authService: AuthenticationService, private toastCtrl: ToastController, private nav: NavController, public httpApiService: HttpApiService) { }

  ngOnInit() {
    this.item = this.navParams.get("item");
    this.id = this.navParams.get("id");
    console.log(this.id, this.item);
    if (this.id != 0) {
      this.name = this.item.name;
      this.address = this.item.address;
      this.link = this.item.link;
    } else {
      this.name = "";
      this.address = "";
      this.link = "";
    }
  }

  async addCourierService() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        name: this.name,
        address: this.address,
        link: this.link,
        id: refCompanyId
      };
      let res: any;
      res = await this.httpApiService.addCourierService(jsonObj);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          this.resetForm();
          let toast = await this.toastCtrl.create({
            message: "Courier created successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    }
  }

  async updateCourierService() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        id: this.id,
        name: this.name,
        address: this.address,
        link: this.link,
        refCompanyId: refCompanyId
      };
      let res: any;
      res = await this.httpApiService.updateCourierService(jsonObj);
      let resp = res.json();
      console.log("resp", resp);
      await this.httpApiService.hideLoading();
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: res.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.data,
            duration: 3000,
            position: "top",
            color: "success"
          });
          toast.present();
        }
      }
    }
  }

  resetForm() {
    this.name = "";
    this.address = "";
    this.link = "";
  }

  async validateControls() {
    if (this.name != "" && this.address != "" && this.link != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please select/insert the information",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    }
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }


}
