import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-customer-data-upload",
  templateUrl: "./customer-data-upload.component.html",
  styleUrls: ["./customer-data-upload.component.scss"]
})
export class CustomerDataUploadComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  selectedFile: File = null;
  public csvFileHistories: any;
  public refCompanyId: any;
  public userData: any;
  public selectedDataFormat: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {
    this.selectedDataFormat = "DD";
  }

  ngOnInit() {
    this.findAll();
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  async processFile() {
    if (this.selectedFile) {
      console.log(this.selectedFile);
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      console.log("form data", formData);
      let res: any;
      let jsonObj = { id: this.refCompanyId, fileFormatName: this.selectedDataFormat, userId: this.userData.id, formData: formData, shouldWait: false };
      await this.httpApiService.showLoading();
      res = await this.httpApiService.fileOrderDispatch(jsonObj);
      await this.httpApiService.hideLoading();
      console.log("response", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().error) {
          let toast = await this.toastCtrl.create({
            message: res.json().error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        } else {
          if (res.json().data) {
            let toast = await this.toastCtrl.create({
              message: res.json().data,
              duration: 3000,
              position: "top",
              color: "success"
            });
            await toast.present();
            await this.findAll();
          }
        }
      }
    }
  }

  async downloadCSV(path) {
    console.log("path ", path);
    window.open(path, "_blank");
  }

  async findAll() {
    this.refCompanyId = await this.storage.get("refCompanyId");
    this.userData = await this.authService.userData();
  }
}
