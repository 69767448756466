import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, ToastController, AlertController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageViewReportPage } from "../manage-view-report/manage-view-report.page";

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss']
})
export class ViewReportComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public viewDetails: any;
  public productWiseDetails: any;
  public productCollectionWiseDetails: any;
  public refCompanyId: any;
  public fromDate: any
  public view = "viewDetails";

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {
    let fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.fromDate = new Date().getFullYear() + "-" + ("0" + (fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + fromDate.getDate()).slice(-2);
  }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllViews();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllViews() {
    let res: any;

    res = await this.httpApiService.getViewReport(this.refCompanyId, this.fromDate);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.viewDetails = res.json().data;
      console.log("viewDetails res", res, this.viewDetails);
    }
  }
  async getProductWiseViews() {
    let res: any;

    res = await this.httpApiService.getProductWiseViewReport(this.refCompanyId, this.fromDate);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.productWiseDetails = res.json().data;
      console.log("productWiseDetails res", res, this.productWiseDetails);
    }
  }
  async getCollectionWiseViews() {
    let res: any;

    res = await this.httpApiService.getCollectionWiseViewReport(this.refCompanyId, this.fromDate);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.productCollectionWiseDetails = res.json().data;
      console.log("productCollectionWiseDetails res", res, this.productCollectionWiseDetails);
    }
  }
  async fetchDetails() {
    if (this.view == 'product') {
      await this.getProductWiseViews();
    } else if (this.view == 'collection') {
      await this.getCollectionWiseViews();
    } else {
      await this.getAllViews();
    }
  }

  async getDetail(type) {
    let obj = {
      type: type,
      view: this.view,
      fromDate: this.fromDate
    }
    let modalPage = await this.modalCtrl.create({
      component: ManageViewReportPage,
      componentProps: obj
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    await this.fetchDetails();
  }

  async onActivate(event, type) {
    if (event.type == 'click') {
      console.log(event.row);
      let obj;
      if (this.view == 'product') {
        obj = {
          type: type,
          view: this.view,
          fromDate: this.fromDate,
          refProductID: event.row.productId
        }
      } else if (this.view == 'collection') {
        obj = {
          type: type,
          view: this.view,
          fromDate: this.fromDate,
          refPcID: event.row.productCollectionId
        }
      }
      let modalPage = await this.modalCtrl.create({
        component: ManageViewReportPage,
        componentProps: obj
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    }
  }
}
