import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "moment"
})
export class MomentPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let yesterday = moment().subtract(1, "day");
    let twoDaysAgo = moment().subtract(2, "day");
    // console.log("value", value);
    if (moment(value).isSame(yesterday, "day")) {
      return "Yesterday";
    } else if (moment(value).isBefore(twoDaysAgo, "day")) {
      return value;
    } else {
      return moment(value).fromNow();
    }
  }
}
