import { Component, Input, ViewChild } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, Slides } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageProductPage } from "../../actions/manage-product/manage-product.page";

@Component({
  selector: 'app-copy-product',
  templateUrl: './copy-product.component.html',
  styleUrls: ['./copy-product.component.scss']
})
export class CopyProductComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  @ViewChild("slides") slides: Slides;

  public getAllProductData: any;
  public items: any;
  public arrowKeyLocation = -1;
  public selectedProduct = "";
  public offset = 0;
  public limit = 30;
  public allCompanyList: any;
  public SKU: string = '';
  public refCompanyId = 0;
  public Company = 0;
  public id: number = 0;
  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    this.refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();

    let res1 = await this.httpApiService.getAllCompany();

    await this.httpApiService.hideLoading();

    if (res1.json().isSuccess) {
      this.allCompanyList = res1.json().data;
    }

    // this.getAllProducts();
  }

  setItems() {
    this.items = this.getAllProductData;
  }

  async filterItems(ev: any) {
    this.selectedProduct = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      let res = await this.httpApiService.fetchProductsByPagination(this.offset, this.limit, val, this.refCompanyId);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().isSuccess) {
          this.getAllProductData = res.json().data;
          this.items = this.getAllProductData;
          this.arrowKeyLocation = -1;
        }
      }
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.getAllProductData;
    }
  }

  async onChange(ev) {
    this.refCompanyId = this.Company;
    this.SKU = '';
    this.filterItems({
      target: {
        value: '%'
      }
    });
  }
  async copyProduct() {
    await this.httpApiService.showLoading();

    let res = await this.httpApiService.copyProduct({
      id: this.id, companyId: this.refCompanyId, refCompanyId: this.cid
    });
    await this.httpApiService.hideLoading();

    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().isSuccess) {
        this.getAllProductData = res.json().data;
        this.items = this.getAllProductData;
        this.arrowKeyLocation = -1;
      }
    }
  }

  async getProductDetail(ev) {
    // if (!!this.SKU) {
    //   let sku = this.SKU;
    //   if (sku.split(",").indexOf(ev.sku) == -1) {
    //     this.SKU += "," + ev.id;
    //   } else {
    //     console.log("error");
    //   }
    // } else {
    this.SKU = ev.sku;
    this.id = Number(ev.id);
    // }
  }

  async copyProductPython() {
    let res = await this.httpApiService.copyProductPythinAdmin({
      sku: this.SKU, refCompanyId: this.refCompanyId
    });
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().isSuccess) {
        this.getAllProductData = res.json().data;
        this.items = this.getAllProductData;
        this.arrowKeyLocation = -1;
      }
    }
    console.log(this.refCompanyId, this.SKU);
  }

}
