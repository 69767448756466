import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, ToastController } from "@ionic/angular";
import { AddSchemesPage } from "../add-schemes/add-schemes.page";
// import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-schemes",
  templateUrl: "./schemes.component.html",
  styleUrls: ["./schemes.component.scss"]
})
export class SchemesComponent implements OnInit {
  public ProductName: String;
  public ProductPrice: Number;
  public ProductImage: String;
  public PromotionalProduct: Number;
  public IsActive: String = '0';

  public listOfProducts: any;
  public items: any;
  public manageSelectedDesignation = "";
  public imageLink: File = null;

  public selectedProduct = "";
  public itemsActions: any;
  public listOfActions: any;
  public listOfActionPermissions: any;
  public flagButton = "";
  public id: any;
  public priority: any;
  public updateButton: any = false;
  public refCompanyId: any;
  public listOfPromotionalProducts: any;
  constructor(
    //private authService: AuthenticationService,
    public storage: Storage,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController
  ) { }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllProducts();
    await this.getAllPromotionalProduct();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllProducts() {
    let res: any;

    res = await this.httpApiService.getAllSchemes(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfProducts = res.json();
      this.setItems();
      console.log("listOfProducts res", res, this.listOfProducts);
    }
  }

  setItems() {
    this.items = this.listOfProducts.data;
  }

  async getAllPromotionalProduct() {
    let res: any;
    res = await this.httpApiService.getAllPromotionalProducts(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      let listOfPromotionalProducts = res.json();
      this.listOfPromotionalProducts = listOfPromotionalProducts.data;
    }
  }

  checkNull(val) {
    return !!val;
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.checkNull(this.ProductName)) {
      flag = 0;
    }
    if (flag == 1) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await false;
    } else {
      return await true;
    }
  }

  async updateDesignation() {

    const formData: FormData = new FormData();
    if (!!this.imageLink) {
      formData.append("file", this.imageLink);
    }
    let objData: any = { formData: formData, name: this.ProductName, image: this.ProductImage, isActive: this.IsActive, shopSchemesId: this.id }
    if (!!this.ProductPrice) {
      objData.price = this.ProductPrice;
    }
    if (!!this.PromotionalProduct) {
      objData.refPromotionalProductID = this.PromotionalProduct;
    }

    let validate = await this.validateControls();
    if (validate) {

      let res = await this.httpApiService.updateSchema(objData, this.refCompanyId);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Schemes update successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
      this.flagButton = '';
      this.getAllProducts();
    }
  }

  async flagButtonAssign(val) {
    this.flagButton = val;
  }

  async searchDesignation(ProductName) {
    if (ProductName) {
      ProductName = ProductName.name;
    } else {
      ProductName = this.manageSelectedDesignation;
    }

    this.updateButton = false;
    this.flagButton = null;
    console.log(ProductName);
    let tmpProductName = "";
    this.items = this.listOfProducts.data;
    if (ProductName != undefined) {
      tmpProductName = ProductName;
      this.selectedProduct = ProductName;
      this.manageSelectedDesignation = ProductName;
    } else {
      this.selectedProduct = this.manageSelectedDesignation;
      tmpProductName = this.manageSelectedDesignation;
    }
    console.log("ProductName", ProductName, tmpProductName);
    let selectedProductId = this.items.filter(function (search) {
      if (search.name == tmpProductName) {
        return search.id;
      }
    });
    console.log("selectedProductId", selectedProductId);
    if (selectedProductId.length > 0) {
      let DesignationId = selectedProductId[0].id;
      let res: any;
      res = await this.httpApiService.getSchemesId(DesignationId, this.refCompanyId);
      if (res.status == 0) {
      } else {
        let DesignationData = res.json().data;
        this.ProductName = DesignationData.name;
        this.ProductPrice = DesignationData.price;
        this.ProductImage = DesignationData.image;
        this.PromotionalProduct = DesignationData.refPromotionalProductID;
        this.IsActive = DesignationData.isActive == false ? '0' : '1';
        this.id = DesignationData.id;
      }
    } else {
      this.ProductName = "";
      this.ProductPrice = 0;
      this.ProductImage = "";
      this.PromotionalProduct = 0;
      this.IsActive = '0';
    }
  }

  async notifyUsers() {
    let resComp = await this.httpApiService.getCompanyDetailById(this.refCompanyId);
    let responseData = resComp.json().data;
    let compData = responseData[0].companyRes;
    compData = compData.companyLogo;

    // let userData = await this.authService.userData();

    let resp = await this.httpApiService.getUsersByScheme(this.refCompanyId, this.id);
    let resUsers = resp.json();

    let usersToSend = [];
    if (resUsers.isSuccess && !!resUsers.data && resUsers.data.length > 0) {
      usersToSend = resUsers.data.map(u => u.refCID);

      var req = {
        refUserId: usersToSend,
        refCompanyId: this.refCompanyId,
        subject: "New Scheme For You!!!",
        title: this.ProductName,
        postBodyData: {
          // to post data with notification
          type: "Schemes",
          refData: this.id
        },
        small_icon: compData,
        large_icon: compData,
        isAddInNotification: true,
        type: "Scheme"
      };
      let res = await this.httpApiService.sendPushNotificationSF(req);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Notification sent", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    } else {
      let toast = await this.toastCtrl.create({ message: "No users found under this scheme", duration: 3000, position: "top", color: "danger" });
    }
  }

  async NewDesignation() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: AddSchemesPage,
      componentProps: { items: this.items, type: 2 }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAllProducts();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  onFileSelected(event) {

    this.imageLink = <File>event.target.files[0];
    this.flagButtonAssign('image');
    console.log("image link", this.imageLink);
  }
}
