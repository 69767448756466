import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, ToastController, AlertController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageCampaignPage } from "../manage-campaign/manage-campaign.page";

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public listOfCampaigns: any;
  public items: any;
  public manageSelectedCampaign = "";

  public selectedCampaign = "";
  public id: any;
  public name: any;
  public landingPage: any;
  public utm_source: any;
  public utm_medium: any;
  public utm_campaign: any;
  public utm_term: any;
  public utm_content: any;
  public refCompanyId: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllCampaigns();
  }
  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllCampaigns() {
    let res: any;

    res = await this.httpApiService.getAllCampaigns(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfCampaigns = res.json();
      this.setItems();
      console.log("listOfCampaigns res", res, this.listOfCampaigns);
    }
  }

  setItems() {
    this.items = this.listOfCampaigns.data;
  }

  async searchCampaign(name) {
    if (name) {
      name = name.name;
    } else {
      name = this.manageSelectedCampaign;
    }

    console.log(name);
    let tmpName = "";
    this.items = this.listOfCampaigns.data;
    if (name != undefined) {
      tmpName = name;
      this.selectedCampaign = name;
      this.manageSelectedCampaign = name;
    } else {
      this.selectedCampaign = this.manageSelectedCampaign;
      tmpName = this.manageSelectedCampaign;
    }
    console.log("name", name, tmpName);
    let selectedCampaignId = this.items.filter(function (search) {
      if (search.name == tmpName) {
        return search.id;
      }
    });
    console.log("selectedCampaignId", selectedCampaignId);
    if (selectedCampaignId.length > 0) {
      let campaignId = selectedCampaignId[0].id;
      let name = selectedCampaignId[0].name;
      console.log(name, campaignId);
      let res: any;
      console.log("ids", campaignId, this.refCompanyId);
      res = await this.httpApiService.getCampaignDetail(campaignId, this.refCompanyId);
      if (res.status == 0) {
      } else {
        console.log("res", res, res.json());
        let campaignData = res.json().data;
        console.log(campaignData);
        this.name = campaignData.name;
        this.landingPage = campaignData.landingPage;
        this.utm_campaign = campaignData.utm_campaign;
        this.utm_content = campaignData.utm_content;
        this.utm_medium = campaignData.utm_medium;
        this.utm_source = campaignData.utm_source;
        this.utm_term = campaignData.utm_term;
        this.id = campaignData.id;
        console.log("this.selectedCampaign", this.selectedCampaign);
      }
    } else {
      this.name = "";
      this.landingPage = "";
      this.utm_campaign = "";
      this.utm_content = "";
      this.utm_medium = "";
      this.utm_source = "";
      this.utm_term = "";
    }
  }

  async newCampaign() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: ManageCampaignPage,
      componentProps: { items: this.items }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAllCampaigns();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
}
