import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: "app-add-addons",
  templateUrl: "./add-addons.page.html",
  styleUrls: ["./add-addons.page.scss"]
})
export class AddAddonsPage implements OnInit {
  public ProductName: String;
  public ProductImage: String;
  public ProductDescription: String;
  public ProductPrice: Number;
  public imageLink: File = null;
  public listOfPromotionalProducts: any;

  constructor(public storage: Storage, public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {}

  async ngOnInit() {}

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async add() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    const formData: FormData = new FormData();

    formData.append("file", this.imageLink);

    let objData: any = { formData: formData, name: this.ProductName, image: this.ProductImage, description: this.ProductDescription };
    if (!!this.ProductPrice) {
      objData.price = this.ProductPrice;
    }

    let validate = await this.validateControls();
    if (validate) {
      res = await this.httpApiService.addAddOn(objData, refCompanyId);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Add On added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }

  async resetControls() {
    this.ProductName = "";
    this.ProductImage = "";
    this.ProductDescription = "";
    this.ProductPrice = 0;
  }

  checkNull(val) {
    return !!val;
  }

  async validateControls() {
    debugger;
    let flag: number;
    flag = 1;
    if (this.checkNull(this.ProductName)) {
      flag = 0;
    }
    if (flag == 1) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await false;
    } else {
      return await true;
    }
  }

  onFileSelected(event) {
    this.imageLink = <File>event.target.files[0];
    console.log("image link", this.imageLink);
  }
}
