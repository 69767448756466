import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, ToastController, AlertController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: 'app-campaign-reports',
  templateUrl: './campaign-reports.component.html',
  styleUrls: ['./campaign-reports.component.scss']
})
export class CampaignReportsComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public listOfCampaigns: any;
  public refCompanyId: any;
  public firstCampaign: any;
  public secondCampaign: any;
  public compareData: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAllCampaigns();
  }
  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAllCampaigns() {
    let res: any;

    res = await this.httpApiService.getAllCampaigns(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.listOfCampaigns = res.json().data;
      console.log("listOfCampaigns res", res, this.listOfCampaigns);
    }
  }

  async compare() {
    console.log("compare ", this.firstCampaign, this.secondCampaign);
    let res: any;

    res = await this.httpApiService.compareCampaign(this.refCompanyId, this.firstCampaign, this.secondCampaign);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.compareData = res.json().data;
      console.log("compare res", res, this.compareData);
    }
  }
}
