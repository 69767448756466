import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";
import { Http } from "@angular/http";
import * as moment from 'moment';

@Component({
  selector: 'app-manage-dispatch-order',
  templateUrl: './manage-dispatch-order.page.html',
  styleUrls: ['./manage-dispatch-order.page.scss'],
})
export class ManageDispatchOrderPage implements OnInit {

  public trackingNo: string = '';
  public courierCompanyName: string;
  public orderList: Array<[]>;
  public selected: Array<[]> = [];
  public refCompanyId: number;
  public orderDetails: any;
  public productDetails = [];
  public limit: number = 20;
  public invoiceDate = moment(new Date()).format('YYYY-MM-DD');
  public shipDate = moment(new Date()).format('YYYY-MM-DD');
  public invoiceNo: string;
  public customerName: string = '';
  public isValid = true;
  public isOpenInvoiceView: Boolean = false;
  public companyDetail: any;
  public shipId: any;
  public courierDetail: any;
  public courierList = [];
  public orderProductDetail: any;

  constructor(public http: Http,
    private authService: AuthenticationService,
    public storage: Storage, public navParams: NavParams,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController, public httpApiService: HttpApiService, public configService: ConfigService) {

  }

  async ngOnInit() {
    this.orderList = [];
    this.orderList = await this.navParams.get('selectedOrderForDispatch')
    console.log(this.orderList)
    this.getCompanyDetail()
    await this.getAllCourierService();

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  //dispatch pending order

  async dispatchPendingOrder() {
    this.orderProductDetail = this.productDetails.filter(item => item.shipQuantity > 0).map(item => {
      return { id: this.orderDetails.id, sku: item.sku, quantity: item.shipQuantity, price: item.shipPrice };
    })
    let validate = await this.validateDispatchControls()
    if (validate) {
      let jsonObj = {
        refCompanyId: this.refCompanyId,
        customer: this.customerName,
        trackingNo: this.trackingNo,
        shipVia: this.courierDetail.name,
        refOrderId: this.orderDetails.id,
        courierId: this.courierDetail.id,
        shipDate: moment(this.shipDate).format('DD-MM-YYYY'),
        invoiceDate: moment(this.invoiceDate).format('DD-MM-YYYY'),
        orderDetails: this.orderProductDetail
      }
      console.log(jsonObj)
      await this.httpApiService.showLoading();
      let res = await this.httpApiService.createDispatch(jsonObj);
      await this.httpApiService.hideLoading()
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let responseData = res.json().data;
        console.log(responseData)
        this.clearData();
        this.orderProductDetail = null;
        let toast = await this.toastCtrl.create({ message: "Order Dispatched successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
        if (!!responseData) {
          this.shipId = responseData[0].id;
          this.openInvoiceView()
        }
      }
    }
  }

  //get All courier service

  async getAllCourierService() {
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let res: any;
    res = await this.httpApiService.getAllCourierService(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      await this.httpApiService.hideLoading();
      this.courierList = res.json().data;
      if (this.courierList.length == 1) {
        this.courierDetail = this.courierList[0];
      }
    }
  }

  // temp
  async openInvoice() {
    await this.configService.print()
    // await this.modalCtrl.dismiss();
  }

  async validateDispatchControls() {
    if (!!this.orderProductDetail && !!this.invoiceDate && !!this.shipDate && !!this.orderDetails && !!this.courierDetail && !!this.trackingNo) {
      return true
    } else {
      let toast = await this.toastCtrl.create({ message: 'please insert details', duration: 3000, position: "top", color: "danger" });
      toast.present();
      return false
    }

  }

  // info order dispatch modal 

  async getCompanyDetail() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getCompanyDetailById(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let responseData = res.json().data;
      this.companyDetail = responseData[0].companyRes;
    }
  }

  compareWith(e1, e2): boolean {
    return e1 && e2 ? e1.id == e2.id : e1 == e2;
  }

  async getCompanyIdByName() {
    let res: any;
    let name = this.configService.getAppName();
    let baseUrl = this.configService.getBaseUrl();
    res = await this.http.get(baseUrl + "companies/getIdByCompanyName?name=" + name).toPromise();
    console.log("responseee", res.json());
    this.refCompanyId = res.json().data.id;
  }

  async getOrderDetailById(orderId) {
    await this.httpApiService.showLoading();
    await this.getCompanyIdByName();
    let res: any;
    res = await this.httpApiService.getAllOrderDetailById(this.refCompanyId, orderId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      await this.httpApiService.hideLoading();
      let responseData = res.json().data;
      console.log(responseData)
      if (!!responseData) {
        this.productDetails = responseData.data;
        if (this.productDetails.length > 0) {
          this.productDetails = this.productDetails.map(item => {
            item.shipQuantity = item.quantity - item.shipment_quantity
            item.shipPrice = item.unitPrice;
            item.isValid = true
            return item;
          })
          this.productDetails = this.productDetails.filter(item => item.quantity - item.shipment_quantity != 0).map(item => {
            return item;
          })
        }
        this.orderDetails = responseData.orderDetails;
      }
    }
  }

  onOrderSelect({ selected }) {
    this.orderDetails = null;
    this.customerName = selected[0].companyName;
    this.getOrderDetailById(selected[0].id)
  }

  //clear form data 

  clearData() {
    this.courierCompanyName = '';
    this.shipDate = moment(new Date()).format('YYYY-MM-DD');
    this.invoiceDate = moment(new Date()).format('YYYY-MM-DD');
    this.trackingNo = '';
  }


  //change product detail value 

  async updateValue(event, cell, rowIndex) {
    this.isValid = true;
    if (cell == 'shipQuantity') {
      this.productDetails[rowIndex][cell] = parseInt(event.target.value);
      if (this.productDetails[rowIndex][cell] > (this.productDetails[rowIndex]['quantity'] - this.productDetails[rowIndex]['shipment_quantity'])) {
        let toast = await this.toastCtrl.create({ message: 'quantity limit exceed!', duration: 3000, position: "top", color: "danger" });
        toast.present();
        this.isValid = false;
      }
    } else {
      this.productDetails[rowIndex][cell] = parseFloat(event.target.value);
      if (this.productDetails[rowIndex][cell] <= 0) {
        let toast = await this.toastCtrl.create({ message: 'Please enter valid price', duration: 3000, position: "top", color: "danger" });
        toast.present();
        this.isValid = false;
      }
      this.productDetails = [...this.productDetails];
    }
  }

  //openInvoiceView

  openInvoiceView() {
    this.isOpenInvoiceView = true;
  }

}
