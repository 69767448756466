import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-add-promotional-product',
  templateUrl: './add-promotional-product.page.html',
  styleUrls: ['./add-promotional-product.page.scss'],
})
export class AddPromotionalProductPage implements OnInit {
  public ProductName: String;
  public ProductDescription: String;
  public ProductPrice: Number;
  public ProductImage: String;
  public imageLink: File = null;

  constructor(public storage: Storage, public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {
  }

  ngOnInit() { }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async addDesignation() {

    let refCompanyId = await this.storage.get("refCompanyId");
    const formData: FormData = new FormData();

    formData.append("file", this.imageLink); 

    let objData = { name: this.ProductName, description: this.ProductDescription, price: this.ProductPrice, image: this.ProductImage, formData: formData };
    let validate = await this.validateControls();
    let res;
    if (!validate) {
      res = await this.httpApiService.addPromotionalProduct(objData, refCompanyId);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Promotional Product added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }
  async resetControls() {
    this.ProductName = "";
    this.ProductDescription = "";
    this.ProductPrice = 0;
    this.ProductImage = "";
  }

  async checkNull(val) {
    return !!val;
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.checkNull(this.ProductName) && this.checkNull(this.ProductDescription)) {
      flag = 0;
    }
    if (flag) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await true;
    } else {
      return await false;
    }
  }

  onFileSelected(event) {

    this.imageLink = <File>event.target.files[0];
    console.log("image link", this.imageLink);
  }

}
