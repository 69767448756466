import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageZipCodePage } from "../manage-zip-code/manage-zip-code.page";

@Component({
  selector: "app-zip-code",
  templateUrl: "./zip-code.component.html",
  styleUrls: ["./zip-code.component.scss"]
})
export class ZipCodeComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;

  public getAllZipCode: any;
  public items: any;
  public offset = 0;
  public limit = 10;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {}

  ngOnInit() {}

  setItems() {
    this.items = this.getAllZipCode;
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      console.log("filter", this.items, val);
      let res;
      res = await this.httpApiService.getAddressByPagination(this.offset, this.limit, val);
      console.log("res", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        this.getAllZipCode = res.json().data;
        this.items = this.getAllZipCode;
        console.log("get all zip code data", this.getAllZipCode);
      }
    } else {
      this.setItems();
    }
  }

  async deleteZipCode(item) {
    console.log("item", item);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.deleteZipCode(item.id);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      console.log("delete Zip Code response", res);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.setItems();
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
      }
    }
  }

  async manageZipCode(item, id) {
    let modalPage = await this.modalCtrl.create({
      component: ManageZipCodePage,
      componentProps: {
        id: id,
        item: item
      }
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d);
  }
}
