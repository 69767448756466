import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.page.html',
  styleUrls: ['./print-page.page.scss'],
})
export class PrintPagePage implements OnInit {
  public list: any;
  public type: any;



  constructor(public modalCtrl: ModalController, public configService: ConfigService, public navParams: NavParams) { }

  ngOnInit() {
    this.list = this.navParams.get("list");
    this.type = this.navParams.get("type");
    console.log("inside print page ", this.list, this.type);
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }
  async printDocument() {
    // window.print();
    await this.configService.print();
  }
}
