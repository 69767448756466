import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-add-shipping-method',
  templateUrl: './add-shipping-method.page.html',
  styleUrls: ['./add-shipping-method.page.scss'],
})
export class AddShippingMethodPage implements OnInit {
  public MethodName: String;

  constructor(public storage: Storage, public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public toastCtrl: ToastController) {
  }

  ngOnInit() { }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async addDesignation() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res;
    let objData = {
    };
    if (!!this.MethodName) {
      objData['name'] = this.MethodName;
    }
    let validate = await this.validateControls();
    if (!validate) {
      res = await this.httpApiService.addShippingMethod(objData, refCompanyId);
      let resp = res.json();
      console.log("resp", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: "Shipping Method added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }
  async resetControls() {
    this.MethodName = "";
  }

  async checkNull(val) {
    return !!val;
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.checkNull(this.MethodName)) {
      flag = 0;
    }
    if (flag) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await true;
    } else {
      return await false;
    }
  }
}
