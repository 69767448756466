import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { NavController, ModalController, ToastController, AlertController, NavParams, Events } from "@ionic/angular";
import { ManageCouponGeneratorPage } from "../../actions/manage-coupon-generator/manage-coupon-generator.page";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-coupon-generator",
  templateUrl: "./coupon-generator.component.html",
  styleUrls: ["./coupon-generator.component.scss"]
})
export class CouponGeneratorComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public items: any;
  public pageNumber = 0;
  public noOfRecords = 10;
  public count: any;
  public totalCouponsDataTable = false;
  public couponDetails = [];
  public mainRecord: any;
  public couponsRecord = 200;
  public allCouponData: any;
  public searchText: any;

  constructor(public configService: ConfigService, public event: Events, public router: Router, public httpApiService: HttpApiService, public modalCtrl: ModalController, public storage: Storage, public toastCtrl: ToastController) {}

  ngOnInit() {
    // await this.loadAllCouponsData();
  }

  async manageCoupon(item, id) {
    console.log("item", item, id);
    if (id == 0 || !item) {
      console.log("item", item, id);
      let modalPage = await this.modalCtrl.create({
        component: ManageCouponGeneratorPage,
        componentProps: {
          id: id,
          item: item
        }
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    } else {
      this.pageNumber = 0;
      console.log("items", item);
      this.mainRecord = item;
      this.totalCouponsDataTable = true;
      await this.couponsDetails(item);
    }
  }

  async couponsDetails(item) {
    this.couponDetails = [];
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let response = await this.httpApiService.generatedCouponsDetails(refCompanyId, item.username, item.points, item.createdDate, this.pageNumber, this.couponsRecord);
    await this.httpApiService.hideLoading();
    if (response.error) {
      let toast = await this.toastCtrl.create({
        message: response.error,
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    } else {
      console.log("couponDetails", this.items);
      this.couponDetails = response.data.content;
      this.configService.couponData = this.couponDetails;
      this.count = response.data.totalElements;
    }
  }

  async loadAllCouponsData() {
    this.items = [];
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    let response = await this.httpApiService.generatedCouponsList(refCompanyId, this.pageNumber, this.noOfRecords);
    await this.httpApiService.hideLoading();
    if (response.error) {
      let toast = await this.toastCtrl.create({
        message: response.error,
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
      return true;
    } else {
      console.log("items", this.items);
      this.items = response.data.content;
      this.allCouponData = this.items;
      this.count = response.data.totalElements;
    }
  }
  async setPage(event) {
    console.log("event", event);
    this.pageNumber = event.offset;
    await this.loadAllCouponsData();
  }

  async couponPage(event) {
    console.log("event2", event);
    this.pageNumber = event.offset;
    await this.couponsDetails(this.mainRecord);
  }

  async handleModalDismiss(d) {
    await this.loadAllCouponsData();
  }

  async print() {
    this.router.navigate(["printCoupon"]);
    console.log("couponDetails", this.couponDetails);
  }

  async back() {
    console.log("back");
    this.totalCouponsDataTable = false;
    // await this.loadAllCouponsData();
  }

  async searchByUserName() {
    console.log("in searchByUserName function");
    if (this.searchText && this.searchText != "") {
      let allData = this.allCouponData;
      return allData.filter(item => {
        return item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1;
      });
    } else {
      return this.allCouponData;
    }
  }

  async filterItems() {
    this.items = await this.searchByUserName();
    console.log("this.items", this.items);
  }
}
