import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { InfoOrderDispatchDetailPage } from '../../actions/info-order-dispatch-detail/info-order-dispatch-detail.page';

@Component({
  selector: "app-order-dispatch",
  templateUrl: "./order-dispatch.component.html",
  styleUrls: ["./order-dispatch.component.scss"]
})
export class OrderDispatchComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  selectedFile: File = null;
  public csvFileHistories: any;
  public orderUploadHistories: any;
  public offset = 0;
  public limit = 20;
  public selectedSegment = "orderDispatch";
  public orderDispatchList = [];
  public companyDetail: any;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  ngOnInit() {
    this.getAllCSVUploadHistoryByType();
    this.getCompanyDetail()
    //this.getOrderHistory()
  }

  segmentChanged(ev: any) {
    console.log('Segment changed', ev);
    this.selectedSegment = ev.target.value;
    this.csvFileHistories = []
    if (this.selectedSegment == 'orderUpload') {
      this.getOrderHistory()
    } else if (this.selectedSegment == 'orderDispatch') {
      this.getAllCSVUploadHistoryByType();
    } else if (this.selectedSegment == 'orderDispatchHistory') {
      this.getAllShipment();
    }
    // this.selectedFile = null;
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  async orderDispatch() {
    if (this.selectedFile) {
      console.log(this.selectedFile);
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      console.log("form data", formData);
      let userData = await this.authService.userData();
      let refCompanyId = await this.storage.get("refCompanyId");
      let res: any;
      let jsonObj = { id: refCompanyId, fileFormatName: "Order Dispatch", userId: userData.id, formData: formData, shouldWait: false };
      await this.httpApiService.showLoading();
      res = await this.httpApiService.fileOrderDispatch(jsonObj);
      await this.httpApiService.hideLoading();
      console.log("response", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().error) {
          let toast = await this.toastCtrl.create({
            message: res.json().error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        } else {
          if (res.json().data) {
            let toast = await this.toastCtrl.create({
              message: res.json().data,
              duration: 3000,
              position: "top",
              color: "success"
            });
            await toast.present();
            await this.getAllCSVUploadHistoryByType();
          }
        }
      }
    }
  }

  async downloadFormat() {
    // console.log("download format");
    window.open("assets/fileFormat/OrderDispatch.csv", "_blank");
  }

  async downloadCSV(path) {
    console.log("path ", path);
    path = "/download/" + path.replace("/tmp/talkbrite/", "");
    window.open(path, "_blank");
  }

  async getOrderHistory() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let fileType = "Order Upload"
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllCSVUploadHistoryByType(refCompanyId, fileType);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.csvFileHistories = res.json().data;
          console.log("csvFileHistories", this.csvFileHistories);
        }
      }
    }
  }

  async getAllCSVUploadHistoryByType() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    let fileType = "Order Dispatch";
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllCSVUploadHistoryByType(refCompanyId, fileType);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.csvFileHistories = res.json().data;
          console.log("csvFileHistories", this.csvFileHistories);
        }
      }
    }
  }

  //get order dispatch history

  async getAllShipment() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllShipmentByPagination(refCompanyId, this.offset, this.limit);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().error) {
        let toast = await this.toastCtrl.create({
          message: res.json().error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        await toast.present();
      } else {
        if (res.json().data) {
          this.orderDispatchList = res.json().data;
          console.log("csvFileHistories", this.orderDispatchList);
        }
      }
    }
  }



  async setPage(event) {
    this.limit = event.limit;
    this.offset = event.offset;
    await this.getAllShipment();
  }

  // info order dispatch modal 

  async getCompanyDetail() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getCompanyDetailById(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let responseData = res.json().data;
      this.companyDetail = responseData[0].companyRes;
    }
  }

  async openDispatchOrderModal(row, flagModal) {
    let dispatchModal = await this.modalCtrl.create({
      component: InfoOrderDispatchDetailPage,
      backdropDismiss: false,
      componentProps: {
        flagModal: flagModal,
        data: row,
        companyDetail: this.companyDetail
      },
    })
    dispatchModal.onDidDismiss().then((d: any) => this.handleModalDismiss(d))
    await dispatchModal.present();
  }

  async handleModalDismiss(d) {

  }

  //end
}


