import { Component, Input } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-display-date-time",
  templateUrl: "./display-date-time.component.html",
  styleUrls: ["./display-date-time.component.scss"]
})
export class DisplayDateTimeComponent {
  @Input() convert: string;
  @Input() time: string;
  public MMMM: string;
  public dddd: string;
  public Do: string;
  public YYYY: string;
  public hmm: string;

  constructor() {}

  ngOnInit() {
    let newMoment = moment(new Date(this.convert));
    if (!!this.time) {
      this.MMMM = newMoment.format("MMMM");
      this.dddd = newMoment.format("dddd");
      this.Do = newMoment.format("Do");
      this.YYYY = newMoment.format("h:mm A");
      this.hmm = newMoment.format("h:mm");
    } else {
      this.MMMM = newMoment.format("MMMM");
      this.dddd = newMoment.format("dddd");
      this.Do = newMoment.format("Do");
      this.YYYY = newMoment.format("YYYY");
      this.hmm = newMoment.format("h:mm A");
    }
  }
}
