import { Component, OnInit, Input } from "@angular/core";
import { IonicSelectableComponent } from "ionic-selectable";
import { NavParams, ModalController, Events } from "@ionic/angular";

@Component({
  selector: "app-ionic-select-searchable",
  templateUrl: "./ionic-select-searchable.component.html",
  styleUrls: ["./ionic-select-searchable.component.scss"]
})
export class IonicSelectSearchableComponent implements OnInit {
  @Input() list: any;
  @Input() selectedValue: any;
  @Input() valueField: any;
  @Input() textField: any;

  constructor(public navParams: NavParams, public modalCtrl: ModalController, public events: Events) {
    console.log("inside select component", this.list, this.selectedValue, this.valueField, this.textField);
  }

  ngOnInit() {
    console.log("inside select component ngOnInit", this.list, this.selectedValue, this.valueField, this.textField);
  }

  valueChange(event: { component: IonicSelectableComponent; value: any }) {
    console.log("selected values:", event.value);
    this.events.publish("selected-values", event.value);
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
