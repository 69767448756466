import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { CodeEditorComponent } from "../../codeEditor/code-editor/code-editor.component";
import { Storage } from "@ionic/storage";
import { calcPossibleSecurityContexts } from "@angular/compiler/src/template_parser/binding_parser";

@Component({
  selector: "app-manage-process-template",
  templateUrl: "./manage-process-template.page.html",
  styleUrls: ["./manage-process-template.page.scss"]
})
export class ManageProcessTemplatePage implements OnInit {
  public roleDetails: any;
  public type: any;
  public title: any;
  public processName: any;
  public webHookState: any;
  public webHookMsg: any;
  public messageProcessBy: any;
  public stateName: any;
  public status: any;
  public processBy: any;
  public callWebHook: any = false;
  public readWebHook: any;
  public processId: any;
  public data: any;
  public formTemplateId: any;
  public formType: any;
  public processBlockData: any;
  public processBlockName: any;
  public blockUpdate: any = false;
  public processIcon: any;
  constructor(private authService: AuthenticationService, public storage: Storage, public navParams: NavParams, public modalCtrl: ModalController, public toastCtrl: ToastController, public httpApiService: HttpApiService, public configService: ConfigService) {
    this.type = this.navParams.get("type");
    this.processId = this.navParams.get("processId");
    this.data = this.navParams.get("data");
    if (this.data) {
      this.processBlockName = this.data.name;
      this.processBlockData = this.data.processBy;
    }
    this.formType = this.navParams.get("formTemplate");
    // console.log("this.processId", this.processId);

    if (this.type == "1") {
      this.title = "Add new process";
    } else if (this.type == "2") {
      this.title = "Add states";
    } else {
      if (this.data.name == "") {
        this.blockUpdate = false;
        this.title = "Add process block";
      } else {
        this.blockUpdate = true;
        this.title = "Update process block";
      }
    }
    // console.log("role details", this.type);
  }

  ngOnInit() {}
  public closeModal() {
    this.modalCtrl.dismiss({ id: this.processId });
  }
  async processByState(block) {
    console.log("block");

    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: block, title: "Add Process", subtitle: "Process By" }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      if (!!data.data) {
        this.processBy = data.data;
        // this.updateBlock(block);
      }
    });
    return await modal.present();
  }

  async processByReadWebHook(block) {
    // console.log("block");

    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: block, title: "Add Process", subtitle: "ReadWebHook" }
    });

    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      if (!!data.data) {
        this.readWebHook = data.data;
        // this.updateBlock(block);
      }
    });
    return await modal.present();
  }
  async processByTemplate(block) {
    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: block, title: "Add process", subtitle: "" }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      if (!!data.data) {
        this.messageProcessBy = data.data;
        // this.updateBlock(block);
      }
    });
    return await modal.present();
  }
  // async updateBlock(block) {
  //   console.log("update block", block);
  // }
  async saveProcess() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let refCompanyId = await this.storage.get("refCompanyId");
      // console.log("refCompanyId", refCompanyId);
      let jsonObj = { templateName: this.processName, webHookState: this.webHookState, webHookMsg: this.webHookMsg, messageProcessBy: this.messageProcessBy, processIcon: this.processIcon };
      // console.log("Process Data", jsonObj);
      let res = await this.httpApiService.createProcessTemplate(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      }
      // console.log("response", res);
      let resp = res.json();
      // console.log("123124", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
        this.closeModal();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: resp.data, duration: 3000, position: "top", color: "success" });
        toast.present();
        this.closeModal();
      }
    }
  }
  async resetControls() {
    if (this.type == "1") {
      this.processName = "";
      this.webHookState = "";
      this.webHookMsg = "";
      this.messageProcessBy = "";
    } else if (this.type == "2") {
      this.stateName = "";
      this.status = "";
      this.processBy = "";
      this.callWebHook = false;
      this.readWebHook = "";
      this.formTemplateId = "";
    } else {
      this.processBlockName = "";
      this.processBlockData = "";
    }
  }
  async saveProcessState() {
    let callValue;
    if (this.callWebHook == true || this.callWebHook == "1") {
      callValue = "1";
    } else {
      callValue = "0";
    }

    let validate = await this.validateControls();
    if (!validate) {
      await this.httpApiService.showLoading();
      let refCompanyId = await this.storage.get("refCompanyId");
      if (this.formTemplateId == null || this.formTemplateId == "" || this.formTemplateId == undefined) {
        this.formTemplateId = "0";
      }
      let jsonObj = {
        stateName: this.stateName,
        status: this.status,
        processBy: this.processBy,
        callWebHook: callValue,
        readWebHook: this.readWebHook,
        refFormTemplateId: parseInt(this.formTemplateId),
        refProcessTemplateId: this.processId
      };

      let res = await this.httpApiService.createProcessTemplateStates(jsonObj, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      }
      // console.log("response", res);
      let resp = res.json();
      // console.log("123124", resp);
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetControls();
        let toast = await this.toastCtrl.create({ message: resp.data, duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
  }
  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.type == "1") {
      if (this.processName != null && this.processName != "" && this.webHookState != null && this.webHookState != "" && this.webHookMsg != null && this.webHookMsg != "" && this.messageProcessBy != null && this.messageProcessBy != "") {
        //  && this.webHookState != null && this.webHookState != "" && this.webHookMsg != null && this.webHookMsg != "" && this.messageProcessBy != null && this.messageProcessBy != ""
        flag = 0;
      }
    } else {
      if (this.stateName != null && this.stateName != "" && this.status != null && this.status != "") {
        flag = 0;
      }
    }
    console.log("flag=", flag);
    if (flag) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await true;
    } else {
      return await false;
    }
  }
  async openProcessBlock(block) {
    console.log("data", this.data);
    if (this.data.name != "") {
      this.processBlockData = this.data.processBy;
    } else {
      console.log("processBlockData", this.processBlockData);
      this.processBlockData = block;
    }
    console.log("processBlockName", this.processBlockName);

    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: this.processBlockData, title: this.title, subtitle: "" }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      if (data.data != undefined) {
        this.processBlockData = data.data;
        // this.updateBlock(block);
      }
    });
    return await modal.present();
  }
  async saveProcessBlock() {
    let updateObj = {
      name: this.processBlockName,
      processBy: this.processBlockData
    };
    let refCompanyId = await this.storage.get("refCompanyId");

    let objData = {
      name: this.processBlockName,
      processBy: this.processBlockData,
      id: refCompanyId,
      processTemplateId: this.data.ProcessTemplateId,
      processTemplateStatesId: this.data.processTemplateStateId
    };
    console.log("jsonObj", objData);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.addProcessBlock(objData, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      let toast = await this.toastCtrl.create({ message: "Added successfully", duration: 3000, position: "top", color: "success" });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: res.json().error, duration: 3000, position: "top", color: "danger" });
      await toast.present();
    }
    console.log("res", res);
    this.resetControls();
  }
  async updateProcessBlock() {
    let refCompanyId = await this.storage.get("refCompanyId");
    // console.log(this.data);
    let objData = { refCompanyId: refCompanyId, id: this.data.PBid, processBlockData: this.processBlockData, name: this.processBlockName };
    console.log("objData1", objData);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.updateProcessBlock(objData);
    await this.httpApiService.hideLoading();
    if (res.json().isSuccess) {
      let toast = await this.toastCtrl.create({ message: "Updated successfully", duration: 3000, position: "top", color: "success" });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({ message: res.json().error, duration: 3000, position: "top", color: "danger" });
      await toast.present();
    }
    console.log("res", res);
    this.resetControls();
    // this.modalCtrl.dismiss(objData);
    this.closeModal();
  }
}
