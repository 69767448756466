import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-manage-action-manager",
  templateUrl: "./manage-action-manager.page.html",
  styleUrls: ["./manage-action-manager.page.scss"]
})
export class ManageActionManagerPage implements OnInit {
  public actionName: any;
  public actionDesc: any;
  public modalFlag: any;
  public order: any;
  public image: any;

  constructor(public modalCtrl: ModalController, public navParams: NavParams, public storage: Storage, public httpApiService: HttpApiService, public authService: AuthenticationService, public toastCtrl: ToastController) {
    this.modalFlag = this.navParams.get("flag");
  }

  ngOnInit() {
    console.log("");
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }
  async addAction() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let objData = {
      actionName: this.actionName,
      description: this.actionDesc,
      order: this.order,
      actionIcon: this.image
    };
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.addAction(objData, refCompanyId);
    await this.httpApiService.hideLoading();
    let resp = res.json();

    if (resp.status == 401) {
      this.authService.logout();
    } else {
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        let resData = {
          permissionName: "Read",
          actionId: resp.data.id
        };
        let res1 = await this.httpApiService.addActionPermission(resData, refCompanyId);
        console.log(res1);
        if (res1.status == 401) {
          this.authService.logout();
        } else {
          if (res1.isSuccess == false) {
            let toast = await this.toastCtrl.create({
              message: res1.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            toast.present();
          } else {
            let toast = await this.toastCtrl.create({
              message: "Action add successfully.",
              duration: 3000,
              position: "top",
              color: "success"
            });
            toast.present();
            this.resetForm();
          }
        }
      }
    }

    console.log(objData, refCompanyId);
  }
  async resetForm() {
    this.actionName = "";
    this.actionDesc = "";
    this.order = 0;
    this.image = "";
  }
}
