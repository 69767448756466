import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, ToastController, AlertController } from "@ionic/angular";
import { AddSocialmdediaPage } from "../add-socialmdedia/add-socialmdedia.page";

@Component({
  selector: 'app-manage-socialmedia',
  templateUrl: './manage-socialmedia.component.html',
  styleUrls: ['./manage-socialmedia.component.scss']
})
export class ManageSocialmediaComponent implements OnInit {
  public refCompanyId: any;
  public feeds: any;
  public items: any;
  public selectedFeed = "";
  public postLinks: any = [];

  public name: String;
  public profileName: String;
  public type: String;
  public isActive: boolean;
  public postSearch: any;

  public flagButton = "";
  public id: any;
  public priority: any;
  public updateButton: any = false;
  public allTypes = [];
  //public postLinks : any
  public selectedSegment = 'Details';
  public linkResp: any;
  public socialMediaData: any;

  constructor(
    //private authService: AuthenticationService,
    public storage: Storage,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController,
    private alertCtrl: AlertController
  ) { }

  async ngOnInit() {
    await this.getRefCompanyId();
    await this.getAll();
    await this.getSocialMediaTypes();
  }

  async getSocialMediaTypes() {
    let res: any;

    res = await this.httpApiService.getSocialMediaTypes(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.allTypes = res.json().data;

    }
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
  }

  async getAll() {
    let res: any;

    res = await this.httpApiService.getAllFeeds(this.refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.feeds = res.json();
      this.setItems();
      console.log("listOfProducts res", res, this.feeds);
    }
  }

  async search(feedName) {

    this.updateButton = false;
    this.flagButton = null;
    console.log(feedName);
    let tmpfeedName = "";
    this.items = this.feeds.data;

    if (feedName != undefined) {
      tmpfeedName = feedName;
      this.selectedFeed = feedName;
    }

    console.log("feedName", feedName, tmpfeedName);
    let selectedFeedId = this.items.filter(function (search) {
      if (search.name == tmpfeedName) {
        return search.id;
      }
    });
    console.log("selectedFeedId", selectedFeedId);
    if (selectedFeedId.length > 0) {
      let selectedId = selectedFeedId[0].id;
      let res: any;
      res = await this.httpApiService.getFeedById(selectedId);
      if (res.status == 0) {
      } else {
        let data = res.json();
        this.name = data.name;
        this.profileName = data.profileName;
        this.type = data.type;
        //this.isActive = data.isActive;
        if (data.isActive) {
          this.isActive = true
        } else {
          this.isActive = false
        }

        this.id = data.id;
        this.socialMediaData = data;
        this.linkResp = await this.httpApiService.findByScocialMediaId(this.id, this.refCompanyId);
        if (res.status == 0) {
        } else {
          this.postLinks = this.linkResp.json().data
        }

      }
    } else {
      this.name = "";
      this.profileName = "";
      this.type = "";
      this.isActive = false;
    }
  }

  changed(evt) {
    if (evt.detail.value !== this.isActive) {
      this.update()
    }
  }

  setItems() {
    this.items = this.feeds.data;
  }

  async flagButtonAssign(val) {
    this.flagButton = val;
  }

  checkNull(val) {
    return !!val;
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.checkNull(this.name)) {
      flag = 0;
    }
    if (flag == 1) {
      let toast = await this.toastCtrl.create({ message: "Please enter/select all fields", duration: 3000, position: "top", color: "danger" });
      toast.present();
      return await false;
    } else {
      return await true;
    }
  }

  async update() {
    let objData: any = {
      name: this.name,
      profileName: this.profileName,
      socialMediaId: this.id,
      type: this.type,
      isActive: this.isActive
    };


    let validate = await this.validateControls();
    if (validate) {
      let res = await this.httpApiService.updateSocialMedia(objData, this.refCompanyId);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Social Media Data update successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
      this.flagButton = "";
      this.getAll();
    }
  }

  async deleteScocialMedia() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteScocialMedia(this.id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {

    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.reset();
        this.selectedFeed = "";
        let toast = await this.toastCtrl.create({ message: "Social Media deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async reset() {
    this.flagButton = "";
    this.name = "";
    this.selectedFeed = "";
    this.profileName = "";
    this.postLinks = [];
    this.getAll();
  }

  async delete() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel:");
          }
        },
        {
          text: "Okay",
          handler: () => {
            console.log("Confirm Okay", this.id);
            this.deleteScocialMedia();
          }
        }
      ]
    });

    await alert.present();
  }

  async editPost(row) {
    let modalPage = await this.modalCtrl.create({ component: AddSocialmdediaPage, componentProps: { data: this.socialMediaData, postData: row, mode: 'Edit Link' } });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async deletePost(row) {
    console.log("row", row);
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.removePostLink(row.id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {

        let toast = await this.toastCtrl.create({ message: "Link deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
        this.search(this.selectedFeed);

      }
    }
  }

  async add() {
    console.log("Item", this.items);
    const modal = await this.modalCtrl.create({
      component: AddSocialmdediaPage,
      componentProps: { mode: 'Add Feed' }
    });
    modal.onDidDismiss().then(data => {
      // console.log("data", data.data);
      //success
      this.getAll();
    });
    // modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async setFilter() {
    this.postLinks = this.linkResp
  }

  async filter(ev: any) {
    this.setFilter();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.postLinks = await this.postLinks.filter(function (item) {
        return item.link.toLowerCase().includes(val.toLowerCase());
      });
      console.log(this.postLinks);
      //this.arrowKeyLocationVariant = -1;
    } else {
      this.postLinks = this.linkResp
      //this.arrowKeyLocationVariant = -1;
    }
  }

  async openModal(item, flagModal) {
    let modalPage = await this.modalCtrl.create({ component: AddSocialmdediaPage, componentProps: { data: this.socialMediaData, mode: 'Add Link' } });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    console.log("handleModalDismiss ", d);
    this.search(this.selectedFeed);

  }

}
