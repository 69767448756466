import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageProductCollectionsPage } from "../manage-product-collections/manage-product-collections.page";
import { CodeEditorComponent } from "../../codeEditor/code-editor/code-editor.component";

@Component({
  selector: "app-product-collections",
  templateUrl: "./product-collections.component.html",
  styleUrls: ["./product-collections.component.scss"]
})
export class ProductCollectionsComponent implements OnInit {
  public getAllPCTags: any;
  public items: any;
  public selectedProductCollectionTag: any = "";
  public manageSelectedPCTag: any = "";
  public id: any;
  public name: any;
  public description: any;
  public listOfValue: any;
  public tagData: any;
  public arrowKeyLocation = -1;
  public flgBtn: any;
  public assignProductCollection: any;
  public itemsValue: any;
  public arrowKeyLocationValue = -1;
  public config = "";
  public myWidget: any;
  public myWidget1: any;
  public enableCloudinaryImage = false;
  public presetName = "";
  public shortImagePublicIds = [];
  public coverImagePublicIds = [];
  public shortImage = "";
  public coverImage = "";
  public collectionOrderBy: any;
  public collectionOrderOptions = [];
  public isPopular = false;
  // public manageSelectedPV: any;
  constructor(public toastCtrl: ToastController, public modalCtrl: ModalController, public storage: Storage, public httpApiService: HttpApiService, private authService: AuthenticationService) { }

  async ngOnInit() {
    await this.getPCTags();
    await this.getConfig();
    await this.getAllCollectionOrderOptions();
    this.myWidget = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: "dlswbovqx",
        uploadPreset: this.presetName
      },
      (error, result) => {
        if (error) {
          console.log(error);
        }
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          if (result.info) {
            let id = "/" + result.info["public_id"];
            //this.selectedImages.push()
            this.shortImagePublicIds.push(id);
          }
        }

        if (!error && result && result.event === "close") {
        }
      }
    );

    this.myWidget1 = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: "dlswbovqx",
        uploadPreset: this.presetName
      },
      (error, result) => {
        if (error) {
          console.log(error);
        }
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          if (result.info) {
            let id = "/" + result.info["public_id"];
            //this.selectedImages.push()
            this.coverImagePublicIds.push(id);
          }
        }

        if (!error && result && result.event === "close") {
        }
      }
    );

  }

  async getConfig() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getCompanyDetailById(refCompanyId);
    this.httpApiService.companyObj = res.json().data[0].companyRes;
    this.setConfig();
  }

  async setConfig() {
    if (this.httpApiService.companyObj && this.httpApiService.companyObj.config) {
      let companyJson = this.httpApiService.companyObj.config;
      // console.log(companyJson);
      if (!!companyJson) {
        companyJson = JSON.parse(companyJson);
        if (!!companyJson) {
          if (!!companyJson.enableCloudinaryImage) {
            this.enableCloudinaryImage = companyJson.enableCloudinaryImage;
          }

          if (!!companyJson.presetName) {
            this.presetName = companyJson.presetName;
          }
        }
      }
    } else {
      console.log("---");
    }
  }

  async getPCTags() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllProductCollection(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllPCTags = res.json().data;
      this.items = this.getAllPCTags;
      console.log(this.items);
    }
  }

  setItems() {
    this.items = this.getAllPCTags;
  }

  async manageProductCollectionTags(flagId) {
    let modalPage = await this.modalCtrl.create({ component: ManageProductCollectionsPage, componentProps: { flag: flagId, id: parseInt(this.id), data: this.listOfValue } });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    await this.getPCTags();
    await this.searchPCTagDetail(this.selectedProductCollectionTag);
  }

  getPCTagDetail(name) {
    this.searchPCTagDetail(name);
  }

  async searchPCTagDetail(name) {
    let tmpName = "";
    this.items = this.getAllPCTags;
    if (name != undefined) {
      tmpName = name;

      this.manageSelectedPCTag = name;
      this.selectedProductCollectionTag = name;
    } else {
      this.selectedProductCollectionTag = this.manageSelectedPCTag;
      tmpName = this.manageSelectedPCTag;
    }

    let selectedTagId = this.items.filter(function (search) {
      if (search.name == tmpName) {
        return search.id;
      }
    });
    if (selectedTagId.length > 0) {
      this.id = selectedTagId[0].id;
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getAllCollectionDetailById(refCompanyId, this.id);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json().data;
        this.name = data[0].name;
        this.description = data[0].description;
        this.config = data[0].config;
        this.assignProductCollection = data[0].listOfProducts;
        this.shortImage = data[0].shortImage;
        this.coverImage = data[0].coverImage;
        this.collectionOrderBy = data[0].collectionOrderBy;
        this.isPopular = data[0].isPopular;
        console.log("collection detail ", this.name, this.description, this.shortImage, this.coverImage);
        this.setValues();
        // if (data.listOfParameter && data.listOfParameter.length > 0) {
        //   this.listOfValue = data.listOfParameter;
        //   this.tagData = this.listOfValue;
        // } else {
        //   this.listOfValue = [];
        // }

        let index = this.items.findIndex(x => x.name == selectedTagId[0].name);
        this.arrowKeyLocation = index;
      }
    } else {
      this.name = "";
      this.selectedProductCollectionTag = "";
    }
  }
  async validateControls() {
    if (this.name != "" && this.description != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({ message: "Please insert the detail", duration: 2000, position: "top", color: "danger" });
      await toast.present();
      return true;
    }
  }

  async showButton(name) {
    if (name == "description") {
      this.flgBtn = "description";
    } else if (name == "name") {
      this.flgBtn = "name";
    } else if (name == "config") {
      this.flgBtn = "config";
    } else if (name == "shortImage") {
      this.flgBtn = "shortImage";
    } else if (name == "coverImage") {
      this.flgBtn = "coverImage";
    } else if (name == "collectionOrderBy") {
      this.flgBtn = "collectionOrderBy";
    } else if (name == "isPopular") {
      this.flgBtn = "isPopular";
    }
  }
  async updatePCTag() {
    let validate = await this.validateControls();
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = { id: refCompanyId, PcID: parseInt(this.id), name: this.name, description: this.description, config: this.config, shortImage: this.shortImagePublicIds.length > 0 ? this.shortImagePublicIds[0] : null, coverImage: this.coverImagePublicIds.length > 0 ? this.coverImagePublicIds[0] : null, collectionOrderBy: this.collectionOrderBy, isPopular: this.isPopular };
      console.log(jsonObj);

      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateProductCollection(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: userData.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          this.flgBtn = "";
          this.shortImagePublicIds = [];
          this.coverImagePublicIds = [];
          await this.getPCTags();
          await this.searchPCTagDetail(this.name);
          let toast = await this.toastCtrl.create({ message: "Product collection updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }
  setValues() {
    this.listOfValue = this.assignProductCollection;
    console.log("test");
  }

  async filterValues(ev: any) {
    this.setValues();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfValue = await this.listOfValue.filter(function (item) {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocationValue = -1;
    } else {
      this.listOfValue = this.assignProductCollection;
      this.arrowKeyLocationValue = -1;
    }
  }
  // keyDownValues(event: KeyboardEvent) {
  //   switch (event.keyCode) {
  //     case 38: // this is the ascii of arrow up
  //       if (this.itemsValue && this.itemsValue.length > 0) {
  //         if (this.arrowKeyLocationValue != 0) {
  //           this.arrowKeyLocationValue--;
  //           // this.manageSelectedPV = this.items[this.arrowKeyLocation].name;
  //           break;
  //         } else {
  //           break;
  //         }
  //       }
  //     case 40: // this is the ascii of arrow down
  //       if (this.itemsValue && this.itemsValue.length > 0) {
  //         if (this.listOfValue.length != this.arrowKeyLocation + 1) {
  //           this.arrowKeyLocationValue++;
  //         }
  //         if (this.itemsValue.length > this.arrowKeyLocation) {
  //           // this.manageSelectedPV = this.items[this.arrowKeyLocation].name;
  //           break;
  //         } else {
  //           break;
  //         }
  //       }
  //       if (this.listOfValue.length > this.arrowKeyLocationValue) {
  //         if (this.listOfValue.length != this.arrowKeyLocationValue + 1) {
  //           this.arrowKeyLocationValue++;
  //         }
  //         break;
  //       } else {
  //         break;
  //       }
  //   }
  //   console.log(this.arrowKeyLocationValue);
  // }
  async deletePC(row) {
    let refCompanyId = await this.storage.get("refCompanyId");
    console.log(row, refCompanyId);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.deletePCTags(row.PcTID, refCompanyId);
    await this.httpApiService.hideLoading();
    let resp = res.json();
    if (resp.status == 401) {
      this.authService.logout();
    } else {
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Delete successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
    await this.searchPCTagDetail(this.selectedProductCollectionTag);
  }

  async updateConfig() {
    console.log("add config");

    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: this.config, title: "Update Collection", subtitle: "Config" }
    });
    modal.onDidDismiss().then(data => {
      console.log("data", data.data, typeof (data.data));
      if (!!data.data || this.config != data.data) {
        this.config = data.data;
        // this.updateBlock(block);
        this.showButton('config')
      }
    });
    return await modal.present();
  }

  array_move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }

  async reorder(index, position) {
    console.log(index, position, this.listOfValue);
    // let images = this.listOfBanners;
    // images = images.map(res => res.id);
    // index = images.indexOf(index);
    let j = index;
    if (position == "left") {
      --j;
    }
    if (position == "right") {
      ++j;
    }
    let array = this.listOfValue;
    this.listOfValue = [];
    setTimeout(() => {
      this.listOfValue = this.array_move(array, index, j);
      this.httpApiService.collectionProductReorder(this.listOfValue);
      this.toastCtrl.create({ message: "Product Reorder successfully", duration: 2000, position: "top", color: "success" });
    });
    // this.productData.data[0].listOfProductImages = this.array_move(this.productData.data[0].listOfProductImages, index, j);
  }

  async move(index, position) {
    let j = 0;
    if (position == "left") {
      j = 0;
    }
    if (position == "right") {
      j = this.listOfValue.length - 1;
    }
    let array = this.listOfValue;
    this.listOfValue = [];
    setTimeout(() => {
      this.listOfValue = this.array_move(array, index, j);
      this.httpApiService.collectionProductReorder(this.listOfValue);
      this.toastCtrl.create({ message: "Product Reorder successfully", duration: 2000, position: "top", color: "success" });
    });
  }

  async shortImageUpload() {
    this.myWidget.open();
    this.showButton('shortImage');
  }

  async coverImageUpload() {
    this.myWidget1.open();
    this.showButton('coverImage');
  }

  async openImage(image) {
    window.open('https://res.cloudinary.com/dlswbovqx/image/upload/' + image);
  }
  async getAllCollectionOrderOptions() {
    let res = await this.httpApiService.getAllCollectionOrderOptions();
    // console.log("get all collection order by options ", res);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.collectionOrderOptions = res.json().data;
    }
  }
}
