import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageDepartmentPage } from "../manage-department/manage-department.page";

@Component({
  selector: "app-department",
  templateUrl: "./department.component.html",
  styleUrls: ["./department.component.scss"]
})
export class DepartmentComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public getAllDepartment: any;
  public items: any;
  public offset = 0;
  public limit = 10;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {}

  ngOnInit() {
    this.getDepartment();
  }

  setItems() {
    this.items = this.getAllDepartment;
  }

  async getDepartment() {
    await this.httpApiService.showLoading();
    let res: any;
    res = await this.httpApiService.getAllDepartment(this.cid);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllDepartment = res.json().data;
      this.items = this.getAllDepartment;
      console.log("get Department", this.getAllDepartment);
    }
    await this.httpApiService.hideLoading();
  }

  async filterItems(ev: any) {
    if (this.getAllDepartment && this.getAllDepartment.length > 0) {
      this.setItems();
      let val = ev.target.value;
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function(item) {
          console.log("item", item, typeof item);
          return item.name.toLowerCase().includes(val.toLowerCase());
        });
      } else {
        this.items = this.getAllDepartment;
      }
    }
  }

  async deleteDepartment(item) {
    await this.httpApiService.showLoading();
    console.log("item", item);
    let res = await this.httpApiService.deleteDepartment(item.id);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      console.log("delete Department response", res);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.getDepartment();
        let toast = await this.toastCtrl.create({
          message: resp.data,
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
      }
    }
  }

  async manageDepartment(item, id) {
    let modalPage = await this.modalCtrl.create({
      component: ManageDepartmentPage,
      componentProps: {
        id: id,
        item: item
      }
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d);
    this.getDepartment();
  }
}
