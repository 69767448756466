import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-merge-customer",
  templateUrl: "./merge-customer.component.html",
  styleUrls: ["./merge-customer.component.scss"]
})
export class MergeCustomerComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  public getAllFromCustomers: any;
  public getAllToCustomers: any;
  public selectedFromCustomer: any;
  public selectedToCustomer: any;
  public manageSelectedFromCustomer: any;
  public manageSelectedToCustomer: any;
  public items: any;
  public itemsToCustomer: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationToCustomer = -1;
  public offset = 0;
  public limit = 10;

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) {}

  ngOnInit() {}

  setItems() {
    this.items = this.getAllFromCustomers;
  }

  setItemsToCustomer() {
    this.itemsToCustomer = this.getAllToCustomers;
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      let refCompanyId = await this.storage.get("refCompanyId");
      let res = await this.httpApiService.fetchCustomerByPagination(this.offset, this.limit, val, refCompanyId);
      console.log("res", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        this.getAllFromCustomers = res.json().data;
        this.setItems();
        console.log("get all from customer data", this.getAllFromCustomers);
        this.arrowKeyLocation = -1;
      }
    } else {
      this.arrowKeyLocation = -1;
      this.setItems();
    }
  }

  async filterToCustomer(ev: any) {
    this.setItemsToCustomer();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      let refCompanyId = await this.storage.get("refCompanyId");
      let res = await this.httpApiService.fetchCustomerByPagination(this.offset, this.limit, val, refCompanyId);
      console.log("res", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        this.getAllToCustomers = res.json().data;
        this.setItemsToCustomer();
        console.log("get all to customer data", this.getAllToCustomers);
        this.arrowKeyLocationToCustomer = -1;
      }
    } else {
      this.arrowKeyLocationToCustomer = -1;
      // this.items = [];
    }
  }

  async selectFromCustomer(customer) {
    console.log("from customer", this.manageSelectedFromCustomer, customer);
    if (customer != undefined) {
      this.manageSelectedFromCustomer = customer;
    }
    this.selectedFromCustomer = this.manageSelectedFromCustomer;
  }

  async selectToCustomer(customer) {
    console.log("to customer", this.manageSelectedToCustomer, customer);
    if (customer != undefined) {
      this.manageSelectedToCustomer = customer;
    }
    this.selectedToCustomer = this.manageSelectedToCustomer;
  }

  async mergeCustomers() {
    console.log("merge", this.manageSelectedFromCustomer, this.selectedFromCustomer, this.manageSelectedToCustomer, this.selectedToCustomer);
    let tmpFromCustomer, tmpToCustomer;
    tmpFromCustomer = this.manageSelectedFromCustomer;
    let selectedFromCustomerId = this.getAllFromCustomers.filter(function(search) {
      if (search.name == tmpFromCustomer) {
        return search.id;
      }
    });
    tmpToCustomer = this.manageSelectedToCustomer;
    let selectedToCustomerId = this.getAllToCustomers.filter(function(search) {
      if (search.name == tmpToCustomer) {
        return search.id;
      }
    });
    console.log("selectedFromCustomerId", selectedFromCustomerId, "selectedToCustomerId", selectedToCustomerId, "company id ", this.cid);
    if (selectedFromCustomerId.length > 0 && selectedToCustomerId.length > 0) {
      if (selectedFromCustomerId[0].id == selectedToCustomerId[0].id) {
        let toast = await this.toastCtrl.create({ message: "From and To Customers can not be same", duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let res: any;
        await this.httpApiService.showLoading();
        res = await this.httpApiService.mergeCustomer(this.cid, selectedFromCustomerId[0].id, selectedToCustomerId[0].id);
        await this.httpApiService.hideLoading();
        if (res.status == 401) {
          this.authService.logout();
        } else {
          if (res.json().error) {
            let toast = await this.toastCtrl.create({
              message: res.json().error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          } else {
            console.log("res", res);
            if (res.json().isSuccess) {
              let toast = await this.toastCtrl.create({
                message: res.json().data,
                duration: 3000,
                position: "top",
                color: "success"
              });
              await toast.present();
              this.reset();
            }
          }
        }
      }
    }
  }

  async reset() {
    this.selectedFromCustomer = "";
    this.selectedToCustomer = "";
    this.manageSelectedFromCustomer = "";
    this.manageSelectedToCustomer = "";
  }
}
