import { Component, Input } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, NavParams } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { PrintPagePage } from "../print-page/print-page.page";
import * as moment from "moment";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"]
})
export class OrdersComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  @Input() disableSearch: any;
  @Input() order: any;
  @Input() pushedPendingOrderID: any;
  public selectedSegment = "Details";
  public previousOrders: any;

  public getAllOrderData: any;
  public manageSelectedOrder: "";
  public selectedOrder = "";
  public items: any;
  public id: any;
  public arrowKeyLocation = -1;
  public listOfActions: any = [];
  public listOfProduct: any = [];
  public getListOfProduct: any = [];
  public companyName: any;
  public category: any;
  public outstandingAmount: any;
  public orderStatus: any;
  public clientRemark = "";

  public addedOn: any;
  public orderNo: any;
  public customerName: any;
  public customerAddress: any;
  public deliveryName = "";
  public mobileNo: any;
  public productData: any;
  public orderTotal: any;
  public refCompanyId: any;
  public offset = 0;
  public limit = 20;
  public userDataID = 0;
  public listOfVariantParameters: any;
  public orderTotalTax = 0;
  public serialNumber = "";
  public tempSelected: any;
  public paymentMode: any;
  public paymentStatus: any;
  public paymentID: any;
  public companyDetail: any;
  public pauseOrders: Boolean = false;
  public pauseOrderMessage = "";

  constructor(private authService: AuthenticationService, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    // if (!!this.pushedOffset) this.offset = this.pushedOffset;
    // if (!!this.pushedLimit) this.limit = this.pushedLimit;
    this.selectedOrder = "";
    await this.getRefCompanyId();
    await this.getCompanyDetail();
    await this.getAllOrder();
  }

  async getRefCompanyId() {
    this.refCompanyId = await this.storage.get("refCompanyId");
    let userDataID = await this.storage.get("userData");
    this.userDataID = userDataID.id;
  }
  setItems() {
    this.items = this.getAllOrderData;
  }

  async getOrderDetail(cName) {
    await this.searchOrder(cName);
  }

  async openOrder(row) {
    this.selectedSegment = "Details";
    this.tempSelected = row;
    //this.orderNo = row.name + "-" + row.id;
    await this.searchOrder(row.fullName + "-" + row.id);
  }

  async searchOrder(cName) {
    // console.log(cName);
    // this.arrowKeyLocation = 0;
    let tmpOrderName = "";
    this.setItems();
    if (cName != undefined) {
      tmpOrderName = cName;
      this.manageSelectedOrder = cName;
    } else {
      tmpOrderName = this.manageSelectedOrder;
    }
    this.selectedOrder = this.manageSelectedOrder;
    let selectedOrderId = this.items.filter(function (search) {
      if (search.OrderNo == tmpOrderName) {
        return search.id;
      }
    });
    if (selectedOrderId.length == 0) {
      selectedOrderId.push(this.tempSelected);
    }
    console.log(selectedOrderId, tmpOrderName);
    if (selectedOrderId.length > 0) {
      this.id = selectedOrderId[0].id;
      // this.orderStatus = selectedOrderId[0].orderStatus;
      // this.serialNumber = selectedOrderId[0].serialNumber;
      // this.clientRemark = selectedOrderId[0].clientRemark;
      // this.addedOn = selectedOrderId[0].addedOn;
      // this.orderNo = selectedOrderId[0].id;
      this.mobileNo = selectedOrderId[0].mobileNo;
      this.customerName = selectedOrderId[0].fullName;
      // this.paymentMode = selectedOrderId[0].paymentMode;
      // let address =
      //   selectedOrderId[0].DeliveryAddress.house +
      //   ", " +
      //   selectedOrderId[0].DeliveryAddress.area +
      //   ", " +
      //   selectedOrderId[0].DeliveryAddress.city +
      //   ", " +
      //   selectedOrderId[0].DeliveryAddress.state +
      //   ", " +
      //   selectedOrderId[0].DeliveryAddress.country +
      //   ", " +
      //   selectedOrderId[0].DeliveryAddress.pinCode;
      // this.customerAddress = address;
      // this.deliveryName = selectedOrderId[0].DeliveryAddress.fullName;
      this.companyName = selectedOrderId[0].customerDetails.companyName;
      this.category = selectedOrderId[0].customerDetails.category;
      this.outstandingAmount = selectedOrderId[0].customerDetails.outstandingAmount;
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getAllOrderDetailById(this.refCompanyId, this.id);
      let prvOrders: any;
      prvOrders = await this.httpApiService.getPreviousOrders(this.refCompanyId, selectedOrderId[0].refCID);
      this.previousOrders = prvOrders.json().data;
      console.log(this.previousOrders);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let responseData = res.json().data;
        this.listOfVariantParameters = responseData.listOfVariantParameters;
        this.orderTotal = 0;
        if (responseData.data.length > 0) {
          this.getListOfProduct = responseData.data;
          this.listOfProduct = responseData.data;
          this.paymentID = responseData.data[0].paymentID;

          this.paymentStatus = responseData.data[0].paymentStatus;
          this.orderStatus = responseData.orderDetails.orderStatus;
          this.serialNumber = responseData.orderDetails.serialNumber;
          this.clientRemark = responseData.orderDetails.clientRemark;
          this.addedOn = responseData.orderDetails.addedOn;
          this.orderNo = responseData.orderDetails.id;
          this.paymentMode = responseData.orderDetails.paymentMode;
          let address =
            responseData.addressDetails.house +
            ", " +
            responseData.addressDetails.area +
            ", " +
            responseData.addressDetails.city +
            ", " +
            responseData.addressDetails.state +
            ", " +
            responseData.addressDetails.country +
            ", " +
            responseData.addressDetails.pinCode;
          this.customerAddress = address;
          this.deliveryName = responseData.addressDetails.fullName;

          let total = 0;
          this.listOfProduct.forEach(product => {
            if (product.priceWithShipCost != 0) {
              total += product.priceWithShipCost;
              console.log("Discount ", total);
            } else if (product.taxAblePrice != 0) {
              // total +=(order.total+(order.total * order.taxes) / 100) * order.quantity;
              total += product.taxAblePrice;
              console.log("Discount", total);
            } else {
              total += product.total;
              console.log("Amount", total);
            }
            if (product.taxPrice != 0) {
              console.log("data.taxPrice ", product.taxPrice);
              this.orderTotalTax += product.taxPrice;
            }
            // if (product.totalDiscountPrice == 0) {
            //   this.orderTotal = this.orderTotal + product.total;
            // } else {
            //   this.orderTotal = this.orderTotal + product.totalDiscountPrice;
            // }
            product.addOns = "";
            if (!!product.selectedAddOns && product.selectedAddOns.length > 0) {
              product.selectedAddOns.forEach(addOn => {
                product.addOns += addOn.name + ", ";
              });
              product.addOns = product.addOns.slice(0, -2);
            }
          });
          this.orderTotal = total;
        } else {
          this.listOfProduct = [];
        }
        console.log("this.listOfProduct", this.listOfProduct);
        let index = this.items.findIndex(x => x.OrderNo == selectedOrderId[0].OrderNo);
        this.arrowKeyLocation = index;
        if (this.productData && this.productData.length > 0) {
          this.listOfActions = this.productData;
        } else {
          this.listOfActions = [];
        }
        this.listOfProduct.map(row => {
          row.PendingCancel = String(Number(!!row.quantity ? row.quantity : 0) - Number(!!row.shipment_quantity ? row.shipment_quantity : 0) - Number(!!row.cancelledQuantity ? row.cancelledQuantity : 0));
          return row;
        });
        console.log(this.listOfProduct);
      }
    } else {
    }
  }
  async loadData(event) {
    this.offset += this.limit;
    await this.fetchUsersByPushRecord();
    event.target.complete();
    console.log("Done");
  }

  async fetchUsersByPushRecord() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllOrders(this.refCompanyId, this.offset, this.limit, "");
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllOrderData = res.json().data;
      this.getAllOrderData.forEach(res => {
        this.items.push(res);
        return res;
      });
      this.getAllOrderData = this.items;
      if (this.order != null && this.order != undefined) {
        this.getOrderDetail(this.order);
      }
      console.log("item", this.items);
    }
  }

  async getAllOrder() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllOrders(this.refCompanyId, this.offset, this.limit, this.pushedPendingOrderID);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllOrderData = res.json().data;
      this.items = this.getAllOrderData;
      if (this.order != null && this.order != undefined) {
        this.getOrderDetail(this.order);
      }
      // console.log("item", this.items);
    }
  }
  async clear() {
    this.pushedPendingOrderID = "";
    this.offset = 0;
    await this.getAllOrder();
  }
  async filterItems(ev: any) {
    this.selectedOrder = "";
    this.offset = 0;
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.OrderNo.toLowerCase().includes(val.toLowerCase());
      });
      if (this.items.length > 0) {
        this.arrowKeyLocation = -1;
      } else {
        this.pushedPendingOrderID = val;
        await this.getAllOrder();
      }
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.getAllOrderData;
    }
  }

  async filterProduct(ev: any) {
    this.setProductList();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfProduct = await this.listOfProduct.filter(function (item) {
        return item.title.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      this.listOfProduct = this.getListOfProduct;
    }
    this.listOfProduct.map(row => {
      row.PendingCancel = String(Number(!!row.quantity ? row.quantity : 0) - Number(!!row.shipment_quantity ? row.shipment_quantity : 0) - Number(!!row.cancelledQuantity ? row.cancelledQuantity : 0));
      return row;
    });
    console.log(this.listOfProduct);
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0 && this.arrowKeyLocation != -1) {
            this.arrowKeyLocation--;
            this.manageSelectedOrder = this.items[this.arrowKeyLocation].OrderNo;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedOrder = this.items[this.arrowKeyLocation].OrderNo;
            break;
          } else {
            break;
          }
        }
    }
  }
  setProductList() {
    this.items = this.getAllOrderData;
  }

  async cancelOrder(object) {
    let res: any;
    await this.httpApiService.showLoading();
    console.log(object);
    res = await this.httpApiService.cancelOrder(object);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getOrderDetail(this.selectedOrder);
    }
  }

  async cancelAllProduct() {
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.cancelAllOrder({
      orderId: this.orderNo,
      refUserId: this.userDataID,
      id: this.refCompanyId
    });
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getOrderDetail(this.selectedOrder);
    }
  }

  async getPendingQty(row) {
    return Number(!!row.quantity ? row.quantity : 0) - Number(!!row.shipment_quantity ? row.shipment_quantity : 0) - Number(!!row.cancelledQuantity ? row.cancelledQuantity : 0);
  }

  async deleteProduct(row) {
    console.log(row);
    // let pendingQty: String = String(Number(!!row.quantity ? row.quantity : 0) - Number(!!row.shipment_quantity ? row.shipment_quantity : 0) - Number(!!row.cancelledQuantity ? row.cancelledQuantity : 0));
    let pendingQty = await this.getPendingQty(row);
    const alert = await this.alertCtrl.create({
      header: "Please enter how much quantity to cancel",
      message: `Total Pending: <strong>${pendingQty}</strong>.`,
      inputs: [
        {
          name: "qty",
          type: "number",
          placeholder: "Enter Qty"
        }
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel");
          }
        },
        {
          text: "Okay",
          handler: answer => {
            if (Number(answer.qty) > 0 && Number(answer.qty) <= Number(pendingQty)) {
              this.cancelOrder({
                orderProductId: row.id,
                cancelledQuantity: Number(answer.qty),
                refUserId: this.userDataID,
                id: this.refCompanyId
              });
            }
            console.log("Confirm Okay");
          }
        }
      ]
    });

    let data = await alert.present();
    console.log(data);
  }
  async printAction(type) {
    // this.changeViewType('Print');

    let selectedOrderId = this.id;
    let selectedOrder = this.items.filter(function (search) {
      if (search.id == selectedOrderId) {
        return search;
      }
    });
    if (selectedOrder.length > 0) {
      selectedOrder[0].details = this.listOfProduct;
      selectedOrder[0].details.forEach(p => {
        p.order_quantity = p.quantity;
      });
      selectedOrder[0].companyName = selectedOrder[0].customerDetails.companyName;
      selectedOrder[0].city = selectedOrder[0].customerDetails.city;
      selectedOrder[0].pinCode = selectedOrder[0].customerDetails.zipCode;
      selectedOrder[0].state = selectedOrder[0].customerDetails.state;
      selectedOrder[0].outstandingAmount = selectedOrder[0].customerDetails.outstandingAmount;
      selectedOrder[0].name = selectedOrder[0].fullName;
      let modalPage = await this.modalCtrl.create({
        component: PrintPagePage,
        componentProps: {
          type: type,
          list: selectedOrder
        }
      });
      // modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    }
  }

  downloadExcel() {
    let metals = this.listOfVariantParameters.filter(p => p.name.toLowerCase() == "metal");
    let sizes = this.listOfVariantParameters.filter(p => p.name.toLowerCase() == "size");
    let list = [];
    this.listOfProduct.forEach(p => {
      let metal = p.listOfProductVariantParameter.filter(pp => pp.name.toLowerCase() == "metal");
      let size = p.listOfProductVariantParameter.filter(pp => pp.name.toLowerCase() == "size");
      let existing = [];
      list.forEach(l => {
        if (l.productSKU.toLowerCase() == p.productSKU.toLowerCase()) {
          if (metal.length > 0) {
            if (l.metal == metal[0].value) {
              existing.push(l);
            } else {
              existing = [];
            }
          } else {
            existing = [];
          }
        } else {
          existing = [];
        }
      });
      // console.log("existing ", existing, p);
      if (existing.length > 0 && size.length > 0) {
        existing[0]["'" + size[0].value] = p.quantity + "-" + p.unitPrice;
      } else {
        let obj = { productSKU: p.productSKU, metal: metal.length > 0 ? metal[0].value : "", description: p.description };
        sizes.forEach(s => {
          if (size.length > 0 && size[0].value == s.value) {
            let v = "";
            if (!!p.discount && p.discount != 0) {
              //v = p.quantity + "-" + "(" + p.discount + ")";
              v = p.quantity;
            } else {
              //v = p.quantity + "-(0)";
              v = p.quantity;
            }
            obj["'" + s.value] = v;
          } else {
            obj["'" + s.value] = "";
          }
        });
        list.push(obj);
      }
    });
    console.log("downloadExcel ", metals, sizes, list);

    this.JSONToCSVConvertor(list, "Order Id:#" + this.orderNo + "_" + moment(new Date(this.addedOn).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm:ss"), "report");
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    console.log("arrData ", arrData);
    var CSV = "";
    CSV += "Order Id:#" + "," + this.orderNo + "\r\n";
    if (!!this.companyName) {
      CSV += "Customer Name:" + "," + this.companyName + "\r\n";
    }
    if (!!this.companyName) {
      CSV += "Contact Name:" + "," + this.customerName + "-" + this.mobileNo + "\r\n\n\n";
    }
    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }
    CSV += "\n\n" + "Total Tax:" + "," + parseFloat(this.orderTotalTax.toString()).toFixed(2) + "\r\n";
    CSV += "Total:" + "," + parseFloat(this.orderTotal).toFixed(2) + "\r\n";

    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    console.log("CSV ", CSV);
    //Generate a file name
    var fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async getCompanyDetail() {
    await this.httpApiService.showLoading();
    let res: any;
    res = await this.httpApiService.getCompanyDetailById(this.refCompanyId);
    console.log("company detail ", res);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.companyDetail = res.json().data;
      if (this.companyDetail.length > 0 && this.companyDetail[0].companyRes) {
        this.pauseOrders = this.companyDetail[0].companyRes.pauseOrders;
        this.pauseOrderMessage = this.companyDetail[0].companyRes.pauseOrderMessage;
        console.log("pauseOrders ", this.pauseOrders, " pauseOrderMessage ", this.pauseOrderMessage);
      }
    }
  }

  async pauseResumeOrders() {
    console.log("pause resume orders ", this.refCompanyId, this.pauseOrders, this.pauseOrderMessage);
    let object = {
      id: this.refCompanyId,
      pauseOrders: this.pauseOrders,
      message: this.pauseOrderMessage
    };
    let res: any;
    await this.httpApiService.showLoading();
    console.log(object);
    res = await this.httpApiService.pauseResumeOrders(object);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      await this.getCompanyDetail();
    }
  }
}
