import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-manage-shop-customer",
  templateUrl: "./manage-shop-customer.page.html",
  styleUrls: ["./manage-shop-customer.page.scss"]
})
export class ManageShopCustomerPage implements OnInit {
  public isModal: any;
  public customer = "";
  public item: any;
  public customerId: any;
  public contactId: any;
  public newCustomerName = "";
  public address = "";
  public category = "";
  public city = "";
  public state = "";
  public zipCode = "";
  public pinCodePattern = "";
  public segment = "";
  public gstNo = "";
  public lang = "";
  public lat = "";
  public contactNo2 = "";
  public userPinCode = "";
  public designation: any = "";
  public email = "";
  public username = "";
  public incorrectZipCode: boolean;
  public newContactName = "";
  public contactAddressID: any;
  public defaultAddress: boolean;
  public designationList: any;
  public house = "";
  public country = "";
  public area = "";
  public allCustomerTags: any;
  public selectedTags: any = [];
  public fullName = "";
  public phoneNo = "";
  public allowAppAccess: any;
  public createSameWorkArea: any = false;
  public code = "";
  public assignWorkArea: any = false;
  public customerNameWorkArea: any;
  public emailInAddress = "";
  public selectedWorkAreas: any = [];
  public getAllWorkArea: any;
  public offset = 0;
  public limit = 10;
  public getAllCustomersData = [];
  public arrowKeyLocation = -1;
  public selectCompanyId = 0;
  public outstanding: any;
  public creditLimit: any;

  constructor(
    private modalCtrl: ModalController,
    public storage: Storage,
    private navParams: NavParams,
    public ConfigService: ConfigService,
    public authService: AuthenticationService,
    private toastCtrl: ToastController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public events: Events
  ) {
    this.events.subscribe("selected-values", data => {
      this.selectedTags = data;
      this.selectedWorkAreas = [];
      if (data.length > 0) {
        data.forEach(element => {
          this.selectedWorkAreas.push(element);
        });
      }
      console.log("this.selectedWorkAreas", this.selectedWorkAreas);
    });
  }

  async ngOnInit() {
    this.isModal = this.navParams.get("flagModal");
    this.customerId = this.navParams.get("id");
    this.customer = this.navParams.get("customerName");
    this.item = this.navParams.get("item");
    console.log(this.isModal, this.item, this.customer, this.customerId);
    if (this.isModal == "addContactAddress") {
      this.contactId = this.item;
    } else if (this.isModal == "updateContactAddress") {
      this.contactAddressID = this.item.id;
      this.defaultAddress = this.item.defaultAddress;
      this.contactId = this.item.refUserId;
      this.house = this.item.house;
      this.area = this.item.area;
      this.zipCode = this.item.pinCode;
      this.city = this.item.city;
      this.state = this.item.state;
      this.country = this.item.country;
      this.fullName = this.item.fullName;
      this.phoneNo = this.item.phoneNo;
      this.emailInAddress = this.item.email;
    } else if (this.isModal == "addTags") {
      let tags = this.navParams.get("data");
      if (tags.length > 0) {
        tags.forEach(tag => {
          this.selectedTags.push({ type: "Customer", name: tag.name, id: tag.tagId });
        });
      }
      console.log("selected tags", this.selectedTags);
      await this.getAllCustomerTags();
    } else if (this.isModal == "addCustomerContact") {
      this.getAllCustomerNameWorkArea();
    } else if (this.isModal == "addWorkAreas") {
      let workAreas = this.navParams.get("data");
      if (workAreas.length > 0) {
        workAreas.forEach(wa => {
          this.selectedWorkAreas.push({ type: "Customer", name: wa.name, id: wa.id });
        });
      }
      console.log("selected work areas", this.selectedWorkAreas);
      await this.getWorkArea();
    } else if (this.isModal == "setCompany") {
    }
    await this.getAllDesignation();
  }

  async findAreaDetailByZipCode(event) {
    let zipCode = event.target.value;
    // await this.httpApiService.showLoading();
    let zipRes = await this.httpApiService.getAllAreaDetailByPinCode(zipCode);
    // await this.httpApiService.hideLoading();
    if (zipRes.status == 401) {
      this.authService.logout();
    } else {
      let data = zipRes.json().data;
      console.log(data);
      if (data) {
        this.city = data[0].city;
        this.state = data[0].state;
        this.incorrectZipCode = false;
      } else {
        this.incorrectZipCode = true;
      }
    }
  }

  async getAllDesignation() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllDesignation(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let desData = res.json().data;
      console.log(desData);
      this.designationList = desData;
    }
  }

  async addCustomer() {
    console.log("add customer", this.customerId, this.newCustomerName, this.code, this.city, this.state, this.address, this.zipCode, this.lang, this.lat, this.gstNo, this.category, this.segment, this.createSameWorkArea);
    let validate = await this.validateControls("addCustomer");
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj: any = {
        id: refCompanyId,
        companyName: this.newCustomerName,
        code: this.code,
        city: this.city,
        state: this.state,
        address: this.address,
        zipCode: this.zipCode,
        lang: this.lang,
        lat: this.lat,
        gstNo: this.gstNo,
        category: this.category,
        segment: this.segment,
        pinCodePattern: this.pinCodePattern
      };

      if (this.outstanding != null && this.outstanding != undefined && this.outstanding.toString().length > 0) {
        jsonObj.outstandingAmount = parseInt(this.outstanding);
        jsonObj.outstandingLastUpdatedDate = new Date();
      }
      if (this.creditLimit != null && this.creditLimit != undefined && this.creditLimit.toString().length > 0) {
        jsonObj.creditLimit = parseInt(this.creditLimit);
        jsonObj.creditLimitLastUpdatedDate = new Date();
      }

      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createCustomer(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          if (this.createSameWorkArea) {
            let workAreaRes: any;
            let workAreaJsonObj = {
              name: this.newCustomerName,
              id: refCompanyId
            };
            workAreaRes = await this.httpApiService.addWorkArea(workAreaJsonObj, refCompanyId);
            console.log("workAreaRes ", workAreaRes);
          }
          let toast = await this.toastCtrl.create({ message: "Customer created successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
          this.reset("customer");
        }
      }
    }
  }

  async addContact() {
    console.log("add contact", this.customerId, this.newContactName, this.designation, this.username, this.contactNo2, this.email);
    let validate = await this.validateControls("updateContact");
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let allowAppAccessVar: any;
      if (this.allowAppAccess == true) {
        allowAppAccessVar = 1;
      } else if (this.allowAppAccess == false) {
        allowAppAccessVar = 0;
      } else if (this.allowAppAccess == null) {
        allowAppAccessVar = null;
      }
      let jsonObj = { id: refCompanyId, customerId: this.customerId, name: this.newContactName, designationId: this.designation.toString(), contactNo: this.username, contactNo2: this.contactNo2, email: this.email, allowAppAccess: allowAppAccessVar, pincode: this.userPinCode };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createCustomerContact(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          this.reset("contact");
          let toast = await this.toastCtrl.create({ message: "Contact added successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
          if (this.assignWorkArea == true && !!this.customerNameWorkArea && this.customerNameWorkArea.length > 0) {
            let assignedWorkAreaRes: any;
            let areaList = [];
            areaList.push(this.customerNameWorkArea[0].id);
            let assignWorkAreaJsonObj = {
              userId: data.data.refUserId,
              areaIdsList: areaList
            };
            assignedWorkAreaRes = await this.httpApiService.createWorkAreaForUser(assignWorkAreaJsonObj, refCompanyId);
            if (assignedWorkAreaRes.status == 401) {
              this.authService.logout();
            } else {
              console.log("assignedWorkAreaRes ", assignedWorkAreaRes);
            }
          }
        }
      }
    }
  }

  async addContactAddress() {
    console.log("add contact address", this.contactId, this.house, this.area, this.city, this.state, this.zipCode, this.country, this.defaultAddress, this.fullName, this.phoneNo, this.emailInAddress);
    let validate = await this.validateControls("addContactAddress");
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      if (this.defaultAddress) {
        this.defaultAddress = true;
      } else {
        this.defaultAddress = false;
      }
      let jsonObj = {
        id: refCompanyId,
        CCId: this.contactId,
        house: this.house,
        area: this.area,
        pinCode: parseInt(this.zipCode),
        city: this.city,
        state: this.state,
        country: this.country,
        defaultAddress: this.defaultAddress,
        fullName: this.fullName,
        phoneNo: this.phoneNo,
        email: this.emailInAddress
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createCustomerAddress(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          this.reset("contact");
          let toast = await this.toastCtrl.create({ message: "Contact address added successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async updateContactAddress() {
    console.log("update contact address", this.customerId, this.contactId, this.house, this.area, this.city, this.state, this.zipCode, this.country, this.defaultAddress, this.fullName, this.phoneNo);
    let validate = await this.validateControls("updateContactAddress");
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      if (this.defaultAddress) {
        this.defaultAddress = true;
      } else {
        this.defaultAddress = false;
      }
      let jsonObj = {
        id: refCompanyId,
        CaID: this.contactAddressID,
        CCId: this.contactId,
        house: this.house,
        area: this.area,
        pinCode: parseInt(this.zipCode),
        city: this.city,
        state: this.state,
        country: this.country,
        defaultAddress: this.defaultAddress,
        fullName: this.fullName,
        phoneNo: this.phoneNo,
        email: this.emailInAddress
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateCustomerAddress(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({ message: "Contact address updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async reset(customerContact) {
    if (customerContact == "customer") {
      this.newCustomerName = "";
      this.code = "";
      this.address = "";
      this.category = "";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.segment = "";
      this.gstNo = "";
      this.lang = "";
      this.lat = "";
      this.emailInAddress = "";
      this.creditLimit = "";
      this.outstanding = "";
    } else {
      this.newContactName = "";
      this.username = "";
      this.contactNo2 = "";
      this.designation = "";
      this.email = "";
      this.house = "";
      this.area = "";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.country = "";
      this.fullName = "";
      this.phoneNo = "";
      this.emailInAddress = "";
    }
  }
  async validateControls(value) {
    if (value == "addCustomer") {
      if (this.newCustomerName != " " && this.address != "" && this.zipCode != "" && !this.incorrectZipCode && this.city != "" && this.state != "") {
        return false;
      } else {
        if (this.incorrectZipCode) {
          let toast = await this.toastCtrl.create({ message: "Please enter correct zipCode", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        } else {
          let toast = await this.toastCtrl.create({ message: "Please insert the details", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        }
      }
    } else if (value == "addContactAddress" || value == "updateContactAddress") {
      if (this.house != " " && this.area != "" && this.zipCode != "" && !this.incorrectZipCode && this.city != "" && this.state != "" && this.country != "" && this.fullName != "" && this.phoneNo != "") {
        return false;
      } else {
        if (this.incorrectZipCode) {
          let toast = await this.toastCtrl.create({ message: "Please enter correct zipCode", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        } else {
          let toast = await this.toastCtrl.create({ message: "Please insert the details", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        }
      }
    } else {
      if (this.newContactName != "" && this.username != "" && this.username.length == 12 && this.designation != "") {
        if (this.contactNo2 != "" && this.contactNo2.length == 12) {
          return false;
        } else if (this.contactNo2 != "" && this.contactNo2.length < 12) {
          let toast = await this.toastCtrl.create({ message: "Please enter 12 digit contact number", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        } else {
          return false;
        }
      } else {
        if (this.username.length < 12) {
          let toast = await this.toastCtrl.create({ message: "Please enter 12 digit contact number", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        } else if (this.designation == "") {
          let toast = await this.toastCtrl.create({ message: "Please select designation", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        } else {
          let toast = await this.toastCtrl.create({ message: "Please insert the details", duration: 2000, position: "top", color: "danger" });
          await toast.present();
          return true;
        }
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss(this.isModal);
  }

  async getAllCustomerTags() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllTagsByType(refCompanyId, "Customer");
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.allCustomerTags = resp;
        console.log("this.allCustomerTags", this.allCustomerTags);
      }
    }
  }

  async addMultipleTagsToCustomer() {
    console.log("selected tags", this.selectedTags);
    let tagIDList = [];
    if (this.selectedTags.length > 0) {
      this.selectedTags.forEach(tag => {
        tagIDList.push(tag.id);
      });
    }
    console.log("tag id list", tagIDList);
    let refCompanyId = await this.storage.get("refCompanyId");
    let jsonObj = {
      id: refCompanyId,
      userID: this.item,
      tagIDList: tagIDList
    };
    console.log(jsonObj);
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.addMultipleTagsToCustomer(jsonObj);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Customer tags added successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async getAllCustomerNameWorkArea() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    // await this.httpApiService.showLoading();
    res = await this.httpApiService.getWorkAreaByName(refCompanyId, this.customer);
    // await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.customerNameWorkArea = res.json().data;
      console.log("customerNameWorkArea ", this.customerNameWorkArea);
    }
  }
  async getWorkArea() {
    let res: any;
    await this.httpApiService.showLoading();
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllWorkArea(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      if (res.json().isSuccess) {
        this.getAllWorkArea = res.json().data;
      } else {
        this.getAllWorkArea = [];
      }
      console.log("get All work area", this.getAllWorkArea);
    }
  }

  async createWorkArea() {
    let areaList = [];
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.selectedWorkAreas.forEach(area => {
      areaList.push(area.id);
    });
    let jsonObj = {
      customerId: this.customerId,
      areaIdsList: areaList
    };
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.createWorkAreaForCustomer(jsonObj, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.modalCtrl.dismiss();
          this.authService.logout();
        } else {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        }
      } else {
        let toast = await this.toastCtrl.create({
          message: "Work Area added successfully",
          duration: 3000,
          position: "top",
          color: "success"
        });
        await toast.present();
        // await this.closeModal();
      }
    }
  }

  async getCustomerByPagination(val) {
    let refCompanyId = await this.storage.get("refCompanyId");

    let res = await this.httpApiService.fetchCustomerByPagination(this.offset, this.limit, val, refCompanyId);
    console.log("res", res);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllCustomersData = res.json().data;
      console.log("get all customer data", this.getAllCustomersData);
      this.arrowKeyLocation = -1;
    }
  }

  async setParent() {
    let refCompanyId = await this.storage.get("refCompanyId");

    // let jsonObj: any = {
    //   id: refCompanyId,
    //   customerId: this.customerId,
    //   refParentCustomerId: this.selectCompanyId
    // }
    // console.log(jsonObj)

    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.setParentCustomer(refCompanyId, this.customerId, this.selectCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Customer Parent added successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async changeCompany() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.changeUserCompany(refCompanyId, this.item, this.selectCompanyId, this.navParams.get("oldId"));
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Customer tags added successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async getCustomerDetail(val) {
    this.selectCompanyId = val.id;
  }

  async filterItems(ev: any) {
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      await this.getCustomerByPagination(val);
    } else {
      this.arrowKeyLocation = -1;
      this.getAllCustomersData;
    }
  }
}
